import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import JobExecutionCard from './JobExecutionCard';
import Header from './Heading';
import { Input } from '../../../../components/genericComponents/Input';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { GetAuth } from '../../../../util/security';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Logsview from './Logsview';
import { Resizable, ResizableBox } from 'react-resizable';
import ViewLogsInfiniteScroll1, { getNewLogTab } from '../../../../views/service/listing/components/ViewLogsInfiniteScroll1'
import AlertStrip from '../../../../components/AlertStrips';
import { Dialog } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import StageCard from '../../../pipeline/execution/components/StageCard';
import SlidingDiv from '../../../pipeline/execution/components/SlidingDiv';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const approval = [
    {
        value: 1,
        label: "Approved"
    },
    {
        value: 2,
        label: "Disapproved"
    }
]

const yes_no_list = [
    {
        value: "yes",
        label: "Yes"
    },
    {
        value: "no",
        label: "No"
    }
]



const ExecutionCard = (props) => {
    const classes = useStyles();
    const pipeline_instance = props.pipeline_instance;
    const context_params = pipeline_instance && pipeline_instance.context_parameter ? pipeline_instance.context_parameter : null;
    const application_id = props.application_id;

    const [state, setState] = useState({
        loaded: false,
        data: { approved: 1, comment: "All Good", update_context_param: "no", context_param: null },
        error: {},
        selected_tab: 1,
        logs: "",
        task_id: "",
        infiniteScrollInherits: {},
        childInherits: {},
        counter: 1,
        api_hit_counter: 1
    });
    const [open, setOpen] = useState(false)
    const requestInstanceData = props.requestInstanceData;
    const classesDialog = useStylesDialog();

    function showApprovalForm(stage_instance_id) {
        setState({
            ...state,
            show_approval_form: true,
            stage_instance_id: stage_instance_id
        })
    }

    function showResumeForm(stage_instance_id) {
        setState({
            ...state,
            show_resume_form: true,
            stage_instance_id: stage_instance_id
        })
    }

    function hideApprovalForm() {
        setState({
            ...state,
            show_approval_form: false,
        })
    }

    function hideResumeForm() {
        setState({
            ...state,
            show_resume_form: false,
        })
    }

    function onChangeHandler(e) {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    const rerunPipeline = (data) => {
console.log(data,"rerrunfdsf")
        var url = GenerateURL({
            pipeline_id: pipeline_instance.pipeline.id,
            trigger_id: pipeline_instance.id,
        }, properties.api.rerun_job_after_failure);
        
        PostData(url, data, handleSuccessContinuePipelineFailure, handleContinueFailed);
    }

    const continuePipelineFailure = (data) => {
        var url = GenerateURL({
            pipeline_id: pipeline_instance.pipeline.id,
            pipeline_instance_id: pipeline_instance.id,
        }, properties.api.after_failure_resume);

        PostData(url, data, handleSuccessContinuePipelineFailure, handleContinueFailed);
    }


    function handleSuccessContinuePipelineFailure(data) {
        var success_msg = data && data.detail ? data.detail : "Pipeline resume successfully"
        requestInstanceData(data, state.counter);
        setState(new_state => ({
            ...new_state,
            success_msg: success_msg,
            trigger: "success",
        }));
    }

    function handleContinueFailed(error) {
        var error_msg_obj = error && error.detail ? error.detail : error.toString();
        console.log(error_msg_obj, "error_msg_objerror_msg_obj")
        setState(new_state => ({
            ...new_state,
            error_msg: error_msg_obj,
            trigger: "failed"
        }));
    }



    function postApprovalRequest(data, stage_instance_id) {

        var url = GenerateURL({
            pipeline_id: pipeline_instance.pipeline.id,
            pipeline_instance_id: pipeline_instance.id,
            stage_instance_id: stage_instance_id
        }, properties.api.approve_pipeline);
        console.log(data,"fdsafsa")
        PostData(url, data, handleSuccesApproved, handleFailedApproved);
        hideApprovalForm();
    }

    function postResumeRequest() {
        var url = GenerateURL({
            pipeline_id: pipeline_instance.pipeline.id,
            pipeline_instance_id: pipeline_instance.id,
        }, properties.api.resume_pipeline);

        try {
            var context_param = JSON.parse(state.data.context_param);
            var data = {
                update_context_param: state.data.update_context_param == "yes",
                context_param: state.data.context_param
            }
            PostData(url, data, handleSuccesApproved, handleFailedApproved);
            hideResumeForm();
        } catch (error) {
            setState({
                ...state,
                error: {
                    ...state.error,
                    context_param: "Invalid Json",
                }
            })
        }

    }

    function handleSuccesApproved(data) {

        requestInstanceData();
    }

    function handleFailedApproved(error) {
        console.log("error-in-approval",error);
        setState(new_state => ({
            ...new_state,
            error_msg: error?.detail ? error.detail : error
        }));
    }


    function showLogs(component_task_instance_id, path_arr, global_task_id) {
        if (state.childInherits.onLogIconClickFunction) {
            state.childInherits.onLogIconClickFunction(component_task_instance_id)
        }
        setState(new_state => ({
            ...new_state,
            selected_component_id: component_task_instance_id,
            logMetadata: {
                ...state.logMetadata,
                [component_task_instance_id]: {
                    path_arr: path_arr,
                    task_id: global_task_id,
                    show_breadcrumb: true
                }
            }
        }));
    }

    function closeLogs() {
        setState({
            ...state,
            logMetadata: null
        });
    }

    function closeTab(tabId) {
        var tabIds = Object.keys(state.logMetadata);
        if (tabIds.length == 1) {
            closeLogs();
            return
        }
        const index = tabIds.indexOf(tabId);

        var nextIndex = -1;
        if (index != tabIds.length - 1) {
            nextIndex = index + 1
        } else {
            nextIndex = index - 1
        }



        if (index > -1) {
            tabIds.splice(index, 1);
        }

        var newTabsData = {}
        tabIds.forEach(tabId => {
            newTabsData[tabId] = state.logMetadata[tabId]
        })

        if (state.childInherits.onLogIconClickFunction) {
            state.childInherits.onLogIconClickFunction(tabIds[nextIndex])
        }

        setState(new_state => ({
            ...new_state,
            logMetadata: newTabsData,
            selected_tab_id: tabIds[nextIndex]
        }))

    }

    function getFullScreenElement() {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullscreenElement
            || document.msFullscreenElement
    }
    function toggleFullScreen() {
        if (getFullScreenElement()) {
            document.exitFullscreen();
        } else {
            document.getElementById("viewFullScreenCanvas").requestFullscreen().catch(console.log)
        }
    }
    function showExecutionDialog() {
        setOpen(true)
    }
    function closeExecutionDialog() {
        setOpen(false)
    }
    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }
    console.log(context_params,"context_params")
    return (
        <>
            <MainDiv>
                <Header application_id={application_id} pipeline={pipeline_instance.pipeline} pipeline_instance={pipeline_instance} />
                <div className='ml-auto'>
                    <button className='btn btn-primary' onClick={showExecutionDialog}>View  Run Parameters</button>
                    {/* <button onClick={toggleFullScreen} className="btn-icon-square bg-purple-text-white"><span className="flaticon-switch-to-full-screen-button"></span></button> */}
                </div>
            </MainDiv>
            {
                state.error_msg ?
                    <div className="" style={{ margin: '0px 20px' }}>
                        <AlertStrip message={state.error_msg ? state.error_msg : "Something went worng"} variant={"error"} />
                    </div>
                    : null
            }
            {
                state.show_approval_form ?
                    <ApprovalDiv>
                        <Input
                            type="radio"
                            name="approved"
                            list={approval}
                            error={{}}
                            onChangeHandler={onChangeHandler}
                            data={state.data} />
                        <Input
                            type="text"
                            placeholder="Leave Comment"
                            name="comment"
                            error={{}}
                            onChangeHandler={onChangeHandler}
                            data={state.data} />


                        <div className="btn-section">
                            <button onClick={hideApprovalForm} className="btn-grey-outline">Dismiss</button>
                            <button className="btn-blue" onClick={postApprovalRequest} style={{ border: 'none' }}>Submit</button>
                        </div>
                    </ApprovalDiv> : null
            }
            {
                state.show_resume_form ?
                    <ApprovalDiv>
                        <Input
                            type="radio"
                            name="update_context_param"
                            label="Update Context params"
                            list={yes_no_list}
                            error={{}}
                            onChangeHandler={onChangeHandler}
                            data={state.data} />
                        <Input
                            type="text"
                            placeholder="Context params"
                            name="context_param"
                            error={state.error}
                            onChangeHandler={onChangeHandler}
                            data={state.data} />


                        <div className="btn-section">
                            <button onClick={hideResumeForm} className="btn-grey-outline">Dismiss</button>
                            <button className="btn-blue" onClick={postResumeRequest} style={{ border: 'none' }}>Submit</button>
                        </div>
                    </ApprovalDiv> : null
            }
            <MainCard>

                <ExecutionSection
                    showLogs={showLogs}
                    postFinalData={postApprovalRequest}
                    show_approval_form={showApprovalForm}
                    show_resume_form={showResumeForm}
                    pipeline_instance={pipeline_instance}
                    continuePipelineFailure={continuePipelineFailure}
                    rerunPipeline={rerunPipeline}
                    counter={state.counter}
                />

                {
                    state.logMetadata ?

                        <ViewLogsInfiniteScroll1 selected_tab_id={state.selected_component_id} closeLogs={closeLogs} tabsData={state.logMetadata} closeTab={closeTab} childInherits={state.childInherits} />
                        : null
                }

                {/* {
                state.view_logs ? 
                
                <ViewLogsInfiniteScroll1 selected_tab_id={state.selected_tab_id} closeLogs={closeLogs} closeTab={closeTab} changeTab={changeTab} tabsData={state.logMetadata} childInherits={state.infiniteScrollInherits} />
                :null
            } */}
            </MainCard>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeExecutionDialog}
                className={classesDialog.dialog_root + " " + " integrations-dialog pipeline-view-params-dialog"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description" >
                <div className="fill-details-head d-flex align-center space-between"
                    style={{ backgroundColor: 'rgb(18, 77, 155)', padding: 14 }}>
                    <p className='color-white'>View Run Parameters</p>
                    <IconButton edge="start" color="inherit" onClick={closeExecutionDialog} aria-label="close">
                        <CloseIcon className="color-white" fontSize="large" />
                    </IconButton>
                </div>
                <div className="pd-10">
                    <div className='card custom-card-exec-paramsn m-10'>
                        <div className='card-header'>
                            <p>
                                <span>Env Type</span>
                                <span>Env Name</span>
                                <span>Service Name</span>
                                <span>Branch Name</span>
                                <span>Deployment Tag</span>
                            </p>
                        </div>
                        <div className='card-body'>
                            
                            <ServiceHierarchy data={context_params} />
                        </div>
                    </div>
                </div>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.trigger == "success" || state.trigger == "failed"}
                onClose={handleClose}
                autoHideDuration={6000}>
                {
                    state.trigger == "success" ?
                        <>
                            <Alert severity="success">
                                {state.success_msg}
                            </Alert>
                        </> : state.trigger == "failed" ?
                            <Alert severity="error">
                                {state.error_msg}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

export default ExecutionCard;

export const ServiceHierarchy = ({ data, all_services, variant }) => {
    console.log(data, all_services, variant, 'dg_kssd_dddd')

    var service_data = data && data.service_branch ? data.service_branch : null
    console.log(service_data, 'srv_dcv')
    var reorderedData = {
        "dev": service_data && service_data.dev ? service_data.dev : null,
        "qa": service_data && service_data.qa ? service_data.qa : null,
        "staging": service_data && service_data.staging ? service_data.staging : null,
        "uat": service_data && service_data.uat ? service_data.uat : null,
        "prod": service_data && service_data.prod ? service_data.prod : null
    };
    function renderEnvironments() {
        const environments = reorderedData != {} && reorderedData != null ? Object.entries(reorderedData) : [];
        console.log(environments,"environmentsenvironments")
        if (environments.some(([_, env]) => env !== null)) {
            return environments.map(([envKey, env]) => (
                envKey && env !== null ?
                    <div key={envKey} className='service-and-branch-listing'>
                        <div className='ul-styles'>{renderSubEnvironments(env, envKey)}</div>
                    </div> : null
            ));
        } else {

            return <p className='font-12 pd-10 text-center'>Run parameters are not available for the pipeline</p>
        }
    }

    function renderSubEnvironments(subEnvs, envKey) {
        { console.log(subEnvs, "fdsafdsafsafads") }
        return subEnvs ? Object.keys(subEnvs).map((subEnv) => (

            <>
                {console.log(subEnvs[subEnv], "fdsafdsafsafads")}
                {
                    subEnvs[subEnv] ?
                        renderServices(subEnvs[subEnv], subEnv, envKey)
                        : null
                }
            </>
        )) : null;
    }
    function renderServices(services, subEnv, envKey) {
        return Object.keys(services).map((service) => {
            return (
                <>
                    <div key={subEnv} className='sub-env-row'>
                        <span className={getClassName(envKey)}>{envKey}</span>
                        <span>{subEnv}</span>
                        <span>{service}</span>
                        <span>{services[service].branch}</span>
                        <span>{services[service].deployment_tag ? services[service].deployment_tag : "N/A"}</span>
                    </div>

                </>
            )
        }
        )

    }

    return (
        <div>
            {renderEnvironments()}
        </div>
    );
}



const getClassName = (data) => {
    switch (data) {
        case "dev":
            return "chip-sq dev-bg";
        case "qa":
            return "chip-sq qa-bg";
        case "staging":
            return "chip-sq stagin-bg";
        case "uat":
            return "chip-sq uat-bg";
        case "prod":
            return "chip-sq dev-bg";

    }
}

const ExecutionSection = (props) => {
    const pipeline_instance = props.pipeline_instance;
    const counter = props.counter;
    const pipeline = pipeline_instance.pipeline;
    const show_approval_form = props.show_approval_form;
    const show_resume_form = props.show_resume_form;
    const showLogs = props.showLogs;
    const postFinalData = props.postFinalData
    const stage_object_with_ids = getStageObjectWithIds(pipeline.stages, pipeline_instance.stage_instance);
    console.log(pipeline, "fdsajjkas")
    const continuePipelineFailure = props.continuePipelineFailure;
    const rerunPipeline = props.rerunPipeline
    function getFullScreenElement() {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullscreenElement
            || document.msFullscreenElement
    }
    function toggleFullScreen() {
        if (getFullScreenElement()) {
            document.exitFullscreen();
        } else {
            document.getElementById("viewFullScreenCanvas").requestFullscreen().catch(console.log)
        }
    }


    return (
        <>
            <ExecutionView id="viewFullScreenCanvas">

                {
                    Object.keys(stage_object_with_ids).map((key, index) => (
                        <>
                            {
                                index != 0 ?
                                    <div class="circular-arrow"><span class="flaticon-right-arrow-forward"></span></div>
                                    : null
                            }
                            <div>
                                <div
                                    className="main-vertical-div-pipeline"
                                    style={{ position: 'relative' }}

                                >
                                    <StageCard
                                        stage={stage_object_with_ids[key]}
                                        count={index + 1}
                                        postFinalData={postFinalData}
                                        pipeline_id={pipeline.id}
                                        pipeline_instance_id={pipeline_instance.id}
                                        show_approval_form={show_approval_form}
                                        show_resume_form={show_resume_form} />
                                    {
                                        stage_object_with_ids[key].tasks.map(task => (
                                            <>
                                                <JobExecutionCard
                                                    stage={stage_object_with_ids[key]}
                                                    showLogs={showLogs}
                                                    pipeline_id={pipeline.id}
                                                    counter={counter}
                                                    pipeline_instance_id={pipeline_instance.id}
                                                    stage_instance_id={
                                                        stage_object_with_ids[key].instance ?
                                                            stage_object_with_ids[key].instance.id
                                                            : null
                                                    }
                                                    task={task}
                                                    version={pipeline.version === 'v3' ? 'v3' : 'v2'} />
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    ))
                }
                <button onClick={toggleFullScreen} style={{ position: 'fixed', bottom: '2rem', right: '1rem' }} className="btn-icon-square bg-purple-text-white exeview"><span className="flaticon-full-screen-exit"></span></button>


            </ExecutionView>
            
            <button onClick={toggleFullScreen} className="btn-icon-square bg-purple-text-white" style={{position:'absolute',bottom:'1rem',right:'1rem'}}><span className="flaticon-switch-to-full-screen-button"></span></button>
            {pipeline.version !== 'v3' && 
                <SlidingDiv
                    data={pipeline_instance.stage_instance}
                    pipeline_post_data={pipeline}
                    all_stages= {pipeline.stages}
                    pipeline_instance_id={pipeline_instance.id}
                    pipeline_id={pipeline_instance.pipeline.id}
                    pipeline_data={pipeline}
                    postFinalData={postFinalData}
                    onHandleOpenFillQuestions={()=>{}}
                    continuePipelineFailure={continuePipelineFailure}
                    rerunPipeline={rerunPipeline}
                    totalServices={pipeline && pipeline.components ? pipeline.components : null}
                />
            }
            
            
        </>
    );
}

export function getStageObjectWithIds(stages, stage_instances) {
    const stage_object_with_ids = {};
    stages.forEach(stage => {
        stage.task_with_ids = {}
        stage.tasks.forEach(task => {
            stage.task_with_ids[task.id] = task;
        });

        stage_object_with_ids[stage.id] = stage;
    })

    stage_instances.forEach(instance => {

        instance.task_instance.forEach(task => {
            stage_object_with_ids[instance.stage_id].task_with_ids[task.task_id].instance = task
        });

        stage_object_with_ids[instance.stage_id].instance = instance;
    })

    return stage_object_with_ids;


}


const MainCard = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15)',
    margin: '0px 20px 0px',
    height: '70vh',
    position: 'relative',
    '& .react-resizable-handle-se': {
        cursor: 'row-resize'
    },
    '& .react-resizable-handle': {
        position: 'absolute',
        width: '20px',
        height: '0px',
        top: '99%',
        bottom: '0!important',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        boxSizing: 'border-box',
        backgroundPosition: ' bottom right',
        padding: '0 3px 3px 0',
        left: '50%',
        '&:before': {
            content: "",
            borderBottom: '1px solid #000',
            position: 'absolute',
            borderLeft: 'none',
            top: '50%',
            transform: 'translateY(-125%)',
            right: 0,
            display: 'inline-block',
            width: '20px',
            margin: '2px 0px'
        },
        '&:after': {
            content: "",
            borderBottom: '1px solid #000',
            position: 'absolute',
            top: '50%',
            borderLeft: 'none',
            transform: 'translateY(-125%)',
            right: 0,
            display: 'inline-block',
            width: '20px',
            margin: '2px 0px'
        }
    },

})
const MainCardHeader = styled('div')({
    padding: '15px',
    display: 'flex',
    alignItems: 'center'
})
const MainCardBodyFullWidth = styled('div')({

})
const ExecutionView = styled('div')({
    backgroundImage: 'url(/images/bg-pipeline-view.png)',
    backgroundSize: '1%',
    position: 'relative',
    borderRadius: '8px',
    display: 'flex',
    overflowY: 'scroll',
    height: '100%',
    padding: '20px 30px',
    '&:fullscreen': {
        backgroundImage: 'url(/images/bg-pipeline-view.png)',
        backgroundSize: '1%',
        overflow: 'scroll'
    },
    '& .btn-icon': {
        backgroundColor: '#6b74a8',
        '& .flaticon-switch-to-full-screen-button': {
            color: '#fff'
        }
    },
    '& .job-one': {
        height: '7rem;',
    },
    '& .vertical-border-pipeline-1': {
        height: 'calc(100% - 33.5rem);',
        width: '3px',
        backgroundColor: '#666',
        position: 'absolute',
        top: '3rem',
        left: '3rem'
    },
    '& height-fixed': {

    },
    '& button.exeview': {
        display: 'none',
    },
    '&#viewFullScreenCanvas:fullscreen button.exeview': {
        display: 'block'
    }
})
const HeadingSection = styled('div')({
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    border: '1px solid #ebedee',
    borderLeft: 'none',
    borderRight: 'none',
    fontSize: '14px',
    color: '#2b2b2b',
    borderRadius: '8px 8px 0px 0px',
    width: '100%',
    lineHeight: 2,
    '& .ml-auto': {
        marginLeft: 'auto',
    }
})
const LogsView = styled('div')({
    backgroundColor: '#000',
    color: '#fff',
    height: '92.5%',
    borderRadius: '0px 0px 8px 8px'
})
const ScrollableView = styled('div')({
    display: 'flex',
})
const ScrollableLogsView = styled('div')({
    display: 'block',
    position: 'relative',
    width: '100%',
    '& .logs-text': {
        fontSize: '13px',
        lineHeight: 1.5,
    },
    '& .btn-icon': {
        position: 'absolute',
        bottom: '5rem',
        right: '5rem'
    }
})
const LogsWorkFlowTree = styled('div')({
    backgroundColor: '#f6f6f6',
})
const GridView = styled('div')({
    display: "grid",
    gridTemplateColumns: '70% 1fr',
    gap: '10px',
    height: '100%',
    padding: '15px',
    overflowY: 'scroll'
})
const HeadingRightPanel = styled('div')({
    fontSize: '12px',
    color: '#000',
    padding: '5px 10px',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #ebedee'
})


const TreeView = styled('div')({
    '& .job-view': {
        color: '#000'
    }
})
const StageView = styled('div')({
    display: 'grid',
    gridTemplateColumns: '10% 1fr',
    color: '#000',
    borderBottom: '1px solid #ebedee',
    '& .flaticon-expand-arrow': {
        color: '#000!important'
    }
})

const ServiceList = styled('div')({
    display: 'grid',
    gridTemplateColumns: '10% 1fr 10%',
    color: '#000',
    borderBottom: '1px solid #ebedee',
    alignItems: 'center',
    paddingLeft: '30px',
    '& .flaticon-expand-arrow': {
        color: '#000!important'
    },

    '& .logs-view': {
        backgroundColor: '#000',
        color: '#0096db',
        padding: '10px'
    }
})
const ApprovalDiv = styled('div')({
    margin: '0px 32px 16px',
    display: 'grid',
    gridTemplateColumns: '18% 63% 18%',
    gap: '10px',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: "10px",
    borderRadius: '8px',
    '& .input-component': {
        marginBottom: '0px'
    },
    '& .btn-section button': {
        margin: '0px 2px'
    }
})
const MainDiv = styled('div')({
    padding: '32px 20px 16px',
    //marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    '& .pipeline-service-data': {
        margin: '0 10px'
    },
    '& .pipeline-tags-data': {
        margin: '0 10px'
    },
    '& .service-name-chip-div .chip-blue': {
        backgroundColor: 'transparent',
        margin: '0px',
        lineHeight: 0
    },
    '& button.btn-icon-square': {
        marginLeft: 'auto'
    }
})


const drawerWidth = 240;

const useStylesDrawer = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));
const useStyles = makeStyles(theme => ({
    stageData: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 10px',
        lineHeight: 1.5,
        '& .main-text': {
            color: '#000',
            fontSize: '13px',
            marginRight: '0px'
        },
        '& .sub-text': {
            color: '#949494',
            fontSize: '13px'
        },
        '& .status-font': {
            fontSize: '12px',
            textTransform: 'uppercase'
        },
        '& .success': {
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol': {
            margin: '0px 0px 0px 2px',
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol:before': {
            fontSize: '14px!important',
            color: '#62E187'
        }
    },
    executionData: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        '& .main-text': {
            color: '#000',
            fontSize: '12px',
            marginRight: '0px'
        },
        '& .sub-text': {
            color: '#949494',
            fontSize: '12px'
        }
    },
    d_Flex: {
        display: 'flex',
        alignItems: 'center',
    }
}))


const useStylesDialog = makeStyles(() => ({
    dialog_root: {
        '& .MuiDialog-paperScrollPaper': {
            width: '100rem',
            backgroundColor: '#fff;'
        },
        '&.integrations-dialog': {
            '.MuiPaper-root': {
                maxWidth: '1000px!important'
            },
        },
        '.execution-params-row': {
            fontSize: '12px',
        }
    },
    dialog: {
        width: '100%',
        '& .card-sub-header': {
            backgroundColor: '#fefefe',
            borderBottom: '1px solid #eaeaea',
            padding: '5px',
            '& .heading-with-icon': {
                color: '#484848',
                fontSize: '12px',
                '& .material-icons': {
                    // fontSize: 
                }
            }
        },
        '& .no-issues-added': {
            width: '300px',
            textAlign: 'center',
            fontSize: '12px',
            margin: 'auto',
            display: 'block',
            padding: '20px'
        },
        '& .sq-chip': {
            backgroundColor: '#626262',
            color: '#fff',
            padding: '1px 3px',
            borderRadius: '4px',
            marginLeft: '3px'
        }
    },
    button_class: {
        fontSize: '12px',
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        },
        '& .small-icon-button': {
            backgroundColor: '#fff',
            marginLeft: '5px',
            border: 'none',
            boxShadow: '0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.2)',
            height: '20px',
            width: '20px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0086ff'
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#bfbfbf',
        backgroundColor: '#ffffff',
        padding: '10px 20px'
    },
    cardBody: {
        // padding: '20px 20px',
        '& ul': {
            '&.headerul': {
                '& li': {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '12px',
                    borderBottom: '1px solid #a3a3a3',
                    color: '#000',
                    '&.active': {
                        color: '#000',
                        borderBottom: '2px solid #124D9B'
                    }
                }
            }
        }
    },
    card_shadow: {
        boxShadow: ' 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        color: '#6e6e6e',
        borderRadius: '25px',
        fontSize: '13px',
        width: '450px',
        height: '50px',
        padding: '15px',
        marginTop: '25px',
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
    },
    button_code: {
        backgroundColor: '#007eff',
        border: 'none',
        color: 'white',
        padding: '11px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '11px',
        margin: '4px 2px',
        cursor: 'pointer',
        float: 'right',
        width: '110px',
        height: '38px',
    },
    title_block: {
        borderBottom: "1px solid #a09e9e", padding: "15px", backgroundColor: '#f4f4f4', display: 'flex', justifyContent: 'space-between',
    },
    condition_block: {
        display: 'flex', alignItems: 'center', borderBottom: "1px solid #a09e9e", borderTop: '1px solid #a09e9e', padding: "8px", backgroundColor: '#f4f4f4', justifyContent: 'space-between',
    }
}));