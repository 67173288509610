import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { getListableArray } from '../../../../util/util';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import AlertStrip from '../../../../components/AlertStrips';
import { getSelectedEnv } from '../../../serviceRevamp/add/cd_flow/CdInfo';
import { Loading } from '../../../utils/Loading';
import { makeStyles } from '@material-ui/core/styles';
import AdvanceSearchFilterCombo from '../../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OnGoingActivity from '../../../superAdmin/activityFramework/OnGoingActivity';
import OverrideVariablesForNonDependentJobs from './OverrideVariablesForNonDependentJobs';
import { Checkbox } from "@material-ui/core";
import { error, relativeLength } from 'highcharts';
import { task_type_list } from './JobForm';


const defaultStateForEnvFormUpdated = (key, value) => {
    return {
        is_value_from_output_var: false,
        key: key,
        value: value,
        outputForm: {
            previous_job: null,
            previous_job_type: null,
            previous_job_service: null,
            services: null,
            step: null,
            output_var: null,
            stage: null,
            task_list: null,
            step_list: null,
            step_order: null,
            output_var_list: null,
            outputVariableMapping: false,
            is_overridden: true
        }
    }
}
export const getServicesForSelectedProject = async (selectedTask, type) => {
    console.log("DJHJSDHJS",selectedTask,type);
    try {
        let url = type == 'v3' ? GenerateURL({ env_id: selectedTask.env, template_id: selectedTask.project_job_template_id, job_code: selectedTask.job_code || selectedTask.task_type, entity_type: selectedTask.job_json.dependent_entity }, properties.api.get_entity_resources) : GenerateURL({ project_env_id: selectedTask.env, job_type: type }, properties.api.get_services_for_projectEnvV2)
        const requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };

        // Using a Promise to wrap the InvokeApi call
        const response = await new Promise((resolve, reject) => {
            InvokeApi(requestInfo, resolve, reject);
        });

        if (response) {
            const result = type == 'v3' ? response : response.results;
            const selectedTaskServices = result.filter(item => 
                selectedTask.components.includes(item.id)
            );
            console.log("DJHJSDHJS", response,selectedTask,type);
            selectedTaskServices.unshift({ name: "all" });
            return selectedTaskServices;
        } else {
            return []; // Return an empty array if there are no results
        }
    } catch (error) {
        console.error("Error fetching services for selected project:", error);
        return []; // Return an empty array or handle the error as needed
    }
};

export const getStepOutputVars = async (stepCode, previousJobType) => {
    try {
        let myendpoint = GenerateURL({}, properties.api.stepCatalogs);
        let requestInfo = {
            endPoint: GenerateSearchURL({ step_code: stepCode, version: previousJobType || 'v3' }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };

        const response = await new Promise((resolve, reject) => {
            InvokeApi(requestInfo, resolve, reject);
        });

        if (response && response.results && response.results.length > 0) {
            const stepData = response.results[0];
            const outPutVars = stepData.output_variables;
            return outPutVars;
        } else {
            return null;  
        }
    } catch (error) {
        console.error("Error fetching output variables:", error);
        return null; 
    }
};

export const getJobTemplateBasesOnService = async (selectedTaskData,project_env_id,serviceName) => {
    try {
        let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
        let requestInfo = {
            endPoint: GenerateURL({ project_env_id: project_env_id, component_name: serviceName}, properties.api.fetch_job_template_based_component+`?job_code=${selectedJobCodeForV2}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        const response = await new Promise((resolve, reject) => {
            InvokeApi(requestInfo, resolve, reject);
        });
        if(response){
            const stepsOfdataOfConfiguredJobTemplate = response?.job_template.jobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps; 
            let serviceDeploymentList = response?.deployment_names ? response.deployment_names : null;
            return {stepsOfdataOfConfiguredJobTemplate , serviceDeploymentList};
        }
    } catch (error) {
        console.error("Error fetching output variables:", error);
        return null; 
    }
}

const AddVersionThreeJobs = ({
    job_templates,
    errors,
    prevState,
    classes,
    hideBottomSaveContainer,
    validateAndSaveJobForVersion3,
    stages,
    job_index,
    stage_index,
    onSaveParamsForm,
    editRuntimeFormState, // its edit from local state
    onlyIncreaseResetCount,
    editResetCount,
    jobJsonData,
    setJobJson,
    independentJobData,
    setParentState,
    editFlowJobStepJson,
    resetStateOfUpdatedEnv,
    isDynamicJobSelected,
    ...props }) => {

    const inherits = props.inherits ? props.inherits : {};

    const prevStateForEdit = prevState ? prevState : {};
    console.log(prevStateForEdit, 'edit_00p_lmn')
    console.log(jobJsonData, 'job_json_data_001')
    const runTimeParamClasses = useStyles();

    const getStateDataForNormalValues = (key, value) => {
        return {
            is_value_from_output_var: false,
            key: key,
            value: value,
            outputForm: {
                previous_job: null,
                step: null,
                output_var: null,
                stage: null,
                task_list: null,
                step_list: null,
                step_order: null,
                output_var_list: null,
                outputVariableMapping: false,
                is_overridden: true,
            }
        }
    }

    const getDependentTaskListForEdit = (sourceValue) => {
        //fetching tasks 
        if (sourceValue != null || sourceValue != '') {
            const tasks = stages?.find(stage => stage.name == sourceValue)?.task
            const updatedTasks = tasks?.map((task, index) => {
                task['order'] = index + 1
                return task
            })
            return updatedTasks

        }

    }

    
    const getDependentStepListForEdit = async (taskList, selectedTask ,serviceName) => {
        if (taskList) {
            let selectedJobCodeForV2;
            const selectedTaskData = taskList?.find(task => task.order == selectedTask)
            const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
            let stepsOfdataOfConfiguredJobTemplateInTargetTask;
            let deploymentList;
            if(!configuredJobTemplateOfTask){ 
                if(serviceName === "all"){
                    const defaultJobTemplateJobs = job_templates?.find(jobTemplate => jobTemplate.default)?.job_template?.jobs;
                    let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                    const stepsdataOfConfiguredJobTemplateInTargetTask = defaultJobTemplateJobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;
                    stepsOfdataOfConfiguredJobTemplateInTargetTask = stepsdataOfConfiguredJobTemplateInTargetTask;
                }else{
                    let { stepsOfdataOfConfiguredJobTemplate,serviceDeploymentList } = await getJobTemplateBasesOnService(selectedTaskData,selectedTaskData.project_env_id || selectedTaskData.env,serviceName);
                    stepsOfdataOfConfiguredJobTemplateInTargetTask = stepsOfdataOfConfiguredJobTemplate;
                    deploymentList = serviceDeploymentList;

                }
            }else{
                const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
                if(selectedTask.task_type_id){
                    selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                }
                const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs;
                stepsOfdataOfConfiguredJobTemplateInTargetTask = selectedJobCodeForV2? jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedJobCodeForV2)?.steps : jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code || selectedTaskData?.task_type)?.steps;  // using selectedTaskData?.task_type in case of v3 pipeline because in v3 pipeline job_code is not present
            }
            const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
            let servicesList;
            // if(isDynamicJobSelected){
            //     selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
            // }
            if(selectedTaskData.components && selectedTaskData.components.length >= 1){
                servicesList = await getServicesForSelectedProject(selectedTaskData,selectedTaskData?.task_type_id ? 'v2' : 'v3');
            }
            return {stepsOfdataOfConfiguredJobTemplateInTargetTask , previousJobType: selectedTaskData?.task_type_id ? 'v2' : 'v3',servicesList:servicesList,deploymentList: deploymentList}
        }
    }

    
    

    const getStateDataForOutputValues = async (key, value) => {
        if (value == null) {
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                outputForm: {
                    previous_job: null,
                    previous_job_type: null,
                    previous_job_service: null,
                    deployment_list: null,
                    deployment_name: null,
                    services: null,
                    step: null,
                    output_var: null,
                    stage: null,
                    task_list: null,
                    step_list: null,
                    step_order: null,
                    output_var_list: null,
                    ouputVariableLoading: false
                }
            }
        }
        const values = value.split('.');
        const stage = values[1]; // "stage"
        const task = values[3]; // 2
        const serviceName = values[4]; // "image-list"
        const stepCode = values[5]; // "test_step_001"
        const outputVariable = values[6]; // "output_var_2.5"
        const stepOrder = values[7];
        const deploymentName = values.length > 7 ? values[8] : null;
        let taskList = null;
        let stepList = null;
        let outputVarList;
        let previous_job_type;
        let services;
        let deploymentList;
        if (stage != undefined) {
            taskList = getDependentTaskListForEdit(stage)
            if (taskList) {
                let {stepsOfdataOfConfiguredJobTemplateInTargetTask,previousJobType,servicesList,deploymentList:serviceDeploymentList} = await getDependentStepListForEdit(taskList, task,serviceName);
                stepList = stepsOfdataOfConfiguredJobTemplateInTargetTask
                // const selectedTaskData = taskList.find(task => task.order === task);
                previous_job_type = previousJobType;
                services=servicesList;
                deploymentList = serviceDeploymentList;
                console.log("sdjhsjh",taskList,previous_job_type,outputVarList,typeof task);
            }
            if(stepCode){
                outputVarList = await getStepOutputVars(stepCode,previous_job_type)
            }
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                outputForm: {
                    previous_job: task,
                    previous_job_type: previous_job_type,
                    previous_job_service: serviceName,
                    deployment_list: deploymentList,
                    deployment_name: deploymentName,
                    services: services,
                    step: stepCode,
                    output_var: outputVariable,
                    stage: stage,
                    task_list: taskList,
                    step_list: stepList,
                    step_order: stepOrder,
                    output_var_list: outputVarList || [],
                    ouputVariableLoading: false
                }
            }
        }
        else {
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                outputForm: {
                    previous_job: null,
                    previous_job_type: null,
                    previous_job_service: null,
                    deployment_list: null,
                    deployment_name: null,
                    services: null,
                    step: null,
                    output_var: null,
                    stage: null,
                    task_list: null,
                    step_list: null,
                    step_order: null,
                    output_var_list: null,
                    outputVariableMapping: false,
                    is_overridden: true
                }
            }
        }
    }

    const transformEditDataToSuitableJson = (globalEditStepJsonData) => {
        console.log(globalEditStepJsonData, 'data_001_gp');
        const job_step_json = { ...globalEditStepJsonData }
        Object.keys(job_step_json)?.forEach(serviceId => {
            const stepData = job_step_json[serviceId]
            Object.keys(stepData)?.forEach(stepCode => {
                const stepOrderObject = stepData[stepCode]
                Object.keys(stepOrderObject)?.forEach(stepOrder => {
                    const envVarsArray = stepOrderObject[stepOrder]
                    envVarsArray?.forEach(async (envVar, index) => {
                        const key = envVar.key
                        const value = envVar.value
                        let correctStructuredValues;
                        if (envVar?.is_value_from_output_var) {
                            correctStructuredValues = await getStateDataForOutputValues(key, value)
                            console.log("jdhsjsd",correctStructuredValues);
                        }
                        else {
                            correctStructuredValues = getStateDataForNormalValues(key, value)
                        }
                        envVarsArray[index] = { ...envVar, ...correctStructuredValues }
                    })
                })
            })
        })
        console.log(job_step_json, 'data_js_032')
        return job_step_json;

    }

    const [state, setState] = useState(
        {
            data: { ...prevStateForEdit },
            error: {},
            all_services: [],
            version_three_templates: [],
            job_name_list: [],
            stepsOfSelectedJob: [],
            loading_components: false,
            dataForRuntimeParamsOfComponents: {              // component_id :  {stepName: value , selectedFromJObValue : true or false, }

            },
            validations: {
                env: [VALIDATION_TYPE_REQUIRED],
                project_job_template_id: [VALIDATION_TYPE_REQUIRED],
                task_type: [VALIDATION_TYPE_REQUIRED],
                // components: [VALIDATION_TYPE_REQUIRED]
            },
            runTimeParamsFormState: {
                resetCount: 0,
                isAdvanceRunTimeParamsForm: false,
                updatedRuntimeParams: null,
                loaderVarFormStateAccordingToStepsAndServices: {},
                stepJsonUpdated: {},
                staticComponentList: [
                ],
                runTimeModifiableVariables: {},
                selectedServicesForParams: [],
                selectedTabOrder: null,
                modeType: null,
                loading: false,
                tabList: [

                ],
                // globalLevelEdit: {
                //     isEditActive: prevStateForEdit?.output_variable_mapping ? true : false, // this is edit from global level when user come from the stage it self
                //     dataForEdit: prevStateForEdit?.output_variable_mapping,
                //     resetCount: 0
                // }
                globalLevelEdit: {
                    isEditActive: prevStateForEdit?.job_step_json ? true : false, // this is edit from global level when user come from the stage it self
                    dataForEdit: prevStateForEdit?.job_step_json,
                    resetCount: 0
                },
                branches: {

                }
            }
        }
    )
    console.log(state, 'fcuk')

    useEffect(() => {
        if(prevStateForEdit){
            let stepJson = prevStateForEdit?.job_step_json ? jobJsonData?.is_dependent ? transformEditDataToSuitableJson(prevStateForEdit?.job_step_json) : {} : {};
            setState((prevState)=>({
                ...prevState,
                runTimeParamsFormState:{
                    ...prevState.runTimeParamsFormState,
                    stepJsonUpdated: stepJson,
                }
            }))
        }
    },[prevStateForEdit])

    useEffect(() => {
        // if (editRuntimeFormState) {
        //     setState(prevState => ({
        //         ...prevState,
        //         runTimeParamsFormState: { ...editRuntimeFormState }
        //     }))
        // }
        // else
        // setState(prevState => ({
        //     ...prevState,
        //     runTimeParamsFormState: {
        //         ...prevState.runTimeParamsFormState,
        //         tabList: [{ tabName: 'All', order: '00' }],
        //         selectedServicesForParams: ['00'],
        //         selectedTabOrder: '00'
        //     }
        // }))
    }, [editRuntimeFormState, editResetCount])


    useEffect(() => {
        //change3

        // const envValue = state.data?.env
        // if (envValue !== '' && envValue != null) {

        //     const selectedEnv = getSelectedEnv(envValue, props.env_list)

        //     setState(prevState => ({
        //         ...prevState,

        //         data: {
        //             ...prevState.data,
        //             selected_project_env: selectedEnv,
        //             project_job_template_id: ''
        //         }
        //     }))
        // }
        // else
        //     setState(prevState => ({
        //         ...prevState,
        //         selected_project_env: null,
        //         data: { ...prevState.data, project_job_template_id: '' }
        //     }))

    }, [state.data?.env])

    useEffect(() => {

        // const outputVariableMapping = prevStateForEdit?.output_variable_mapping
        const outputVariableMapping = state.runTimeParamsFormState?.globalLevelEdit?.dataForEdit
        const jobStepJson = state.runTimeParamsFormState?.globalLevelEdit?.dataForEdit
        console.log(jobStepJson, 'data_0001_sd')
        console.log('edit_flow_initiated_001', state, outputVariableMapping, prevStateForEdit)
        if (jobStepJson && state.data?.env != undefined) {
            let selectedServicesForParams = []
            let envId = state.data?.env

            let selectedTabOrder = null
            let foundAll = false
            let tabList = []
            if (envId !== '' && envId != null) {

                const selectedEnv = getSelectedEnv(envId, props.env_list)

                setState(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        selected_project_env: selectedEnv
                    }
                }))
            }
            Object.keys(jobStepJson)?.forEach(serviceId => {
                if (state.all_services?.length > 0) {
                    if (serviceId == '00') {
                        selectedServicesForParams.push('00')
                        foundAll = true
                        selectedTabOrder = '00'
                        tabList.push({ order: '00', tabName: 'All' })
                    }
                    else {
                        let serviceName = state.all_services?.find(service => service.id == serviceId)?.label

                        selectedServicesForParams.push(String(serviceId))
                        tabList.push({ order: serviceId, tabName: serviceName })


                    }
                }
            })
            if (!foundAll) {
                if (selectedServicesForParams?.length > 0) {
                    selectedTabOrder = selectedServicesForParams[0]
                }
            }
            setState(prevState => ({ //1
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    selectedServicesForParams: selectedServicesForParams,
                    selectedTabOrder: selectedTabOrder,
                    tabList: tabList
                }
            }))
        }

    }, [state.all_services, state.runTimeParamsFormState?.globalLevelEdit?.resetCount])



    function getEnvStateAccordingToEditMode(data) {

        console.log(data, 'data)))llkdsd')
        const { key,
            stepOrder,
            stepCode,
            selectedServiceID } = data;

        let editStateData = state.runTimeParamsFormState?.globalLevelEdit?.dataForEdit
        console.log(editStateData, 'next_00p_mn')
        if (editStateData[selectedServiceID] && editStateData[selectedServiceID][stepCode] && editStateData[selectedServiceID][stepCode][stepOrder]) {

            const dataOfEnvVars = editStateData[selectedServiceID][stepCode][stepOrder]
            const envVarData = dataOfEnvVars?.find(envVarData => envVarData.key == key)
            const value = envVarData?.value
            const isValueFromOutput = envVarData?.is_value_from_output_var
            console.log(envVarData, value, isValueFromOutput, 'edit_,mode_001)p')
            if (!isValueFromOutput) {
                return getStateDataForNormalValues(key, value)
            }
            else {
                getStateDataForOutputValues(key, value)
            }
        }
        else
            return { ...defaultStateForEnvFormUpdated(key, null) }
    }

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            error: errors ? errors : {}
        }))
    }, [errors])

    useEffect(() => {
        if (state.data.components) {
            let staticList = [{ value: "00", label: "All" }]
            let services = state.all_services
            let components = state.data.components
            let componentIdNameObject = {}
            components.forEach(component => {
                const id = component
                services?.forEach(service => {
                    if (service.id == id) {
                        staticList.push({ value: id, label: service.label, component_env: service.component_env })
                        componentIdNameObject[id] = service
                    }
                })
            })

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    staticComponentList: staticList,
                    componentIdNameObject: componentIdNameObject
                }
            }))
        }
    }, [state.data, state.all_services])


    const getServiceNameKeys = (services) => {
        console.log(services, 'data_00p_mn');
        let obj = {}
        services?.forEach(service => {
            obj[service.id] = service?.label
        })
        return obj;
    }

    const serviceIdKeyObject = useMemo(() => getServiceNameKeys(state.all_services), [state.all_services]);
    console.log(serviceIdKeyObject, 'getter_00p')


    useEffect(() => {
        if (state.data.project_job_template_id != null && state.data.project_job_template_id != '') {
            const selectedJobTemplate = job_templates && job_templates.find(template => template.id == state.data.project_job_template_id)
            const versionOfSelectedTemplate = selectedJobTemplate?.version
            let selectedTempalteJobs = []
            selectedJobTemplate?.job_template?.jobs?.forEach(({ job_code, job_name, is_dynamic }) => {
                if (versionOfSelectedTemplate == 'v2') {
                    if (is_dynamic) {
                        selectedTempalteJobs.push({ id: job_code, label: job_name })
                    }
                }
                else {
                    selectedTempalteJobs.push({ id: job_code, label: job_name })

                }
            })

            setState(prevState => ({
                ...prevState,
                job_name_list: selectedTempalteJobs?.length > 0 ? selectedTempalteJobs : [],
                versionOfSelectedTemplate: versionOfSelectedTemplate,
            }))

        }
        else
            setState(prevState => ({
                ...prevState,
                data: { ...prevState.data, project_job_template_id: '', task_type: '' },
                job_name_list: [],
                stepsOfSelectedJob: []
            }))

    }, [state.data.project_job_template_id, job_templates])

    useEffect(() => {
        if (state.data.task_type !== '' && state.data.task_type != null) { // fix here
            let selectedJObIndex = null
            let labelOfJob = ''
            let selectedSteps = null
            let selectedJob = null
            let jobDependetData = null

            state.job_name_list?.forEach((job, index) => {
                if (job.id === state.data.task_type) {
                    labelOfJob = job.label
                    selectedJObIndex = index
                }
            })

            const selectedTempalteJobs = job_templates && job_templates.find(template => template.id == state.data.project_job_template_id)?.job_template
            selectedTempalteJobs?.jobs.forEach((job, index) => {
                if (job.job_code === state.data.task_type) {
                    labelOfJob = job.label
                    selectedJObIndex = index
                }
            })

            // console.log(selectedTempalteJobs,selectedJObIndex, 'selecteed_jobs_2323223');
            if (selectedTempalteJobs) {

                selectedJob = selectedTempalteJobs['jobs'][selectedJObIndex]
                selectedSteps = selectedTempalteJobs['jobs'][selectedJObIndex]?.steps
                let dependentJob = (selectedJob?.is_dependent == true || selectedJob?.is_dependent == 'true')
                let dependent_entity
                if (dependentJob) {
                    dependent_entity = selectedJob?.dependent_entity
                }
                if (dependent_entity == "SERVICE" || true) {
                    jobDependetData = {   // fix here
                        "is_dependent": dependentJob ? true : false,
                        "dependent_entity": dependentJob ? dependent_entity : null,
                        "entity_resource_data": {
                            [dependent_entity]: [],
                            "is_overridden": true
                        },
                        jobData: selectedJob
                    }
                    if (!dependentJob) {
                        delete jobDependetData.entity_resource_data
                    }

                    setState(prevState => ({
                        ...prevState, data: { ...prevState.data, name: labelOfJob },
                        stepsOfSelectedJob: selectedSteps,
                    }))
                    setJobJson && setJobJson(jobDependetData)
                    dependent_entity && fetchServicesForTempalte(dependent_entity)
                }
            }
            else {
                // setJobJson({
                //     "is_dependent": false,
                //     "dependent_entity": "SERVICE",
                //     "entity_resource_data": {
                //         "SERVICE": [],
                //         "is_overridden": false
                //     }
                // })

                // setState(prevState => ({
                //     ...prevState,
                //     jobDepedent: {
                //         is_dependent_job: false,
                //         jobData: null,
                //         dependentEntity: null
                //     }
                // }))
            }
        }
        else {

            setState(prevState => ({
                ...prevState,
                all_services: [],
                data: {
                    ...prevState.data,
                    components: []
                }
            }))
        }
    }, [state.data.task_type])
    console.log('edit_00plmn', editRuntimeFormState, 'local_state', state.runTimeParamsFormState)

    useEffect(() => {

        console.log('local_edit_checked_here_001', editRuntimeFormState, state.runTimeParamsFormState.isAdvanceRunTimeParamsForm);
        // it is responsible for the first time selecting all and values

        // !editRuntimeFormState && state.runTimeParamsFormState.isAdvanceRunTimeParamsForm
        if (!editRuntimeFormState && state.runTimeParamsFormState.isAdvanceRunTimeParamsForm && !state.runTimeParamsFormState?.globalLevelEdit?.isEditActive) {   // pre selected all tab here 
            setState(prevState => ({ //2
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: [{ tabName: 'All', order: '00' }],
                    selectedServicesForParams: ['00'],
                    selectedTabOrder: '00'
                }
            }))
        }
        if (editRuntimeFormState) {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: { ...editRuntimeFormState, isAdvanceRunTimeParamsForm: state.runTimeParamsFormState.isAdvanceRunTimeParamsForm }
            }))
        }
    }, [state.runTimeParamsFormState.isAdvanceRunTimeParamsForm])

    useEffect(() => {

        console.log(state.runTimeParamsFormState?.selectedServicesForParams, 'params_001') // add services in tablist to show
        if (state.runTimeParamsFormState?.selectedServicesForParams?.length > 0) {

            const serviceArray = state.runTimeParamsFormState.selectedServicesForParams
            const componentIdNameObject = state.runTimeParamsFormState?.componentIdNameObject

            const tablist = serviceArray?.map(item => {
                if (item == '00') {
                    return { order: '00', tabName: 'All' }
                }
                return { order: item, tabName: componentIdNameObject[item]?.label }
            })
            setState(prevState => ({ //3
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: tablist
                }
            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: []
                }
            }))
        }

    }, [state.runTimeParamsFormState.selectedServicesForParams])

    useEffect(() => {

        console.log('starting_point_of_this', state.runTimeParamsFormState.selectedTabOrder)
        const alreadyExistForms = state.runTimeParamsFormState?.stepJsonUpdated
        if (state.runTimeParamsFormState.selectedTabOrder) {
            if (state.runTimeParamsFormState.selectedTabOrder == '00') {
                if (state?.runTimeParamsFormState?.staticComponentList?.length > 0) {
                    const componentId = state.runTimeParamsFormState.staticComponentList?.find(({ value }) => value != '00')?.value
                    if (alreadyExistForms[state.runTimeParamsFormState.selectedTabOrder] == undefined) {
                        fetchRunTimeParams(componentId, true, false) // this will run only in case of all tab
                    }
                    else {
                        fetchRunTimeParams(componentId, true, true) // this will run only in case of all tab and only fetch modifable vaiables will not update stepJson
                    }
                }
            }
            else {
                if (alreadyExistForms[state.runTimeParamsFormState.selectedTabOrder] == undefined) {
                    fetchRunTimeParams(state.runTimeParamsFormState.selectedTabOrder, false)  // it will run for all component id expect all  - 00
                }
                else {
                    fetchRunTimeParams(state.runTimeParamsFormState.selectedTabOrder, false, true)  // it will run for all component id expect all  - 00
                }
            }
        }

    }, [state.runTimeParamsFormState.selectedTabOrder])


    const commonFunctions = getCommonFunctions(state, setState, inherits)

    function handleEnvInput(e) {
        let envValue = e.target.value
        commonFunctions.onChangeHandler(e);
        if (envValue !== '' && envValue != null) {

            const selectedEnv = getSelectedEnv(envValue, props.env_list)

            setState(prevState => ({
                ...prevState,

                data: {
                    ...prevState.data,
                    selected_project_env: selectedEnv,
                    project_job_template_id: ''
                }
            }))
        }
        else
            setState(prevState => ({
                ...prevState,
                selected_project_env: null,
                data: { ...prevState.data, project_job_template_id: '' }
            }))
    }

    function onChangeHandlerForKeyValue(key, value) {
        if (key == "components" || key == "pipelines") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function fetchServicesForTempalte(dependent_entity) {

        var requestInfo = {
            // endPoint: GenerateURL({ env_id: state.data.env, template_id: state.data.project_job_template_id, job_code: state.data.task_type }, properties.api.get_services_for_pipeline_version_3_jobs),
            endPoint: GenerateURL({ env_id: state.data.env, template_id: state.data.project_job_template_id, job_code: state.data.task_type, entity_type: dependent_entity }, properties.api.get_entity_resources),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setTimeout(() => InvokeApi(requestInfo, (data) => {

            let fetchedServices = []

            if (data?.length > 0) {
                const services = data?.map(service => {
                    return { id: service.id, label: service.name, component_env: service.component_env }
                })
                fetchedServices = services
            }

            setState(prevState => ({
                ...prevState,
                all_services: fetchedServices,
                loading_components: false
            }))
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                loading_components: false,
                error: error
            }))

        }),
            500)


        setState(currentState => ({
            ...currentState,
            loading_components: true
        }));
    }


    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;

        var switch_key = [
            "latest_enable",
            "pipeline_suspend",
            "response_update_context_param",
            "no_cache",
            "on_failure_exit",
            "on_success_exit",
            "suspend_pipeline",
            "deployment_status_check",
            "skip_task",
            "mark_success_upon_skip",
            "is_linked_issue"
        ]

        if (switch_key.includes(key)) {
            value = !state.data[key];
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }


    function fetchModifyEnvVarsSuccess(data, componentId, isForAll, fetchOnlyRuntimeParamsDontUpdateStepJson) {

        let apiFethcedCanModifiedVariables = data
        let componentObj = {}
        let loaderObj = {}
        let componentLabel = null
        let jobStepJson = {}

        if (componentId == '00') {
            componentLabel = 'all_0'
        }
        else {
            componentLabel = state.all_services?.find(service => service.id == componentId)?.label
        }
        let target = '00'
        if (!isForAll) {
            target = componentId
        }
        componentObj = {
            [target]: {}
        }
        jobStepJson = {
            [target]: {}
        }

        loaderObj = {
            [target]: {}
        }

        if (fetchOnlyRuntimeParamsDontUpdateStepJson) { // will have to update if new variables arrived in edit stage and they will not present in stepJson
            setState(prevState => {
                return {
                    ...prevState,
                    runTimeParamsFormState: {
                        ...prevState.runTimeParamsFormState,
                        runTimeModifiableVariables: {
                            ...prevState.runTimeParamsFormState.runTimeModifiableVariables,
                            [prevState.runTimeParamsFormState.selectedTabOrder]: data
                        },

                        loaderVarFormStateAccordingToStepsAndServices: {
                            ...prevState.runTimeParamsFormState.loaderVarFormStateAccordingToStepsAndServices,
                            ...loaderObj
                        },
                        loading: false
                    }
                }
            })
            return true;
        }
        apiFethcedCanModifiedVariables && apiFethcedCanModifiedVariables.forEach((step, index) => {

            step && step.environment_variables.forEach(envVar => {
                // real job stepjson
                if (envVar.integration) {
                    envVar.integration_values?.forEach(integrationObj => {
                        Object.keys(integrationObj)?.forEach(integrationKey => {

                            const integrationDataArray = integrationObj[integrationKey]
                            integrationDataArray?.forEach(integration => {

                                if (integration.key === 'GIT_BRANCH') {
                                    let targetDataForEditFlow = {
                                        key: integration.key,
                                        stepOrder: step.order,
                                        stepCode: step.step_code,
                                        selectedServiceID: target
                                    }

                                    console.log(integration, 'integration_0003')
                                    const key = integration['key']
                                    const value = integration['value']
                                    if (jobStepJson[target][step.step_code]) {

                                        jobStepJson[target][step.step_code] = {
                                            ...jobStepJson[target][step.step_code],
                                            [step.order]: [
                                                ...jobStepJson[target][step.step_code][step.order],
                                                state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(targetDataForEditFlow) } :
                                                    { ...defaultStateForEnvFormUpdated(key, !isForAll ? value : null) }
                                            ]
                                        }
                                    }
                                    else {
                                        jobStepJson[target][step.step_code] = {
                                            [step.order]: [
                                                state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(targetDataForEditFlow) } :
                                                    { ...defaultStateForEnvFormUpdated(key, !isForAll ? value : null) }
                                            ]
                                        }
                                    }
                                }
                            })
                            if (envVar.integration == 'GIT_REPO') { //updated code
                                const repo = integrationDataArray[0]?.value
                                if (repo != undefined) {
                                    getGitUrl(target, step?.order, integrationKey, repo)
                                }
                            }
                        })
                    })

                }
                else {
                    console.log(envVar, 'env_var_001_ss')
                    const key = envVar['key']
                    const value = envVar['value']
                    let targetDataForEditFlow = {
                        key: key,
                        stepOrder: step.order,
                        stepCode: step.step_code,
                        selectedServiceID: target
                    }

                    let inputTypeData = {
                        input_type: envVar?.input_type ? envVar.input_type : null,
                        default_value: envVar.default_value ? envVar.default_value : null,
                        does_default_active: envVar.does_default_active ? envVar.does_default_active : null,
                        select_dropdown_options: envVar.select_dropdown_options ? envVar.select_dropdown_options : null
                    }

                    if (jobStepJson[target][step.step_code]) {

                        jobStepJson[target][step.step_code] = {
                            ...jobStepJson[target][step.step_code],
                            [step.order]: [
                                ...jobStepJson[target][step.step_code][step.order],
                                state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(targetDataForEditFlow, ...inputTypeData) } :
                                    { ...defaultStateForEnvFormUpdated(key, !isForAll ? value : null), ...inputTypeData }
                            ]
                        }
                    }
                    else {
                        jobStepJson[target][step.step_code] = {
                            [step.order]: [
                                state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(targetDataForEditFlow), ...inputTypeData } :
                                    { ...defaultStateForEnvFormUpdated(key, !isForAll ? value : null), ...inputTypeData }
                            ]
                        }
                    }
                }
                console.log(data, componentObj, jobStepJson, 'def_00pp')
                // formData[index] = { ...formData[index], [envVar['key']]: envVar['value'] }
            })
        })

        setState(prevState => {
            return {
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    runTimeModifiableVariables: {
                        ...prevState.runTimeParamsFormState.runTimeModifiableVariables,
                        [prevState.runTimeParamsFormState.selectedTabOrder]: data
                    },
                    stepJsonUpdated: {
                        ...prevState.runTimeParamsFormState.stepJsonUpdated,
                        ...jobStepJson,
                    },
                    loaderVarFormStateAccordingToStepsAndServices: {
                        ...prevState.runTimeParamsFormState.loaderVarFormStateAccordingToStepsAndServices,
                        ...loaderObj
                    },
                    loading: false
                }
            }
        })
    }

    const handleChange = (e, type, targetObj) => {

        const envKey = e.target.name
        const value = e.target.value
        const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices

        varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][envKey][envKey] = value
        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                varFormStateAccordingToStepsAndServices: {
                    ...varFormState
                },
            }
        }));

    }

    const handleChangeUpdated = (e, data) => {
        const envKey = e.target.name
        const value = e.target.value
        let selectedServiceID = data?.selectedServiceID
        let stepOrder = data?.stepOrder
        let stepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated
        let envVarData = varFormState[selectedServiceID][stepCode][stepOrder][envVaribaleIndex]
        console.log(envVarData, 'data_00od')
        if (envVarData.integration) {

        }
        else {
            envVarData.value = value
            UpdateChangesInState(varFormState)
        }
        console.log(data, 'data_001o');
    }

    const getStepCatelogData = (data, stepCode , previousJobType) => {
        let selectedServiceID = data?.selectedServiceID
        let targetStepOrder = data?.stepOrder
        let targetStepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        console.log("jdjsksks",data);
        let myendpoint = GenerateURL({}, properties.api.stepCatalogs);
        var requestInfo = {
            endPoint: GenerateSearchURL({ step_code: stepCode, version: previousJobType || 'v3' }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }


        InvokeApi(requestInfo,
            (response) => {
                if (response) {
                    const result = response.results
                    if (result.length > 0) {
                        const stepData = result[0]
                        const outPutVars = stepData.output_variables
                        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated
                        varFormState[selectedServiceID][targetStepCode][targetStepOrder][envVaribaleIndex]['outputForm']['output_var_list'] = outPutVars

                        setState(prevState => ({
                            ...prevState,
                            runTimeParamsFormState: {
                                ...prevState.runTimeParamsFormState,
                                stepJsonUpdated: {
                                    ...varFormState
                                },
                            }
                        }));
                    }
                }
            },
            (error) => {

            });

    }

    function fetchServicesForSelectedProject(data,selectedTask,type) {
        let selectedServiceID = data?.selectedServiceID
        let targetStepOrder = data?.stepOrder
        let targetStepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        console.log("jdjsksks",data,selectedTask,type);
        let url = type == 'v3' ? GenerateURL({ env_id: selectedTask.env, template_id: selectedTask.project_job_template_id, job_code: selectedTask.job_code || selectedTask.task_type, entity_type: selectedTask.job_json.dependent_entity }, properties.api.get_entity_resources) : GenerateURL({ project_env_id: selectedTask.env, job_type: type }, properties.api.get_services_for_projectEnvV2)
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, 
            (response) => {
                if (response) {
                    const result = type == 'v3' ? response : response.results
                    if (result.length > 0) {
                        let selectedTaskServices = result.filter((item) => {
                            if(selectedTask.components.includes(item.id)){
                                return item;
                            }
                        })
                        selectedTaskServices.unshift({ name: "all" });
                        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated;
                        varFormState[selectedServiceID][targetStepCode][targetStepOrder][envVaribaleIndex]['outputForm']['services'] = selectedTaskServices

                        setState(prevState => ({
                            ...prevState,
                            runTimeParamsFormState: {
                                ...prevState.runTimeParamsFormState,
                                stepJsonUpdated: {
                                    ...varFormState
                                },
                            }
                        }));
                    }
                }
            }, (error)=>{
            console.log("kdjksjdks",error);
        });
        
    }

    function fetchJobTemplateBasedOnService(data,selectedTaskData,project_env_id,service_name){
        let selectedServiceID = data?.selectedServiceID
        let targetStepOrder = data?.stepOrder
        let targetStepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        let job_code = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
        let url = GenerateURL({ project_env_id: project_env_id, component_name: service_name }, properties.api.fetch_job_template_based_component+`?job_code=${job_code}`)
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, 
            (response) => {
                if (response) {
                        let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                        const stepsOfdataOfConfiguredJobTemplateInTargetTask = response?.job_template.jobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;

                        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated;
                        varFormState[selectedServiceID][targetStepCode][targetStepOrder][envVaribaleIndex]['outputForm']['deployment_list'] = response?.deployment_names ? response.deployment_names : null;;
                        varFormState[selectedServiceID][targetStepCode][targetStepOrder][envVaribaleIndex]['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask
                        
                        setState(prevState => ({
                            ...prevState,
                            runTimeParamsFormState: {
                                ...prevState.runTimeParamsFormState,
                                stepJsonUpdated: {
                                    ...varFormState
                                },
                            }
                        }));
                    }
                }
            , (error)=>{
            console.log("kdjksjdks",error);
        });
    }

    const handleChangeForOutputVariableForm = (e, data) => {

        const targetKey = e.target.name
        const value = e.target.value
        let selectedServiceID = data?.selectedServiceID
        let stepOrder = data?.stepOrder
        let stepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated
        let envVarData = varFormState[selectedServiceID][stepCode][stepOrder][envVaribaleIndex]

        if (targetKey == 'stage') {
            //fetching tasks 
            if (value != null || value != '') {
                const tasks = stages?.find(stage => stage.name == value)?.task
                const updatedTasks = tasks?.map((task, index) => {
                    task['order'] = index + 1
                    return task
                })
                envVarData['outputForm'][targetKey] = value;
                envVarData['outputForm']['task_list'] = updatedTasks;
                UpdateChangesInState(varFormState)
            }
        }
        else if (targetKey == 'previous_job') {
            const taskList = envVarData['outputForm']['task_list']
            let selectedJobCodeForV2;
            envVarData['outputForm'][targetKey] = value;
            if (taskList) {
                const selectedTaskData = taskList?.find(task => task.order == value)
                const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
                const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
                const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs
                if(isDynamicJobSelected){
                    selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                }
                const stepsOfdataOfConfiguredJobTemplateInTargetTask = selectedJobCodeForV2 ? jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedJobCodeForV2)?.steps : jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code || selectedTaskData?.task_type)?.steps // using selectedTaskData?.task_type in case of v3 pipeline because in v3 pipeline job_code is not present
                envVarData['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask;
                envVarData['outputForm']['previous_job_type'] = selectedTaskData?.task_type_id ? 'v2' : 'v3';
                if(selectedTaskData.components && selectedTaskData.components.length >= 1){
                    fetchServicesForSelectedProject(data,selectedTaskData, selectedTaskData?.task_type_id ? 'v2' : 'v3');
                }
                console.log("hdhsjdhsj",value,envVarData,selectedTaskData,configuredJobTemplateOfTask,dataOfConfiguredJobTemplateInTargetTask,jobsOfdataOfConfiguredJobTemplateInTargetTask,stepsOfdataOfConfiguredJobTemplateInTargetTask)
                UpdateChangesInState(varFormState)
            }
        }
        else if(targetKey == 'previous_job_service'){
            const taskList = envVarData['outputForm']['task_list'];
            const selectedTaskData = taskList?.find(task => task.order == envVarData['outputForm']['previous_job']);
            if(!selectedTaskData?.project_job_template_id){
                if(value === "all"){
                    const defaultJobTemplateJobs = job_templates?.find(jobTemplate => jobTemplate.default)?.job_template?.jobs;
                    let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                    const stepsOfdataOfConfiguredJobTemplateInTargetTask = defaultJobTemplateJobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;
                    envVarData['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask;
                }else{
                    fetchJobTemplateBasedOnService(data,selectedTaskData,selectedTaskData.project_env_id || selectedTaskData.env,value)
                }
            }
            envVarData['outputForm'][targetKey] = value;
            UpdateChangesInState(varFormState);
        }
        else if (targetKey == 'step_order') {
            const stepList = envVarData['outputForm']['step_list']
            envVarData['outputForm'][targetKey] = value
            const selectedStepData = stepList?.find(step => step.order == value)
            const stepOrder = selectedStepData?.order
            const stepCode = selectedStepData?.step_code
            envVarData['outputForm']['step'] = stepCode
            envVarData['outputForm']['step_order'] = stepOrder
            const previousJobType = envVarData['outputForm']['previous_job_type'];
            UpdateChangesInState(varFormState)
            console.log("sdjjdshdsj",selectedStepData,envVarData,varFormState);
            // getting step_order outputvars

            getStepCatelogData(data, stepCode, previousJobType)

        }
        else if (targetKey == 'output_var') { // need to change
            // const varFormState = state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices
            // varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm'][[targetKey]] = value
            console.log("dshsdjshdj",varFormState,envVarData,value,data);
            envVarData['outputForm'][targetKey] = value;
            UpdateChangesInState(varFormState)
            // setState(prevState => ({
            //     ...prevState,
            //     runTimeParamsFormState: {
            //         ...prevState.runTimeParamsFormState,
            //         varFormStateAccordingToStepsAndServices: {
            //             ...varFormState
            //         },
            //     }
            // }));
        }
        else if(targetKey == 'deployment_name'){
            console.log("dshsdjshdj",envVarData,value,targetKey);
            envVarData['outputForm'][targetKey] = value;
            UpdateChangesInState(varFormState)
        }
    }

    function UpdateChangesInState(updatedState) {
        console.log("ksdjdsjk",updatedState);
        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                stepJsonUpdated: {
                    ...updatedState
                },
            }
        }));
    }
    const onHandleChangeForInputType = (data) => {
        let selectedServiceID = data?.selectedServiceID
        let stepOrder = data?.stepOrder
        let stepCode = data?.stepCode
        let envVaribaleIndex = data?.envVaribaleIndex
        let envVariableKey = data?.envVariableKey
        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated
        let envVarData = varFormState[selectedServiceID][stepCode][stepOrder][envVaribaleIndex]
        const isValueFromOutput = envVarData.is_value_from_output_var
        envVarData.is_value_from_output_var = !isValueFromOutput
        UpdateChangesInState(varFormState)
    }

    const activeAdvanceRuntimeParams = (value, isEditMode) => {

        if (isEditMode)
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    is_advance_configuration: value
                },
                runTimeParamsFormState: {

                    ...prevState.runTimeParamsFormState,
                    isAdvanceRunTimeParamsForm: value,
                    globalLevelEdit: {
                        ...prevState.runTimeParamsFormState.globalLevelEdit,
                        isEditActive: false,
                        dataForEdit: null,
                        resetCount: prevState.runTimeParamsFormState.globalLevelEdit.resetCount + 1
                    }
                }
            }))
        else
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    is_advance_configuration: value
                },
                runTimeParamsFormState: {

                    ...prevState.runTimeParamsFormState,
                    isAdvanceRunTimeParamsForm: value
                }
            }))
        hideBottomSaveContainer(value)
    }

    const handleServiceSelectUpdate = (uniqueId, serviceArray) => {

        if (serviceArray.length > 0) {

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    selectedServicesForParams: [...serviceArray],
                }
            }))

            // fetchin Runtime params from here 
            // const prevSelectedService = state.runTimeParamsFormState.selectedServicesForParams
        }
        else {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    runTimeModifiableVariables: {},
                    selectedServicesForParams: [...serviceArray],
                }
            }))
        }
    }

    const fetchModifyEnvVars = (component_id, sub_env_id, job_code, isForAll, fetchOnlyRuntimeParamsDontUpdateStepJson) => {

        if (component_id != null && sub_env_id != null && job_code != null) {

            const requestInfo = {
                // endPoint: GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_trigger_time_modify_env_vars),
                endPoint: GenerateURL({ env_id: state.data.env, job_code: job_code, entity_type: "SERVICE", entity_resource_id: component_id }, properties.api.get_runtime_params_for_dependent_job),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    loading: true
                }
            }))
            InvokeApi(requestInfo, (response) => {

                fetchModifyEnvVarsSuccess(response, component_id, isForAll, fetchOnlyRuntimeParamsDontUpdateStepJson)
            },
                (error) => {
                    setState(prevState => ({
                        ...prevState,
                        runTimeParamsFormState: {
                            ...prevState.runTimeParamsFormState,
                            loading: false,
                            error: error
                        }
                    }))
                });
        }
    }

    const toggleState = () => {

        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                is_advance_configuration: !prevState.data.is_advance_configuration
            },
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                isAdvanceRunTimeParamsForm: !prevState.data.is_advance_configuration

            }
        }))
        hideBottomSaveContainer(!state?.data?.is_advance_configuration)

    }

    const fetchRunTimeParams = (selectedServiceID, isForAll, fetchOnlyRuntimeParamsDontUpdateStepJson) => {

        // searching subEnvId 

        const componentEnv = state.runTimeParamsFormState?.componentIdNameObject[selectedServiceID]?.component_env
        const selectedEnvname = state.data.selected_project_env?.name
        const subEnvId = componentEnv?.find(componentEnv => componentEnv.name == selectedEnvname)?.id
        let jobCode = state.data?.task_type
        fetchModifyEnvVars(selectedServiceID, 1, jobCode, isForAll, fetchOnlyRuntimeParamsDontUpdateStepJson)
    }

    const onSelectTab = (order) => {

        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                selectedTabOrder: order
            }
        }))
    }

    const onRemoveTab = (removedTabOrder) => { // remove tab remaining 

        let tabs = state.runTimeParamsFormState.selectedServicesForParams
        let newSelectedTabOrder = state.runTimeParamsFormState.selectedTabOrder
        let updatedTabs = tabs.filter(tab => {
            if (tab != removedTabOrder) {
                return tab
            }
        })
        if (removedTabOrder == state.runTimeParamsFormState.selectedTabOrder && updatedTabs.length != 0) {

            const indexOfRemovedTab = tabs.indexOf(removedTabOrder)
            if (indexOfRemovedTab == 0) {
                newSelectedTabOrder = updatedTabs[0]
            }
            else
                newSelectedTabOrder = updatedTabs[indexOfRemovedTab - 1]
        }

        if (updatedTabs.length == 0) {
            newSelectedTabOrder = null
        }

        let stepJsonUpdated = { ...state.runTimeParamsFormState?.stepJsonUpdated }
        delete stepJsonUpdated[removedTabOrder]

        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                selectedServicesForParams: updatedTabs,
                stepJsonUpdated: stepJsonUpdated,
                selectedTabOrder: newSelectedTabOrder
            }
        }))

    }

    const handleOnSaveRuntimeParams = () => {

        // changing services name
        console.log(state.runTimeParamsFormState, 'edit_001')
        onSaveParamsForm({ ...state.runTimeParamsFormState, isAdvanceRunTimeParamsForm: false })
        activeAdvanceRuntimeParams(false, state?.runTimeParamsFormState?.globalLevelEdit?.isEditActive)
    }

    const handleOnDeleteServiceRuntimeParamsEdit = (serviceKey) => {
        let tablistData = editRuntimeFormState.tabList
        let selectedTabOrder = editRuntimeFormState.selectedTabOrder
        let varFormstate = editRuntimeFormState?.varFormStateAccordingToStepsAndServices
        let resetCount = editRuntimeFormState.resetCount
        let updatedTablist = tablistData?.filter(tab => tab.order != serviceKey)
        delete varFormstate[serviceKey]
        if (updatedTablist?.length > 0) {
            if (serviceKey == selectedTabOrder) {
                selectedTabOrder = updatedTablist[0]?.order
            }
            // if(updatedTablist.length==1){

            // }
            onSaveParamsForm({
                ...editRuntimeFormState, tabList: [...updatedTablist],
                varFormStateAccordingToStepsAndServices: { ...editRuntimeFormState?.varFormStateAccordingToStepsAndServices },
                resetCount: resetCount + 1,
                selectedTabOrder: selectedTabOrder
            })
        }
        else
            onSaveParamsForm(null)
        // need to update code it will be busted if all services removed

    }

    const handleOnEditClickOnServiceRuntimeParamsEdit = () => {

    }

    const checkForAvailableLists = (targetData, targetType) => {

        if (targetType == 'stage') {
            if (targetData['outputForm'][targetType] != null && targetData['outputForm'][targetType] != '') {
                return true
            }
            else
                return false
        }
    }

    const onhandleCancelTrigger = () => {
        if (editRuntimeFormState) {
            onlyIncreaseResetCount(state.runTimeParamsFormState?.resetCount + 1)
            activeAdvanceRuntimeParams(false)
        }
        if (state.runTimeParamsFormState?.globalLevelEdit?.isEditActive) {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    globalLevelEdit: {
                        ...prevState.runTimeParamsFormState.globalLevelEdit,
                        resetCount: prevState.runTimeParamsFormState.globalLevelEdit.resetCount + 1
                    }
                }
            }))
            activeAdvanceRuntimeParams(false)
        }
        else
            activeAdvanceRuntimeParams(false)
    }

    const handleServiceOverridden = (e) => {

        if (jobJsonData && setJobJson) {
            setJobJson({
                ...jobJsonData,
                entity_resource_data: {
                    "SERVICE": [],
                    "is_overridden": e.target.checked
                }
            })
        }
    }

    const resetUpdatedRuntimevariablesData = () => {
        console.log('callked');
        //for normal case locally form 

        resetStateOfUpdatedEnv(); // for parent reset
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                components: []
            },
            runTimeParamsFormState: {
                resetCount: prevState.runTimeParamsFormState.resetCount + 1,
                isAdvanceRunTimeParamsForm: false,
                updatedRuntimeParams: null,
                stepJsonUpdated: {},
                loaderVarFormStateAccordingToStepsAndServices: {},
                staticComponentList: [
                ],
                runTimeModifiableVariables: {},
                selectedServicesForParams: [],
                selectedTabOrder: null,
                modeType: null,
                loading: false,
                tabList: [

                ],
                globalLevelEdit: {
                    isEditActive: false,
                    dataForEdit: null,
                    resetCount: prevState?.runTimeParamsFormState?.globalLevelEdit?.resetCount + 1
                },
                branches: {},
            }
        }))
    }

    const handleJobTemplateChange = (e) => {

        const k = e.target.name;
        const v = e.target.value;

        if (k == 'project_job_template_id') {
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [k]: v,
                    task_type: ''
                }
            }))
            resetUpdatedRuntimevariablesData()
        }

        else {
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [k]: v,
                }
            }))
            resetUpdatedRuntimevariablesData()
        }
    }

    console.log(state.runTimeParamsFormState, 'test_001sdd')

    console.log(state, 'def_00p_lmn')

    const getCorrectValueAccordingToInput = (data) => {

        const { targetData, stepVarIndex, envVariableKey, isIntegration } = data;
        const { selectedServiceID, stepCode, stepOrder } = targetData;
        const varFormState = state?.runTimeParamsFormState.stepJsonUpdated
        let envVarData = varFormState[selectedServiceID][stepCode][stepOrder][stepVarIndex]
        console.log(envVarData, 'env_var_key_90p0')
        if (envVarData.is_value_from_output_var) {

            return {
                output_var: envVarData.outputForm?.output_var != undefined ? envVarData.outputForm?.output_var : null
            }
        }
        else {
            return { [envVariableKey]: envVarData?.value != undefined ? envVarData.value : null }
        }
    }


    const getRequiredDataAccordingToState = (targetedServiceData, stepVarIndex, realInputType, target, selectDropdownOptions, integrationKey) => { // to access correct inputs and lists in env input listing

        let isValeuFromOutput = targetedServiceData[stepVarIndex]?.is_value_from_output_var
        if (target == 'input-type') {
            // if (isValeuFromOutput || realInputType == 'toggle' || realInputType == 'select' || integrationKey=='GIT_BRANCH') {
            if (isValeuFromOutput || realInputType == 'toggle' || realInputType == 'select') {

                return 'select'
            }
            return realInputType;
        }

        else if (target == 'listing') {

            if (isValeuFromOutput) {
                let outVarList =
                    targetedServiceData[stepVarIndex]['outputForm'] &&
                        targetedServiceData[stepVarIndex]['outputForm']['output_var_list'] ?
                        targetedServiceData[stepVarIndex]['outputForm']['output_var_list'].map(task => { return { id: task.order, label: task.name } })
                        : []

                return outVarList
            }
            // else if(integrationKey=='GIT_BRANCH'){
            //     return getListOfBranches();
            // }
            else if (realInputType == 'text') {
                return []
            }

            else if (realInputType == 'select' || realInputType == 'toggle') {
                if (realInputType == 'select' || realInputType == 'toggle') {
                    if (selectDropdownOptions) {
                        return selectDropdownOptions?.split(',').map(item => {
                            return { id: item.trim(), label: item.trim() }
                        })
                    }
                    else {
                        return [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]
                    }
                }
            }
        }
    }

    const getBranches = (postData) => {
        return new Promise(function (resolve, reject) {

            PostData(GenerateURL({}, properties.api.git_test),
                postData,
                (data) => {
                    resolve(data)
                },
                (error) => reject(error));
        })

    }

    function getGitUrl(selectedService, stepOrder, integrationKey, name) { //getting git_url and credential
        if (name && name != '') {

            let apiEndpoint = GenerateURL({}, properties.api.fetch_all_repo)

            let requestInfo = {
                endPoint: GenerateSearchURL({ name: name }, apiEndpoint),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(state, 'getter_00p')
            if (state?.runTimeParamsFormState?.branches[selectedService] != undefined) {
                setState(prevState => ({
                    ...prevState,
                    runTimeParamsFormState: {
                        ...prevState.runTimeParamsFormState,
                        branches: {
                            ...prevState.runTimeParamsFormState.branches,
                            [selectedService]: {
                                ...prevState.runTimeParamsFormState.branches[selectedService],
                                [stepOrder]: {
                                    [integrationKey]: {
                                        loading: true,
                                        loaded: false,
                                        branches: []
                                    }

                                }
                            }

                        }
                    }

                }))
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    runTimeParamsFormState: {
                        ...prevState.runTimeParamsFormState,
                        branches: {
                            ...prevState.runTimeParamsFormState.branches,
                            [selectedService]: {
                                [stepOrder]: {
                                    [integrationKey]: {
                                        loading: true,
                                        loaded: false,
                                        branches: []
                                    }

                                }
                            }

                        }
                    }

                }))
            }

            InvokeApi(requestInfo, (response) => {
                console.log(state, response, '   ')

                let gitData = response.results[0]
                if (gitData) {
                    const credential_id = gitData.credential_id
                    const git_url = gitData.git_url
                    let post_data = { git_url: git_url }
                    if (credential_id != undefined) {
                        post_data = { ...post_data, credential_id: credential_id }
                    }
                    let result = []
                    getBranches(post_data).then(response => {
                        console.log(response, 'resp_00p')
                        let { branches } = response
                        if (branches) {
                            if (branches?.length > 0) {
                                branches = branches.map(branch => {
                                    return { id: branch, label: branch }
                                })
                                result = branches
                                if (state?.runTimeParamsFormState?.branches[selectedService] != undefined) {
                                    setState(prevState => ({
                                        ...prevState,
                                        runTimeParamsFormState: {
                                            ...prevState.runTimeParamsFormState,
                                            branches: {
                                                ...prevState.runTimeParamsFormState.branches,
                                                [selectedService]: {
                                                    ...prevState.runTimeParamsFormState.branches[selectedService],
                                                    [stepOrder]: {
                                                        [integrationKey]: {
                                                            loading: false,
                                                            loaded: true,
                                                            branches: [...branches]
                                                        }
                                                    }
                                                }

                                            }
                                        }

                                    }))
                                }


                                else {
                                    setState(prevState => ({
                                        ...prevState,
                                        runTimeParamsFormState: {
                                            ...prevState.runTimeParamsFormState,
                                            branches: {
                                                ...prevState.runTimeParamsFormState.branches,
                                                [selectedService]: {
                                                    [stepOrder]: {
                                                        [integrationKey]: {
                                                            loading: false,
                                                            loaded: true,
                                                            branches: [...branches]
                                                        }
                                                    }
                                                }

                                            }
                                        }

                                    }))

                                }
                            }
                        }
                    })
                        .catch(error => {
                            console.log(error, 'err_00p')
                        })

                    return result
                }

            }, (error) => {
                console.log(error, 'error_00p_lmn')
            });
        }
    }

    const isSpecificBranchesLoading = (selectedServiceId, stepOrder, integrationKey) => {
        try {
            let isLoading = state.branches[selectedServiceId][stepOrder][integrationKey]['loading']
            return isLoading
        }
        catch (e) {
            return false
        }

    }

    const getListOfBranches = (selectedServiceId, stepOrder, integrationKey) => {

        console.log(selectedServiceId, stepOrder, integrationKey, 'fcp_0p')

        try {
            let branches = state.runTimeParamsFormState.branches[selectedServiceId][stepOrder][integrationKey]['branches']
            return branches
        }
        catch (e) {
            return []
        }
    }

    console.log(state, 'res_00p_mn');

    return (
        <>
            {
                state.runTimeParamsFormState?.isAdvanceRunTimeParamsForm ? <Grid container>
                        
                        <>
                        <div style={{ width: '100%' }} className="d-flex align-center mb-12">
                            <div>
                                <div className="font-15 mr-20">Job Steps Environment Variables</div>
                            </div>
                            <div style={{ marginRight: '-12px' }}>
                                <AdvanceSearchFilterCombo
                                    reset={state.runTimeParamsFormState.resetCount}
                                    selectedCheckBoxes={state.runTimeParamsFormState.selectedServicesForParams}
                                    staticList={state?.runTimeParamsFormState?.staticComponentList ? state.runTimeParamsFormState.staticComponentList : []}
                                    searchVariable='service-name'
                                    uniqueId='run-params-form-service-001'
                                    labelName='Choose Service'
                                    onUpdate={handleServiceSelectUpdate}
                                // getFetchUrl=null
                                // filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                                />
                            </div>

                        </div>
                        <div className={runTimeParamClasses.runTimeParamsContainer}>

                            {/* <div>
                                <label className="font-14">{state?.runTimeParamsFormState.selectedServiceName}</label>
                            </div> */}

                            <div className="border-tab step-condition-tab" style={{ marginBottom: '15px', overflowY: 'auto' }}>
                                <CommonHorizontalTab
                                    variant="removable-tabs"
                                    onRemove={onRemoveTab}
                                    style={{ borderBottom: '1px solid #dedede' }}
                                    tabList={state?.runTimeParamsFormState?.tabList}
                                    selectedTabOrder={state?.runTimeParamsFormState?.selectedTabOrder}
                                    updateSelectedTab={onSelectTab} />
                            </div>
                            <div className='runtime-form-container'>


                                <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>
                                    {
                                        state?.runTimeParamsFormState?.tabList.length > 0 && state?.runTimeParamsFormState?.selectedTabOrder != null ?
                                            <>
                                                {
                                                    state?.runTimeParamsFormState?.loading ?
                                                        <Loading varient="light" />
                                                        :
                                                        <>
                                                            {
                                                                state?.runTimeParamsFormState?.stepJsonUpdated && state?.runTimeParamsFormState?.stepJsonUpdated[state.runTimeParamsFormState.selectedTabOrder] && state.runTimeParamsFormState.runTimeModifiableVariables[state.runTimeParamsFormState.selectedTabOrder]?.map((step, order) => {
                                                                    const selectedServiceID = state.runTimeParamsFormState.selectedTabOrder
                                                                    console.log(selectedServiceID, 'def_00p_0pmn')
                                                                    const stepCode = step.step_code
                                                                    const stepOrder = step.order
                                                                    const varForm = state?.runTimeParamsFormState?.stepJsonUpdated
                                                                    console.log(varForm, 'var_form_001p')
                                                                    const loaderState = state?.runTimeParamsFormState?.loaderVarFormStateAccordingToStepsAndServices
                                                                    const targetedServiceData = varForm[selectedServiceID][stepCode][stepOrder]
                                                                    console.log(targetedServiceData, 'data_0001_0kmn')
                                                                    const targetServiceLoader = loaderState[selectedServiceID][stepOrder]
                                                                    const targetObj = {
                                                                        selectedServiceID: selectedServiceID,
                                                                        stepCode: stepCode,
                                                                        stepOrder: stepOrder,
                                                                    }
                                                                    const data = state.runTimeParamsFormState?.stepJsonUpdated[selectedServiceID][stepOrder]
                                                                    // state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]
                                                                    console.log(step, 'step_001_mnf')
                                                                    return (
                                                                        <Grid key={`${state.runTimeParamsFormState.selectedTabOrder}_${order}`} style={{ paddingTop: '15px' }} item xs={12}>

                                                                            <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                                                <span className='font-13 mr-12'>Step Name</span>
                                                                                <label style={{ color: 'gray' }} className='font-13'>{step.step_name ? step.step_name : step.step_code}</label>
                                                                            </Grid>

                                                                            <div style={{
                                                                                paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                                                backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                {
                                                                                    step && step.environment_variables.map((variable, stepVarIndex) => {
                                                                                        console.log(variable, stepVarIndex, 'var_data_001_0psd')

                                                                                        const isValueFromOutputVar = targetedServiceData[stepVarIndex]?.is_value_from_output_var
                                                                                        const outputVarData = targetedServiceData[stepVarIndex]['outputForm']
                                                                                        const taskList = targetedServiceData[stepVarIndex]['outputForm']['task_list']
                                                                                        const servicesList = targetedServiceData[stepVarIndex]['outputForm']['services'] || null;
                                                                                        const stepList = targetedServiceData[stepVarIndex]['outputForm']['step_list']
                                                                                        const outputVarList = targetedServiceData[stepVarIndex]['outputForm']['output_var_list']
                                                                                        const deploymentList = targetedServiceData?.[stepVarIndex]?.['outputForm']?.['deployment_list'] || null;
                                                                                        console.log("djhdjhdsj",taskList);
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    variable.integration ?
                                                                                                        <>
                                                                                                            {
                                                                                                                variable.integration_values?.map(integrationObj => {
                                                                                                                    return (<>
                                                                                                                        {
                                                                                                                            Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                                                const integrationData = integrationObj[integrationKey]

                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        {integrationData?.map(integration => {

                                                                                                                                            return (

                                                                                                                                                integration.key === 'GIT_BRANCH' ?
                                                                                                                                                    <Grid container spacing={2}>
                                                                                                                                                        {
                                                                                                                                                            isValueFromOutputVar &&
                                                                                                                                                            <>
                                                                                                                                                                <div className='font-12' style={{ width: '100%' }}>
                                                                                                                                                                    {integration.key}
                                                                                                                                                                </div>
                                                                                                                                                                <Grid key={stepVarIndex} item xs={4}>
                                                                                                                                                                    <Input
                                                                                                                                                                        type='select'
                                                                                                                                                                        name='stage'
                                                                                                                                                                        list={stages ? stages.map(item => {
                                                                                                                                                                            return { id: item.name, label: item.name }
                                                                                                                                                                        }) : []}
                                                                                                                                                                        label='Stage'
                                                                                                                                                                        placeholder=""
                                                                                                                                                                        onChangeHandler={e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key })}
                                                                                                                                                                        data={outputVarData}
                                                                                                                                                                        error={state.error}
                                                                                                                                                                    />
                                                                                                                                                                </Grid>
                                                                                                                                                                <Grid key={stepVarIndex} item xs={4}>
                                                                                                                                                                    <Input
                                                                                                                                                                        type='select'
                                                                                                                                                                        list={taskList ? taskList.map(task => { return { id: task.order, label: `${task.name || task?.task_type} order-${task.order}` } }) : []}
                                                                                                                                                                        name='previous_job'
                                                                                                                                                                        label='Previous Job'
                                                                                                                                                                        placeholder=""
                                                                                                                                                                        onChangeHandler={taskList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key }) : false}
                                                                                                                                                                        data={outputVarData}
                                                                                                                                                                        error={state.error}
                                                                                                                                                                    />
                                                                                                                                                                </Grid>
                                                                                                                                                                {servicesList && 
                                                                                                                                                                    <Grid key={stepVarIndex} item xs={4}>
                                                                                                                                                                        <Input
                                                                                                                                                                            type='select'
                                                                                                                                                                            list={servicesList ? servicesList.map(task => { return { id: task.name, label: task.name } }) : []}
                                                                                                                                                                            name='previous_job_service'
                                                                                                                                                                            label='Previous Job Services'
                                                                                                                                                                            placeholder=""
                                                                                                                                                                            onChangeHandler={servicesList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key }) : false}
                                                                                                                                                                            data={outputVarData}
                                                                                                                                                                            error={state.error}
                                                                                                                                                                        />
                                                                                                                                                                    </Grid>
                                                                                                                                                                }
                                                                                                                                                                {deploymentList && 
                                                                                                                                                                        <Grid item xs={4}>
                                                                                                                                                                            <Input
                                                                                                                                                                                type='select'
                                                                                                                                                                                list={deploymentList ? deploymentList.map(cd => { return { id: cd, label: cd } }) : []}
                                                                                                                                                                                name='deployment_name'
                                                                                                                                                                                label='Select Deployment'
                                                                                                                                                                                placeholder=""
                                                                                                                                                                                onChangeHandler={deploymentList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key }) : false}
                                                                                                                                                                                data={outputVarData}
                                                                                                                                                                                error={state.error}
                                                                                                                                                                            />
                                                                                                                                                                        </Grid>
                                                                                                                                                                }
                                                                                                                                                                <Grid key={stepVarIndex} item xs={4}>
                                                                                                                                                                    <Input
                                                                                                                                                                        type='select'
                                                                                                                                                                        list={stepList ? stepList.map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                                                                                                                                                                        name='step_order'
                                                                                                                                                                        label='Step Name'
                                                                                                                                                                        placeholder=""
                                                                                                                                                                        onChangeHandler={stepList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key }) : false}
                                                                                                                                                                        data={outputVarData}
                                                                                                                                                                        error={state.error}
                                                                                                                                                                    />
                                                                                                                                                                </Grid>
                                                                                                                                                            </>
                                                                                                                                                        }
                                                                                                                                                        <Grid key={stepVarIndex} item xs={isValueFromOutputVar ? 4 : 12}>
                                                                                                                                                            {false ?
                                                                                                                                                                <Loading varient="light" />
                                                                                                                                                                :
                                                                                                                                                                <div style={{ position: 'relative' }}>
                                                                                                                                                                    <SideLabelForInput isValueFromOutputVar={isValueFromOutputVar} onHandleChange={() => onHandleChangeForInputType({ ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key })} />
                                                                                                                                                                    <Input
                                                                                                                                                                        type={getRequiredDataAccordingToState(targetedServiceData, stepVarIndex, 'text', 'input-type', false, integration.key)}
                                                                                                                                                                        name={isValueFromOutputVar ? 'output_var' : integration.key}
                                                                                                                                                                        label={isValueFromOutputVar ? 'Output variable' : `${integration.key}`}
                                                                                                                                                                        placeholder=""
                                                                                                                                                                        list={getRequiredDataAccordingToState(targetedServiceData, stepVarIndex, 'text', 'listing', false, integration.key)}

                                                                                                                                                                        onChangeHandler={isValueFromOutputVar ?
                                                                                                                                                                            outputVarList ?
                                                                                                                                                                                e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key }) :
                                                                                                                                                                                false :
                                                                                                                                                                            e => handleChangeUpdated(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: integration.key })}
                                                                                                                                                                        data={getCorrectValueAccordingToInput({
                                                                                                                                                                            targetData: targetObj,
                                                                                                                                                                            stepVarIndex: stepVarIndex,
                                                                                                                                                                            envVariableKey: integration.key,
                                                                                                                                                                            isIntegration: true
                                                                                                                                                                        })}
                                                                                                                                                                        error={state.error}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </Grid>
                                                                                                                                                    </Grid>

                                                                                                                                                    : null
                                                                                                                                            )
                                                                                                                                        })}
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>)

                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                        :

                                                                                                        <Grid container spacing={2}>
                                                                                                            <>
                                                                                                                {
                                                                                                                    isValueFromOutputVar &&
                                                                                                                    <>
                                                                                                                        <div className='font-12' style={{ width: '100%' }}>
                                                                                                                            {variable.key}
                                                                                                                        </div>
                                                                                                                        <Grid key={stepVarIndex} item xs={4}>

                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                name='stage'
                                                                                                                                list={stages ? stages.map(item => {
                                                                                                                                    return { id: item.name, label: item.name }
                                                                                                                                }) : []}
                                                                                                                                label='Stage'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key })}
                                                                                                                                data={targetedServiceData[stepVarIndex]['outputForm']}
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                        <Grid key={stepVarIndex} item xs={4}>

                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                list={targetedServiceData[stepVarIndex]['outputForm']['task_list'] ? targetedServiceData[stepVarIndex]['outputForm']['task_list'].map(task => { return { id: task.order, label: `${task.name} order-${task.order}` } }) : []}
                                                                                                                                name='previous_job'
                                                                                                                                label='Previous Job'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={targetedServiceData[stepVarIndex]['outputForm']['task_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key }) : false}
                                                                                                                                data={targetedServiceData[stepVarIndex]['outputForm']}
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                        {servicesList && 
                                                                                                                            <Grid key={stepVarIndex} item xs={4}>
                                                                                                                                <Input
                                                                                                                                    type='select'
                                                                                                                                    list={servicesList ? servicesList.map(task => { return { id: task.name, label: task.name } }) : []}
                                                                                                                                    name='previous_job_service'
                                                                                                                                    label='Previous Job Services'
                                                                                                                                    placeholder=""
                                                                                                                                    onChangeHandler={servicesList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key }) : false}
                                                                                                                                    data={targetedServiceData[stepVarIndex]['outputForm']}
                                                                                                                                    error={state.error}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                        }
                                                                                                                        {deploymentList && 
                                                                                                                                <Grid item xs={4}>
                                                                                                                                    <Input
                                                                                                                                        type='select'
                                                                                                                                        list={deploymentList ? deploymentList.map(cd => { return { id: cd, label: cd } }) : []}
                                                                                                                                        name='deployment_name'
                                                                                                                                        label='Select Deployment'
                                                                                                                                        placeholder=""
                                                                                                                                        onChangeHandler={deploymentList ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key }) : false}
                                                                                                                                        data={targetedServiceData[stepVarIndex]['outputForm']}
                                                                                                                                        error={state.error}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                        }
                                                                                                                        <Grid key={stepVarIndex} item xs={4}>

                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                list={targetedServiceData[stepVarIndex]['outputForm']['step_list'] ? targetedServiceData[stepVarIndex]['outputForm']['step_list'].map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                                                                                                                                name='step_order'
                                                                                                                                label='Step Name'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={targetedServiceData[stepVarIndex]['outputForm']['step_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key }) : false}
                                                                                                                                data={targetedServiceData[stepVarIndex]['outputForm']}
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }
                                                                                                                <Grid key={stepVarIndex + isValueFromOutputVar} item xs={4}>
                                                                                                                    <div style={{ position: 'relative' }}>
                                                                                                                        <SideLabelForInput isValueFromOutputVar={isValueFromOutputVar} onHandleChange={() => onHandleChangeForInputType({ ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key })} />


                                                                                                                        <Input
                                                                                                                            type={getRequiredDataAccordingToState(targetedServiceData, stepVarIndex, variable.input_type, 'input-type')}

                                                                                                                            name={isValueFromOutputVar ? 'output_var' : variable.key}
                                                                                                                            list={getRequiredDataAccordingToState(targetedServiceData, stepVarIndex, variable.input_type, 'listing', variable.select_dropdown_options)}
                                                                                                                            label={isValueFromOutputVar ? 'Out put variable' : `${variable.key}`}
                                                                                                                            placeholder=""

                                                                                                                            onChangeHandler={isValueFromOutputVar ?
                                                                                                                                outputVarList ?
                                                                                                                                    e => handleChangeForOutputVariableForm(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key }) :
                                                                                                                                    false :
                                                                                                                                e => handleChangeUpdated(e, { ...targetObj, envVaribaleIndex: stepVarIndex, envVariableKey: variable.key })}

                                                                                                                            data={getCorrectValueAccordingToInput({
                                                                                                                                targetData: targetObj,
                                                                                                                                stepVarIndex: stepVarIndex,
                                                                                                                                envVariableKey: variable.key,
                                                                                                                                isIntegration: false
                                                                                                                            })} error={state.error}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Grid>
                                                                                                            </>
                                                                                                        </Grid>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>

                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }


                                            </>

                                            :
                                            <>
                                                {
                                                    state.runTimeParamsFormState?.tabList?.length == 0 ?
                                                        <div className="d-flex align-center justify-center font-12 full-width">
                                                            Please Select Service To See Runtime parameters
                                                        </div>
                                                        :
                                                        state.runTimeParamsFormState?.selectedTabOrder == null ?
                                                            <div className="d-flex align-center justify-center font-12 full-width">
                                                                Please Click On service tabs to update/override runtime parameters

                                                            </div>

                                                            : null
                                                }
                                            </>
                                    }
                                </Grid>
                            </div>

                        </div>
                        </>
                        
                    <div style={{ width: '100%', borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit', background: '#fff' }} className="fill-details-footer border-top mt-10">
                        <div style={{ height: "30px", minWidth: "80px" }} onKeyDown={() => { }} onClick={onhandleCancelTrigger} className="btn btn-outline-grey d-flex align-center justify-center" tabIndex={0} role='button'>Cancel</div>
                        <div className="btn btn-add" onKeyDown={() => { }} onClick={handleOnSaveRuntimeParams} role='button' tabIndex={0}
                        >{'Save'}</div>
                    </div>

                </Grid>
                    :
                    <Grid container>
                        <Grid item lg={12}>
                            <Input
                                type="select"
                                mandatorySign
                                name="env"
                                list={getListableArray(props?.env_list)}
                                label="Project Env"
                                onChangeHandler={handleEnvInput
                                }
                                error={state.error}
                                data={state.data} />
                        </Grid>
                        {
                            // job_templates?.filter(template => template.version === 'v3')?.length != 0 ?
                            job_templates?.length != 0 ?

                                <Grid item lg={12}>
                                    <Input
                                        type="select"
                                        mandatorySign
                                        name="project_job_template_id"
                                        list={getListableArray(job_templates)}
                                        label="Job Template"
                                        onChangeHandler={(state.data.env != null && state.data.env != '') ? handleJobTemplateChange : false
                                        }
                                        error={state.error}
                                        data={state.data} />
                                </Grid> :
                                <AlertStrip message="Please add v3 job templates first" vairant="info" />
                        }

                        <Grid item lg={12}>
                            {
                                (state.job_name_list?.length == 0 && state?.versionOfSelectedTemplate == 'v2') ?

                                    <AlertStrip variant="p-v3-alert" message="No dynamic job exist in this template , please select a diffrent template" vairant="info" />

                                    :
                                    <Input
                                        type="select"
                                        mandatorySign
                                        name="task_type"
                                        list={state.job_name_list}
                                        label="Job Type"
                                        onChangeHandler={state.job_name_list?.length > 0 ? handleJobTemplateChange : false
                                        }
                                        error={state.error}
                                        data={state.data} />
                            }

                        </Grid>
                        {
                            state.loading_components ?
                                <Loading varient="light" />
                                : <>
                                    {
                                        jobJsonData?.is_dependent && state.all_services?.length > 0 ?
                                            <div className='service-container-v3 mt-5' style={{ width: '100%', position: 'relative ' }}>
                                                <div className='service-overridable-container' style={{ position: 'absolute', right: 0, top: '-12px' }}>
                                                    <Checkbox checked={jobJsonData?.entity_resource_data?.is_overridden} onChange={handleServiceOverridden} name={''} value={''} />
                                                    <label style={{ color: '#505050' }} className='font-12 font-weight-400'>Overridable at Runtime</label>
                                                </div>
                                                <Grid item lg={12}>
                                                    <Input
                                                        type="checkbox"
                                                        name="components"
                                                        mandatorySign
                                                        varient="inner_component"
                                                        label="Services"
                                                        list={state.all_services}
                                                        onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : false}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                                </Grid>
                                            </div>
                                            :
                                            jobJsonData?.is_dependent && state.data.task_type && state.data.task_type != '' && state.all_services?.length === 0 ?

                                                <div style={{ width: '100%' }}>
                                                    <AlertStrip variant="p-v3-alert" message={`${state.data.task_type} Job is not configured. Please select a different job.`} />
                                                </div>
                                                :

                                                !jobJsonData?.is_dependent && state.data.task_type && state.data.task_type != '' ?

                                                    <>

                                                        <OverrideVariablesForNonDependentJobs
                                                            jobData={jobJsonData?.jobData}
                                                            independentJobData={independentJobData}
                                                            setParentState={setParentState}
                                                            editFlowJobStepJson={editFlowJobStepJson}
                                                            stages={stages}
                                                            job_templates={job_templates}
                                                        />
                                                    </> : null
                                    }

                                </>

                        }
                        {
                            state?.data?.components?.length > 0 && jobJsonData?.is_dependent &&
                            <>
                                <div style={{
                                    height: "50px", border: '1px solid #dedede',
                                    borderRadius: '0px',
                                    width: '100%',
                                    padding: "10px",
                                    boxShadow: "none",
                                    marginTop: "12px",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    borderBottomLeftRadius: '6px',
                                    borderBottomRightRadius: '6px'
                                }} className={classes.card_shadow + " " + "bg-f8-grey border-top d-flex align-center space-between"}>
                                    <div style={{ color: '#000', lineHeight: '1.5' }} className="font-13">Provide/Update Runtime Environment Variables</div>
                                    {/* <div className='remove-margin-from-input'>
                                        <Input
                                            type="only-switch"
                                            label=""
                                            name="is_advance_configuration"
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={toggleState} />

                                    </div> */}

                                    {
                                        (state.runTimeParamsFormState?.globalLevelEdit?.isEditActive || (editRuntimeFormState && Object.keys(editRuntimeFormState.stepJsonUpdated)?.length > 0)) ?

                                            <Tooltip title='Edit'>

                                                <EditIcon onClick={toggleState} style={{ cursor: 'pointer', color: '#4395d9', fontSize: '20px' }} />
                                            </Tooltip>
                                            :
                                            <span style={{ cursor: 'pointer', color: '#4395d9' }} onKeyDown={() => { }} onClick={toggleState} role="button" tabIndex={0}>+Add</span>
                                    }
                                </div>

                                <div style={{ height: "100px", width: "100%", border: "1px solid #dedede", borderTop: 'none', borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px", padding: '15px', overflow: 'auto' }}>
                                    {

                                        <>
                                            {
                                                editRuntimeFormState && Object.keys(editRuntimeFormState.stepJsonUpdated)?.length > 0 ?
                                                    <div style={{ width: '100%' }}>
                                                        {
                                                            Object.keys(editRuntimeFormState.stepJsonUpdated)?.map(serviceID => {

                                                                let serviceName = serviceID
                                                                // will obtain  correct service Name
                                                                if (serviceID == '00') {
                                                                    serviceName = 'All'
                                                                }
                                                                else {
                                                                    serviceName = serviceIdKeyObject[serviceID]
                                                                }

                                                                return (
                                                                    <div style={{ marginBottom: "20px", borderBottom: '1px solid #dedede' }} className='d-flex align-center space-between'>
                                                                        <label className='font-14'>{serviceName}</label>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> :
                                                    state?.runTimeParamsFormState?.globalLevelEdit?.isEditActive ?
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                {
                                                                    Object.keys(state.runTimeParamsFormState.globalLevelEdit.dataForEdit)?.map(serviceID => {
                                                                        let serviceName = serviceID
                                                                        // will obtain  correct service Name
                                                                        if (serviceID == '00') {
                                                                            serviceName = 'All'
                                                                        }
                                                                        else {
                                                                            serviceName = serviceIdKeyObject[serviceID]
                                                                        }
                                                                        return (
                                                                            <div style={{ marginBottom: "20px", borderBottom: '1px solid #dedede' }} className='d-flex align-center space-between'>
                                                                                <label className='font-14'>{serviceName}</label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ width: '100%', height: '100%' }} className='d-flex align-center justify-center'>
                                                            <label className="font-12">No Advance Configuration is added</label>

                                                        </div>

                                            }
                                        </>
                                    }

                                </div>
                            </>
                        }
                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>

                        </Grid>


                    </Grid>
            }
        </>

    )
}

AddVersionThreeJobs.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default AddVersionThreeJobs;

const useStyles = makeStyles((theme) => ({
    runTimeParamsContainer: {
        border: "1px solid #dedede",
        borderRadius: "7px",
        width: "100%",
        '& .runtime-form-container': {
            padding: "10px",
        },
        '& .border-tab': {
            borderBottom: '1px solid #b7b7b7',
        }
    }
}));


export const SideLabelForInput = ({ isValueFromOutputVar, onHandleChange, forIndependent=false }) => {

    const handleOnClick = () => {

        onHandleChange()
    }

    return <button
        onClick={handleOnClick}
        style={{ position: 'absolute', right: 0, cursor: 'pointer', top: `${forIndependent ? '-12px' :"-5px"}`,textTransform:"capitalize", zIndex: 1 }}
        className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0">{!isValueFromOutputVar ? 'Select From Previous Job' : 'Provide Custom Value'}</button>

}

SideLabelForInput.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};