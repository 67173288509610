
import GenerateURL from './APIUrlProvider';
import properties from '../properties/properties';
import InvokeApi, { PostData } from './apiInvoker';
import initializeRoutes from '../routes';

var minutesInMillis = 60 * 1000;
var secondsInMillis = 1000;
var hoursInMillis = 60 * 60 * 1000;
const inexedDbKeyName = 'BP'
const checkSizeForIndexedDb = 3 // in mb
export function PersistLoginCreds(userData) {

    userData.assumed_role_superadmin = userData.is_superuser
    var authData = {
        authenticated: true,
        userDetails: userData,
        accessTokenExpiresOn: (new Date()).getTime() + 5.5 * hoursInMillis,
        expiresOn: (new Date()).getTime() + 6.5 * hoursInMillis
    }
    localStorage.setItem("authData", JSON.stringify(authData));
    console.log(JSON.parse(localStorage.getItem("authData")), "opop1")
}

export function RemoveLoginCreds(handleLogoutResponse, handleLogoutFailure) {
    var logoutURL = GenerateURL({}, properties.api.logoutURL);
    var data = {
        token: GetAuth()?.refresh
    }
    const handleLogoutResponseLocal = (data) => {
        localStorage.setItem("authData", null);
        localStorage.setItem("user_want_to_access_beta", null);
        localStorage.clear();
        removeIndexedDb();
        handleLogoutResponse(data);
        // window.history.replaceState({}, document.title)
        // clearing indexdDbOn logout
    }
    clearGlobalSseConnection();
    PostData(logoutURL, data, handleLogoutResponseLocal, handleLogoutFailure,false,true);

}

const clearGlobalSseConnection = () => {
    let requestID = localStorage.getItem('global_sse_id')
    if (requestID) {
        let postData = { request_id: requestID, status: false };

        const endPoint = GenerateURL({}, properties.api.closed_sse_from_backend);
        PostData(endPoint, postData, (response) => {
            if (response) {
                localStorage.clear()
            }
        },
            (error) => {
                localStorage.clear()
            });
    }
}


export function IsAuthenticated() {
    const authDataString = localStorage.getItem("authData");

    if (authDataString != null && authDataString != "null") {
        var now = new Date();
        var authData = JSON.parse(authDataString);
        if ((authData.accessTokenExpiresOn - now.getTime()) > 20 * secondsInMillis) {
            return true;
        }
    }
    return false;
}

export function IsSuperUser(args) {
    console.log('args----->', args)
    const user_data = GetAuth();
    return user_data ? user_data.assumed_role_superadmin : null;
}

export function IsUserActualSuperUser() {
    const user_data = GetAuth();
    return user_data ? user_data.is_superuser : null;
}

export function GetAuth() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    return authData ? authData.userDetails : null;
}

export function RegenerateRefreshAndAccessToken(handleRefreshResponse, handleRefreshFailure) {
    var authData = JSON.parse(localStorage.getItem("authData"));
    console.log("refreshTOken", authData.userDetails.refresh)
    var data = {
        refresh: authData.userDetails.refresh
    }
    var url = GenerateURL({}, properties.api.refreshTokenURL);
    const handleRefreshResponseLocal = (data) => {
        console.log(data, "opop")
        authData.userDetails.refresh = data.refresh;
        authData.userDetails.access = data.access;
        authData.expiresOn = (new Date()).getTime() + 6.5 * hoursInMillis;
        authData.accessTokenExpiresOn = (new Date()).getTime() + 5.5 * hoursInMillis;
        localStorage.setItem("authData", JSON.stringify(authData));
        handleRefreshResponse(data);
    }

    const handleRefreshFailureLocal = (data) => {
        handleRefreshFailure(data);
    }

    PostData(url, data, handleRefreshResponseLocal, handleRefreshFailureLocal, true);
}


export function IsRefreshTokenValid() {
    var now = new Date();
    const authDataString = localStorage.getItem("authData");
    if (authDataString != null && authDataString != "null") {
        var authData = JSON.parse(authDataString);
        if (((authData.expiresOn - now.getTime()) > 20 * secondsInMillis)) {
            return true;
        }
    }

    return false;
}

export function UpdateAuthUserData(userData, reloadRoutes) {
    console.log("success updateauth")
    let authData = {}
    const authDataFromLocalStorage = JSON.parse(localStorage.getItem("authData"))
    authData = authDataFromLocalStorage ? authDataFromLocalStorage : {}; // handling user detail not exist
    if (authData.userDetails) {
        authData.userDetails = {
            ...authData.userDetails,
            ...userData,
        }
    }
    else {
        authData.userDetails = {
            ...userData
        }
    }
    localStorage.setItem("authData", JSON.stringify(authData));
    console.log(userData, 'inside_', userData);
    if (userData?.permission != undefined) {
        const { mb } = getSizeOfData(userData?.permission);
        localStorage.setItem('permission_size', mb)
        localStorage.setItem('permission_place', 'localStorage')
    }
    reloadRoutes && initializeRoutes()
}

export function getPermissionCacheFunctionalityValue() {

    console.log('called_for_feature')
    return new Promise((resolve, reject) => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_permissoin_store_in_indexedDB_flag),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        console.log('invoke api_00sd')

        InvokeApi(requestInfo,
            (response) => {
                console.log(response, 'feature_flag_001')
                resolve(response)
            }, (error) => {
                reject(error)
            }
        )

    })


}
export function GetPermitedURLs(handleSucess, handleFail) {
    var requestInfo = {
        endPoint: GenerateURL({}, properties.api.permission_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleSucess ? handleSucess : (response) => {
        console.log('permission_ersd', response)
        getPermissionCacheFunctionalityValue().then(flag => {
            if (flag) {
                let updatedResponse = savePermissionToCorrectPlaceAndGetUpdatedResponse(response);
                console.log(updatedResponse, 'updated_response_0032')
                UpdateAuthUserData(updatedResponse, false)
            }
            else {
                UpdateAuthUserData(response, false)
            }
        })
            .catch(valueError => {
                console.log('error in fetching permisison cache feature flag', valueError)
            })

    }, handleFail ? handleFail : (error) => { console.log(error) });
}

export function GetPermitedURLsPromisified() {
    return new Promise((resolve, reject) => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.permission_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => {
            console.log('permission_ersd', response);
            getPermissionCacheFunctionalityValue()
                .then(flag => {
                    if (flag) {
                        let updatedResponse = savePermissionToCorrectPlaceAndGetUpdatedResponse(response);
                        console.log(updatedResponse, 'updated_response_0032');
                        UpdateAuthUserData(updatedResponse, false);
                        resolve(updatedResponse);
                    } else {
                        UpdateAuthUserData(response, false);
                        resolve(response);
                    }
                })
                .catch(valueError => {
                    console.log('error in fetching permission cache feature flag', valueError);
                    reject(valueError);
                });
        },
            (error) => {
                console.log(error);
                reject(error);
            });
    });
}

// do not change false as true in the above UpdateAuthUserData function.

export function GetPermitedURLsAfterSwitch() {
    var requestInfo = {
        endPoint: GenerateURL({}, properties.api.permission_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, (response) => {
        getPermissionCacheFunctionalityValue().then(flag => {
            if (flag) {
                let updatedResponse = savePermissionToCorrectPlaceAndGetUpdatedResponse(response);
                console.log(updatedResponse, 'updated_response_0032')
                UpdateAuthUserData(updatedResponse, false)
            }
            else {
                UpdateAuthUserData(response, false)
            }
        })
            .catch(valueError => {

                console.log('error in fetching permisison cache feature flag', valueError)
            })
    }, (error) => { console.log(error) });
}

export function SwitchToSuperAdminRole() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    if (authData) {
        var user_details = authData.userDetails;
        user_details.assumed_role_superadmin = user_details.is_superuser;
        authData.userDetails = user_details
        localStorage.setItem("authData", JSON.stringify(authData));
    }
    console.log(JSON.parse(localStorage.getItem("authData")), "opop")
    return authData.userDetails.is_superuser && authData.userDetails.assumed_role_superadmin;
}

export function SwitchToNormalUserRole() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    if (authData) {
        var user_details = authData.userDetails;
        user_details.assumed_role_superadmin = false;
        authData.userDetails = user_details
        localStorage.setItem("authData", JSON.stringify(authData));
    }
    console.log(JSON.parse(localStorage.getItem("authData")), "opop___")
    return !authData.userDetails.is_superuser && !authData.userDetails.assumed_role_superadmin;
}

//indexedDb methods

function removeIndexedDb() {
    const request = indexedDB.deleteDatabase('PermissionsDB');
    request.onsuccess = function (event) {
        console.log('deleteSuccess', event)
    };

    request.onerror = function (event) {
        console.error('error -> delete database:', event.target.error)
    };

    request.onblocked = function (event) {
        console.log('blocked db deletetion', event)
    };
}

const savePermissions = (db, permissions) => {

    const transaction = db.transaction(['permissions'], 'readwrite')
    const store = transaction.objectStore('permissions');

    store.put(permissions);

    transaction.oncomplete = () => {
        console.log('added permissions successfully')
    };

    transaction.onerror = (event) => {
        console.error('Error storing permissions:', event.target.error)
    };
};

export const createIndexedDbDatabase = (permissions) => {
    console.log(permissions, 'permissions_00sd')
    const request = indexedDB.open("PermissionsDB", 1);
    console.log(request, 'request_00p')

    request.onerror = (event) => {
        console.error(event, 'indexedDb_error');
    };
    request.onupgradeneeded = (event) => {
        console.log('upgrade_function_called_0sd')
        let db = event.target.result;
        if (!db.objectStoreNames.contains('permissions')) {
            db.createObjectStore('permissions', { keyPath: 'id' });
        }
    };

    request.onsuccess = (event) => {
        const db = event.target.result;
        console.log('Database opened successfully');
        savePermissions(db, { id: inexedDbKeyName, permissions: permissions?.permission });
    };
}

export const getPermissionsFromIndexedDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('PermissionsDB', 1)
        request.onsuccess = function (event) {
            const db = event.target.result
            if (db.objectStoreNames.contains('permissions')) {
                const transaction = db.transaction(['permissions'], 'readonly')
                const store = transaction.objectStore('permissions')
                const getRequest = store.get(inexedDbKeyName)
                getRequest.onsuccess = () => {
                    if (getRequest.result) {
                        console.log(getRequest.result, 'result_calling_ew')
                        resolve(getRequest.result.permissions)
                    } else {
                        resolve(null);
                    }
                };

                getRequest.onerror = (even) => {
                    reject('Error fetching permissions from IndexedDB')
                };
            }
            else {
                console.log('_indexedDB_not_store_found')
            }

        };

        request.onerror = (event) => {
            reject('Error opening IndexedDB')
        };
    });
};


export const getSizeOfData = (data) => {
    console.log(data, 'data_in_gets_0we')
    const serializedPermissions = JSON.stringify(data);
    const sizeInBytes = serializedPermissions.length * 2; // Approximate, assuming UTF-16 encoding
    const kiloBytes = sizeInBytes / 1024;
    const megaBytes = kiloBytes / 1024;
    console.log(serializedPermissions, sizeInBytes, kiloBytes, megaBytes, 'sizes')
    return { bytes: sizeInBytes, kb: kiloBytes, mb: megaBytes };
}

export const getPermissionAsyncFun = async () => {
    try {
        const permissionPlace = localStorage.getItem('permission_place') // possible values -> indexedDB , localStorage
        console.log(permissionPlace, 'pp_plce')
        if (permissionPlace == 'indexedDB') { // fetch from indexd Db
            const permissions = await getPermissionsFromIndexedDB();
            return permissions;
        }
        else {
            //  fetch from local storage
            let userDetails = GetAuth();
            return userDetails.permission
        }
    }

    catch (error) {
        console.log(error, 'error_in permission loading...')
        return null;
    }
}

export const savePermissionToCorrectPlaceAndGetUpdatedResponse = (response) => {
    // get size of permissions 
    const { mb } = getSizeOfData(response?.permission);
    localStorage.setItem('permission_size', mb)
    if (mb > checkSizeForIndexedDb) { // store in indexedDB
        let copiedResponse = JSON.parse(JSON.stringify(response))
        createIndexedDbDatabase(response);
        // delete resposne  to avoid store in local storage
        delete copiedResponse.permission
        localStorage.setItem('permission_place', 'indexedDB')
        return copiedResponse
    }
    else {
        localStorage.setItem('permission_place', 'localStorge')
        return response
    }
}
