import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import GenericSkeleton from './Skeletons/GenericSkeleton';


const BpAgentStatusActionAlert = ({ open, onClose,type, onTrigger,loading }) => {

    const classes = useStyles();

    return (

        <Dialog className={classes.root} onClose={() =>onClose()} open={open}>

            <div className='container'>
                <div className='content-box'>
                <img style={{marginBottom:'15px'}} src="/images/agent_down.svg" alt="" />
                    <div className='font-20 main-header'>{`${type} is Down`}</div>

                    <div>
                    <span className='font-14'>Please Note:</span>
                    <span className='font-14 info-text'> {`You can still trigger the ${type==='Build Agent'?'build':type==='Deploy Agent'?'deploy':''} and it will be pushed to queue and will be picked for execution when the agent is back online.`}</span>
                    </div>
                </div>

                <div style={{ height: '70px', border: '1px solid #dedede', alignSelf:'end', padding:'16px' }} className='d-flex align-center space-between'>
                    <button className="btn btn-outline-grey" style={{ padding: '1rem', minWidth: "108px" }} onClick={() =>onClose()} >Cancel</button>
                    {!loading ? 
                        <button className="btn btn-primary-v2 d-flex align-center" style={{ padding: '0.5rem 1rem' }} onClick={()=>{onTrigger()}} >Trigger Anyway &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>
                    :
                        <GenericSkeleton width={168} height={39} style={{borderRadius: '6px'}} rootStyle={{flex:0}} />
                    }
                </div>
            </div>
        </Dialog>

    )
}

BpAgentStatusActionAlert.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default BpAgentStatusActionAlert;

const useStyles = makeStyles({
    root: {
        background: 'rgba(0,0,0, 0.3) !important',
        '& .container': {
            display:'grid',
            width: '100%',
            gridTemplateRows:'70% auto',
            height:'300px',
        },
        '& .content-box':{
            padding:'30px',
            display:'flex',
            flexDirection:'column',
            alignItems: 'center',
            '& .main-header':{
                color:'#ff7b7b',
                marginBottom:'15px'
            },
            '& .info-text':{
                color:'gray',
            }
        }
    },

});