import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDebounce } from "../../../../hooks/useDebounce";
import properties from "../../../../properties/properties";
import InvokeApi, { PostData } from "../../../../util/apiInvoker";
import { getJiraTypeIcon, JiraTicket } from "./AddJiraStories";
import { LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import { ErrorComponent } from "../../../utils/Error";
import GenerateURL from "../../../../util/APIUrlProvider";
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";

const SearchJiraTicket = ({ onClose, onJiraTicketClick,inherits,prev_state, selectedTickets,selectedRelease,configuration, path,toggelApiCalling }) => {
    const { application_id } = useParams();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedValue = useDebounce(searchQuery, 400);
    const [state, setState] = useState(prev_state ? prev_state : {
        search_applied: false,
        data: {
            search_query: "",
            jiraTicketList: [],
            filteredJiraTickteList: [],
        },
        openSearch: false,
        error: {},
        searching: false,
    });
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log("jdsskdjjsdk",prev_state);
    const [hoveredTicket, setHoveredTicket] = useState(null);
    const integrationId = configuration?.integration;
    const pickFrom = configuration?.pick_from;
    const classes = useStylesForJira({ openSearch: state.openSearch, selectedTickets });
    useEffect(() => {
        console.log(selectedTickets,"jiraTicketObjjiraTicketObjjiraTicketObjjiraTicketObj")
        if (selectedTickets) {
        const filteredList = state.data.jiraTicketList.filter((item) => 
            !selectedTickets.some((selected) => selected.story_name === item.story_name)
        );
          setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              filteredJiraTickteList: filteredList,
            },
          }));
        }
    }, [selectedTickets]);

  

    function internalSearch(value){
        const filteredList = state.data.jiraTicketList.filter((item) => 
            item.story_name.toLowerCase().includes(value.toLowerCase())
        );
        console.log("skddksldksl",filteredList);
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                filteredJiraTickteList: filteredList,
            },
            search_applied: true,
            searching: false,
        }));
    }

    function setStateforEmptyQuery(){
        const filteredList = state.data.jiraTicketList.filter((item) => 
            !selectedTickets.some((selected) => selected.story_name === item.story_name)
        );

        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                filteredJiraTickteList: [...filteredList],
            },
            search_applied: false,
            error: null,
        }));
    }

    useEffect(() => {
        if (debouncedValue) {
            if (pickFrom === "all") {
                fetchJiraStories(debouncedValue);
            }else{
                // Internal filtering when pickFrom is not "all"
                internalSearch(debouncedValue);
            }
        } else {
            setStateforEmptyQuery();
            console.log("jksjdksj",state);
        }
    }, [debouncedValue]);

    useEffect(()=>{
     
            if(selectedRelease && configuration){
                fetchValidStories();
            }
        
        
    },[selectedRelease,configuration])

    function fetchValidStories(){
        toggelApiCalling();
        let postData = {release_name: selectedRelease,
            config_data: configuration,
            
        }
        if(path){
            postData.path = path
        }
        PostData(GenerateURL({},properties.api.get_valid_stories),postData,handleSuccessApiHit,handleFailedApiHit,false);
        setState((prevState) => ({
            ...prevState,
            searching: true,
            search_applied: true,
        }));
    }


    // Function to fetch Jira stories
    const fetchJiraStories = (query) => {
        const requestInfo = {
            endPoint: GenerateURL({}, `${properties.api.jira_issue_list}?jira_integration_id=${integrationId}&query=${query}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState((prevState) => ({
            ...prevState,
            searching: true,
            search_applied: true,
        }));
    };

    const handleSuccessApiHit = (response) => {
        const filteredList = response.issues_list.filter((item) => 
                !selectedTickets.some((selected) => selected.story_name === item.story_name)
        );
        
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                jiraTicketList: response?.issues_list || [],
                filteredJiraTickteList: path? []: filteredList,
            },
            searching: false,
            error: "",
        }));
        toggelApiCalling();
    };

    const handleFailedApiHit = (error) => {
        const error_msg = error.detail ? error.detail.toString() : JSON.stringify(error);
        setState(prevState => ({
            ...prevState,
            error: error_msg,
            searching: false,
        }));
        toggelApiCalling();
    };


      

    // Effect to apply debounced search

    const handleSearchQueryChange = (e) => {
        let value = e.target.value;
        setSearchQuery(value);
    };

    const onTicketClick = useCallback((obj) => {
        const filteredList = selectedTickets?.length > 0
            ? state.data.jiraTicketList.filter(item => !selectedTickets.includes(item))
            : state.data.jiraTicketList || [];

        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, 
                filteredJiraTickteList: filteredList, }
        }));
        onJiraTicketClick(obj);
    }, [onJiraTicketClick]);
    console.log("kdlksskld",state);
    const renderTickets = useMemo(() => {
        return state.data.filteredJiraTickteList.map(jiraObject => {
            const { issue_type, story_name, status, valid } = jiraObject;
            const selected = valid && (hoveredTicket === story_name);
            return (
                <div
                    className={`${classes.resultItem} container-item`}
                    key={story_name}
                    style={valid ? {} : { backgroundColor: "#FAFAFA" }}
                    onMouseEnter={() => setHoveredTicket(story_name)}
                    onMouseLeave={() => setHoveredTicket(null)}
                >
                    <div className='d-flex align-center' style={{ gap: "12px" }}>
                        {getJiraTypeIcon(issue_type)}
                        <Tooltip title={story_name} arrow>
                            <div className='jira-summary font-14 font-weight-500 text-ellipsis color-value'>
                                {story_name}
                            </div>
                        </Tooltip>
                    </div>
                    <div className="d-flex align-center justify-end" style={{ gap: '4px' }}>
                        {selected ? (
                            <div className={'blueBtn avtar'} onClick={() => onTicketClick(jiraObject)}>
                                Add
                            </div>
                        ) : (
                            <>
                                <Tooltip title={status} arrow>
                                    <div className={`${valid ? "validBtn" : "greyStatus"} text-ellipsis avtar`}>{status}</div>
                                </Tooltip>
                                <div className={`${valid ? "validBtn" : "InValidBtn"} text-ellipsis avtar`}>{valid ? "Valid" : "InValid"}</div>
                            </>
                        )}
                    </div>
                </div>
            );
        });
    }, [state.data.filteredJiraTickteList, hoveredTicket, classes.resultItem, onTicketClick]);

    return (
        <div className={classes.root}>
            <div className="header">
                <span className="font-16 font-weight-600 color-value">Stories</span>
            </div>
            <div className={classes.inputWrapper}>
                <span className='ri-search-2-line font-20' style={{ lineHeight: '1', color: '#CACACA' }}></span>
                <input type='text' placeholder={!selectedRelease ? 'Select Release from above' :'Search'} name='search_query' value={searchQuery} onChange={handleSearchQueryChange} disabled={!selectedRelease} />
            </div>
            <div className={classes.resultContainer}>
                {!state.search_applied && !selectedRelease ? (
                    <div className="no-stories">
                        <div className='d-flex align-center f-direction-column' style={{ gap: "12px" }}>
                            <div className={classes.iconContainer}>
                                <span className='ri-inbox-line font-28'></span>
                            </div>
                            <span className='font-14 font-weight-600 color-key-78'>No Stories Found</span>
                        </div>
                    </div>
                ) : state.searching ? (
                    <>
                        <LinearProgress sx={{ background: 'linear-gradient(to right, #0086ff, #ffff00)' }} />
                        {/* <div className={classes.resultTable}>
                            <div className="table-header">
                                <span className="font-12 font-weight-600 color-value">Name</span>
                                <span className="font-12 font-weight-600 color-value">Status</span>
                            </div>
                            {
                                renderTickets
                            }
                        </div> */}
                        <div className="no-stories">
                            <div className='d-flex align-center f-direction-column' style={{ gap: "12px" }}>
                                <div className={classes.hourGlassContainer}>
                                    <span className='ri-hourglass-2-fill font-28'></span>
                                </div>
                                <span className='font-14 font-weight-600 color-key-78'>Please Wait , This might take some time</span>
                            </div>
                        </div>
                    </>
                ) : state.error ? (
                        <ErrorComponent error={state.error} />
                ) : state.data.filteredJiraTickteList.length > 0 ? (
                    <div className={classes.resultTable}>
                        <div className="table-header">
                            <span className="font-12 font-weight-600 color-value">Name</span>
                            <span className="font-12 font-weight-600 color-value">Status</span>
                        </div>
                        {
                            renderTickets
                        }
                    </div>
                ) : (
                    <div className="no-stories">
                        <div className='d-flex align-center f-direction-column' style={{ gap: "12px" }}>
                            <div className={classes.iconContainer}>
                                <span className='ri-inbox-line font-28'></span>
                            </div>
                            <span className='font-14 font-weight-600 color-key-78'>No Stories Found</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

SearchJiraTicket.propTypes = {
    onClose: PropTypes.func.isRequired,
    onJiraTicketClick: PropTypes.func.isRequired,
    searchType: PropTypes.string.isRequired,
    selectedTickets: PropTypes.array.isRequired,
    selectedTicketing: PropTypes.object.isRequired,
    onJiraTicketRemove: PropTypes.func.isRequired,
};

export default SearchJiraTicket;

const useStylesForJira = makeStyles((theme) => ({
    searchContainer: {
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        padding: '10px 12px 10px 12px',
        borderBottomLeftRadius: (props) => props.openSearch ? "0px" : "6px",
        borderBottomRightRadius: (props) => props.openSearch ? "0px" : "6px",
        borderBottom: (props) => props.openSearch ? "none" : "1px solid #E6E6E6",

        "& .heading": {
            padding: "10px 12px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .input-component": {
                marginBottom: "0px !important",
            },
            "& .divider": {
                height: "30px",
                width: "1px",
                backgroundColor: "#CACACA",
                margin: "0px 8px",
            }
        },
        "& .search-container": {
            padding: "10px 12px",
            height: "40px",
            border: "1px solid #E6E6E6",
            borderLeft: "none",
            borderRight: "none",
            backgroundColor: "#FAFAFA",
            width: "100%",
            display: "flex",
            gap: "10px",
            "& input": {
                border: "none",
                background: "#FAFAFA",
                outline: "none",
                padding: "0 !important",
                "&:focus": {
                    outline: "none !important",
                    boxShadow: "none !important",
                    border: "none !important",
                },
            }
        }
    },
    inputWrapper: {
        padding: "10px 12px",
        height: "40px",
        //border: "1px solid #E6E6E6",
        backgroundColor: "#FFFFFF",
        width: "100%",
        display: "flex",
        gap: "10px",
        borderRadius: '6px',
        "& input": {
            border: "none",
            background: "#FFFFFF",
            outline: "none",
            padding: "0 !important",
            "&:focus": {
                outline: "none !important",
                boxShadow: "none !important",
                border: "none !important",
            },
            "&:disabled":{
                border: "none",
                background: "#FFFFFF !important",
                outline: "none",
                padding: "0 !important",
            }
        },
    },
    resultContainer: {
        
    },
    resultItem: {
        padding: "12px",
        // borderLeft:"none",
        // borderRight:"none",
        display: "grid",
        gridTemplateColumns: '56% 1fr',
        gap: "12px",
        //borderRadius: "8px",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: 'space-between',
        "&:hover": {
            backgroundColor: (props) => props.isFinalise ? '#FFFFFF' : "#f2f2f2",
            borderColor: (props) => props.isFinalise ? '#FFFFFF' : "#f2f2f2"
        },
        '& .greyStatus': {
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#F4F4F4',
            color: '#949494',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth: '96px'
        },
        '& .InValidBtn':{
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#626262',
            color: '#FFFFFF',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth: '96px'
        },
        '& .validBtn':{
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#E6FBEA',
            color: '#2EBE79',
            fontSize: '12px',
            fontWeight: '700',
            //border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth: '96px'
        },
        '& .blueBtn': {
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: "#0086FF",
            color: "#FFFFFF",
            fontSize: '12px',
            fontWeight: '700',
        }
    },
    resultChip: {
        height: '28px',
        width: '162px',
        padding: '4px 8px 4px 8px',
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '20px 1fr 16px',
        borderRadius: '24px',
        backgroundColor: '#F4F4F4'
    },
    icon: {
        padding: "6px",
        borderRadius: "4px",
        color: "#ffffff",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
    },
    selectedTicketsSection: {
        "& > .container-item": {
            borderBottom: '1px solid #E6E6E6',
            borderTop: 'none',
            borderRadius: '0px',
            padding: '7px 8px 7px 8px',
        },
        "& > .container-item:first-child": {
            borderTop: 'none',
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
        },

        /* Style for the last child (direct child) */
        "& > .container-item:last-child": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderBottom: 'none !important',
        },
        '& .jira-summary': {
            fontSize: '12px !important',
            color: "#505050 !important",
        },

        "& $resultItem": {
            borderLeft: "none !important",
            borderRight: "none !important",
        },
    },
    root: {
        maxHeight: '437px',
        padding: '16px 0px 0px 0px',
        border: '1px solid #E6E6E6',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        '& .header': {
            padding: '0px 16px 0px 16px',
        },
        '& .no-stories': {
            height: '354px',
            display: 'grid',
            placeItems: 'center',
            backgroundColor: '#FAFAFA',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
        }
    },
    iconContainer: {
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        backgroundColor: "#F4F4F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#787878",
    },
    "@keyframes hourglassRotate": {
        "0%": {
        transform: "rotate(0deg)",
        },
        "50%": {
        transform: "rotate(180deg)",
        },
        "50.1%": {
        transform: "rotate(180deg)",
        },
        "100%": {
        transform: "rotate(360deg)",
        },
    },
    hourGlassContainer: {
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        backgroundColor: "#F4F4F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#787878",
        '& span': {
            animation: "$hourglassRotate 2s infinite",
        }
    },
    resultTable: {
        height: '354px',
        minHeight: '354px',
        overflowY: 'auto',
        '& .table-header': {
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            padding: '12px 16px',
            border: '1px solid #E6E6E6',
            borderLeft: 'none',
            borderRight: 'none',
            position: 'sticky',
            top: '0',
            backgroundColor: '#FFFFFF',
        },
        "& > .container-item": {
            // display: 'flex',
            // justifyContent: 'space-between',/* Ensures the status stays at the end */
            // alignItems: 'center',
            borderBottom: '1px solid #E6E6E6',
            padding: '7px 8px',
        },
        "& > .container-item:first-child": {
            borderTop: 'none',
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
        },

        /* Style for the last child (direct child) */
        "& > .container-item:last-child": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
        },
        '& .jira-summary': {
            fontSize: '12px !important',
            color: "#505050 !important",
            flexGrow: 1,
            flexShrink: 1,

        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#0086FF !important",
        }
    }
}))