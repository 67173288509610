import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SortSharpIcon from '@material-ui/icons/SortSharp';
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { Loading } from '../../../../../utils/Loading';
import InvokeApi, { PostData } from '../../../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@material-ui/core';
import OverridableVariablesDialouge from '../OverridableVariablesDialouge';
import { Link, useHistory } from 'react-router-dom';
import JobTemplateTriggerHistory from '../JobTemplateTriggerHistory';
import ListAltIcon from '@material-ui/icons/ListAlt';
import moment from 'moment';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TriggerDetailsDialouge from './TriggerDetailsDialouge';


const JobCard = ({ 
    wholeJob, data, actions, fetchLastTriggeredData, showOnlyConfigured,
    setSnackBar,
    component_id,
    service_data,
    application_id,
    selectedServiceEnv,
    wideget_deployment_name,
    masterLabel,
    sub_env_id }) => {
    console.log("hsdhbdsh",service_data);
    const classes = useStyles();
    const history = useHistory();
    console.log(data, 'f_is_nsss')
    const [showOverRidableDialog, setShowOverRidableDialog] = useState({ show: false, getEnvVarUrl: '', triggered_error: null })

    const { job_name, job_code, templateName, configured } = data;

    const { handleConfigure } = actions;

    const [state, setState] = useState({
        triggeredData: null,
        loading: false,
        historyView: false
    })

    console.log(configured, 'ccccc', job_code);

    useEffect(() => {

        fetchLastTriggeredData && configured && !showOverRidableDialog.show && fetchTriggeredHistory()
        
        if (!configured) {
            setState(prevState => ({
                ...prevState,
                triggeredData: null
            }))
        }else{
           
                fetchWidget();
        }

    }, [fetchLastTriggeredData, configured, showOverRidableDialog])


    function fetchTriggeredHistory() {

        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_job_recent_trigger_data)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, fetchSuccess, fetchFailure);

            setState(new_state => ({
                ...new_state,
                loading: true

            }));
            console.log('api_called_ljwe;o')
        }
    }

    const fetchSuccess = (data) => {

        if (data && data.component_env_job_meta_information_id != undefined) {
            console.log(data, 'data_knalkdnf')
            // let { results } = data
            // let last_triggered_data = results[0]

            setState(prevState => ({
                ...prevState,
                loading: false,
                triggeredData: data

            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                loading: false

            }))
        }

    }

    const fetchFailure = (error) => {

        console.log(error, 'error_jkadslkkd')
        setState(prevState => ({
            ...prevState,
            loading: false,
            triggeredData: null

        }))
    }



    const IconAccordingToJobState = ({ isConfigured }) => {
        return (
            <div className='d-flex align-center justify-center' style={{ backgroundColor: '#f0f2fb', width: '75px', height: '75px', borderRadius: '70px', marginBottom: '30px', marginTop: '40px' }}>
                {
                    isConfigured ?
                        <PlaylistAddCheckOutlinedIcon style={{ fontSize: '36px', color: '#3e73ec' }} /> :
                        <BuildOutlinedIcon style={{ fontSize: '36px', color: '#3e73ec' }} />
                }
            </div>
        )
    }


    IconAccordingToJobState.propTypes = {
        ...PropTypes.objectOf(PropTypes.any),
    }


    const StatusIcon = ({ status }) => {

        return (
            <>
                {
                    status && status.status === 'IN_QUEUE' ?
                        <RotateLeftIcon style={{ fontSize: '20px', color: '#ffc30c' }} />
                        : status && status.status === 'SUCCESS' ?
                            <CheckCircleIcon style={{ fontSize: '20px', color: '#69e09c' }} />
                            : status && status.status === 'FAILED' ?
                                <ErrorOutlineOutlinedIcon style={{ fontSize: '20px', color: '#ff4747' }} />
                                : status && status.status === 'RUNNING' ?
                                    <TimelapseIcon style={{ fontSize: '20px', color: '#ffc30c' }} /> : null
                }
            </>
        )
    }
    StatusIcon.propTypes = {
        ...PropTypes.objectOf(PropTypes.any),
    };
    const ConfigurationMessage = ({ isConfigured, jobName }) => {

        return (
            <>
                {
                    isConfigured ?
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ fontSize: '20px', fontWeight: '300', lineHeight: '25px', marginBottom: '12px' }}>{`Configuration is Complete`}</label>
                            <br />
                            <span style={{ fontSize: '13px', color: 'gray' }}>Click the <span style={{ fontSize: '13px', color: '#1d45ff' }}>Play Icon</span></span>
                            <label style={{ fontSize: '13px', color: 'gray' }}>{`to Trigger the Promote Job`}</label>
                        </div>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ fontSize: '20px', fontWeight: '300', lineHeight: '25px', marginBottom: '12px' }}>{`Configure ${jobName}`}</label>
                            <br />
                            <label style={{ fontSize: '13px', color: 'gray' }}>{`Please Provide the ${jobName} information to get started.`}</label>
                            <span style={{ fontSize: '13px', color: 'gray' }}>Click on the <span style={{ fontSize: '13px', color: '#1d45ff' }}>Setting Icon</span> below to configure build information.</span>
                        </div>
                }
            </>
        )
    }
    ConfigurationMessage.propTypes = {
        ...PropTypes.objectOf(PropTypes.any),
    };
    console.log(state.triggeredData, 'jssfsfd_sds', job_code)

    const getDateAndTime = (data) => {
        console.log(data, 'data+updated')
        if (data) {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let { created_at } = data
            let another_date = new Date(created_at)
            console.log('date_an_th', another_date)
            let timeString = ''
            let date = another_date.getDate()
            let year = another_date.getFullYear()
            let month = another_date.getMonth()
            let time = moment(another_date).format('HH:mm')
            let date_string = date + " " + months[month] + " " + year
            timeString = timeString + date_string + " " + time
            console.log('updated_timestring_001', timeString)
            return timeString
        }
    }

    const onHandleTrigger = (triggerData) => {

        let post_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
        PostData(post_url, triggerData, () => {
            setShowOverRidableDialog({ show: false, getEnvVarUrl: '' })
            setSnackBar && setSnackBar((prevState => ({ ...prevState, jobTrigger: true })))
        },
            (error) => { setShowOverRidableDialog(prevState => ({ ...prevState, triggered_error: error })) });
    }

    const onClickJobTrigger = (job_code) => {

        let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_trigger_time_modify_env_vars)
        setShowOverRidableDialog({ show: true, getEnvVarUrl: get_url })

    }

    const handleViewLogs = () => {

        let global_task_id = state.triggeredData.global_task_id
        let id = state.triggeredData.job_number
        let to = "/logs?global_task_id=" + global_task_id +
            "&tab_id=" + id +
            "&service_name=" + 'service_name' +
            "&service_env=" + 'service_env' +
            "&service_type=" + 'service_env_type' +
            "&tab_name=BUILD" + "&num=" + id
        history.push(to)
    }

    const refershTriggerStatus = () => {
        fetchTriggeredHistory()
    }

    const onHandleClick = () => {

        let previousConfigData = null;

        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {

            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.configure_job_data)
            if(data && data?.configured?.status){
                let status = data.configured.status
                get_url = GenerateSearchURL({status:status},get_url)
            }
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(requestInfo.endPoint,"bxhbvhbvhbxbvhxc")
            InvokeApi(requestInfo, (data) => {
                console.log(data, 'config_data')
                previousConfigData = { ...data }
                handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            }, (error) => {
                console.log(error, 'error_0001')
                handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            });
        }
    }

    function fetchWidget(){
        let previousConfigData = null;
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {

            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.configure_job_data)
            if(data && data?.configured?.status){
                let status = data.configured.status
                get_url = GenerateSearchURL({status:status},get_url)
            }
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(requestInfo.endPoint,"bxhbvhbvhbxbvhxc")
            InvokeApi(requestInfo, (data) => {
                console.log(data, 'config_data')
                previousConfigData = { ...data }
                let keys = Object.keys(data.widget_data);
                let valueList = data.widget_data[keys[0]];
                let selectorList = valueList.map(item => { return { label: item, id: item} });
                setState((prev_state)=>({
                    ...prev_state,
                    widget_data_list: selectorList,
                }))
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            }, (error) => {
                console.log(error, 'error_0001')
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            });
        }
    }
    console.log("hdshdbh",state);

    const handleViewAllBuildHistory = ()=>{
        setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })
        setState(prevState => ({
            ...prevState,
            historyView: true
        }))
    }

    return (
      
        <div className={classes.container} style={{ border: '1px solid #dedede', backgroundColor: '#fff', borderRadius: '5px', width: '100%', height: '100%' }}>
            {
                state.loading 
                ?
                    <div style={{ minHeight: '425px', display: 'flex' }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <>
                        <div style={{ height: '100%', padding: '18px 18px 18px 18px' }}>
                            <div style={{ display: 'flex', marginBottom: '15px' }}>
                                <div className='d-flex justify-center align-center' style={{ width: '51px', height: '51px', backgroundColor: '#6bcbb6', borderRadius: '6px' }}>
                                    <FlashOnOutlinedIcon style={{ fontSize: '27px', color: '#fff' }} />
                                </div>
                                <span style={{ marginLeft: '15px' }}>
                                    <div className="font-13">{job_name}</div>
                                    <div style={{ color: '#646464' }} className="font-12">Job Template</div>
                                    <div style={{ color: '#afafaf' }} className="font-12">{templateName}</div>
                                </span>
                                <button style={{ width: '18px', height: '18px', display: 'flex', marginRight: '0px', marginLeft: 'auto' }} className="btn-round border-navy bg-clear-btn align-center justify-center" onClick={refershTriggerStatus} ><span style={{ fontSize: '12px' }} className="flaticon-refresh-button-1"></span></button>
                            </div>
                            <div style={{ width: '100%', borderBottom: '1px solid #dedede' }} ></div>
                            {
                                state.triggeredData ?
                                    
                                        <div style={{ height: '210px' }}>
                                            {/* {
                                                Object.keys(state.triggeredData.key_value_pair).map((key, index) => {

                                                    return (
                                                        <>
                                                            {
                                                                key !== 'trigger_by_user_id' && Boolean(state.triggeredData.key_value_pair[key]) && 
                                                                <div className='d-flex align-center' style={{ height: '50px', borderBottom: '1px solid #dedede' }}>
                                                                    <div style={{ width: '45%' }}>
                                                                        <label style={{ color: '#646464', fontSize: '12px' }}>{key}</label>
                                                                    </div>
                                                                    <div style={{ width: '55%' }}>
                                                                        <label style={{ color: '#979797', fontSize: '11px' }}>{state.triggeredData.key_value_pair[key]}</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })
                                            } */}
                                            <div className='d-flex align-center' style={{ height: '50px', borderBottom: '1px solid #dedede' }}>
                                                <div style={{ width: '45%' }}>
                                                    <span style={{ color: '#646464', fontSize: '12px' }}>Status</span>
                                                </div>
                                                <div className='d-flex' style={{ width: '55%', alignItems: 'center' }}>
                                                    <StatusIcon status={state.triggeredData && state.triggeredData.activity_status} />
                                                    <label className='mr-5' style={{ color: '#979797', fontSize: '11px' }}>{state.triggeredData && state.triggeredData.activity_status && state.triggeredData.activity_status.status}</label>
                                                   
                                                   <TriggerDetailsDialouge lastTrigger={state.triggeredData} />
                                                    {/* <label style={{ color: '#002eff', fontSize: '11px', cursor: 'pointer' }}>View Details</label> */}
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <div className='align-center' style={{ display: 'flex', flexDirection: 'column', minHeight: '260px' }}>
                                        <IconAccordingToJobState isConfigured={configured} />
                                        <ConfigurationMessage isConfigured={configured} jobName={job_name} />
                                    </div>
                            }

                        </div>
                        {
                            state.triggeredData &&
                            <div className='d-flex align-center justify-center' style={{ height: '50px', borderTop: '1px solid #dedede' }}>
                                <div className='d-flex' style={{ fontSize: '12px' }}>
                                    <label style={{ color: '#a6a6a6' }}>{`Triggered on ${getDateAndTime(state.triggeredData)} by`}</label>
                                    <Tooltip title={state.triggeredData.trigger_by_user}>
                                        <label
                                            style={{
                                                color: '#3e3e3e',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                width: '80px',
                                                overflow: 'hidden',
                                                marginLeft: '3px',
                                            }}>{state.triggeredData.trigger_by_user}</label>
                                    </Tooltip>
                                </div>
                            </div>
                        }
                        <div className='flex align-center d-flex' style={{ borderTop: '1px solid #dedede', justifyContent: 'space-between', height: '60px', padding: '0px 16px 0px 16px' }}>
                            <div>
                                {/* {
                                    state.triggeredData &&
                                    <div>
                                        <label className='mr-12' style={{ fontSize: '13px', color: '#979797' }}>{state.triggeredData.status}</label>
                                        <Link style={{ fontSize: '13px', color: '#002eff' }} tp=''>View</Link>
                                    </div>
                                } */}
                            </div>
                            <div className='d-flex align-center'>
                                {state.widget_data_list  ?
                                    // <Link target="_blank" to={{
                                    //     pathname : `/deployment-analytics`,
                                    //     search: `application_id=${encodeURIComponent(application_id)}&service_id=${encodeURIComponent(component_id)}&env_id=${encodeURIComponent(sub_env_id)}&cd_id=${encodeURIComponent(wideget_deployment_name? wideget_deployment_name : null)}&env_name${encodeURIComponent(selectedServiceEnv.project_env.name)}&service_data=${encodeURIComponent(JSON.stringify({ name: service_data?.name, type: masterLabel }))}&all_available_deployments=${encodeURIComponent(JSON.stringify( state.widget_data_list ))}}`
                                    // }} 
                                    // >
                                    //     <Tooltip title="Monitoring" className={classes.monitorButtonV3}>
                                    //         <button className="btn btn-link text-anchor-blue d-flex align-center m-0 btn-flaticon" onClick={handleClickOpen}>
                                    //         <PersonalVideoIcon className="" fontSize="" />
                                    //         </button>
                                    //     </Tooltip>
                                    // </Link>
                                
                                    <Link target="_blank" to={{
                                        pathname : `/deployment-analytics`,
                                        search: `application_id=${application_id}&service_id=${component_id}&service_name=${service_data?.name}&env_id=${sub_env_id}&cd_id=${wideget_deployment_name? wideget_deployment_name : null}&env_name${selectedServiceEnv.project_env.name}&env_type=${masterLabel}`
                                    }} 
                                    >
                                        <Tooltip title="Monitoring" className={classes.monitorButtonV3}>
                                            <button className="btn btn-link text-anchor-blue d-flex align-center m-0 btn-flaticon" onClick={handleClickOpen}>
                                            <PersonalVideoIcon className="" fontSize="" />
                                            </button>
                                        </Tooltip>
                                    </Link>
                                
                                : null}
                                                
                                {!showOnlyConfigured &&
                                    // <Tooltip title="Configure">
                                    //     <SettingsOutlinedIcon onClick={onHandleClick} style={{ fontSize: '24px', marginRight: '12px', color: '#0c38ff', cursor: 'pointer' }} />
                                    // </Tooltip>
                                    <Tooltip title="Configure">
                                        <span id= "configureV3" onClick={onHandleClick}>
                                            <SettingsOutlinedIcon  style={{ fontSize: '24px', marginRight: '12px', color: '#0C38FF', cursor: 'pointer' }} />
                                        </span>
                                    </Tooltip>
                                }
                                {
                                    state.triggeredData ?
                                        <Link
                                            target='_blank'
                                            to={"/logs?global_task_id=" + state.triggeredData.global_task_id +
                                                "&tab_id=" + state.triggeredData.job_number +
                                                "&service_name=" + `${(service_data && service_data.name) || 'service_name'}` +
                                                "&service_env=" + `${(service_data && service_data.service_env) || 'service_env'}` +
                                                "&service_type=" + `${(service_data && service_data.type) || 'service_env_type'}` +
                                                "&tab_name=BUILD" + "&num=" + state.triggeredData.job_number
                                            }
                                        // to={"/sse-testing"}
                                        >
                                            <Tooltip title="View Logs">

                                                <ListAltIcon

                                                    style={{ fontSize: '24px', marginRight: '12px', color: '#0c38ff' }
                                                    } />
                                                </Tooltip>
                                        </Link>
                                        : <ListAltIcon
                                            style={
                                                { fontSize: '24px', marginRight: '12px', color: '#d3d3d3' }} />
                                }
                                {/* <Tooltip title="View History">
                                <SortSharpIcon
                                    style={state.triggeredData ? { fontSize: '24px', marginRight: '12px', color: '#0c38ff', cursor: 'pointer' } :
                                        { fontSize: '24px', marginRight: '12px', color: '#d3d3d3' }}
                                    onClick={state.triggeredData ? () => {
                                        setState(prevState => ({
                                            ...prevState,
                                            historyView: true
                                        }))
                                    } : () => { }
                                    } />
                                    </Tooltip> */}
                                    <Tooltip title="View History">
                                    <span id={"viewHistory"}
                                        onClick={state.triggeredData ? () => {
                                            setState(prevState => ({
                                                ...prevState,
                                                historyView: true
                                            }))
                                        } : () => { }
                                        }
                                    >
                                        <SortSharpIcon
                                            style={state.triggeredData ? { fontSize: '24px', marginRight: '12px', color: '#0C38FF', cursor: 'pointer' } :
                                                { fontSize: '24px', marginRight: '12px', color: '#D3D3D3' }}
                                            />
                                    </span>
                                    </Tooltip>
                                    {/* <Tooltip title="Trigger Job">
                                <PlayArrowSharpIcon
                                    onClick={configured ? () => onClickJobTrigger(job_code) : () => { }}
                                    style={configured ? { fontSize: '24px', marginRight: '12px', color: '#0c38ff', cursor: 'pointer' } :
                                        { fontSize: '24px', marginRight: '12px', color: '#d3d3d3' }} />
                                          </Tooltip> */}
                                          <Tooltip title="Trigger Job">
                                            <span id={"trigger_jobv3"} onClick={configured ? () => onClickJobTrigger(job_code) : () => { }}>
                                                <PlayArrowSharpIcon
                                                    
                                                    style={configured ? { fontSize: '24px', marginRight: '12px', color: '#0c38ff', cursor: 'pointer' } :
                                                        { fontSize: '24px', marginRight: '12px', color: '#d3d3d3' }} />
                                            </span>
                                          </Tooltip>
                            </div>
                        </div>
                        {
                            showOverRidableDialog && showOverRidableDialog.show &&
                            <OverridableVariablesDialouge
                                component_id={component_id}
                                handleViewAllBuildHistory={handleViewAllBuildHistory}
                                sub_env_id={sub_env_id}
                                job_code={job_code}
                                job_name={job_name}
                                triggredError={showOverRidableDialog.triggered_error}
                                handleTrigger={onHandleTrigger}
                                envProps={showOverRidableDialog} onClose={() => setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })} />
                        }
                        {
                            state.historyView && <JobTemplateTriggerHistory
                                job_name={job_name}
                                component_id={component_id}
                                handleClose={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        historyView: false
                                    }))
                                }
                                }
                                sub_env_id={sub_env_id}
                                job_code={job_code}
                                open={state.historyView} />
                        }

                    </>
            }
        </div >

    )
}

JobCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default JobCard;

const useStyles = makeStyles({
    container: {
        '& .card': {
          
            backgroundColor: '#fff',
            borderRadius: '8px',
            height: '135px',
            width: '200px',
            margin: 'auto',
            border: '1px solid white',
        }
    }
})
