import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { withRouter, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Card, Grid, Link, Tooltip } from "@material-ui/core";

import InvokeApi from "../../../util/apiInvoker";
import GenerateURL, { GenerateSearchURL, GenerateURLForPins } from "../../../util/APIUrlProvider";
import { Error, ErrorComponent } from "../../utils/Error";
import { Loading, LoadingContainer } from "../../utils/Loading";
import { Body } from "./Body";
import properties from "../../../properties/properties";

import SearchBar from "../../../components/SearchBar";
import Pagination from "../../../components/Pagination";
import { PostData } from "../../../util/apiInvoker";
import { styled } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GenerateRouteFromParams } from "../../../routes";
import AddMsDialog from "./AddMsDialog";
import EnvAndUserGroup from "../../serviceRevamp/components/EnvAndUserGroup";
import AddEnvToMS from "./AddEnvToMS";
import EnvAndUserGroupNew from "../../serviceRevamp/components/EnvAndUserGroupNew";
import GenericSkeleton from "../../../components/genericComponents/Skeletons/GenericSkeleton";
import { GetAuth } from "../../../util/security";
import AdvanceSearchFilterCombo from "../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20
  },
  heading: {
    margin: "1.5rem 0rem",
  },
  mainHeading: {
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#666",
    lineHeight: 1,
  },
}));

const ServiceListing = (props) => {
  const [state, setState] = useState({
    loaded: false,
    total_page: "",
    curr_page: "",
    refresh_count: 1,
    moreAdvFilterList: [],
    advFilters: {
      name_exact: [],
      business_function: [],
    },
    resetCount: 0,
  });
  const { application_id } = props.match.params;
  const classes = useStyles();
  const defaultFilters = ["name_exact"]
  const history = useHistory();

  const moreFilterData = [
    { label: "Name", value: "name_exact" },
    { label: "Business Fn", value: "business_function" },
  ]

  const resetFilterData = {
    business_function: [],
    name_exact: []
  }

  useEffect(() => {
    fetchSystemSettingsData();
  }, [application_id, state.refresh_count])

  useEffect(() => {
    if (state.available_settings) {
      fetchServices();
    }
  }, [application_id, state.refresh_count, state.available_settings]);

  useEffect(() => {
    resetAdvFilter();
  }, [])

  useEffect(() => {
    isFiltersApplied()
  }, [state.advFilters])

  function isFiltersApplied() {
    var count = 0;
    Object.keys(state.advFilters).forEach((item) => {
      if (state.advFilters[item].length > 0) {
        count = count + 1;
      }
    })

    setState((new_state) => ({
      ...new_state,
      is_filters_applied: count > 0 ? true : false
    }))
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    console.log(urlSearchParams, "bdhadbhabdha")
    if (urlSearchParams.size != 0) {
      let moreAdvFilter = defaultFilters
      let advFilters = {}
      urlSearchParams?.forEach((value, key) => {
        if (value) {
          if (key == 'adv_search') {
            let advValue = value
            let updatedList = advValue.split(',')
            updatedList = [...moreAdvFilter, ...updatedList]
            moreAdvFilter = [...new Set(updatedList)]
          }
          else {
            let filterValue = value
            const updatedList = filterValue.split(',')
            advFilters[key] = updatedList
          }
        }
      })
      setState(prevState => ({
        ...prevState,
        moreAdvFilterList: moreAdvFilter,
        advFilters: { ...advFilters }
      }))
      fetchServicesForListFilter(advFilters, moreAdvFilter)
    }

  }, [])


  const isFilterActive = (filterKey, list) => {

    const isFound = list?.find(name => filterKey == name)

    return isFound;
  }

  function generateFilterContainURL(baseURL, listDict, activeFilters) {
    var searchParams = ''
    const filtersKeyArray = Object.keys(listDict)
    let i = 0
    let advFilterApplied = false
    const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
    filtersKeyArray.forEach(key => {

      const arrayFilterValues = listDict[key];
      if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
        if (i != 0) {
          searchParams += '&'
        }
        advFilterApplied = true
        var arrayString = arrayFilterValues;
        const stringKey = `${key}=${arrayString}`
        searchParams += stringKey
        i++;
      }
    })

    const finalUrl = baseURL + '?' + searchParams.toString()

    return finalUrl
  }

  function fetchServicesForListFilter(listFilterData, activeFilterList) {

    let baseURL = GenerateURL({ application_id: application_id }, properties.api.service_dahboard_data_url)

    const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);

    let requestInfo = {
      endPoint: resultURL,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(requestInfo,
      (response) => {
        setState((new_state) => ({
          ...new_state,
          loaded: true,
          count: response.count,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          data: response.results,
          total_page: Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0),
          curr_page: 1,
        }));
      },
      ServiceInfoFetchFailure);
  }

  function ServiceInfoFetchFailure(error) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loaded: true,
    }));
  }

  function fetchServices(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.service_dahboard_data_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      if (state.pin_micro_services) {
        let pinned_services = JSON.parse(localStorage.getItem("pinned_services"));
        if (pinned_services && pinned_services.length > 0) {
          requestInfo.endPoint = GenerateURLForPins(pinned_services.reverse(), requestInfo.endPoint, true);
        }
      }

    } else {
      if (state.pin_micro_services) {
        let pinned_services = JSON.parse(localStorage.getItem("pinned_services"));
        if (pinned_services && pinned_services.length > 0) {
          requestInfo.endPoint = GenerateURLForPins(pinned_services.reverse(), requestInfo.endPoint);
        }
      }
    }

    if (url) {
      requestInfo.endPoint = url;
    }

    InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      loaded: false,
      search_query_name: data ? (data.name ? data.name : "") : "",
    }));
  }

  const handleFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception,
    });
  };

  const handleSuccessApiHit = (response) => {
    console.log("handleSuccessApiHit", response);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      data: response.results,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
  };

  function fetchNextServices(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.service_dahboard_data_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }

    InvokeApi(requestInfo, handleNextSuccessApiHit, handleNextFailedApiHit);
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      loaded: false,
    }));
  }

  const handleNextFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception,
    });
  };

  const handleNextSuccessApiHit = (response) => {
    console.log("handleSuccessApiHit", response);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      data: response.results,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  };



  //start of page code ----------------------------

  function fetchPageServices(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.service_dahboard_data_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        handlePageSuccessApiHit(response, current_page);
      },
      handlePageFailedApiHit
    );
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
  }

  const handlePageFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception,
    });
  };

  const handlePageSuccessApiHit = (response, count) => {
    console.log("handleSuccessApiHit", response);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      data: response.results,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(count)
    }));
  };

  //end of page code ----------------------------------------

  function fetchPrevServices(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.service_dahboard_data_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }

    InvokeApi(requestInfo, handlePrevSuccessApiHit, handlePrevFailedApiHit);
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      loaded: false,
    }));
  }

  const handlePrevFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception,
    });
  };

  const handlePrevSuccessApiHit = (response) => {
    console.log("handleSuccessApiHit", response);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      data: response.results,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  };

  const invokeSearch = (data) => {
    console.log("invokeSearch", data);
    let normalizedData = { ...data, name: data.name.toLowerCase() }
    fetchServices(normalizedData);
  };


  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "component",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "component",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function fetchSystemSettingsData() {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.system_settings),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

    setState(new_state => ({
      ...new_state,
      loading: true
    }))

  }

  function fetchSystemSettingsDataSuccess(data) {
    var pin_micro_services = data.find(item => item.key == "PIN_MICRO_SERVICES");
    setState(new_state => ({
      ...new_state,
      available_settings: data,
      pin_micro_services: pin_micro_services ? pin_micro_services.value == "true" ? true : false : false,
      loading: false
    }));

  }
  function fetchSystemSettingsDataFailed(error) {

    setState(new_state => ({
      ...new_state,
      error: error,
      loading: false
    }))
  }

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
      resetCount: new_state.resetCount + 1,
    }));
  }

  console.log(state.data, "sbcdgsbchjsdbchjsd")

  const onUpdateHandle = (uniqueId, updatedList) => {

    let updatedKey = ''
    if (uniqueId === 'more-button-adv-0') {
      addFiltersToUrl('adv_search', updatedList)
      if (updatedList?.length == 0) {
        resetAdvFilter()
      }
      else {
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: updatedList,
        }))
      }
    }
    else {
      if (uniqueId == 'name_adv_1') {
        updatedKey = 'name_exact'
      }
      if (uniqueId == 'tag_adv_3') {
        updatedKey = 'business_function'
      }

      setState(prevState => ({
        ...prevState,
        advFilters: {
          ...prevState.advFilters,
          [updatedKey]: updatedList
        }
      }))
      addFiltersToUrl(updatedKey, updatedList)
      const advFilters = state.advFilters
      advFilters[updatedKey] = updatedList
      fetchServices(advFilters)
    }
  }

  const resetAdvFilter = () => {

    fetchServices(resetFilterData)
    addFiltersToUrl('all_delete')
    setState(prevState => ({
      ...prevState,
      moreAdvFilterList: defaultFilters,
      advFilters: resetFilterData,
      resetCount: prevState.resetCount + 1
    }))

    // will call here normal function
  }

  const addFiltersToUrl = (filterName, filterValue) => {

    let urlSearchParams = new URLSearchParams(location.search);
    const allFilters = [
      "Name",
      "Business Fn",
    ]

    if (filterName == 'all_delete') {
      urlSearchParams = ''
    } else {
      if (filterName == 'adv_search') {
        allFilters.forEach(value => {
          if (!filterValue.includes(value)) {
            urlSearchParams.delete(value)
          }
        })
      }

      if (filterValue?.length == 0) {
        urlSearchParams.delete(filterName)
      }
      else {
        if (urlSearchParams.has(filterName)) {
          urlSearchParams.set(filterName, filterValue.join(','));
        } else {
          urlSearchParams.append(filterName, filterValue.join(','));
        }
      }
    }

    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  function filterDataParseForPipelineName(data) {
    console.log(data, 'bcdhbhsdbchsbdbc')
    const updatedList = data?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })

    return updatedList
  }

  function filterDataParseForPipelineTags(data) {
    
    const tags_list = [];
    data?.forEach(item => {
      item.business_function.forEach((single_tag) => {
        if (!tags_list.includes(single_tag.name)) {
          tags_list.push(single_tag.name)
        }
      })
    })
    console.log("tag_list", tags_list);
    const updatedList = tags_list?.map(item => {
      return { 'label': item, 'value': item, ...item }
    })

    return updatedList


  }

  const advanceFilterJson = {
    'name_exact': {
      staticList: false,
      labelName: 'Name',
      uniqueId: 'name_adv_1',
      searchVariable: 'name',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: properties.api.service_dahboard_data_url,
      searchUrl: properties.api.service_dahboard_data_url,
      filterDataPraseFunction: filterDataParseForPipelineName
    },
    'business_function': {
      staticList: false,
      labelName: 'Business Fn',
      uniqueId: 'tag_adv_3',
      searchVariable: 'business_function',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: properties.api.service_dahboard_data_url,
      searchUrl: properties.api.service_dahboard_data_url,
      filterDataPraseFunction: filterDataParseForPipelineTags,
      showMoreNotRequired: true
    },
   
  }

  const Page = (data) => {
    return (
      <Grid item lg={12} sm={12} xl={12} xs={12} className="pd-tb">
        {state.data.length != 0 ? (
          <Body data={state.data} refresh={fetchServices} sendForApproval={sendApprovalRequest} confirmForApproval={confirmForApproval} pinEnabled={state.pin_micro_services} />
        ) : data.search_query_name ? (
          <div className="main-container-error" style={{ height: "70vh" }}>
            <div className="svg">
              <div className="servicePanel">
                <div className="blank-div">
                  <div className="blank-div-text">
                    No Service found with the name : {data.search_query_name}
                  </div>
                  <button
                    onClick={() => {
                      data.refreshFn();
                    }}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="main-container-error" style={{ height: "70vh" }}>
            <div className="svg">
              <div className="servicePanel">
                <div className="blank-div">
                  <div className="blank-div-text">
                    You have 0 services associated with this app
                  </div>
                  <button onClick={handleClickOpenDialog}>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                    </svg>
                    ADD SERVICE
                  </button>
                  {/* <Link
                    onClick={handleToggle}
                  >
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                    </svg>
                    ADD SERVICE
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Grid>
    );
  };

  console.log(state.search_data, "search data part")
  const [open, setOpen] = useState(false);
  const [openAddEnvDialog, setOpenAddEnvDialog] = useState(false);
  const [addedServiceResponse, setServiceResponse] = useState(null)
  const handleClickOpenDialog = () => {
    setOpen(true)
  }
  const handleToggle = () => {
    setOpen(false)
  }
  const handleOpenAddEnvDialog = (response) => {

    setServiceResponse(response)
    setOpenAddEnvDialog(true)
  }
  const handleCloseAddEnvDialog = () => {
    refreshState()
    setOpenAddEnvDialog(false)
  }
  var url = GenerateURL({ application_id: application_id }, properties.api.service_basic_details_post_url)
  const is_permited = /* GetAuth().permission.POST.includes(url); */ true

  return (
    <>
      {state.exception ? <Redirect to={"/exception"} /> : null}

      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} sm={12} xl={12} xs={12} className="pd-bottom">
            <div className="head-section">
              <div className="heading-section-service">
                <h1 className="heading-primary">
                  Service Overview
                </h1>
                <h2 className="sub-heading-primary">
                  Displaying all associated services.
                </h2>

              </div>
                <button
                  className="btn btn-primary font-12 font-weight-500 d-flex align-center justify-center m-0"
                  onClick={handleClickOpenDialog}
                  style={{ textWrap: "nowrap" }}
                ><span className="ri-add-line font-18"></span> Add Service</button>
                </div>
          </Grid>
          <Grid container style={{marginTop:'15px', paddingLeft:'16px'}}>
            {
              state.moreAdvFilterList?.map(filterName => {
                console.log(filterName, 'ftvbfd')
                return (
                  <div key={filterName} className="ad-more-search mb-20">
                    <AdvanceSearchFilterCombo
                      key={`${filterName}-${application_id}-${state.refresh_count}`}
                      reset={state.resetCount}
                      selectedCheckBoxes={state.advFilters[filterName]}
                      searchVariable={advanceFilterJson[filterName]['searchVariable']}
                      staticList={advanceFilterJson[filterName]['staticList']}
                      uniqueId={advanceFilterJson[filterName]['uniqueId']}
                      labelName={advanceFilterJson[filterName]['labelName']}
                      searchUrl={advanceFilterJson[filterName]['searchUrl']}
                      onUpdate={onUpdateHandle}
                      getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                      placeholder_name={advanceFilterJson[filterName]['placeholder_name']}
                      placeholder_value={advanceFilterJson[filterName]['placeholder_value']}
                      filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                      showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                      apiHitOnClick={true}
                    />
                  </div>
                )
              })
            }
            <div className="ad-more-search mb-20">
              <AdvanceSearchFilterCombo
                key={`more-adv-${application_id}-${state.refresh_count}`}
                selectedCheckBoxes={state.moreAdvFilterList}
                reset={state.resetCount}
                staticList={moreFilterData}
                autoClosedAfterSelection={true}
                onUpdate={onUpdateHandle}
                variant='more-button'
                uniqueId='more-button-adv-0'
              />
            </div>

            <div onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }}>
              <label style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</label>
            </div>
          </Grid>
          {/* <Grid item lg={7} sm={12} xl={6} xs={12}>
            <div className="d-grid align-center space-between" style={{ gridTemplateColumns: '4fr 1fr', gap: 24 }}>
              <SearchBar
                search_data={state.search_data}
                default_filter={{ name: "name", label: "Name" }}
                search_call_back={invokeSearch}
                clear_search_callback={fetchServices}
              />
              {
                is_permited ?
                  <button
                    onClick={handleClickOpenDialog}
                    className="btn btn-primary font-12 font-weight-500 d-flex align-center justify-center mr-0"
                    style={{ textWrap: "nowrap" }}
                  >
                    <span className="ri-add-line font-18"></span>
                    Add Service</button>
                  :
                  <Tooltip title="You are not allowed to perform this action">
                    <button
                      className="btn btn-disable cursor-not-allowed"
                    >
                      Add Service
                    </button>
                  </Tooltip>

              }

              
            </div>
          </Grid> */}

          {/* state.loaded */}
          {state.loaded ? (
            <Page
              search_query_name={state.search_query_name}
              refreshFn={fetchServices}
            />
          ) : state.error ? (
            <ErrorComponent error={state.error} />
          ) : (
            // <LoadingContainer />
            <div className="d-flex f-direction-column width-full" style={{ gap: "12px", margin: "0px 16px" }}>
              <ServiceCardOpenSkeleton />
              {Array.from({ length: 2 }).map((_, index) => {
                return <ServiceCardSkeleton />
              })}
            </div>
          )}
          {/* <Grid item lg={8} sm={12} xl={3} xs={12}></Grid> */}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Pagination
              total_count={state.total_page}
              current_page_count={state.curr_page}
              next={state.next}
              previous={state.previous}
              on_previous_click={() => {
                fetchPrevServices(null, state.previous);
              }}
              on_next_click={() => {
                fetchNextServices(null, state.next);
              }}
              on_pageNumber_click={(pageNumber) => {
                fetchPageServices(pageNumber);
              }}
              skeleton={!state.loaded}
            />
          </Grid>
        </Grid>
      </div>
      {
        open ?
          <AddMsDialog
            open={open}
            application_id={application_id}
            handleOpenAddEnvDialog={handleOpenAddEnvDialog}
            handleClose={handleToggle} /> : null
      }
      {
        openAddEnvDialog &&
        <EnvAndUserGroupNew
          variant="edit_mode"
          application_id={application_id}
          handleClose={handleCloseAddEnvDialog}
          open={openAddEnvDialog}
          component_id={""}
          componentData={addedServiceResponse}
          refreshState={refreshState}
        />
      }

    </>
  );
};

ServiceListing.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const ServiceCardOpenSkeleton = () => {
  const classes = skeletonStyle();
  return (
    <Card className="width-full" style={{ height: "349px", borderRadius: "8px" }}>
      <header className={classes.header}>
        <div className="d-flex align-center">
          <GenericSkeleton variant={"rect"} height={48} width={48} style={{ borderRadius: "8px" }} />
          <GenericSkeleton variant={"text"} height={23} width={80} style={{ marginLeft: "10px" }} />
        </div>
        <div className="d-flex align-center" style={{ gap: "12px" }}>
          <GenericSkeleton variant={"rect"} width={330} height={41} style={{ borderRadius: "6px" }} />
          <GenericSkeleton variant={"rect"} width={36} height={36} count={2} style={{ borderRadius: "4px" }} rootStyle={{ gap: "12px", flexDirection: "row" }} />
        </div>
      </header>
      <main className={classes.main}>
        <GenericSkeleton variant={"rect"} height={196} width={"50%"} style={{ borderRadius: "6px" }} count={2} rootStyle={{ gap: "10px", flexDirection: "row" }} />
      </main>
      <footer className={classes.footer}>
        <div className="ml-auto" style={{ width: "fit-content", marginTop: "10px" }}>
          <GenericSkeleton count={6} width={36} height={36} style={{ borderRadius: "4px" }} rootStyle={{ flexDirection: "row", gap: "4px", flex: "0" }} />
        </div>
      </footer>
    </Card>
  );
}

const ServiceCardSkeleton = () => {
  const classes = skeletonStyle();
  return (
    <Card className="width-full" style={{ height: "76px", borderRadius: "8px" }}>
      <header className={classes.header}>
        <div className="d-flex align-center">
          <GenericSkeleton variant={"rect"} height={48} width={48} style={{ borderRadius: "8px" }} />
          <GenericSkeleton variant={"text"} height={23} width={80} style={{ marginLeft: "10px" }} />
        </div>
        <div className="d-flex align-center" style={{ gap: "12px" }}>
          <GenericSkeleton variant={"rect"} width={36} height={36} style={{ borderRadius: "4px" }} />
        </div>
      </header>
    </Card>
  );
}

export default withRouter(ServiceListing);

const BtnSection = styled('div')({
  display: "flex",
  alignItems: 'center'
})


const skeletonStyle = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px 12px 12px",
    alignItems: "center",
    height: "76px"
  },
  main: {
    //height:"196px",
    padding: "10px",
    borderRadius: "0px 0px 4px 4px"
  },
  footer: {
    height: "45px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "end",
  }
}))
