import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../components/genericComponents/Input';
import { makeStyles } from '@material-ui/core/styles';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet, VALIDATION_TYPE_CRON_PATTERN, VALIDATION_TYPE_POSITIVE_NUMBER } from '../../../util/Validator';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { styled } from '@material-ui/core/styles';
import FileUpload, { FileUploadDefaultState } from '../../../components/genericComponents/Forms/FileUpload';
import { Grid } from '@material-ui/core';
import JiraReferenceIds from './components/JiraReferenceIds';
import EditableTable from '../../../components/genericComponents/EditableTable';
import { getStageRunConditionsAndJiraRefList, RemoveFromArray } from '../../../util/util';
import AddIcon from '@material-ui/icons/Add';
import PipepineAddiitonalField, { getAddMoreEnvVar } from './PipepineAddiitonalField';
import { ResetComponent, getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { getEnvVarFormDefaultState } from '../../catalog/components/createStepComponents/EnvVarForm';
import { getMultiRowDefaultState } from '../../../components/genericComponents/MultiRow';


const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: 0,
        '& .add-pipeline-card-body': {
            padding: '10px 10px'
        },
        '& .card-header-switch': {
            '& .card-header': {
                backgroundColor: '#fcfcfc',
                height: 'auto'
            }
        }
    },
    rootEdit: {
        paddingBottom: '50px',
        paddingTop: '0px',
        position: 'relative',
        width: '820px',
        '& .card-header-switch': {
            '& .card-header': {
                backgroundColor: '#fcfcfc',
                height: 'auto'
            }
        },

        '& .add-pipeline-card-body': {
            padding: '0px 10px',
            height: '39rem',
            overflowY: 'scroll'
        }
    },
}));
const Footer = styled('div')({
    width: '100%',
    padding: '10px 10px 20px',
    borderTop: '1px solid #dedede',
    backgroundColor: '#fff',
    position: 'absolute',
    bottom: '0px',
    '& .btn-save': {
        display: 'block',
        marginLeft: 'auto'
    }

})


// const available_roles = properties.roles;


export const triggerType = [
    {
        label: "Manual",
        value: "MANUAL"
    },
    {
        label: "Schedule",
        value: "SCHEDULE"
    },
    {
        label: "SCM POLL",
        value: "SCM_POLL"
    },
    {
        label: "Web Hooks",
        value: "WEBHOOKS"
    }

]

export const executionTypes = [
    {
        label: 'Run With Parameters',
        value: 'run_with_parameters',
    },
    {
        label: 'Run With Release Package',
        value: 'release_package',
    },
    {
        label: 'Both',
        value: 'both',
    },
]

const BasicInfo = (props) => {
    const classes = useStyles();
    const pipeline = props.pipeline;
    const origin = props?.addOrigin ? true : false;
    if (pipeline) {
        if (pipeline.jira_references && pipeline.jira_references.length > 0) {
            pipeline.jira_refrence_ids = true
        } else {
            pipeline.jira_refrence_ids = false
        }
    }
    const application = props.application;
    const inherits = props.inherits ? props.inherits : {}
    transformData();
    const services = props.services;
    const saveBasicInfoAndContinueToDetails = props.saveBasicInfoAndContinueToDetails;
    const default_data = {
        name: "",
        retention_execution_count: "",
        trigger: "MANUAL",
        components: [],
        jira_references: [],
        tags: [],
        stage_run_condition: [],
        update_state_fn: false,
        jira_refrence_ids: false,
        default_run: false,
        manual_deploy_tag_select: false,
        roles_list: [],
        version: 'v2',
        runtime_execution: 'both',
    }
    const getKeyValEnvDetails = (keyVals) => {

        let child_inherits = {}

        let keyValList = {}
        keyVals && keyVals.forEach((item, index) => {
            let count = index + 1
            keyValList = { ...keyValList, [count]: { ...getAddMoreEnvVar(), data: { ...item } } }
            child_inherits = { ...child_inherits, [count]: {} }
        })

        let obj = { ...keyValList, child_inherits: child_inherits }

        console.log('returning_obj_', obj)

        return obj

    }

    const base_state = {
        data: pipeline ? pipeline : default_data,
        error: props.error ? props.error : {},
        user_id_list: [],
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],
            retention_execution_count: [VALIDATION_TYPE_REQUIRED],
            trigger: [],
            cron_regex: [],
            tags: [],
            pipeline_role_access: []
        },
        child_inherits: {
            jira_refrence_ids: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            env_var: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
        },
        env_var: {
            ...getEnvVarFormDefaultState(),
            env_var_form: {
                ...getMultiRowDefaultState(),
                ...getKeyValEnvDetails(pipeline ? pipeline.pipeline_vars : [])
            }
        },
    }
    const [state, setState] = useState(base_state);
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log("lntlnt", base_state,pipeline)
    useEffect(() => {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application.id }, properties.api.application_role_detail_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessRoleApiHit, handleFailedApiHit);
        fetchUserData();
    }, []);

    const handleFailedApiHit = (error) => {
        console.log("==== > ", error);
    }

    const handleSuccessRoleApiHit = (response) => {
        setState(new_state => ({
            ...new_state,
            roles: response,
        }));
    }

    function getRoleCodeFromId(id) {
        var role_code = "";
        if (state.roles) {
            state.roles.forEach(role => {
                if (role.id == id) {
                    role_code = role.role_master.code;
                }
            });
        }
        return role_code;
    }

    function getDataForRoles(data) {
        var new_value = [];
        if (data.pipeline_role_access) {
            data.pipeline_role_access.forEach(role => {
                if (role.access) {
                    new_value.push(getRoleCodeFromId(role.project_role_id));
                }
            });
        }
        return { pipeline_role_access: new_value };
    }

    const onRoleChangeHandler = (key, value) => {
        if (!(value.length > 0)) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    [key]: null
                },
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        } else {
            var new_value = [];
            state.roles.forEach(role => {
                new_value.push({
                    project_role_id: role.id,
                    access: value.includes(role.role_master.code)
                });
            });
            setState({
                ...state,
                data: {
                    ...state.data,
                    [key]: new_value
                },
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        }
    }

    function transformData() {
        if (pipeline && !pipeline.trigger) {
            pipeline.trigger = pipeline.trigger_type_id == 3 ? "MANUAL" : pipeline.trigger_type_id == 4 ? "WEBHOOKS" :
                pipeline.trigger_type_id == 2 ? "SCHEDULE" : "SCM_POLL";
        }
    }

    function changePanel() {
        var data = { ...state.data };
        var validations = state.validations;
        var child_states = inherits.getState ? inherits.getState() : null
        console.log(child_states, 'c_s_001')
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        console.log(children_vaidation_result, 'validation_ressdsdsd')
        if (data.trigger != "MANUAL" && data.trigger != "WEBHOOKS") {
            if (data.trigger === "SCM_POLL" && data.trigger !== "SCHEDULE") {
                if (!validations.cron_regex.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.cron_regex.push(VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_POSITIVE_NUMBER);
                }
            } else {
                if (data.trigger === "SCHEDULE" && data.trigger !== "SCM_POLL") {
                    if (!validations.cron_regex.includes(VALIDATION_TYPE_REQUIRED)) {
                        validations.cron_regex.push(VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_CRON_PATTERN);
                    }
                }

            }

        } else {
            validations.cron_regex = [];
        }
        if (data.components.length == 0) {
            data.components = null;
        }
        var validation_result = ValidateDataSet(data, validations);
        if (validation_result.valid && children_vaidation_result.valid) {
            var env_var_data = child_states?.env_var;
            let pipeline_vars = null
            if (env_var_data) {
                let envVardData = getEnvVarKeyValuePair(env_var_data)
                pipeline_vars = envVardData?.length > 0 ? envVardData : null;
            }
            console.log("final_data_to_post", state.data, pipeline_vars);
            console.log({ ...state.data, pipeline_vars: pipeline_vars }, 'dfghtm')
            saveBasicInfoAndContinueToDetails({ ...state.data, pipeline_vars: pipeline_vars });
        } else {
            setState({
                ...state,
                error: validation_result.error,
            });
        }
    }
    function getEnvVarKeyValuePair(env_var) {

        var env_var_key_value = []

        Object.keys(env_var.env_var_form).forEach(key => {
            if (key !== "data" && key !== "child_inherits" && key !== 'show_view' && key !== "count") {

                let obj = {}

                let dataObj = env_var.env_var_form[key] ? env_var.env_var_form[key].data : {}

                for (let item in dataObj) {

                    obj[item] = env_var.env_var_form[key].data[item]
                }
                if (obj['key'])
                    env_var_key_value = [...env_var_key_value, { ...obj }]
            }
        })

        return env_var_key_value
    }

    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update_state = true;
        var clear_cron_regex = false;

        if (key == "retention_execution_count" || (key == "cron_regex" && state.data.trigger == "SCM_POLL")) {
            if (!Number(value)) {
                update_state = value != "" ? false : true;
                value = "";
            } else {
                value = Number(value);
            }
        }

        if (key == "trigger") {
            clear_cron_regex = true;
        }

        if (value == "name") {
            update_state = e.target.value.toLowerCase()
        }

        if (update_state) {
            var data = {
                ...state.data,
                [key]: value,
            }

            if (clear_cron_regex) {
                data.cron_regex = "";

            }
            setState({
                ...state,
                data: data,
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        }
    }


    function onChangeHandlerRadio(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update_state = true;
        var clear_cron_regex = false;

        if (key == "retention_execution_count" || (key == "cron_regex" && state.data.trigger == "SCM_POLL")) {
            if (!Number(value)) {
                update_state = value != "" ? false : true;
                value = "";
            } else {
                value = Number(value);
            }
        }

        if (key == "trigger") {
            clear_cron_regex = true;
            RemoveFromArray(state.validations.cron_regex, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(state.validations.cron_regex, VALIDATION_TYPE_CRON_PATTERN);
            RemoveFromArray(state.validations.cron_regex, VALIDATION_TYPE_POSITIVE_NUMBER);
        }

        if (value == "name") {
            update_state = e.target.value.toLowerCase()
        }

        if (update_state) {
            var data = {
                ...state.data,
                [key]: value,
            }

            if (clear_cron_regex) {
                data.cron_regex = "";

            }
            setState({
                ...state,
                data: data,
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        }
    }

    function onChangeHandlerForKeyValue(key, value) {
        if (key == "components") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function onChangeHandlerkv(key, value) {
        if (key == "selected_users_pipeline_approval" || key == "selected_roles_pipeline_approval") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        if (key == "selected_roles_pipeline_approval") {
            var user_list_for_selected_role = getUserListForSelectedRole(value, state.user_data);
            setState({
                ...state,
                user_id_list: user_list_for_selected_role,
                data: {
                    ...state.data,
                    [key]: value,
                    selected_users_pipeline_approval: defaultAllSelected(user_list_for_selected_role),

                },
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        } else {
            setState({
                ...state,
                data: {
                    ...state.data,
                    [key]: value
                },
                error: {
                    ...state.error,
                    [key]: null,
                }
            });
        }
    }
    function onChangeHandlerSwitch(e) {
        var key = e.target.name;
        var value = e.target.value;

        var switch_key = [
            "jira_refrence_ids",
            "default_run",
            "manual_deploy_tag_select",
            "hotfix"
        ]

        if (switch_key.includes(key)) {
            value = !state.data[key];
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }
    const columnNames = [

        {
            label: 'Jira Referance ID',
            width: '40%',
            grey: false,
            name: 'jira_reference_key'
        },
        // {
        //     label: 'Uploaded Validation Script',
        //     width: '60%',
        //     grey: true,
        //     name: 'validation_script_name'
        // },

    ]
    const addJiraIdsInList = (jira_ids) => {
        console.log("total_jiraids_list", jira_ids)
        // if(jira_ids.length > 0){
        var jira_id_list = []
        jira_id_list.push(jira_ids);
        // }
        return jira_id_list
    }

    const resetParentState = () => {

        setState(prevState => ({

            ...prevState,
            fileUpload: {}
        }))
    }

    const getStageRunConditions = () => {
        // var current_stage_index=stage_index
        // if(!current_stage_index){
        //     current_stage_index=stages.length
        // }
        return getStageRunConditionsAndJiraRefList()
    }
    const addConditionsToStageFunction = (conditions) => {
        setState(new_state => ({
            ...new_state,
            data: { ...new_state.data, stage_run_condition: [...conditions] }
        }));
    }
    const toggleClick = () => {

        setState(new_state => ({
            ...new_state,
            open: !new_state.open
        }));
    }
    const handleDeleteRow = (data) => {
        var total_arr = state.data.jira_references;
        total_arr.forEach((item, key) => {

            if (item.jira_reference_key == data.jira_reference_key) {

                total_arr.splice(key, 1)

            }

        })
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                jira_references: [
                    ...total_arr
                ]
            }
        }))
    }
    const tableActionHandler = (type, data) => {
        setState(prevState => ({
            ...prevState,
            open: true,
            update_state_fn: true,
            single_form_data: {
                ...prevState.single_form_data,
                ...data
            }
        }))
    }
    const validateAndFetchJiraKey = () => {
        var children_vaidation_result = inherits.validateForm ? inherits.validateChildFormOnly() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var jira_data = child_states.jira_refrence_ids

        if (children_vaidation_result.valid) {
            var final_arr = state.data.jira_references;
            if (final_arr.length > 0) {
                let found_duplicate = final_arr.find(item => item.jira_reference_key == jira_data.data.jira_reference_key)
                if (!found_duplicate) {
                    final_arr.push({ jira_reference_key: jira_data.data.jira_reference_key, release_ticket_flag: jira_data.data?.release_ticket_flag ? jira_data.data?.release_ticket_flag : false })
                }
            } else {
                final_arr.push({ jira_reference_key: jira_data.data.jira_reference_key, release_ticket_flag: jira_data.data.release_ticket_flag, release_ticket_flag: jira_data.data?.release_ticket_flag ? jira_data.data?.release_ticket_flag : false })
            }
            setState(new_state => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    jira_references: [
                        ...final_arr
                    ]
                }
            }))

            toggleClick();
        }

    }
    const updateStateObj = (keyValue) => {
        var children_vaidation_result = inherits.validateForm ? inherits.validateChildFormOnly() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var jira_data = child_states.jira_refrence_ids
        if (children_vaidation_result.valid) {
            // keyValue.jira_reference_key = jira_data.data.jira_reference_key;
            let updatedList = state.data.jira_references.map(item => {
                if (item.jira_reference_key == keyValue.jira_reference_key) {
                    return { ...item, jira_reference_key: jira_data.data.jira_reference_key, release_ticket_flag: jira_data.data?.release_ticket_flag ? jira_data.data.release_ticket_flag : false };
                } else {
                    return item;
                }
            }
            )
            setState(new_state => ({
                ...new_state,
                update_state_fn: false,
                data: {
                    ...new_state.data,
                    jira_references: updatedList,
                }
            }))
            toggleClick();
        }
    }
    function fetchUserData() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application.id }, properties.api.addUserURL),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setState(new_state => ({
            ...new_state,
            show_loading_icon: true
        }));
        InvokeApi(requestInfo, handleUserDataSuccessApiHit, handleUserDataFailedApiHit);
    }
    function handleUserDataSuccessApiHit(data) {
        var user_role_list = getUserRoleList(data)
        if (state.data.selected_roles_pipeline_approval) {
            var user_id_list = getUserListForSelectedRole(state.data.selected_roles_pipeline_approval, data)
        }
        setState(new_state => ({
            ...new_state,
            user_id_list: user_id_list,
            roles_list: user_role_list,
            user_data: data,
            show_loading_icon: false
        }));
    }

    function handleUserDataFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }
    function getUserRoleList(user_data) {

        var all_roles_list = [];
        var check_if_roles_duplicate = [];
        user_data.map(item => {
            if (!check_if_roles_duplicate.includes(item.role_master)) {
                check_if_roles_duplicate.push(item.role_master)
                all_roles_list = [...all_roles_list, {
                    label: item.role_master,
                    id: item.role_master_id
                }]
            }
        });
        return all_roles_list;
    }
    function getUserListForSelectedRole(value, user_data) {
        console.log(value, user_data, "fjnasjkfjksnda")
        var user_list = [];
        user_data.map(user => {
            value.map(val => {
                if (user.role_master_id == val) {
                    if (state.user_id_list && !state.user_id_list.includes({ label: user.user.name, id: user.user.id, role_master: user.role_master, role_master_id: user.role_master_id })) {
                        user_list = [...user_list, { label: user.user.name, id: user.user.id, email: user.user.email, role_master: user.role_master, role_master_id: user.role_master_id }]
                    }
                }
            })
        })
        return user_list
    }
    function defaultAllSelected(users) {
        var selected = [];
        users.map(user => {
            selected.push(user.id)
        })
        return selected
    }
    return (
        <div style={{ position: 'relative' }}>
            <div className={origin ? classes.root : pipeline ? classes.rootEdit : classes.root} style={{ width: '100%' }}>
                <div className="add-pipeline-card">
                    <div className="add-pipeline-card-body">

                        <div className="form-controll">
                            <Input
                                type="text"
                                name="name"
                                mandatorySign
                                placeholder="Give a name to the pipeline"
                                label="Pipeline Name"
                                error={state.error}
                                data={state.data}
                                onChangeHandler={onChangeHandler}
                            />
                        </div>
                        <div className="form-controll">
                            <Input
                                type="select"
                                name="version"
                                mandatorySign
                                placeholder="Select Version"
                                list={[{ id: 'v2', label: 'Version 2' }, { id: 'v3', label: 'Version 3' }]}
                                label="Pipeline Version"
                                error={state.error}
                                data={state.data}
                                onChangeHandler={onChangeHandler}
                            />
                        </div>

                        <div className="form-controll">
                            <Input
                                type="text"
                                name="retention_execution_count"
                                mandatorySign
                                label="Retention Count"
                                placeholder="Retention Count"
                                error={state.error}
                                data={state.data}
                                onChangeHandler={onChangeHandler}
                            />
                        </div>
                        <div className="card box-shadow-none bg-white mb-15" style={{ padding: '1.5rem', paddingBottom: '0px' }}>
                            <div className="form-controll">
                                <Input
                                    type="radio"
                                    name="runtime_execution"
                                    label="Pipeline Exexution Via"
                                    list={executionTypes}
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerRadio}
                                    style={{ marginBottom: '0px !important' }}
                                />
                            </div>
                        </div>


                        <div className="form-controll">
                            <Input
                                type="radio"
                                name="trigger"
                                label="How you want to trigger the pipeline?"
                                list={triggerType}
                                data={state.data}
                                error={state.error}
                                onChangeHandler={onChangeHandlerRadio}
                            />
                        </div>

                        {
                            state.data.trigger != "MANUAL" && state.data.trigger != "WEBHOOKS" ?
                                <div className="form-controll mb-22">
                                    <Input
                                        type="text"
                                        name="cron_regex"
                                        mandatorySign
                                        placeholder={state.data.trigger == "SCHEDULE" ? "Enter cron pattern only" : "In minutes"}
                                        label={state.data.trigger == "SCHEDULE" ? "How frequently you want to trigger the pipeline?" : "How frequently you want to check for new commit"}
                                        subHeading={state.data.trigger == "SCHEDULE" ? "( Enter cron pattern only )" : "( In minutes )"}
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={onChangeHandler}
                                    />
                                </div> : null
                        }
                        <div className="card box-shadow-none bg-white mb-15">
                            <div className="form-controll card-header-switch">
                                <Input
                                    type="switch"
                                    name="hotfix"
                                    label="Is this pipeline used to deploy production hotfixes?"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerSwitch}
                                    info="If enabled, the pipeline will deploy patches directly to production. Each execution will be treated as a production rollback and used to calculate the change failure rate."
                                />
                            </div>
                        </div>
                        <div className="card box-shadow-none bg-white mb-15">
                            <div className="form-controll card-header-switch">
                                <Input
                                    type="switch"
                                    name="jira_refrence_ids"
                                    label="Is JIRA Ticket reference required to trigger the pipeline?"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerSwitch}
                                />
                            </div>
                            {
                                state.data.jira_refrence_ids ?
                                    <>
                                        {state.data.jira_references && state.data.jira_references.length > 0 ?
                                            <EditableTable columnNames={columnNames} actionButton={true}
                                                apiFetchedData={state.data.jira_references} // 
                                                tableActionHandler={tableActionHandler}
                                                fetchAfterAction={() => { }}
                                                endPointObj={{}}
                                                handleDeleteRow={handleDeleteRow}
                                                variant="static_delete"
                                                apiUrl={""}
                                            />
                                            : null
                                        }
                                        {
                                            state.data.jira_references && state.data.jira_references.length > 0 ? null :
                                                <div className="no-data-div">
                                                    <p>Please provide details of JIRA ticket required to trigger the pipeline</p>
                                                    <button
                                                        onClick={toggleClick}
                                                        className="btn btn-primary-v2  mb-15 m-auto  d-flex align-center text-center"
                                                    >
                                                        <AddIcon />&nbsp;
                                                        <span>Add Ticket Details</span>
                                                    </button>
                                                </div>
                                        }

                                        {
                                            state.open ?
                                                <JiraReferenceIds
                                                    inherits={state.child_inherits.jira_refrence_ids}
                                                    prev_state={state.single_form_data}
                                                    parentComponentState={state.single_form_data}
                                                    closeForm={toggleClick}
                                                    validateAndSave={
                                                        state.update_state_fn ?
                                                            () => { updateStateObj(state.single_form_data) } : validateAndFetchJiraKey

                                                    }
                                                    resetParentState={resetParentState}
                                                    addJiraIdsInList={addJiraIdsInList}
                                                /> :

                                                state.data.jira_references && state.data.jira_references.length > 0 ?
                                                    <Grid
                                                        className={`pr-15 pl-15 bg-fc-grey border-top  border-radius-0 `}
                                                        container
                                                        style={{ height: '40px' }}
                                                        direction="row"
                                                        justifyContent="flex-end"
                                                        alignItems="center"
                                                    >
                                                        <div className="d-flex">
                                                            <button className="transparent-btn nowrap" onClick={toggleClick}>
                                                                <AddIcon style={{ color: 'blue' }} /> Add More
                                                            </button>
                                                        </div>

                                                    </Grid>
                                                    :
                                                    null
                                        }

                                    </> :
                                    <ResetComponent inherits={state.child_inherits.jira_refrence_ids} />
                            }
                        </div>
                        <div className="card box-shadow-none bg-white mb-15">
                            <div className="form-controll card-header-switch">
                                <Input
                                    type="switch"
                                    name="default_run"
                                    label="Are you about to setup multi services pipeline? If yes do you want to disable single click execution of the pipeline?"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerSwitch}
                                />
                            </div>
                        </div>
                        <div className="card box-shadow-none bg-white mb-15">
                            <div className="form-controll card-header-switch">
                                <Input
                                    type="switch"
                                    name="manual_deploy_tag_select"
                                    label="Do you want to select tag while executing pipeline?"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerSwitch}
                                />
                            </div>
                        </div>
                        <div className="form-controll">
                            <Input
                                type="tagged-input"
                                placeholder="Add a tags to your pipeline"
                                label="Tags"
                                subHeading="(Enter tag and press enter)"
                                name="tags"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={onChangeHandlerForKeyValue}
                            />
                        </div>
                        <div className={`d-flex align-center border-bottom space-between `} style={{ height: '35px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                            <label style={{ color: '#6d6a6a' }} className='font-12 mr-5'>Pipeline Variables</label>
                        </div>
                        <div style={{ padding: "15px" }}>
                            <PipepineAddiitonalField
                                prev_state={state.env_var}
                                inherits={state.child_inherits.env_var}
                            />
                        </div>


                        {/* <div className="form-controll">
                            <Input
                                label="Role"
                                type="imgCheckbox"
                                name="pipeline_role_access"
                                list={available_roles}
                                onChangeHandler={onRoleChangeHandler}
                                data={getDataForRoles(state.data)}
                                error={state.error}
                                varient="multicheck"
                            />
                        </div> */}

                    </div>
                </div>
                {pipeline && !origin ?
                    <Footer>
                        <button className="btn-save btn-save-primary btn-save-lg" onClick={changePanel}>Save Basic Info</button></Footer> :
                    <div className="add-pipeline-card-footer">
                        <button className="btn-save btn-save-primary btn-save-lg" onClick={changePanel}>Save</button>
                    </div>}
            </div>

        </div>
    );
}

BasicInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default BasicInfo;

const JiraReferenceIdsInput = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state :
        {
            data: {},
            error: {},
            fileUpload: null,
            gitRepo: null,
            child_inherits: {
                fileUpload: {
                    "validateForm": () => { return { valid: true } },
                    "getState": () => { return {} },
                    "getData": () => { return {} }
                }
            }
        })
    const commonFunctions = getCommonFunctions(state, setState, inherits);



    function getFileUploadState() {
        var state_temp = FileUploadDefaultState();
        if (state.fileUpload) {
            state.fileUpload.files.forEach(file_name => {
                if (typeof (file_name) == "string") {
                    state_temp.form_data.data.files.push({
                        name: file_name
                    });
                } else {
                    state_temp.form_data.data.files.push(file_name);
                }
            });
        }
        return state_temp;
    }
    return (
        <>
            <Grid item lg={4}>
                <Input
                    label="Jira Reference Id"
                    type="text"
                    placeholder="main.jira.id"
                    name="jira_id"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                />
            </Grid>
            <Grid item lg={3}>
                <Input
                    type="simple-checkbox"
                    label="Validation"
                    name="jira_id_validation"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                />
            </Grid>
            {
                state.data.jira_id_validation &&
                <Grid item lg={4}>
                    <FileUpload
                        inherits={state.child_inherits.fileUpload}
                        varient="show_filename_only"
                        state={
                            state.fileUpload ?
                                state.fileUpload.files ?
                                    getFileUploadState() :
                                    null :
                                null} />
                </Grid>
            }

        </>
    )
}

JiraReferenceIdsInput.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const HeaderComponent = () => {
    return (

        <Grid item lg={12} className="border-bottom">
            <div>
                Pre-Requisite JIRA IDs
            </div>
        </Grid>

    )
}

export function getServicesList(services, ignoreServiceId) {
    var serviceList = [];
    if (!ignoreServiceId) {
        ignoreServiceId = 0
    }
    if (services && services.length != 0) {
        services.forEach(service => {
            if (service.id != ignoreServiceId) {
                serviceList.push({
                    id: service.id,
                    label: service.name
                });
            }
        });
    }

    return serviceList;
}
export function getRadioList(services) {
    var serviceList = [];

    if (services && services.length != 0) {
        services.forEach(service => {
            serviceList.push({
                value: service.id,
                label: service.name
            });
        });
    }

    return serviceList;
}

export function getServicesListByNameOnly(services) {

    var serviceList = [];

    if (services && services.length != 0) {
        services.forEach(service => {
            serviceList.push({
                id: service.name,
                label: service.name
            });
        });
    }

    return serviceList;
}
