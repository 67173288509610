import React, { useEffect, useState } from 'react';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import DynamicFilterField from './DynamicFilterField';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Grid } from '@material-ui/core';
import { Loading } from '../../../utils/Loading';
import { makeStyles } from "@material-ui/core/styles";
import AlertStrip from '../../../../components/AlertStrips';

const CheckJiraIds = ({ pipeline, 
    pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,
    ...props }) => {
    const classes = useStylesDialog();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    const default_job_template = props.default_job_template;
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });
    const selectedJobTemplateId = props.selectedJobTemplateId;
    const [state, setState] = useState(getCheckJiraIdsDefaultState(prev_state, issue_keys_list))
    const ticketing_system_project_list = props.ticketing_system_project_list ? props.ticketing_system_project_list : [];
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const jira_comments_validation_type = [
        {
            label: "Text",
            value: "Text"
        },
        {
            label: "Pattern",
            value: "Pattern"
        }
    ]

    const onEnterKeyPress = (e, id, variableId) => {
        if (e.key === 'Enter') {
            fetchJiraUserList(id, state.data[e.target.name], variableId)
        }
    }

    const onChangeHandlerSearchInput = e => {
        const k = e.target.name;
        const v = e.target.value;

        commonFunctions.onChangeHandler(e)

    }
    const onKeyValueChangeHandlerForApprovalUser = (k, v) => {
        // console.log(state.event_list,"fsadjfajs");
        console.log("fsadjfajs", k, v);
        setState({
          ...state, data: { ...state.data, [k]: v },
          error: { ...state.error, [k]: null }
        });
    }

    const fetchJiraUserList = (id, user) => {
        console.log(id,user,'Isdds_0001sdd');
        let variableId = state.data.jira_issue_list;
        let get_project_key = variableId.split(".");
        const projectIndex = get_project_key.indexOf("project");
        const valueAfterProject = projectIndex !== -1 && get_project_key[projectIndex + 1] ? get_project_key[projectIndex + 1] : null;
        if (user) {
            if (user.length > 0) {
                if (user.length > 2) {
                    var requestInfo = {
                        endPoint: GenerateURL({ template_id: id, user_query: user,issue_key:valueAfterProject}, properties.api.jira_users + `&project=${valueAfterProject}`),
                        httpMethod: "GET",
                        httpHeaders: { "Content-Type": "application/json" }
                    }
                    InvokeApi(requestInfo, (data) => handleJiraUserListSuccess(data, variableId), (error)=>handleJiraUserListFailed(error,variableId));
                    setState(new_state => ({
                        ...new_state,
                        user_object: {
                            ...new_state.user_object, 
                            [`user_loading_jira_user_list`]: true
                        }
                    }));
                } else {

                    setState(state => ({
                        ...state,

                        error: {
                            ...state.error,
                            [`user_`]: "Please enter atleast first three characters of name"
                        }
                    }))

                }
            } else {
                setState(state => ({
                    ...state,

                    error: {
                        ...state.error,
                        [`user_`]: "Please enter atleast first three characters of name"
                    }
                }))
            }


        } else {
            setState(new_state => ({
                ...new_state,
                no_jira_user_found: false,
                no_user_found: false,
                error: {
                    ...new_state.error,
                    user: "please enter atleast first three characters of name"
                }
            }));
        }


    }

    function handleJiraUserListSuccess(data, variableId) {

        console.log(variableId, 'var_001wd');
        var jira_user_list = data.user;
        if (jira_user_list.length > 0) {
            setState(new_state => ({
                ...new_state,
                // loading_jira_user_list: false,
                // jira_user_list: jira_user_list,
                // show_user_dropdown: true,
                // no_jira_user_found: false,
                user_object: {
                    ...new_state.user_object, [`user_jira_user_list`]: jira_user_list,
                    [`user_show_user_dropdown`]: true,
                    [`user_no_jira_user_found`]: false,
                    [`user_loading_jira_user_list`]: false
                }

            }));
        } else {
            setState(new_state => ({
                ...new_state,
                // loading_jira_user_list: false,
                // jira_user_list: [],
                // no_user_found: "user not found with these initials",
                user_object: {
                    ...new_state.user_object, [`user_jira_user_list`]: jira_user_list,
                    [`user_no_user_found`]: "user not found with these initials",
                    [`user_show_user_dropdown`]: true,
                    [`user_loading_jira_user_list`]: false
                }
            }));
        }

    }

    function handleJiraUserListFailed(error, variableId) {
        console.log("brrhuhrune",error);
        setState(new_state => ({
            ...new_state,
            // no_jira_user_found: true,
            // loading_jira_user_list: false,
            // error_msg_jira_list: error,
            // jira_user_list: [],
            user_object: {
                ...new_state.user_object,
                [`user_no_jira_user_found`]: true,
                [`user_loading_jira_user_list`]: false,
                //[`user_show_user_dropdown`]: true,
                [`user_error_msg_jira_list`]: error,
                [`user_jira_user_list`]: []

            }
        }))
    }

    useEffect(()=>{
        if(state.data.already_picked){
            setState((prev_state)=>({
                ...prev_state,
                data: {
                    ...prev_state.data,
                    already_comment_validator: "Text",
                }
            }))
        }else{
            setState((prev_state)=>({
                ...prev_state,
                data: {
                    ...prev_state.data,
                    already_comment_validator: null,
                }
            }))
        }
    },[state.data.already_picked])

    console.log("nfknksdjs", state);
  return (
    <>
        <Input
                type="select"
                name="ticketing_project"
                mandatorySign
                list={ticketing_system_project_list}
                label="Available Projects"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
                jiraOperation={true}
                jiraOperationInfo={"Please Select an Option"}
            />
                                        <DynamicFilterField
                                            jiraIssueKeys={jiraIssueKeys}
                                            pipelineVars={pipelineVars}
                                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                                            setParentState={setState}
                                            parentState={state}
                                            prev_state={prev_state}
                                            dynamicValidationFormError={dynamicValidationFormError}
                                            setTopParentState={setParentState}
                                            topParenState={parentState}
                                        >
                                            <Input
                                                type="select"
                                                name="jira_issue_list"
                                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                                label="Select Jira Id Refrence key"
                                                placeholder="Jira id refrence key"
                                                mandatorySign
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                data={state.data}
                                                error={state.error} />
                                        </DynamicFilterField>
                                        <Input
                                        type="radio"
                                        name="comment_validator_type"
                                        label="Comment Validation Type"
                                        mandatorySign
                                        list={jira_comments_validation_type}
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />

                                        <Input          
                                        type="text"
                                        name="comment_validator"
                                        mandatorySign
                                        label={state.data.comment_validator_type == "Text" ? "Comment Validation Text" : "Comment Validation Pattern"}
                                        placeholder={state.data.comment_validator_type == "Text" ? "Text..." : "Pattern..."}
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />

                                        <div className='job-template-dropdown input-mb-0' style={{
                                            border: '1px solid #dedede',
                                            marginBottom: '25px',
                                            overflow: 'hidden',
                                            borderRadius: '8px'
                                        }}>
                                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                                <div className="heading" style={{display:"flex",alignItems:"center"}}>Check if already picked for previous release
                                                </div>
                                                <div className="switch-input-wrapper">
                                                    <Input
                                                        type="only-switch"
                                                        name="already_picked"
                                                        data={state.data}
                                                        error={state.error}
                                                        onChangeHandler={commonFunctions.toggleState}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {state.data.already_picked && 
                                            <>
                                                <Input
                                                    type="radio"
                                                    name="already_comment_validator_type"
                                                    label="Comment Validation Type For Previous Release"
                                                    list={jira_comments_validation_type}
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />

                                                    <Input          
                                                    type="text"
                                                    name="already_comment_validator"
                                                    label={state.data.already_comment_validator_type == "Text" ? "Comment Validation Text For Previous Release" : "Comment Validation Pattern For Previous Release"}
                                                    placeholder={state.data.already_comment_validator_type == "Text" ? "Text..." : "Pattern..."}
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </>
                                        }
                                        <>

                                            <Grid item lg={12}>
                                                <span style={{ fontSize: '12px', color: '#828282' }}>
                                                    Search the user here.</span><span style={{ fontSize: '11px', color: '#828282' }}>&nbsp;(Please enter atleast first three characters of name)</span>
                                                <div className={classes.searchBar}>
                                            
                                                    <Input
                                                        type="text"
                                                        varient="handle-keys"
                                                        placeholder="Search User For Approval"
                                                        name={"jira_user_search"}
                                                        data={state.data}
                                                        error={state.error}
                                                        onChangeHandler={onChangeHandlerSearchInput}
                                                        onKeyPress={(e, template_id) => { onEnterKeyPress(e, selectedJobTemplateId ? selectedJobTemplateId : default_job_template.id ) }}
                                                    />
                                                    <button className='btn btn-searchbar' onClick={() => { fetchJiraUserList(selectedJobTemplateId ? selectedJobTemplateId : default_job_template.id , state.data["jira_user_search"],) }}>
                                                        <span class="material-icons material-symbols-outlined">
                                                            search
                                                        </span>
                                                    </button>
                                                </div>
                                            </Grid>

                                            {
                                                state.user_object?.[`user_loading_jira_user_list`] ?
                                                    <Loading varient="light" /> :
                                                    state.user_object?.[`user_show_user_dropdown`] ?
                                                        
                                                            <Grid item lg={12}>
                                                                <Input
                                                                    type="select-with-checkbox"
                                                                    name={"selected_jira_users"}
                                                                    mandatorySign
                                                                    list={
                                                                        state.user_object?.[`user_jira_user_list`].map(item => {
                                                                            return { label: item, id: item }
                                                                        })}
                                                                    label="Select Jira Users For Approval"
                                                                    placeholder="Jira Users"
                                                                    onChangeHandler={onKeyValueChangeHandlerForApprovalUser}
                                                                    data={state.data}
                                                                    error={state.error} />
                                                            </Grid>
                                                            
                                                        : state.user_object?.[`user_no_jira_user_found`] ?
                                                            <AlertStrip
                                                                message={typeof (state.user_object?.[`user_error_msg_jira_list`]) === "string" ? state.user_object?.[`user_error_msg_jira_list`] : "Jira Users not found in this Job Template"}
                                                                variant="error" />
                                                            :
                                                            state.user_object?.[`user_no_user_found`] ?
                                                                <AlertStrip
                                                                    message={state.user_object?.[`user_no_user_found`]}
                                                                    variant="error" />
                                                                :
                                                                <Grid item lg={12}>
                                                                
                                                                    <Input
                                                                        type="select-with-checkbox"
                                                                        name={"selected_jira_users"}
                                                                        mandatorySign
                                                                        list={[]}
                                                                        label="Available Jira Users"
                                                                        placeholder="Jira Users"
                                                                        onChangeHandler={null}
                                                                        data={state.data}
                                                                        error={state.error} />
                                                                </Grid>
                                            }
                                            {
                                                state.user_object?.[`user_jira_user_list`] && state.user_object?.[`user_jira_user_list`].length > 0? null:
                                                    state.user_object?.[`user_no_user_found`] ?
                                                    <AlertStrip
                                                        message={state.user_object?.[`user_no_user_found`]}
                                                        variant="error"
                                                        dismissible= {true}
                                                        /> : null
                                            }
                                        </>
                                </>
  )
}

function getCheckJiraIdsDefaultState(prev_state, issue_keys_list) {
    return {
        data: prev_state ? {
            //issue_key: prev_state.issue_key,
            comment_validator_type: prev_state.comment_validator_type ? prev_state.comment_validator_type : "Text",
            comment_validator: prev_state.comment_validator,
            already_picked: prev_state.already_picked ? prev_state.already_picked : false,
            already_comment_validator_type: prev_state.already_comment_validator_type ? prev_state.already_comment_validator_type : null,
            already_comment_validator: prev_state.already_comment_validator,
            selected_jira_users: prev_state.selected_jira_users,
            project_job_template_id: prev_state.project_job_template_id? prev_state.project_job_template_id: null,
            jira_issue_list: prev_state.jira_issue_list,
            ticketing_project: prev_state.ticketing_project ? prev_state.ticketing_project : null
        } : {
                issue_key: issue_keys_list.length > 0 ? issue_keys_list[0].id : null,
                comment_validator_type: "Text",
                already_picked: false,
                already_comment_validator_type: "Text",
        },
        error: {},
        dynamic_fields:{},
        validations: {
            //issue_key: [VALIDATION_TYPE_REQUIRED],
            comment_validator: [VALIDATION_TYPE_REQUIRED],
            selected_jira_users: [VALIDATION_TYPE_REQUIRED],
        },
    };
}

const useStylesDialog = makeStyles(() => ({

    borderRadius: {
        borderRadius: '5px'
    },
    searchBar: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 60px',
        border: '1px solid #b7b7b7',
        marginBottom: '15px',
        borderRadius: '4px',
        '& .input-component': {
            marginBottom: '0!important',
            borderRight: '1px solid #b7b7b7',
            '& input': {
                border: 'none',
                '&:focus': {
                    outline: 'none',
                    border: '0px!important'
                },
                '&:disabled': {
                    cursor: 'not-allowed'
                }
            }
        },
        '& .btn': {
            marginRight: 0,
            padding: '.3rem 1rem'
        }
    },
    card_shadow: {
        boxShadow: ' 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        color: '#6e6e6e',
        borderRadius: '25px',
        fontSize: '13px',
        width: '450px',
        marginTop: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        border: 'none',
        "& .card-header": {
            width: '100%',
            borderRadius: '25px',
            border: 'none!important',
        },
    },
    conditions_div_header: {
        backgroundColor: "#fafafa",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
        borderBottom: '1px solid #dedede',
        padding: '10px',
        '& .heading': {
            lineHeight: '1.5',
            fontSize: '13px',
            color: '#000'
        },
    },

    condition_block: {
        display: 'flex', alignItems: 'center', borderBottom: "1px solid #a09e9e", borderTop: '1px solid #a09e9e', padding: "8px", backgroundColor: '#f4f4f4', justifyContent: 'space-between',
    }
}));

export default CheckJiraIds