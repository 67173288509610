import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import RouteWithLayout from "./components/RouteWithLayout/RouteWithLayout";
import RouteWithMinimalLayout from "./components/RouteWithMinimalLayout/RouteWithMinimalLayout";
import ProfileLayout from "./components/ProfileLayout/ProfileLayout";
import ServiceDetailsView from "./views/service/detail";
import MainLayout from "./layouts/Main";
import ProflieLayout from "./layouts/profile/ProflieLayout";
import Minimal from "./layouts/Minimal/Minimal";
import theme from "./theme";
import ServiceListing from "./views/service/listing";
import ServiceAdd from "./views/service/add";
import { LogOutView } from "./views/LogOutView";
import { IsAuthenticated, IsRefreshTokenValid, IsSuperUser, IsUserActualSuperUser } from "./util/security";
import ApplicationListing from "./views/application/listing";
import JobTemplatesListing from "./views/jobTemplates/JobTemplatesListing";
import CanaryTemplatesListing from "./views/canaryTemplates/canaryTemplatesListing";
import BuildDeploy from "./views/build_deploy/BuildDeploy";
import ManifestPlaceholder from "./views/manifest/ManifestPlaceholder";
import EnvList from "./views/Environment/list/EnvList";
import ServiceAddRevamp from "./views/serviceRevamp/Index";
import NodeDetails from "./views/superAdmin/nodes/NodeDetails";
import NodesListing from "./views/superAdmin/nodes";
import PodsDetails from "./views/superAdmin/pods/PodsDetails"
import ClusterDetail from "./views/superAdmin/Cluster/Detail";
import IngressList from "./views/superAdmin/Ingress/list/IngressList";
import ProjectListing from "./views/superAdmin/project/listing/index";
import AddClusterFirstScreen from "./views/superAdmin/Cluster/Add/AddClusterFirstScreen";
import AddNewCluster from "./views/superAdmin/Cluster/Add/New";
import properties from "./properties/properties";
import AddPipeline from "./views/pipeline/add";
import PipelineCard from "./views/pipeline/listing/pipelineCard";
import PipelineExecution from "./views/pipeline/execution/PipelineExecution";
import ClusterList from "./views/superAdmin/Cluster/Listing";
import AddClusterFlow from "./views/superAdmin/Cluster/Add";
import AddNamespaces from "./views/superAdmin/Namespace/Add/AddNamespace";
import PodsListing from "./views/superAdmin/pods";
import NamespaceDetails from "./views/superAdmin/Namespace/Detils/NamespaceDetails"
import NamespaceList from "./views/superAdmin/Namespace/list/NamespaceList";
// import Maintenance from './views/Maintenance';
import { Loading } from "./views/utils/Loading";

import SideCarsInfo from './views/serviceRevamp/add/cd_flow/SideCarsInfo';
import ErrorPageNotFound from "./views/ErrorPageNotFound";
import { RedirectToProdEnv } from "./views/RedirectToProdEnv";
import PipelineRuntimeParamsUpdate from "./views/pipeline/pipelineRuntimeParamsV3/PipelineRuntimeParamsUpdate";
import HelmIntegrationList from "./views/integrations/HelmIntegrations/HelmIntegrationList";
import PremiumFeaturesInfo from "./components/genericComponents/FeaturesValidation/PremiumFeatureComponents/PremiumFeaturesStoped";

import MonitoringSourceList from "./views/monitoringSource/MonitoringSourceList";
import StabilityMatrixList from "./views/stabilityMatrix/StabilityMatrixList";
import AddStabilityMatrix from "./views/stabilityMatrix/AddStabilityMatrix";
import AddCanary from "./views/canaryTemplates/addCanaryTemplates/AddCanary";
import CanaryAnalysisReport from "./views/pipeline/execution/components/CanaryAnalysisReport";
import WhiteTopMinimal from "./layouts/WhiteTopMinimal/WhiteTopMinimal";


import CustomDialogLayout from "./layouts/CustomLayout/CustomDialogLayout";



const LoginView = lazy(() => import("./views/LoginView"));
const RefreshSessionView = lazy(() => import("./views/RefreshSessionView"))
const Welcome = lazy(() => import("./views/Welcome"))
const TestView = lazy(() => import("./views/TestView"))
const LandingPage = lazy(() => import("./views/LandingPage"))
const SignUp = lazy(() => import("./views/saas/signUp/SignUp"))
const AuthComplete = lazy(() => import("./views/AuthComplete"))
const ViewCredentials = lazy(() => import("./views/settings/credentials/ViewCredentials"))
const AddProject = lazy(() => import("./views/saas/signUp/AddProject"))
const NewSignup = lazy(() => import("./views/saas/signUp/SignupThree"))
const AddMonitoring = lazy(() => import("./views/superAdmin/monitoring/AddMonitoring"))
const ManageUsers= lazy(() => import("./views/superAdmin/UserAndExternalRoles/ManageUsers")) 
const ExternalGroupingMapping= lazy(() => import("./views/superAdmin/UserAndExternalRoles/ExternalGroupMapping")) 
const UploadUsers= lazy(() => import("./views/superAdmin/UserAndExternalRoles/UploadUsers")) 

const UploadUsersNew= lazy(() => import("./views/superAdmin/UserAndExternalRoles/UploadUsersNew")) 
const ResetPassword= lazy(() => import("./views/ResetPassword")) 
const ContainerRegistries= lazy(() => import("./views/integrations/containerRegisteries/ContainerRegistries")) 
const AddRegistry= lazy(() => import("./views/integrations/containerRegisteries/AddRegistry")) 
const Secrets= lazy(() => import("./views/integrations/secrets/Secrets")) 
const AddSecret= lazy(() => import("./views/integrations/secrets/AddSecret")) 
const Tickets= lazy(() => import("./views/integrations/tickets/Tickets")) 
const AddTicket= lazy(() => import("./views/integrations/tickets/AddTicket")) 
const ReposListing= lazy(() => import("./views/integrations/repos/ReposListing")) 
const SystemSetting= lazy(() => import("./layouts/components/systemSettings/SystemSetting")) 
const AddBitBucket= lazy(() => import("./views/integrations/repo/bitbucket/addBitbucket/AddBitBucket")) 
const GitLabLayout= lazy(() => import("./views/integrations/repo/gitlab/mapGitlabAccount/GitLabLayout")) 
const UploadUserGroup= lazy(() => import("./views/superAdmin/UserAndExternalRoles/uploadUserGroup/UploadUserGroup")) 
const AddGitHub= lazy(() => import("./views/integrations/repo/gitHub/addBitbucket/AddGitHub")) 
const PermissionsListing= lazy(() => import("./views/superAdmin/permissions/listing/PermissionsListing")) 
const SSOAuthComplete= lazy(() => import("./views/SSOAuthComplete")) 
const PermissionsPoliciesListing= lazy(() => import("./views/superAdmin/permissions-policies/listing/PermissionsPoliciesListing")) 
const EventListener= lazy(() => import("./views/gitops/EventListener")) 
const AddEventListenerFrom= lazy(() => import("./views/gitops/add/AddEventListenerFrom"))
const CreateEvent = lazy(() => import("./views/gitops/revamp/add/CreateEvent"))
const SseTesting= lazy(() => import("./views/SseTesting")) 
const EnvScheduleDialogNew= lazy(() => import("./views/Environment/EnvironmentDashboard/components/EnvScheduleDialogNew")) 
const IndexNew= lazy(() => import("./views/application/listing/IndexNew")) 
const DashboardHome= lazy(() => import("./views/application/listing/DashboardHome")) 
const WorkspaceAccess= lazy(() => import("./views/service/listing/components/workspace/WorkspaceAccess")) 
const WorkspaceAccessV3= lazy(() => import("./views/service/listing/components/workspaceV3/WorkspaceAccessV3")) 

const SystemSettings= lazy(() => import("./layouts/components/SystemSettings")) 
const EventListenerStatus= lazy(() => import("./views/event-listener/EventListenerStatus")) 
const EventListenerDetails= lazy(() => import("./views/event-listener/EventListenerDetails")) 
const UserGroupList= lazy(() => import("./views/superAdmin/UserGroup/UserGroupList")) 
const PolicyTemplateListing= lazy(() => import("./views/superAdmin/policyTemplate/PolicyTemplateListing")) 
const BpAgentStatusComponent= lazy(() => import("./components/genericComponents/BpAgentStatusComponent"))
const BetaEnvStatusComponent = lazy(() => import ("./components/genericComponents/BetaEnvStatusComponent"))
const Thankyou= lazy(() => import("./views/Thankyou")) 
const BuildInsights= lazy(() => import("./views/buildInsight/BuildInsights")) 
const AddJobTemplate= lazy(() => import("./views/jobTemplates/AddJobTemplate"))
const JobTemplateListing= lazy(() => import("./views/catalog/JobTemplateListing")) 
const CreateNewStepCatalog= lazy(() => import("./views/catalog/components/CreateNewStepCatalog")) 
const CreateEventListener = lazy(() => import("./views/gitopsRevamp/list/CreateEventListener"))


const AddCanaryTemplates= lazy(() => import("./views/canaryTemplates/AddCanaryTemplates")) 
const CompareEnv= lazy(() => import("./views/Environment/CompareEnv")) 
const UserAndExternalRoles= lazy(() => import("./views/superAdmin/UserAndExternalRoles/UserAndExternalRoles")); 
const clusterPermissions = lazy(()=>import("./views/superAdmin/Cluster/Add/clusterPermissions"));
const LogsViewer= lazy(() => import("./views/service/listing/components/LogsViewer"));
const MonitoringDialogue = lazy(() => import("./views/service/detail/components/MonitoringDialogue"))
const CustomLayoutLogs= lazy(() => import("./layouts/CustomLayout/CustomLayout")) 
const AddRegistries= lazy(() => import("./views/superAdmin/ManageRegistries/addRegistries/AddRegistries")) 
const UnderMaintenance= lazy(() => import("./views/UnderMaintenance")) 
const AddStepToJob= lazy(() => import("./views/jobTemplates/components/AddStepsToJob")) 
const AddVault = lazy(() => import("./views/superAdmin/Cluster/Resources/Components/Vault")) 
const AddSonar = lazy(() => import("./views/superAdmin/Cluster/Resources/Components/Sonar")) 
const Setup = lazy(() => import("./views/superAdmin/Setup")) 
const ClusterBackupListing = lazy(() => import("./views/superAdmin/clusterBackup/listing/ClusterBackupListing")) 
const UserInfo = lazy(() => import("./views/superAdmin/userSettings/UserInfo")) 
const BillingModule = lazy(() => import("./views/superAdmin/billing/Index")) 
const BillingRegistry = lazy(() => import("./views/superAdmin/billing/BillingRegistry")) 
const AddEnv = lazy(() => import("./views/Environment/AddEnv")) 
const ConfigMap = lazy(() => import("./views/Environment/AddConfig")) 
const AddSecrets = lazy(() => import("./views/Environment/AddSecrets")) 
const CiInfo = lazy(() => import("./views/serviceRevamp/add/ci_flow/CiInfo")) 
const CdInfo = lazy(() => import("./views/serviceRevamp/add/cd_flow/CdInfo")) 
const AddEnvRevamp = lazy(() => import("./views/serviceRevamp/add/add_env/Index")) 
const EditEnv = lazy(() => import("./views/Environment/EditEnv")) 
const EnvAndUserGroup = lazy(() => import("./views/serviceRevamp/components/EnvAndUserGroup")) 
const Manifest = lazy(() => import("./views/service/add/panels/CDDetails/Manifest")) 
const LDCForm = lazy(() => import("./views/superAdmin/Cluster/Add/LDC_flow/LDCForm")) 
const Celery = lazy(() => import("./views/superAdmin/celery/Celery")) 
const OnGoingActivity = lazy(() => import("./views/superAdmin/activityFramework/OnGoingActivity")) 
const OnGoingActivityNew = lazy(() => import("./views/superAdmin/activityFramework/OnGoingActivityNew")) 
const AddIstio = lazy(() => import("./views/superAdmin/istio/AddIstio")) 
const AddKeda = lazy(() => import("./views/superAdmin/keda/AddKeda")) 
const AddIstioGateway = lazy(() => import("./views/superAdmin/istio/AddIstioGateway")) 
const GatewayList = lazy(() => import("./views/superAdmin/istio/GatewaysList")) 
const IstioAddOns = lazy(() => import("./views/superAdmin/istio/IstioAddOns")) 
const ViewYaml = lazy(() => import("./components/genericComponents/ViewYaml")) 
const EnvironmentDashboard = lazy(() => import("./views/Environment/EnvironmentDashboard/EnvironmentDashboard")) 
const SchedulerListing = lazy(() => import("./views/Environment/EnvironmentDashboard/components/SchedulerListing")) 
const ViewLog = lazy(() => import("./components/genericComponents/ViewLog")) 
const ViewLogsInfiniteScroll = lazy(() => import("./views/service/listing/components/ViewLogsInfiniteScroll")) 
const AddProjectWithCluster  = lazy(() => import("./views/superAdmin/project/AddProject")) 
const AddLogMonitioring  = lazy(() => import("./views/superAdmin/monitoring/Log/Add")) 
const ClusterDetails  = lazy(() => import("./views/superAdmin/ClusterDetails/ClusterDetails")) 
const AddClusterSecondFlow = lazy(() => import("./views/superAdmin/AddCluster/components/AddClusterSecondFlow")) 
const ClusterBackup  = lazy(() => import("./views/superAdmin/clusterBackup/ClusterBackup")) 
const ErrorPage  = lazy(() => import("./views/ErrorPage")) 
const AddIngress  = lazy(() => import("./views/superAdmin/Ingress/Add")) 
const ForgotPassword  = lazy(() => import("./views/ForgotPassword")) 
const Exception  = lazy(() => import("./views/Exception")) 
const DeployInsights = lazy(() => import("./views/deployInsight/DeployInsights"))
const AdminDashboard = lazy(() => import("./views/superAdmin/AdminDashboard"))
const VelocityInsight = lazy(() => import("./views/velocityInsights/VelocityInsights"))
const EventListenerList = lazy(()=> import("./views/gitopsRevamp/list/EventListnerList"))
const DatabaseListing = lazy(()=>import("./views/superAdmin/databaseOnboarding/DatabaseListing"))
const PipelineHistoryListing = lazy(() => import("./views/pipeline/listing/component/PipelineHistoryListing"))


const VirtualMachineSetListing = lazy(() => import("./views/superAdmin/VirtualMachineSetListing/VirtualMachineSetListing"))
const AddVirtualMachineSet = lazy(() => import("./views/superAdmin/VirtualMachineSetListing/AddVirtualMachineSet/AddVirtualMachineSet"))
const LoadbalancerList = lazy(() => import("./views/superAdmin/loadBalancer/LoadbalancerList"))
const AddLoadBalancer = lazy(() => import("./views/superAdmin/loadBalancer/addLoadBalancer/AddLoadBalancer"))
import AddGlobalPipeline from './views/GlobalPipelines/add';
import GlobalJobTemplates from "./views/globalJobTemplate/GlobalJobTemplates";
import AddGlobalJobTemplate from "./views/globalJobTemplate/AddGlobalJobTemplate";
import EditGlobalJobTemplate from "./views/globalJobTemplate/EditGlobalJobTemplate";
import GlobalPipelineCard from "./views/GlobalPipelines/listing/GlobalPipelineCard";
import GlobalPipelineExecution from "./views/GlobalPipelines/execution/GlobalPipelineExecution";
import GlobalPipelineHistoryListing from "./views/GlobalPipelines/listing/component/GlobalPipelineHistoryListing";
import EditJobTemplates from './views/jobTemplates/EditJobTemplate';
import SprintApprove from "./views/sprintView/SprintApprove";
import MoveSprintTask from "./views/sprintView/components/MoveSprintTask";
import BulkMoveSprintTask from "./views/sprintView/components/BulkMoveSprintTask";
import SelectApplicationForSpirntApprove from "./views/sprintView/components/SelectApplicationForSpirntApprove";
const ReleasePackageAddForm = lazy(() => import("./views/releasePackage/add/components/ReleasePackageAddForm"))
const ReleasePackageIndex = lazy(() => import("./views/releasePackage/listing/ReleasePackageIndex"))
const SingleReleasePackage = lazy(()=> import("./views/releasePackage/listing/SingleReleasePackage"))

import { RepoListingPageSkeleton } from "./views/integrations/repos/ReposListing";
import AddTicketingSystemDialog from "./views/integrations/tickets/AddTicketingSystemDialog";
export const routes = {
  application: {
    key: "application",
    label: "Your Apps",
    path: "/application/:application_id/service/listing",
    parent_path: "/ApplicationDashboard",
    component: ServiceListing,
    parent_component: ApplicationListing,
    type: "element-static-heading",
    icon: "flaticon-show-apps-button",
    autherization: [
      {
        route: properties.api.service_dahboard_data_url,
        type: "GET",
      },
    ],
    routes: {
      service: {
        key: "service",
        label: "Service Overview",
        path: "/application/:application_id/service/:service_id/detail",
        parent_path: "/application/:application_id/service/listing",
        component: ServiceDetailsView,
        type: "element-only-heading",
        icon: "flaticon-settings-cogwheel-button",
        imgPath : "/images/service-overview.svg",
        imgPathSelected : "/images/service-overview-selected.svg",
        autherization: [
          {
            route: properties.api.service_dahboard_data_url,
            type: "GET",
          },
        ],
        routes: {
          service_edit: {
            label: "Edit Service",
            path: "/application/:application_id/service/:service_id/edit",
            component: ServiceAdd,
            autherization: [
              {
                route: properties.api.service_basic_details_update_url,
                type: "POST",
              },
            ],
            type: "none",
          },
          env_add: {
            label: "Add Env",
            path: "/application/:application_id/service/:service_id/env/add",
            component: ServiceAdd,
            autherization: [
              {
                route: properties.api.env_data_post_url,
                type: "POST",
              },
            ],
            type: "none",
          },
          env_edit: {
            label: "Edit Environment",
            path: "/application/:application_id/service/:service_id/env/:env_id/edit",
            component: ServiceAdd,
            autherization: [
              {
                route: properties.api.env_data_post_url,
                type: "POST",
              },
            ],
            ignore_sub_path: [":env_id"],
            type: "none",
          }
        }
      },
      service_add: {
        label: "Add Service",
        path: "/application/:application_id/service/add",
        autherization: [
          {
            route: properties.api.service_basic_details_post_url,
            type: "POST",
          },
        ],
        component: ServiceAddRevamp,
        type: "none",
      },
      pipeline: {
        key: "pipeline",
        label: "Pipeline Overview",
        path: "/application/:application_id/pipeline/:pipeline_id/edit",
        parent_path: "/application/:application_id/pipeline/listing",
        parent_component: PipelineCard,
        component: AddPipeline,
        autherization: [
          {
            route: properties.api.pipeline_save_url,
            type: "POST",
          },
        ],
        type: "element-only-heading",
        icon: "flaticon-magic-wand-auto-fix-button",
        imgPath : "/images/pipeline-overview.svg",
        imgPathSelected : "/images/pipeline-selected.svg",
        routes: {
          pipeline_edit: {
            label: "Edit Pipeline",
            path: "/application/:application_id/pipeline/:pipeline_id/edit",
            component: AddPipeline,
            autherization: [
              {
                route: properties.api.pipeline_save_url,
                type: "POST",
              },
            ],
            type: "none",
          },
          pipeline_execution: {
            key: "pipeline_execution",
            label: "Pipeline Execution Details",
            path: "/application/:application_id/pipeline/:pipeline_id/execution/:pipeline_instance_id",
            component: PipelineExecution,
            type: "none",
            ignore_sub_path: [":pipeline_instance_id"]
          },
          canary_analysis_report: {
            key: "canary_analysis_report",
            label: "Canary Analysis Report",
            path: "/application/:application_id/pipeline/:pipeline_id/execution/:pipeline_instance_id/analysis/report",
            component: CanaryAnalysisReport,
            type: "none",
            ignore_sub_path: [":pipeline_instance_id"]
          }
        },
        pipeline_add: {
          key: "pipeline_add",
          label: "Add Pipeline",
          path: "/application/:application_id/pipeline/add",
          parent_path: "/application/:application_id/pipeline/add",
          component: AddPipeline,
          type: "none",
          autherization: [
            {
              route: properties.api.pipeline_save_url,
              type: "POST",
            },
          ],
          icon: "flaticon-settings-cogwheel-button",
        }
      },
      pipeline_add: {
        key: "pipeline_add",
        label: "Add Pipeline",
        path: "/application/:application_id/pipeline/add",
        parent_path: "/application/:application_id/pipeline/add",
        component: AddPipeline,
        type: "none",
        autherization: [
          {
            route: properties.api.pipeline_save_url,
            type: "POST",
          },
        ],
        icon: "flaticon-settings-cogwheel-button",
      },
      enivronment: {
        key: "env",
        label: "Environment Overview",
        path: "/application/:application_id/env/list",
        component: EnvList,
        type: "element-only-heading",
        icon: "flaticon-settings-cogwheel-button",
        imgPath : "/images/env-overview.svg",
        imgPathSelected : "/images/env-selected.svg",
      },
      job_templates: {
        key: "jobtemplates",
        label: "Job Templates",
        path: "/application/:application_id/job-templates",
        parent_path: "",
        component: JobTemplatesListing,
        type: "element-only-heading",
        icon: "flaticon-google-drive-document",
        imgPath : "/images/job-templates.svg",
        imgPathSelected : "/images/job-templated-selected.svg",
      },
      build_deploy: {
        key: "build_deploy",
        label: "Build / Deploy",
        path: "/application/:application_id/build_deploy",
        parent_path: "",
        component: BuildDeploy,
        type: "element-only-heading",
        icon: "flaticon-settings-cogwheel-button",
        imgPath : "/images/build-deploy.svg",
        imgPathSelected : "/images/build-deploy-selected.svg",
      },
      canary_templates: {
        key: "canarytemplates",
        label: "Canary Templates",
        path: "/application/:application_id/canary-templates",
        parent_path: "",
        component: CanaryTemplatesListing,
        type: "element-only-heading",
        icon: "flaticon-google-drive-document",
        imgPath : "/images/canary-templates.svg",
        imgPathSelected : "/images/canary-selected.svg",
      },
      manifest_placeholders: {
        key: "manifest_placeholders",
        label: "Manifest Placeholder",
        path: "/application/:application_id/manifest-placeholder",
        parent_path: "",
        component: ManifestPlaceholder,
        type: "element-only-heading",
        icon: "flaticon-google-drive-document",
        imgPath : "/images/manifest-placeholder.svg",
        imgPathSelected : "/images/manifest-selected.svg",
      }
    }
  },
  
}

export const super_admin_routes = {
  cluster: {
    key: "cluster",
    label: "Your Clusters",
    path: "/cluster/:cluster_id/detail",
    parent_path: "/clusterdashboard",
    component: ClusterDetail,
    parent_component: ClusterList,
    type: "element-static-heading",
    icon: "flaticon-show-apps-button",
    routes: {
      cluster: {
        key: "cluster",
        label: "Cluster Overview",
        path: "/cluster/:cluster_id/detail",
        imgPath: "/images/cluster-overview.svg",
        imgPathSelected: "/images/cluster-overview-selected.svg",
        parent_path: "/cluster/:cluster_id/detail",
        component: ClusterDetail,
        type: "element-only-heading",
        icon: "",
      },
      node: {
        key: "node",
        label: "Nodes Availaible",
        path: "/cluster/:cluster_id/node/list",
        imgPath: "/images/node.svg",
        imgPathSelected: "/images/node-selected.svg",
        parent_path: "/cluster/:cluster_id/node/list",
        component: NodesListing,
        type: "element-only-heading",
        icon: "",
        routes: {
          node_detail: {
            key: "node_detail",
            label: "Nodes Detail",
            path: "/cluster/:cluster_id/node/:node_name/detail",
            parent_path: "/cluster/:cluster_id/node/list",
            component: NodeDetails,
            type: "none",
          }
        }
      },
      pods: {
        key: "pods",
        label: "Pods Availaible",
        path: "/cluster/:cluster_id/pod/list",
        imgPath: "/images/pod.svg",
        imgPathSelected: "/images/pod-selected.svg",
        parent_path: "/cluster/:cluster_id/pod/list",
        component: PodsListing,
        type: "element-only-heading",
        icon: "",
        routes: {
          pod_detail: {
            key: "pod_detail",
            label: "Pod Detail",
            path: "/cluster/:cluster_id/pod/:pod_name/detail",
            parent_path: "/cluster/:cluster_id/pod/:pod_name/detail",
            component: PodsDetails,
            type: "none",
            icon: "",
          },
        },
      },
      namespace: {
        key: "namespace",
        label: "Manage Namespaces",
        path: "/cluster/:cluster_id/namespace/list",
        imgPath: "/images/namespace.svg",
        imgPathSelected: "/images/namespace-selected.svg",
        parent_path: "/cluster/:cluster_id/namespace/list",
        component: NamespaceList,
        type: "element-only-heading",
        icon: "",
        routes: {
          namespace_detail: {
            key: "namespace_detail",
            label: "Namespace Detail",
            path: "/cluster/:cluster_id/namespace/:namespace_name/detail",
            parent_path: "/cluster/:cluster_id/namespace/:namespace_name/detail",
            component: NamespaceDetails,
            type: "none",
            icon: "",
          },
        }
      },
      istio_gateway: {
        key: "istio_gateway",
        label: "Manage Gateways",
        path: "/cluster/:cluster_id/gateway/list",
        imgPath: "/images/gateway.svg",
        imgPathSelected: "/images/gateway-selected.svg",
        parent_path: "/cluster/:cluster_id/gateway/list",
        component: GatewayList,
        type: "element-only-heading",
        icon: "",

      },
      ingress: {
        key: "ingress",
        label: "Manage Ingress",
        path: "/cluster/:cluster_id/ingress/list",
        imgPath: "/images/ingress.svg",
        imgPathSelected: "/images/ingress-selected.svg",
        parent_path: "/cluster/:cluster_id/ingress/list",
        component: IngressList,
        type: "element-only-heading",
        icon: "",
      },
      // namespace_add: {
      //   key: "namespace_add",
      //   label: "Add Namespace",
      //   path: "/cluster/:cluster_id/namespace/add",
      //   parent_path: "/cluster/:cluster_id/namespace/add",
      //   component: AddNamespaces,
      //   type: "none",
      //   icon: "",
      // },
    },
  },
  new_cluster: {
    label: "New Cluster",
    path: "/cluster/new",
    component: AddClusterFirstScreen,
    type: "none",
  },
  onboard_cluster: {
    label: "Onboard Cluster",
    path: "/cluster/new/onboard",
    component: AddClusterFlow,
    type: "none",
  },
  add_cluster: {
    label: "Add Cluster",
    path: "/cluster/new/add",
    component: AddNewCluster,
    type: "none",
  },

  application: {
    key: "application",
    label: "",
    path: "/project/list",
    parent_path: "/project/list",
    component: ProjectListing,
    parent_component: ProjectListing,
    type: "element-static-heading",
    icon: "flaticon-show-apps-button",
  }
}

export const super_admin_portal = {
  cluster: {
    key: "cluster",
    label: "Infra Inventory",
    icon: "ri-cloud-line",
    isImg: false,
    path: null,
    subOptionArray: [],
    parent_path: "/clusterdashboard",
    subOptions: {
      cluster: {
        key: "cluster",
        label: "Cluster Overview",
        path: "/cluster/:cluster_id/detail",
        entity_path: null,
        entity_label: null,
        icon: null,
      },
      node: {
        key: "node",
        label: "Nodes Availaible",
        path: "/cluster/:cluster_id/node/list",
        entity_path: "/cluster/:cluster_id/node/:node_name/detail",
        entity_label: "Node",
        icon: null,
      },
      pods: {
        key: "pods",
        label: "Pods Availaible",
        path: "/cluster/:cluster_id/pod/list",
        entity_path: null,
        entity_label: null,
        icon: null,
      },
      namespace: {
        key: "namespace",
        label: "Manage Namespaces",
        path: "/cluster/:cluster_id/namespace/list",
        entity_path: "/cluster/:cluster_id/namespace/:namespace_name/detail",
        entity_label: "Namespace",
        icon: null,
      },
      istio_gateway: {
        key: "istio_gateway",
        label: "Manage Gateways",
        path: "/cluster/:cluster_id/gateway/list",
        entity_path: null,
        entity_label: null,
        icon: null,

      },
      ingress: {
        key: "ingress",
        label: "Manage Ingress",
        path: "/cluster/:cluster_id/ingress/list",
        entity_path: null,
        entity_label: null,
        icon: null,
      },
      // namespace_add: {
      //   key: "namespace_add",
      //   label: "Add Namespace",
      //   path: "/cluster/:cluster_id/namespace/add",
      //   entity_path: null,
      //   entity_label: null,
      //   icon: null,
      // },
    },//super_admin_routes.cluster.routes,
    moreOptions: true,
    ignore_sub_path: ["/cluster/"],
    type: "dynamic-route",
  },
  stepCatalog: {
    key: "stepCatalog",
    label: "Step Catalog",
    icon: "ri-file-copy-2-line",
    isImg: false,
    path: "/step/list",
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  manageApplication: {
    key: "manageApplication",
    label: "Manage Application",
    icon: "ri-airplay-line",
    isImg: false,
    path: "/project/list",
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  userManagement: {
    key: "userManagement",
    label: "Manage User",
    icon: "ri-user-settings-line",
    isImg: false,
    path: null,
    type: "static-route",
    subOptionArray : ["/role-groups","/user/group/list","/manage-users","/external-group-mapping"],
    subOptions: {
      manageExtGrp: {
        key: "manageExtGrp",
        label: "Manage External Groups",
        icon: null,
        path: "/role-groups",
      },
      manageUserGrp: {
        key: "manageUserGrp",
        label: "Manage User Groups",
        icon: null,
        path: "/user/group/list",
      },
      manageUsers: {
        key: "manageUsers",
        label: "Manage Users",
        icon: null,
        path: "/manage-users",
      },
      // extGrpMapping: {
      //   key: "extGrpMapping",
      //   label: "External Group Mappping",
      //   icon: null,
      //   path: "/external-group-mapping",
      // },
    },
    moreOptions: false,
  },
  userPolicies: {
    key: "userPolicies",
    label: "User Policies",
    icon: "ri-user-star-line",
    isImg: false,
    path: null,
    type: "static-route",
    subOptionArray: ["/permissions/listing","/policy/listing","/policy/template/listing"],
    subOptions: {
      permissionGrp: {
        key: "permissionGrp",
        label: "Permission Groups",
        icon: null,
        path: "/permissions/listing",
      },
      policies: {
        key: "policies",
        label: "Policies",
        icon: null,
        path: "/policy/listing",
      },
      policyTemplate: {
        key: "policyTemplate",
        label: "Policy Templates",
        icon: null,
        path: "/policy/template/listing",
      },
    }
  },
  integration: {
    key: "integration",
    label: "Integration",
    icon: "ri-settings-3-line",
    isImg: false,
    path: null,
    type: "static-route",
    subOptionArray: ["/repo/list","/secrets/list","/eventlistener/list","/integration/containerRegistries","/tickets/list","/helm/integration/list"],
    subOptions: {
      containerRegistries: {
        key: "containerRegistries",
        label: "Container Registries",
        icon: null,
        path: "/integration/containerRegistries",
      },
      RepoIntegration: {
        key: "RepoIntegration",
        label: "Repo Integration",
        icon: null,
        path: "/repo/list",
      },
      secrets: {
        key: "secrets",
        label: "Secrets",
        icon: null,
        path: "/secrets/list",
      },
      helmIntegration: {
        key: "helmIntegration",
        label: "Helm Repo Integration",
        icon: null,
        path: "/helm/integration/list",
      },
      ticketing: {
        key: "ticketing",
        label: "Ticketing System",
        icon: null,
        path: "/tickets/list",
      }
    }
  }

} 

export const user_portal = {
  application: {
    key: "application",
    label: "Application",
    icon: "ri-apps-line",
    isImg: false,
    path: null,
    subOptionArray: [],
    parent_path: "/ApplicationDashboard",
    ignore_sub_path: ["/application/"],
    subOptions: {
      service: {
        key: "service",
        label: "Service Overview",
        path: "/application/:application_id/service/listing",
        entity_path: "/application/:application_id/service/:service_id/detail",
        entity_label: "Service",
        icon: null,
      },
      pipeline: {
        key: "pipeline",
        label: "Pipeline Overview",
        path: "/application/:application_id/pipeline/listing",
        entity_path: "/application/:application_id/pipeline/:pipeline_id/execution/:pipeline_instance_id",
        entity_label: "Pipeline",
        icon: null,
      },
      enivronment: {
        key: "env",
        label: "Environment Overview",
        path: "/application/:application_id/env/list",
        entity_path: "/application/:application_id/environment/:env_id/dashboard",
        entity_label: "Environment",
        icon: null,
      },
      job_templates: {
        key: "jobtemplates",
        label: "Job Templates",
        path: "/application/:application_id/job-templates",
        entity_path: "/application/:application_id/job-template/:template_id/edit",
        entity_label: "Template",
        icon: null,
      },
      build_deploy: {
        key: "build_deploy",
        label: "Build / Deploy",
        path: "/application/:application_id/build_deploy",
        entity_path: null,
        entity_label: null,
        icon: null,
      },
      canary_templates: {
        key: "canarytemplates",
        label: "Canary Templates",
        path: "/application/:application_id/canary-templates",
        entity_path: "/application/:application_id/canary-template/:template_id/edit",
        entity_label: "Edit Canary Templates",
        icon: null,
      },
      manifest_placeholders: {
        key: "manifest_placeholders",
        label: "Manifest Placeholder",
        path: "/application/:application_id/manifest-placeholder",
        entity_path: null,
        entity_label: null,
        icon: null,
      }
    },
    moreOptions: true,
    ignore_sub_path: ["/application/"],
    type: "dynamic-route",
  },
  stepCatalog: {
    key: "stepCatalog",
    label: "Step Catalog",
    icon: "ri-file-copy-2-line",
    isImg: false,
    path: "/step/list",
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  webhookEvent: {
    key: "webhookEvent",
    label: "Webhook Event",
    icon: "/images/Webhook.svg",
    isImg: true,
    path: "/event-listener/status",
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  velocityInsight: {
    key: "velocityInsight",
    label: "Velocity Insight",
    icon: "ri-pie-chart-2-line",
    isImg: false,
    path: "/velocity-insights",
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  insights: {
    key: "insights",
    condition: properties.insight_url ? true : false,
    label: "Maturity Insight",
    icon: "ri-bar-chart-fill",
    isImg: false,
    path: properties.insight_url,
    newTab: true,
    subOptions: null,
    moreOptions: false,
    type: "direct-route",
  },
  integration: {
    key: "integration",
    label: "Integration",
    icon: "ri-settings-3-line",
    isImg: false,
    path: null,
    type: "static-route",
    subOptionArray: ["/repo/list","/secrets/list","/eventlistener/list","/integration/containerRegistries","/tickets/list","/helm/integration/list"],
    subOptions: {
      RepoIntegration: {
        key: "RepoIntegration",
        label: "Repo Integration",
        icon: null,
        path: "/repo/list",
      },
      secrets: {
        key: "secrets",
        label: "Secrets",
        icon: null,
        path: "/secrets/list",
      },
      helmIntegration: {
        key: "helmIntegration",
        label: "Helm Repo Integration",
        icon: null,
        path: "/helm/integration/list",
      },
      gitEvents: {
        key: "gitEvents",
        label: "Git Events",
        icon: null,
        path: "/eventlistener/list",
      },
      ticketing: {
        key: "ticketing",
        label: "Ticketing System",
        icon: null,
        path: "/tickets/list",
      }
    }
  },    
  release_package: {
    key: "release_package",
    label: "Release Package",
    //path: "/release-package/listing",
    isImg: false,
    icon: 'ri-inbox-line',
    subOptions: null,
    moreOptions: false,
    type: "static-route",
    subOptionArray: ['/release-package/listing','/select-application','/sprint/scope', "/finalize/sprint-scope", "/sprint/move-bulk-item"],
    subOptions: {
      releasePackageListing: {
        key: "releasePackageListing",
        label: "Release Packages",
        icon: null,
        path: "/release-package/listing",
      },
      approveSprint: {
        key: "approveSprint",
        label: "Approve Sprint",
        icon: null,
        path: "/sprint/scope",
      }
    }
  },
  templates: {
    key: "job_templates",
    label: "Job Template",
    icon: "ri-suitcase-line",
    isImg: false,
    type: "direct-route",
    // subOptionArray: ["/global/job/templates", "/secrets/list"],
    subOptions: null,
    moreOptions:false,
    path: "/global/job/templates",
  },
  global_pipelines: {
    key: "global_pipelines",
    label: "Pipeline",
    icon: "/images/pipeline-overview.svg",
    isImg: true,
    path: "/global/pipeline/listing",
    type: "direct-route",
    subOptions: null,
    moreOptions: false,
  },
}



// Generating Dynamic routes
const routes_with_layout = [];

function fillRoutesWithLayout(routes) {
  var all_routes_keys = Object.keys(routes);
  all_routes_keys.forEach((key) => {
    const route = routes[key];
    routes_with_layout.push(
      <RouteWithLayout strict={true} exact={true} label={route.label} path={route.path} autherization={route.autherization} component={route.component} layout={MainLayout} />
    );
    if (route.parent_path) {
      routes_with_layout.push(
        <RouteWithLayout strict={true} exact={true} label={route.label} path={route.parent_path} component={route.parent_component ? route.parent_component : route.component} layout={MainLayout} />
      );
    }
    if (route.routes) {
      fillRoutesWithLayout(route.routes);
    }
  });
}



function getRoutesWithLayout() {
  fillRoutesWithLayout(IsUserActualSuperUser() ? { ...super_admin_routes, ...routes } : routes);
  return routes_with_layout;
}


function initializeRoutes() {
  const authenticated = IsAuthenticated();
  console.log(authenticated, "oooo_____")
  var redirectTo = "/login";
  if (!authenticated) {
    var isRefreshTokenValid = IsRefreshTokenValid();
    redirectTo = isRefreshTokenValid ? "/refreshSession" : "/login";
  }


  ReactDOM.render((
    <Suspense fallback={
    
      <Loading />
    
    }>
      
        <ThemeProvider theme={theme}>
          <BrowserRouter >
            <Switch>
              
              <Route exact={true} path="/login" render={(props) => (<LoginView data={props} />)} />
              <Route exact={true} path="/refreshSession" render={(props) => (<RefreshSessionView data={props} />)} />
              <Route path="/forgot-password" exact={true} component={ForgotPassword} />
              <Route path="/enter-new-password" exact={true} component={ResetPassword} />
              <Route path="/exception" exact={true} component={Exception} />
              <Route path="/build-insights-og" component={BuildInsights} />

              {/* <Route path="/maintenance" exact={true} component={Maintenance} /> */}
              <RouteWithLayout path="/usersetting" layout={MainLayout} component={UserInfo} />
              {!authenticated ?
                <Route component={SignUp} exact layout={Minimal} path="/signup" />
                : <Route path="/signup" render={(props) => (<> <Redirect to={{ pathname: IsSuperUser() ? "/clusterdashboard" : "/applicationdashboard", state: { prevLocation: props.location } }} /></>)} />}
              <Route path="/auth/complete/:client" exact={true} component={AuthComplete} />
              <RouteWithMinimalLayout component={AddProject} layout={Minimal} path="/add/project" />
              <RouteWithMinimalLayout component={NewSignup} layout={Minimal} path="/signupthree" />
              <Route path="/new_acs/" exact={true} component={SSOAuthComplete} />

              <Route path="/test" component={TestView} />
              <Route path="/thankyou" component={Thankyou} />
              <Route path="/sse-testing" component={SseTesting} />

              {authenticated ?
                <>
                  
                  <BpAgentStatusComponent />
                  <BetaEnvStatusComponent />
                  <PremiumFeaturesInfo/>
                  {/* <NewReleaseNotification /> */}
                  <Switch>
                    <Route path="/" exact={true} render={() => (<Redirect to={IsSuperUser() ? "/clusterdashboard" : "/applicationdashboard"} />)} />
                    <Route path="/Welcome" exact={true} component={Welcome} />
                    {/* <Route path="/testing-sse" exact={true} component={TestingSSeConnection} /> */}
                    <Route exact={true} path="/maintenance" component={LandingPage} />
                    <Route exact={true} path="/undermaintenance" component={UnderMaintenance} />
                    <Route path="/initiateLogOut" exact={true} component={LogOutView} />
                    <Route path="/redirect-to-prod" exact={true} component={RedirectToProdEnv} />
                    <RouteWithLayout path="/applicationDashboard/new" layout={MainLayout} component={IndexNew} />
                    <RouteWithLayout path="/dashboardHome" layout={MainLayout} component={DashboardHome} />
                    <RouteWithLayout path="/application/:application_id/service/add" layout={Minimal} component={ServiceAddRevamp} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/edit" layout={Minimal} component={ServiceAddRevamp} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/add" layout={Minimal} component={EnvAndUserGroup} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/edit" layout={Minimal} component={EnvAndUserGroup} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/ci/add" layout={MainLayout} component={CiInfo} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/cd/add" layout={MainLayout} component={CdInfo} />
                    <RouteWithLayout path="/addenv" layout={MainLayout} component={AddEnvRevamp} />
                    <RouteWithMinimalLayout path="/application/:application_id/job-template/add" layout={Minimal} component={AddJobTemplate} />
                    <RouteWithLayout exact path="/application/:application_id/job-template/:template_id/edit" layout={MainLayout} component={EditJobTemplates} />
                    <RouteWithLayout path="/application/:application_id/job-edit/:template_id/job/:job_code/steps" layout={MainLayout} component={AddStepToJob} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/ci/:ci_id/edit" layout={MainLayout} component={CiInfo} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/cd/:cd_id/edit" layout={MainLayout} component={CdInfo} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/cd/manifest/add" layout={MainLayout} component={Manifest} />
                    {/* <RouteWithLayout exact path="/application/:application_id/environment/:env_id/scheduler/add" layout={MainLayout} component={EnvScheduleDialog} /> */}
                    <RouteWithLayout exact path="/application/:application_id/environment/:env_id/scheduler/add/new" layout={MainLayout} component={EnvScheduleDialogNew} />
                    <RouteWithLayout path="/application/:application_id/environment/:env_id/scheduler/list" layout={MainLayout} component={SchedulerListing} />
                    <RouteWithLayout path="/application/:application_id/environment/:env_id/scheduler/:scheduler_id/edit" layout={MainLayout} component={EnvScheduleDialogNew} />
                    <RouteWithLayout path="/application/:application_id/service/:component_id/env/:component_env_id/cd/:cd_id/manifest/edit"  layout={MainLayout} component={Manifest} />
                    <RouteWithLayout path="/application/:application_id/environment/:env_id/dashboard" layout={MainLayout} component={EnvironmentDashboard} />
                    <RouteWithMinimalLayout path="/yamlviewer" layout={Minimal} component={ViewYaml} />
                    <RouteWithMinimalLayout path="/logviewer" layout={Minimal} component={ViewLog} />
                    <RouteWithLayout path="/application/:application_id/service/:service_id/env/:component_env_id/cd_id/:cd_id/container/add" layout={MainLayout} component={SideCarsInfo} />
                    <RouteWithLayout path="/application/:application_id/service/:service_id/env/:component_env_id/cd_id/:cd_id/:container/:edit_id/edit" layout={MainLayout} component={SideCarsInfo} />
                    <RouteWithMinimalLayout path="/setup" layout={Minimal} component={Setup} />
                    <RouteWithMinimalLayout path="/billinginfo" layout={Minimal} component={BillingRegistry} />
                    <RouteWithMinimalLayout path="/logs" layout={CustomLayoutLogs} component={LogsViewer} />

                    <RouteWithMinimalLayout path="/deployment-analytics" layout={CustomLayoutLogs} component={MonitoringDialogue} />

                    <RouteWithMinimalLayout path="/step/list" layout={MainLayout} component={JobTemplateListing} />
                    <RouteWithMinimalLayout path="/step/add" layout={MainLayout} component={CreateNewStepCatalog} />
                    <RouteWithMinimalLayout path="/step/:step_id/edit" layout={MainLayout} component={CreateNewStepCatalog} />
                    <RouteWithMinimalLayout path="/application/:application_id/canary-templates" layout={MainLayout} component={CanaryTemplatesListing} />
                    <RouteWithMinimalLayout path="/application/:application_id/canary-template/add" layout={MainLayout} component={AddCanary} />
                    <RouteWithMinimalLayout path="/application/:application_id/canary-template/:template_id/edit" layout={MainLayout} component={AddCanaryTemplates} />
                    <RouteWithMinimalLayout path="/event-listener/status" layout={MainLayout} component={EventListenerStatus} />
                    <RouteWithMinimalLayout path="/event-listener/details/:event_id" layout={MainLayout} component={EventListenerDetails} />
                    {
                      getRoutesWithLayout()
                    }
                    <RouteWithLayout path="/application/:application_id/manifest-placeholder" layout={MainLayout} component={ManifestPlaceholder} />
                    <RouteWithLayout path="/application/:project_id/env/:env_id/detail" layout={MainLayout} component={EditEnv} />
                    <RouteWithLayout path="/application/:project_id/env/add" layout={MainLayout} component={AddEnv} />
                    <RouteWithLayout path="/application/:project_id/env/:env_id/edit" layout={MainLayout} component={AddEnv} />
                    {/* <RouteWithLayout path="/application/:project_id/workflow" layout={MainLayout}  component={WorkFlow} /> */}
                    <RouteWithLayout path="/application/:project_id/env/:env_id/:project_env_namespace_id/config/add" layout={MainLayout} component={ConfigMap} />
                    <RouteWithLayout path="/application/:project_id/env/:env_id/:project_env_namespace_id/secret/add" layout={MainLayout} component={AddSecrets} />
                    <RouteWithLayout path="/application/:project_id/env/:env_id/:project_env_namespace_id/config/:config_name/edit/:config_status" layout={MainLayout} component={ConfigMap} />
                    <RouteWithLayout path="/application/:project_id/env/:env_id/:project_env_namespace_id/secret/:secret_name/edit/:secret_status" layout={MainLayout} component={AddSecrets} />
                    <RouteWithLayout path="/application/:project_id/env/compare" layout={MainLayout} component={CompareEnv} />
                    <RouteWithLayout path="/source/monitoring" layout={MainLayout} component={MonitoringSourceList} />
                    <RouteWithLayout path="/stability/matrix" layout={MainLayout} component={StabilityMatrixList} />
                    <RouteWithLayout path="/add/stability/matrix" layout={MainLayout} component={AddStabilityMatrix} />
                    <RouteWithLayout path="/edit/stability/matrix/:stability_id" layout={MainLayout} component={AddStabilityMatrix} />
                    <RouteWithLayout path="/ticketing/system/configure" layout={MainLayout} component={AddTicketingSystemDialog} />
                    <RouteWithLayout path="/webhook-events/setup/strategy" layout={MainLayout} component={CreateEventListener} />
                    <RouteWithLayout path="/create/webhook-events" layout={MainLayout} component={EventListenerList} />
                    <RouteWithLayout path="/update/:edit_id/webhook-events" layout={MainLayout} component={EventListenerList} />
                    <RouteWithLayout path="/credentials" layout={MainLayout} component={ViewCredentials} />
                    <RouteWithLayout path="/health" layout={MainLayout} component={Celery} />
                    <RouteWithLayout path="/activity1.0" layout={MainLayout} component={OnGoingActivity} />
                    <RouteWithLayout path="/activity" layout={MainLayout} component={OnGoingActivityNew} />
                    <RouteWithLayout path="/infinitescroll" layout={MainLayout} component={ViewLogsInfiniteScroll} />
                    <RouteWithLayout path="/test" layout={MainLayout} component={TestView} />
                    <RouteWithLayout path="/build-insights" layout={MainLayout} component={BuildInsights} />
                    <RouteWithLayout path="/application/:application_id/service/:service_id/:action/:action_id/workspace/" layout={CustomLayoutLogs} component={WorkspaceAccess} />
                    <RouteWithLayout path="/application/:application_id/service/:service_id/job/:job_name/workspace-v3/" layout={CustomLayoutLogs} component={WorkspaceAccessV3} />

                    <RouteWithLayout path="/integration/containerRegistries" layout={MainLayout} component={ContainerRegistries} />
                    <RouteWithLayout path="/integration/addRegistry" layout={MainLayout} component={AddRegistry} />
                    <RouteWithLayout path="/secrets/list" layout={MainLayout} component={Secrets} />
                    <RouteWithMinimalLayout path="/secrets/add" layout={Minimal} component={AddSecret} />
                    <RouteWithMinimalLayout path="/secrets/:edit_id/edit" layout={Minimal} component={AddSecret} />
                    <RouteWithLayout path="/tickets/list" layout={MainLayout} component={Tickets} />
                    <RouteWithMinimalLayout path="/tickets/add" layout={Minimal} component={AddTicket} />
                    <RouteWithMinimalLayout path="/ticket/:edit_id/edit" layout={Minimal} component={AddTicket} />
                    <RouteWithLayout path="/repo/bitbucket/add" layout={Minimal} component={AddBitBucket} />
                    <RouteWithLayout path="/repo/bitbucket/:edit_id/edit" layout={Minimal} component={AddBitBucket} />
                    <RouteWithLayout path="/repo/git/add" layout={Minimal} component={AddBitBucket} />
                    <RouteWithLayout path="/repo/gitlab/add" layout={Minimal} component={GitLabLayout} />
                    <RouteWithLayout path="/repo/gitlab/:edit_id/edit" layout={Minimal} component={GitLabLayout} />
                    <RouteWithLayout path="/repo/github/add" layout={Minimal} component={AddGitHub} />
                    <RouteWithLayout path="/repo/github/:edit_id/edit" layout={Minimal} component={AddGitHub} />
                    <RouteWithLayout path="/repo/list" layout={MainLayout} component={ReposListing} />
                    <RouteWithLayout path="/helm/integration/list" layout={MainLayout} component={HelmIntegrationList} />
                    <RouteWithLayout path="/permission-not-found" layout={MainLayout} component={ErrorPageNotFound} />
                    <RouteWithLayout path="/eventlistener/list" layout={MainLayout} component={EventListener} />
                    <RouteWithLayout exact path="/eventlistener/add" layout={MainLayout} component={AddEventListenerFrom} />
                    <RouteWithLayout exact path="/event/add" layout={MainLayout} component={CreateEvent} />


                    
                    <RouteWithLayout path="/eventlistener/:git_provider_id/edit/:edit_id" layout={MainLayout} component={AddEventListenerFrom} />
                    <RouteWithLayout path="/deploy-insights" layout={MainLayout} component={DeployInsights} />
                    <RouteWithMinimalLayout path="/velocity-insights" layout={MainLayout} component={VelocityInsight} />
                    <RouteWithLayout path="/application/:application_id/pipeline/:pipeline_id/runtime-params-update" layout={MainLayout} component={PipelineRuntimeParamsUpdate}/>
                    <RouteWithLayout path="/aman" layout={MainLayout} component={PipelineRuntimeParamsUpdate}/>
                    <RouteWithLayout path="/application/:application_id/pipeline/listing/:pipeline_id/history" layout={MainLayout} component={PipelineHistoryListing} />
                    {/* Release Package */}
                    <RouteWithLayout path="/release-package/add" layout={WhiteTopMinimal} component={ReleasePackageAddForm} />
                    <RouteWithLayout path="/release-package/:release_package_id/edit" layout={WhiteTopMinimal} component={ReleasePackageAddForm}/>
                    <RouteWithLayout path="/release-package/listing" layout={MainLayout} component={ReleasePackageIndex} />
                    <RouteWithLayout path="/release-package/:release_package_id" layout={MainLayout} component={SingleReleasePackage}/>
                     {/* Release Package */}
                    <RouteWithLayout exact path="/global/job/templates" layout={MainLayout} component={GlobalJobTemplates} />
                    <RouteWithMinimalLayout path="/global/job/template/add" layout={Minimal} component={AddGlobalJobTemplate} />
                    <RouteWithLayout path="/global/job/template/:template_id/edit" layout={MainLayout} component={EditGlobalJobTemplate} />
                    <RouteWithLayout path="/global/job/job-edit/:template_id/job/:job_code/steps" layout={MainLayout} component={AddStepToJob} />
                    <RouteWithLayout exact path="/global/pipeline/listing" layout={MainLayout} component={GlobalPipelineCard} />
                    <RouteWithLayout exact path="/global/pipeline/add" layout={MainLayout} component={AddGlobalPipeline} />
                    <RouteWithLayout exact path="/global/pipeline/:pipeline_id/edit" layout={MainLayout} component={AddGlobalPipeline} />
                    <RouteWithLayout exact path="/global/pipeline/:pipeline_id/execution/:pipeline_instance_id" layout={MainLayout} component={GlobalPipelineExecution} />
                    <RouteWithLayout exact path="/global/pipeline/:pipeline_id/execution/:pipeline_instance_id" layout={MainLayout} component={GlobalPipelineExecution} />
                    <RouteWithLayout path="/global/pipeline/listing/:pipeline_id/history" layout={MainLayout} component={GlobalPipelineHistoryListing} />
                    <RouteWithLayout path="/select-application" layout={MainLayout} component={SelectApplicationForSpirntApprove} />
                    <RouteWithLayout path="/sprint/scope" layout={MainLayout} component={SprintApprove} />
                    <RouteWithLayout path="/finalize/sprint-scope" layout={MainLayout} component={MoveSprintTask} />
                    <RouteWithLayout path="/sprint/move-bulk-item" layout={MainLayout} component={BulkMoveSprintTask} />
                    
                    {/* Release Package */}
                    <RouteWithLayout exact path="/release-package/add" layout={WhiteTopMinimal} component={ReleasePackageAddForm} />
                    <RouteWithLayout exact path="/release-package/:release_package_id/edit" layout={WhiteTopMinimal} component={ReleasePackageAddForm}/>
                    <RouteWithLayout exact path="/release-package/listing" layout={MainLayout} component={ReleasePackageIndex} />
                    <RouteWithLayout exact path="/release-package/:release_package_id" layout={MainLayout} component={SingleReleasePackage}/>
                     {/* Release Package */}
                    {/* <RouteWithLayout path="/manage-users" layout={MainLayout} component={MultiUsersList} /> */}

                    {IsSuperUser('loading_routes')  ? <>
                      <ProfileLayout path="/billing" layout={ProflieLayout} component={BillingModule} />
                      {/* <RouteWithLayout path="/registry/list" exact layout={MainLayout} component={Registrieslist} /> */}
                      <RouteWithMinimalLayout path="/registry/add" exact layout={Minimal} component={AddRegistries} />
                      <RouteWithMinimalLayout path="/registry/:registery_id/edit" exact layout={Minimal} component={AddRegistries} />
                      <RouteWithLayout path="/cluster-permissions" layout={MainLayout} component={clusterPermissions} />
                      <RouteWithLayout path="/role-groups" layout={MainLayout} component={UserAndExternalRoles} />
                      <RouteWithLayout path="/manage-users" layout={MainLayout} component={ManageUsers} />
                      <RouteWithLayout path="/external-group-mapping" layout={MainLayout} component={ExternalGroupingMapping} />
                      <RouteWithLayout path="/upload-users" layout={MainLayout} component={UploadUsers} />
                      <RouteWithLayout path="/upload-users-new" layout={MainLayout} component={UploadUsersNew} />
                      <RouteWithLayout path="/upload/user-group" layout={MainLayout} component={UploadUserGroup} />
                      <RouteWithLayout path="/cluster/addform" layout={MainLayout} component={AddClusterSecondFlow} />
                      <RouteWithLayout path="/vm-groups/list" layout={MainLayout} component={VirtualMachineSetListing} />
                      <RouteWithLayout path="/vm-group/configure" layout={MainLayout} component={AddVirtualMachineSet} />
                      <RouteWithLayout path="/vm-group/:id/edit" layout={MainLayout} component={AddVirtualMachineSet} />
                      <RouteWithLayout path="/load-balancer/list" layout={MainLayout} component={LoadbalancerList} />
                      <RouteWithMinimalLayout path="/load-balancer/configure" layout={CustomDialogLayout} component={AddLoadBalancer} />
                      <RouteWithMinimalLayout path="/load-balancer/:edit_id/edit" layout={CustomDialogLayout} component={AddLoadBalancer} />
                      {/* <RouteWithLayout path="/vm-group/:id/edit" layout={MainLayout} component={AddVirtualMachineSet} /> */}
                      
                      <RouteWithLayout path="/cluster/:cluster_id/ingress/add" layout={MainLayout} component={AddIngress} />
                      <RouteWithLayout path="/cluster/:cluster_id/ingress/:ingress_id/edit" layout={MainLayout} component={AddIngress} />
                      <RouteWithLayout path="/cluster/:cluster_id/monitoring/log/add" layout={MainLayout} component={AddLogMonitioring} />
                      <RouteWithLayout path="/cluster/:cluster_id/monitoring/log/:log_monitoring_id/edit" layout={MainLayout} component={AddLogMonitioring} />
                      <RouteWithLayout path="/cluster/:cluster_id/nodes/list" layout={MainLayout} component={NodesListing} />
                      <RouteWithLayout path="/cluster/:cluster_id/node/list" layout={MainLayout} component={NodesListing} />
                      <RouteWithLayout path="/cluster/:cluster_id/node/:node/pod/list" layout={MainLayout} component={PodsListing} />
                      <RouteWithLayout path="/cluster/:cluster_id/detail" layout={MainLayout} component={ClusterDetail} />
                      <RouteWithLayout path="/backup/add" layout={MainLayout} component={ClusterBackup} />
                      <RouteWithLayout path="/backup/:backup_id/edit" layout={MainLayout} component={ClusterBackup} />
                      <RouteWithLayout path="/backup/list" layout={MainLayout} component={ClusterBackupListing} />
                      <RouteWithLayout path="/cluster/:cluster_id/ingress/list" layout={MainLayout} component={IngressList} />
                      <RouteWithLayout path="/cluster/:cluster_id/namepsace/list" layout={MainLayout} component={NamespaceList} />
                      {/* <RouteWithLayout path="/usersetting" layout={MainLayout} component={UserInfo} /> */}
                      <RouteWithLayout path="/system-settings" exact layout={MainLayout} component={SystemSetting} />
                      <RouteWithLayout path="/old-system-setting" exact layout={MainLayout} component={SystemSettings} />
                      <RouteWithLayout path="/cluster/:cluster_id/namespace/:namespace_name/pod/:pod_name" layout={MainLayout} component={PodsDetails} />
                      <RouteWithLayout path="/ErrorPage" layout={MainLayout} component={ErrorPage} />
                      <RouteWithLayout path="/clusterlist" layout={MainLayout} component={ClusterDetails} />
                      <RouteWithLayout path="/cluster/aws/add" layout={MainLayout} component={AddNewCluster} />
                      <RouteWithLayout path="/cluster/ldc/add" layout={MainLayout} component={LDCForm} />
                      <RouteWithLayout path="/cluster/add" layout={MainLayout} component={AddClusterFlow} />
                      <RouteWithLayout path="/cluster/:cluster_id/namespace/:namespace_id/edit" layout={MainLayout} component={AddNamespaces} />
                      <RouteWithLayout path="/cluster/:cluster_id/namespace/add" layout={MainLayout} component={AddNamespaces} />
                      <RouteWithLayout path="/cluster/:cluster_id/monitoring/infra/add" layout={MainLayout} component={AddMonitoring} />
                      <RouteWithLayout path="/cluster/:cluster_id/monitoring/infra/:monitoring_id/edit" layout={MainLayout} component={AddMonitoring} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/add" layout={MainLayout} component={AddIstio} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/:istio_id/edit" layout={MainLayout} component={AddIstio} />
                      <RouteWithLayout path="/cluster/:cluster_id/keda/add" layout={MainLayout} component={AddKeda} />
                      <RouteWithLayout path="/cluster/:cluster_id/keda/:keda_id/edit" layout={MainLayout} component={AddKeda} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/:istio_id/gateway/add" layout={MainLayout} component={AddIstioGateway} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/:istio_id/gateway/:gateway_id/edit" layout={MainLayout} component={AddIstioGateway} />
                      <RouteWithLayout path="/cluster/:cluster_id/gateway/list" layout={MainLayout} component={GatewayList} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/:istio_id/addons/add" layout={MainLayout} component={IstioAddOns} />
                      <RouteWithLayout path="/cluster/:cluster_id/istio/:istio_id/addons/:addons_id/edit" layout={MainLayout} component={IstioAddOns} />
                      <RouteWithLayout path="/project/add" layout={MainLayout} component={AddProjectWithCluster} />
                      <RouteWithLayout path="/project/:project_id/edit" layout={MainLayout} component={AddProjectWithCluster} />
                      <RouteWithLayout path="/cluster/:cluster_id/sonar/add" layout={MainLayout} component={AddSonar} />
                      <RouteWithLayout path="/cluster/:cluster_id/vault/add" layout={MainLayout} component={AddVault} />
                      <RouteWithLayout path="/cluster/:cluster_id/sonar/:resource_id/edit" layout={MainLayout} component={AddSonar} />
                      <RouteWithLayout path="/cluster/:cluster_id/vault/:resource_id/edit" layout={MainLayout} component={AddVault} />
                      <RouteWithLayout path="/project/list" layout={MainLayout} component={ProjectListing} />
                      <RouteWithLayout path="/permissions/listing" layout={MainLayout} component={PermissionsListing} />
                      <RouteWithLayout path="/policy/listing" layout={MainLayout} component={PermissionsPoliciesListing} />
                      <RouteWithLayout path="/user/group/list" layout={MainLayout} component={UserGroupList} />
                      <RouteWithLayout path="/policy/template/listing" layout={MainLayout} component={PolicyTemplateListing} />
                      <RouteWithLayout path="/manage-database" layout={MainLayout} component={DatabaseListing} />
                      


                      <RouteWithLayout path="/cluster/:cluster_id/dr/cluster/onboard" layout={MainLayout} component={AddClusterFlow} />

                      {/* <RouteWithMinimalLayout path="/registry/:docker_registry_id/edit" exact={true} layout={Minimal} component={Setup} /> */}

                    </> : <Route component={ErrorPage} />}

                    <Route component={ErrorPage} />
                  </Switch>
                </>
                : <Route render={(props) => (<> <Redirect to={{ pathname: redirectTo, state: { prevLocation: props.location } }} /></>)} />}
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      
    </Suspense>
  ), document.getElementById("root"))
}
console.log('change-in-routes');
export const GenerateRouteFromParams = (route, params) => {
  console.log("bieoeiowe",route,params);
  const keys = Object.keys(params);
  var generated_route = route;
  keys.forEach(key => {
    generated_route = generated_route.replace(":" + key, params[key]);
  });
  return generated_route;
}
export default initializeRoutes;
