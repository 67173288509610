import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import { defaultLinkedIssueState } from './LinkedIssuesDialog';

export default function CreateTicket({ pipeline, pipelineVars, jiraIssueKeys, jobStepOrderOutputVars, ...props }) {
    const inherits = props.inherits;
    const jira_id_reference_key = props.jira_id_reference_key
    const prev_state = props.prev_state;
    const default_job_template = props.default_job_template ? props.default_job_template : null;
    const getIssueType = props.getIssueType;
    const getProject = props.getProject;
    const ticketing_system_project_list = props.ticketing_system_project_list ? props.ticketing_system_project_list : [];
    const ticketing_integration_name = props.ticketing_integration_name ? props.ticketing_integration_name : null;
    const [state, setState] = useState(prev_state ? getCreateTicketDefaultState(prev_state, jira_id_reference_key) : getCreateTicketDefaultState(jira_id_reference_key));
    useEffect(() => {
        if (default_job_template && state.data.ticketing_project) {
            fetchJiraIssueTypeList();
        }

    }, [default_job_template, state.data.ticketing_project])
    useEffect(() => {
        if(ticketing_integration_name){
            fetchJiraPriorityList();
        }
    },[ticketing_integration_name])
    function fetchJiraIssueTypeList() {
        var requestInfo = {
            endPoint: GenerateURL({ job_template_id: default_job_template }, properties.api.jira_issue_type) + "?job_template_id=" + default_job_template + "&project="+state.data.ticketing_project,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchJiraIssueTypeListSuccess, fetchJiraIssueTypeListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJiraIssueTypeListSuccess(response) {
        var issue_type = response.issue_types.map(item => {
            return { label: item, id: item }
        })

        setState(new_state => ({
            ...new_state,
            jira_issue_type: issue_type,
            loading_data: false
        }));

    }
    function fetchJiraIssueTypeListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }

    const default_priority_list = [
        { label: 'High', id: 'high' },
        { label: 'Medium', id: 'medium' },
        { label: 'Low', id: 'Low' },
        { label: 'Lowest', id: 'lowest' }
    ]

    function fetchJiraPriorityList(){
        var requestInfo = {
            endPoint: GenerateURL({ }, properties.api.fetch_jira_priority_list) + "?integration_name=" + ticketing_integration_name, //+ "&project="+state.data.ticketing_project,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {
            console.log("jdjkds",response);
            var priority_list = response.map(item => {
                return { label: item, id: item }
            })
            setState(new_state => ({
                ...new_state,
                jira_priority_list: priority_list,
            }));
        }, (error) => {
            console.log("dhsjshjds",error);
            setState(new_state => ({
                ...new_state,
                jira_priority_list: default_priority_list,
            }));
        });
    }
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log("nsjajn", jira_id_reference_key,prev_state);
    
    const list = [
        { label: "Bug", id: 'bug' },
        { label: "Epic", id: 'epic' },
        { label: "Story", id: 'story' },
        { label: "Task", id: 'task' },
        { label: "Internal Service Request", id: 'Internal Service Request' }
    ]
    
    useEffect(() => {
        if (state.data.issuetype != "" || state.data.issuetype != null || state.data.issuetype != undefined) {
            sendIssueTypeToParent();
        }
    }, [state.data.issuetype])

    useEffect(() => {
        if (state.data.ticketing_project != "" || state.data.ticketing_project != null || state.data.ticketing_project != undefined) {
            sendTicketingProjectToParent();
        }
    },[state.data.ticketing_project])

    function sendIssueTypeToParent() {
        var issue_type = state.data.issuetype;
        getIssueType(issue_type)
    }

    function sendTicketingProjectToParent() {
        let project = state.data.ticketing_project;
        getProject(project);
    }

    function onChangeHandlerSwitch(e) {
        var key = e.target.name;
        var value = e.target.checked;
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }
    const onChangeHandlerApplication = (e) => {
        var key = e.target.name;
        var value = e.target.value;
        setState(new_state => ({
            ...new_state,
            data:{
                ...new_state.data,
                issue_key : jira_id_reference_key+".project."+value
            },
            error:{
                ...new_state.error,
                
            }
        }));
        commonFunctions.onChangeHandler(e)
    } 
    
    return (
        <>
            <Input
                type="select"
                name="ticketing_project"
                mandatorySign
                list={ticketing_system_project_list}
                label="Available Projects"
                onChangeHandler={onChangeHandlerApplication}
                data={state.data}
                error={state.error}
                jiraOperation={true}
                jiraOperationInfo={"Please Select an Option"}
            />
            {
                state.loading_data ? <Loading varient="light" /> :
                    <Input
                        type="select"
                        mandatorySign
                        name="issuetype"
                        list={state.jira_issue_type}
                        label="Issue Type"
                        onChangeHandler={commonFunctions.onChangeHandler}
                        data={state.data}
                        error={state.error} />
            }
            <Input
                type="text"
                name="issue_key"
                label="Jira Id Refrence key"
                placeholder="Jira id refrence key"
                onChangeHandler={null}
                data={state.data}
                error={state.error} />
            <Input
                type="text"
                name="issue_name"
                label="Jira issue refrence name"
                mandatorySign
                placeholder="release-one"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            {
                state.data.issuetype == "epic" &&
                <Input
                    type="text"
                    mandatorySign
                    name="epic_name"
                    label="Epic Name"
                    placeholder="Epic Name"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error} />
            }

            <Input
                type="text"
                name="summary"
                label="Summary"
                placeholder="Ticket Summary"
                mandatorySign
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Input
                type="text"
                name="description"
                label="Description"
                placeholder="description for the ticket"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

            <Input
                type="text"
                name="account_id"
                label="Account Id"
                placeholder="Assignee Account id"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Input
                type="select"
                mandatorySign
                name="priority"
                list={state?.jira_priority_list || default_priority_list}
                label="Select Priority for the ticket"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

            <div className="form-controll card-header-switch" style={{ border: '1px solid #dedede', borderRadius: "8px" }}>
                <Input
                    type="switch"
                    name="release_ticket_flag"
                    label="Is this ticket a release ticket?"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={onChangeHandlerSwitch}
                />
            </div>
        </>
    )
}

CreateTicket.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function getCreateTicketDefaultState(prev_state, jira_id_reference_key) {
    console.log(prev_state, "fdsafjknjkjknkjkjsd")
    return {
        data: prev_state ? {
            summary: prev_state.summary ? prev_state.summary : "",
            issuetype: prev_state.issuetype ? prev_state.issuetype : null,
            priority: prev_state.priority ? prev_state.priority : "medium",
            account_id: prev_state.account_id ? prev_state.account_id : null,
            description: prev_state.description ? prev_state.description : null,
            epic_name: prev_state.issuetype && prev_state.issuetype == "epic" ? prev_state.epic_name ? prev_state.epic_name : null : null,
            issue_key: prev_state.issue_key ? prev_state.issue_key : jira_id_reference_key,
            issue_name: prev_state.issue_name ? prev_state.issue_name : null,
            release_ticket_flag: prev_state?.release_ticket_flag ? prev_state.release_ticket_flag : false,
            ticketing_project: prev_state.ticketing_project ? prev_state.ticketing_project : null
        } : {
            priority: "medium",
            issue_key: jira_id_reference_key,
            jira_issue_type: [],
            release_ticket_flag: false,
        },
        linked_issue_list: prev_state ?
            prev_state.issue_link && prev_state.issue_link.length > 0 ?
                {
                    ...defaultLinkedIssueState(),
                    issue_link: prev_state.issue_link
                } : null : null,
        error: {},
        validations: {
            issue_key: [VALIDATION_TYPE_REQUIRED],
            issue_name: [VALIDATION_TYPE_REQUIRED],
            issuetype: [VALIDATION_TYPE_REQUIRED],
            summary: [VALIDATION_TYPE_REQUIRED],
            priority: [VALIDATION_TYPE_REQUIRED],
            issue_name: [VALIDATION_TYPE_REQUIRED]
        },

        child_inherits: {
            linked_issue_list: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    };
}
