import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { Input } from '../../../components/genericComponents/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getServicesListByNameOnly } from '../add/BasicInfo';
import KeyValue from '../../../components/genericComponents/inputs/KeyValues';
import properties from '../../../properties/properties';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import BranchChangeDialog from './BranchChangeDialog'
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LinkedIssuesDialog from '../add/components/LinkedIssuesDialog';
import { useParams } from 'react-router-dom';
import { Loading } from '../../utils/Loading';
import CustomFieldsDialog from '../add/components/CustomFieldsDialog';
import AdditionalFiledDialog from '../add/components/AdditionalFiledDialog';
import RunParamsCustomFields from '../add/components/RunParamsCustomFields';
import { makeStyles } from '@material-ui/core/styles';
import PipelineVarsListing from './PipelineVarsListing';
import PipelineVariableOverrideFields from './PipelineVariableOverrideFields';
import PipelineReleasePackage from './releasePackageComponent/PipelineReleasePackage';
import { StyledRadio } from '../../releasePackage/add/components/StylesRadio';

const RunWithParameter = ({ application_id, ...props }) => {
    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState(false)
    const [ciFetchErrors, setCiFetchErros] = useState({})
    const [branchSaveSucces, setBranchSaveSuccess] = useState(false)
    const required_jira_keys = props.required_jira_keys ? props.required_jira_keys : [];
    const [onlyBuildTasks, setOnlyBuildTasks] = useState(null);
    const [onlyAdditionalFields, setOnlyAdditionalFields] = useState(null)
    const [customFields, setCustomFields] = useState({

    })
    const [pipelineVarsData, setPipelineVarsData] = useState()
    const [openedReleasePackage,setOpenedReleasePackage] = useState(null);

    const jira_reference_name_list = props.jira_reference_name_list ? props.jira_reference_name_list : [];
    const pipeline_basic_data = props.pipeline_basic_data ? props.pipeline_basic_data : {}
    const runtime_execution = pipeline_basic_data?.runtime_execution || "both";
    console.log(pipeline_basic_data, 'bdp')
    const classes = useStyles();

    const handleToggle = () => {
        setOpen(!open);
    }
    useEffect(() => {
        props.pipeline_id && fetchAllStagesOfPipeline(props.pipeline_id)
        fetchSystemSettingsData()
    }, [props.pipeline_id])


    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState({
        data: {
            components: [],
            trigger_type:'parameters',
        },
        open_custom: false,
        error: {},
        validations: {},
        type_service_branch_data: {},
        current_target_service: null,
        change_service_branch_final_data: {},
        ci_fetch_errors: {},
        edited_daya_by_services: {},
        child_inherits: {
            linked_issue_list: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            custom_field_data:{
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            pipeline_vars: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        },
        additional_issue_card: false,
        uat_required:false,
        selectedIssueKeyForJiraAdditionalFieldDialouge: null,
        branchRequired: false,
        branchRequiredForRuntimeParams: false,
        componentListError: {},
        pipelineVarsDialouge: false
    });
    const [flipState, setFlipState] = useState(false)
    useEffect(()=>{
        if(runtime_execution != 'both'){
            if(runtime_execution == 'release_package'){
                setState((prev_state)=>({
                    ...prev_state,
                    data:{
                        ...prev_state.data,
                        trigger_type:'release_package',
                    }
                }))
            }
        }
    },[runtime_execution])
    // useEffect(() => {
    //     console.log(state.type_service_branch_data, 'current_tg_s')
    //     if (state.type_service_branch_data[state.current_target_service]) {
    //         setTimeout(() => setDialog(prevState => ({ ...prevState, loading: false })), 3000)
    //     }
    // }, [state.type_service_branch_data, state.ci_fetch_errors])
    useEffect(()=>{
        if(runtime_execution != 'both'){
            if(runtime_execution == 'release_package'){
                setState((prev_state)=>({
                    ...prev_state,
                    data:{
                        ...prev_state.data,
                        trigger_type:'release_package',
                    }
                }))
            }
        }
    },[runtime_execution])

    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const pipeline_id = props.pipeline_id;
    const services = props.services;
    const triggerPipeline = props.triggerPipeline;
    const toggleDismiss = props.toggleDismiss;


    const fetchAllStagesOfPipeline = (pipelineId) => {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipelineId }, properties.api.stages_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSuccesStages, (error) => console.log(error));

    }

    const fetchSuccesStages = (data) => {

        let allStagesBuildTasks = [];
        let allStagesAdditionalFileds = [];
        data && data.forEach(stage => {
            let build_taks = stage && stage.tasks.filter(task => {
                if (task.task_type_id === 1) {
                    return task
                } 
                if (task.task_type_id === 2) {
                    return task
                }
               
            })
            let task_additional_fileds = stage && stage.tasks.filter(task => {
                if(task.task_type_id === 10 && task.operation === "create"){
                    return task
                }
            })
            allStagesBuildTasks = [...allStagesBuildTasks, ...build_taks]
            allStagesAdditionalFileds= [...allStagesAdditionalFileds, ...task_additional_fileds]

        })
        data && setOnlyAdditionalFields(allStagesAdditionalFileds)
        data && setOnlyBuildTasks(allStagesBuildTasks)

    }

    function onChangeHandlerForKeyValue(key, value) {
        updateKeyValue(key, value);
    }

    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))
    }

    function fetchSystemSettingsDataSuccess(data) {
        console.log(data, 'dfghsd')
        var custom_fileds_jira_settings = data.filter(item => item.key == "jira_custom_fields_enable");
        var branchRequiredForRuntimeParams = data.find(item => item.key == "BRANCH_SELECTION_REQUIRED_IN_RUNTIMEPARAMS");
        var branchRequired = data.find(item => item.key == "BRANCH_SELECTION_REQUIRED_FOR_EVERY_BUILD");

        setState(new_state => ({
            ...new_state,
            custom_fileds_jira_settings: custom_fileds_jira_settings,
            data_loading: false,
            branchRequiredForRuntimeParams: branchRequiredForRuntimeParams?.value == 'true' ? true : false,
            branchRequired: branchRequired?.value == "true" ? true : false
        }));
    }

    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }

    function updateKeyValue(key, value) {
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function updateExtraParams(data) {
        const genereated_obj = {};
        if (!data) {
            data = []
        }
        data.forEach(pair => {
            if (pair.key) {
                genereated_obj[pair.key] = pair.value;
            }
        });
        setState({
            ...state,
            data: {
                ...state.data,
                ...genereated_obj,
            }
        });
    }
    const get_issues_of_same_ref_name = (arr, single_name) => {
        console.log(arr, single_name, "linked_issue_data")
        var final_arr = [];
        arr.forEach(item => {
            if (item.jira_ref_name_key == single_name) {
                final_arr.push({
                    issue_link_type: item.issue_link_type,
                    issue: item.issue.map(item => { return item.id })
                })
            }
        })
        return final_arr
    }
    const setCustomFieldsToState = (data, issueKey) => {
        console.log(data, "final_post_data", issueKey)
        // setCustomFields(data)
        setCustomFields(prevCustomFields => ({
            ...prevCustomFields,
            [issueKey]: data
        }))
    }

    const setPipelineVarsToState = (data) => {
        console.log(data, "final_post_data")
        // setCustomFields(data)
        setPipelineVarsData(data)
    }

    console.log(customFields, 'custom_fields_ssdsd')
    function validateAndTrigger() {
        var validations_arr = {}
        if (required_jira_keys && required_jira_keys.length > 0) {
            required_jira_keys.forEach(item => {
                validations_arr[item.jira_reference_key] = [VALIDATION_TYPE_REQUIRED]
            })

        }
        const error = {};
        var has_error = false;
        var result = ValidateDataSet(state.data, validations_arr);

        var child_states = inherits.getState ? inherits.getState() : null;
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };

        var linked_issue_data = child_states.linked_issue_list;
        var custom_additional_field_data = child_states.custom_field_data && child_states.custom_field_data.data ? child_states.custom_field_data.data : null ;
            delete custom_additional_field_data?.additional_issue;
            console.log({...custom_additional_field_data},"post_mapper_data")
        var jira_name_list = linked_issue_data.link_issues_list;

        var jira_name_key = jira_name_list.map(item => {
            return item.jira_ref_name_key
        })
        let uniqueChars = [...new Set(jira_name_key)];
        var data = {};
        console.log(children_vaidation_result, 'restosdnsd')
        console.log(child_states.pipeline_vars, 'pipeline_data_pch')
        uniqueChars.forEach(item => {
            jira_name_list.forEach(single_data => {
                if (item == single_data.jira_ref_name_key) {
                    data = {
                        ...data,
                        [item]: get_issues_of_same_ref_name(jira_name_list, single_data.jira_ref_name_key)
                    }
                    state.data = {
                        ...state.data,
                        ...data
                    }
                }
            })
        })
        // TODO code to validate
        if (!(state.data.components && state.data.components.length > 0)) {
            error.components = "Atleast One Service should be selected";
            has_error = true;
        }

        if (!children_vaidation_result.valid) {
            has_error = true
        }
        if (!has_error && result.valid && children_vaidation_result.valid) {
            let changed_branches = {}
            let allServicesVisited = true
            let componentListError = {}
            state.data.components.forEach(service => {
                if (state.change_service_branch_final_data[service]) {
                    changed_branches = { ...changed_branches, ...state.change_service_branch_final_data[service] }
                    componentListError[service] = false
                }
                else {
                    componentListError[service] = true
                    allServicesVisited = false
                }
            })

            if (state.branchRequired && !allServicesVisited) {
                error.components = "Selection of Branch for all selected services is required";
                setState(prevState => ({
                    ...prevState,
                    error: { ...error },
                    componentListError: componentListError
                }))
                return 0
            }

            if (state.open_custom && state.tag != "" && state.custom_tag != "") {
                delete state.data.tag
            } else {
                delete state.data.custom_tag
            }
            if (!state.open_custom && (state.data.tag && state.data.tag.length === 0)) {
                delete state.data.tag
            }
            if (state.open_custom && (state.data.custom_tag && state.data.custom_tag.length === 0)) {
                delete state.data.custom_tag
            }
            if (state.data.tag == '' || state.data.tag == null) {
                delete state.data.tag
            }
            if (state.data.custom_tag == '' || state.data.custom_tag == null) {
                delete state.data.custom_tag
            }
            console.log(state.data, custom_additional_field_data, "post_mapper_data")
            triggerPipeline(pipeline_id, { 
                ...state.data, 
                ...changed_branches,
                custom_additional_field_data: customFields,
                runtime_pipeline_vars: child_states.pipeline_vars?.data,
                uat_required: state.uat_required? true :false
            });
        } else {
            var dynamic_input_error = result.error;
            setState({
                ...state,
                error: { ...error, ...dynamic_input_error },
            });
        }
    }

    const handleOnChangeTextClickFun = (data) => {

        fetchAllEnvironments(data)
    }

    const handleCloseDialog = () => {
        setDialog(prevState => ({ ...prevState, open: false }))
        setCiFetchErros({})
    }

    function fetchAllEnvironments(data) {

        var requestInfo = {
            endPoint: GenerateURL({ component_id: data.service_id }, properties.api.fetch_component_envs + "&run_with_parameter=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (fetchedData) => fetchAllEnvironmentsSuccess(fetchedData, data), fetchAllEnvironmentsFailure);
    }

    function fetchAllEnvironmentsSuccess(fetchedData, callingData) {
        console.log(fetchedData, callingData, "fsajfkdkjas")
        getServiceBranchData(fetchedData, callingData)
    }

    function fetchAllEnvironmentsFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: error
        }));
    }

    function getCiData(serviceData, sub_env_data) {
        return new Promise(function (myResolve, myReject) {

            var requestInfo = {
                endPoint: GenerateURL({ service_id: serviceData.service_id, component_env_id: sub_env_data.id }, properties.api.save_ci),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (fetchedData) => {
                myResolve(fetchedData)
            }, (error) => { myReject(error) })

        });
    }

    const getBranches = (gitData) => {
        console.log(gitData, "cgfdfdd===========>")
        return new Promise(function (resolve, reject) {
            let post_data = {}
            if (gitData.git_url) {
                post_data['git_url'] = gitData.git_url
            }
            if (gitData.credential_id) {
                post_data['credential_id'] = gitData.credential_id
            }
            PostData(GenerateURL({}, properties.api.git_test),
                post_data,
                (data) => {
                    resolve(data)
                },
                (error) => reject(error));
        })

    }
    const getCdData = (service_id, env_id) => {
        console.log(env_id, "env data in cd ================>")
        return new Promise(function (resolve, reject) {
            var requestInfo = {
                endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, }, properties.api.save_cd),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo,
                (data) => {
                    resolve(data)
                },
                (error) => reject(error));
        })

    }

    const getBuildTags = (service_id, env_id, cd_id) => {
        console.log(env_id, "gdjgfjsgf =============>")
        return new Promise(function (resolve, reject) {
            var requestInfo = {
                endPoint: GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.env_build_tags_list_url),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo,
                (data) => {
                    resolve(data)
                },
                (error) => reject(error));
        })

    }

    const getEnvironmentAccordingToPipeline = (serviceId) => {
        let enviornmentForCurrentService = {}
        onlyBuildTasks && onlyBuildTasks.forEach(task => {
            if (task && task.components && task.components.includes(serviceId)) {
                enviornmentForCurrentService[task.project_env.name] = true
            }
        })

        return enviornmentForCurrentService
    }


    const getServiceBranchData = (data, serviceData) => {
        let onlyEligibleEnvs = getEnvironmentAccordingToPipeline(serviceData.service_id);
        console.log(onlyEligibleEnvs, serviceData, "onlyEligibleEnvs")
        let object = {}
        setDialog({ loading: true, open: true })
        var all_deployments_list = [];
        object[serviceData.name] = {}
        setState(prevState => ({
            ...prevState,
            current_target_service: serviceData.name
        }))
        if (data && data.length > 0) {
            data.forEach((subEnv, index) => {
                let { project_env } = subEnv
                console.log(subEnv, "onlyEligibleEnvs")
                console.log(project_env, subEnv, onlyEligibleEnvs, onlyEligibleEnvs[project_env.name], "onlyEligibleEnvs")
                if (project_env && onlyEligibleEnvs[project_env.name]) {
                    object[serviceData.name][project_env.name] = {}
                    object[serviceData.name][project_env.name]['master'] = project_env.environment_master
                    getCiData(serviceData, subEnv).then(ciData => {
                        if (ciData) {
                            console.log(ciData, "__fdsafsaf")
                            object[serviceData.name][project_env.name]['default_brnach'] = ciData.branch_name
                            setState(new_state => ({
                                ...new_state,
                                data_loading: "SUCCESS",
                                type_service_branch_data: { ...new_state.type_service_branch_data, ...object },
                                current_target_service: serviceData.name
                            }));
                            console.log(ciData, "fdjsanfjksa")
                            if (ciData && ciData[0] && ciData[0].git_repo) {
                                console.log(ciData, "fdjsanfjksa")
                                getBranches(ciData[0].git_repo).then((branches) => {
                                    if (branches && branches.branches) {
                                        object[serviceData.name][project_env.name]['branches'] = branches.branches
                                    }

                                    setState(new_state => ({
                                        ...new_state,
                                        data_loading: "SUCCESS",
                                        type_service_branch_data: { ...new_state.type_service_branch_data, ...object },
                                        current_target_service: serviceData.name
                                    }));

                                })
                                    .catch(error => {
                                        let comingError = ''
                                        console.log(error,"environment_masterenvironment_master")
                                        if (typeof (error) === 'object') {
                                            comingError = error['git_url']
                                        }
                                        else {
                                            comingError = error
                                        }
                                        console.log(error,"environment_masterenvironment_master")
                                        setCiFetchErros(prevState => ({
                                            ...prevState,
                                            [`${project_env['environment_master'].name}.${serviceData.name}.build.branch`]: String(comingError)
                                        }))
                                    });
                            } else {

                                setCiFetchErros(prevState => ({
                                    ...prevState,
                                    [`${project_env['environment_master'].name}.${serviceData.name}.build.branch`]: "Unable to find CI details"
                                }))
                            }

                        }
                        //if (ciData&&ciData[0] ) {
                            getCdData(serviceData.service_id, subEnv.id ).then(  //ciData[0].component_env_id
                                (cd_data) => {
                                    if (cd_data && cd_data.length > 0) {
                                        object[serviceData.name][project_env.name]['deployment_name_list'] = cd_data.map(item => { return { id: item.deployment_name, label: item.deployment_name } });
                                        all_deployments_list = object[serviceData.name][project_env.name]['deployment_name_list'];
                                    }
                                    setState(new_state => ({
                                        ...new_state,
                                        data_loading: "SUCCESS",
                                        type_service_branch_data: { ...new_state.type_service_branch_data, ...object },
                                    }));
                                    getBuildTags(serviceData.service_id, subEnv.id, cd_data[0].id).then( //ciData[0].component_env_id
                                        (tags) => {
                                            if (tags.tags && tags.tags.length > 0) {
                                                object[serviceData.name][project_env.name]['tags'] = tags.tags
                                            }

                                            setState(new_state => ({
                                                ...new_state,
                                                data_loading: "SUCCESS",
                                                type_service_branch_data: { ...new_state.type_service_branch_data, ...object },
                                            }));
                                        }
                                    )
                                        .catch(error => {
                                            let comingError = ''
                                            if (typeof (error) === 'object') {
                                                comingError = error
                                            }
                                            else {
                                                comingError = error
                                            }
                                            console.log(comingError.error, error, "comingError==>")
                                            setCiFetchErros(prevState => ({
                                                ...prevState,
                                                [`${project_env['environment_master'].name}.${serviceData.name}.deploy.version`]: "Unable to load tags"
                                            }))

                                        });
                                }
                            )
                                .catch(error => {
                                    let comingError = ''
                                    if (typeof (error) === 'object') {
                                        comingError = error
                                    }
                                    else {
                                        comingError = error
                                    }
                                    console.log(comingError.error, error, "comingError==>")
                                    setCiFetchErros(prevState => ({
                                        ...prevState,
                                        [`${project_env['environment_master'].name}.${serviceData.name}.deploy.deployment_name`]: "Unable to cd details"
                                    }))

                                });
                        //}
                        console.log(all_deployments_list, "checkingsomedata")

                    })
                        .catch(error => {
                            setCiFetchErros(prevState => ({
                                ...prevState,
                                [`${project_env['environment_master'].name}.${serviceData.name}.build.branch`]: error
                            }))
                            setState(new_state => ({
                                ...new_state,
                                type_service_branch_data: { ...new_state.type_service_branch_data, ...object }
                            }));
                        });
                }

            })
        }
    }
    console.log(ciFetchErrors, "ciFetchErrors>>") // fshfhsaj
    const toggleTagState = () => {
        var custom_tag_flag = state.open_custom;
        if (custom_tag_flag) {
            setState({
                ...state,
                loading_tag: true,
                data: {
                    ...state.data,
                    tag: null
                }
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    data: {
                        ...state.data,
                        tag: null
                    }
                });
            }, 200);
        } else {
            setState({
                ...state,
                loading_tag: true,
                data: {
                    ...state.data,
                    custom_tag: null
                }
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    data: {
                        ...state.data,
                        custom_tag: null
                    }
                });
            }, 200);
        }

    }
    const flipAndOpenAdditionalFiledsForm = (issueKey) => {

        setState(prevState => ({
            ...prevState,
            selectedIssueKeyForJiraAdditionalFieldDialouge: issueKey
        }))
    }
    const flipPipelineVaiableDialouge = () => {

        setState(prevState => ({
            ...prevState,
            pipelineVarsDialouge: !prevState.pipelineVarsDialouge
        }))
    }
    const flipCloseAndRetainState = () => {
        setState(prevState => ({
            ...prevState,
            selectedIssueKeyForJiraAdditionalFieldDialouge: null
        }))
    }

    console.log(jira_reference_name_list, onlyAdditionalFields, state, 'data_giot')

    const toggleState = (e) => {
        const name = e.target.name;
        setState(prevState => ({
            ...prevState,
            [name]: !state[name],
        }));
    }
    console.log(onlyAdditionalFields,"onlyAdditionalFieldsonlyAdditionalFields")

    console.log(state, 'stun_psp')
    console.log(pipelineVarsData, 'defjmn')
    const triggerThroughTypes = [{label:'Parameters',value:'parameters'},{value:'release_package',label:'Release package'}]
    return (
        <RunParameter>
            {runtime_execution == "both" && !openedReleasePackage ? 
                    <div className='d-flex f-direction-column pl-10' style={{gap:'8px'}}>
                        <span className='font-12 font-weight-700 color-key-78 text-transform-uppercase'>Trigger through</span>
                        <div style={{height:"34px"}}>
                            <Input
                                type={"custom-radio"}
                                name={"trigger_type"}
                                data={state.data}
                                error={state.error}
                                list={triggerThroughTypes}
                                customRadio={StyledRadio}
                                mandatorySign
                                onChangeHandler={commonFunctions.onChangeHandler}
                            />
                        </div>
                    </div>
                : null}
                {state.data.trigger_type == 'parameters' ? 
                    <>
                        <div className='run-with-para-div' style={(state.selectedIssueKeyForJiraAdditionalFieldDialouge) ? { display: 'none' } : null} >
                            <div className='pl-10 pr-10'>
                                <Input
                                    type="checkbox"
                                    varient="checkbox-with-change-text-error"
                                    name="components"
                                    label="Services"
                                    listError={state.componentListError}
                                    mandatorySign
                                    list={services && services.map(service => {
                                        return { ...service, id: service.name, label: service.name, service_id: service.id }
                                    })}
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandlerForKeyValue}
                                    onTextClick={handleOnChangeTextClickFun}
                                />
                            </div>
                            <BodyRunParameter>
                                <Input
                                    type="text"
                                    label="Enter Branch"
                                    placeholder="Master"
                                    name="branch"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                                {
                                    state.loading_tag ? <Loading varient="light" /> :
                                        <Input
                                            type={"text"}
                                            name={state.open_custom ? "custom_tag" : "tag"}
                                            placeholder={state.open_custom ? "custom-tag" : "tag"}
                                            label={"Give a tag"}
                                            mandatory
                                            paramsHeading={<span className='' style={{ color: '#ff8969', fontWeight: '500' }}>Artifact tag has to be unique</span>}
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}

                                        />
                                }
                                {/* <Input
                                type="text"
                                label="Give a tag to the artifact"
                                name="tag"
                                placeholder="Tag Name"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={commonFunctions.onChangeHandler}
                            /> */}

                                <Input
                                    type="text"
                                    label="Give a deploy version to the pipeline"
                                    name="version"
                                    placeholder="version"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                                {
                                    required_jira_keys && required_jira_keys.length > 0 ? required_jira_keys.map((variable, index) => {
                                        return (
                                            <>
                                                <Input
                                                    type={"text"}
                                                    mandatorySign={true}
                                                    name={variable.jira_reference_key}
                                                    label={variable.jira_reference_key}
                                                    placeholder={variable.jira_reference_key}
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error}
                                                />
                                            </>
                                        )
                                    }) : null
                                }

                            </BodyRunParameter>
                            <div className={classes.formCard} >
                                <div class={"bg-f8-grey " + " " + classes.custom_css}>
                                    <div class="card-header with-btn custom-switch">
                                        <div class="heading">UAT Required?</div>
                                        <Input
                                            type="switch"
                                            label=""
                                            name="uat_required"
                                            data={state}
                                            error={state.error}
                                            onChangeHandler={toggleState}
                                        />
                                    </div>
                                </div>
                            </div>
                            <LinkedIssuesDialog
                                prev_state={state.linked_issue_list}
                                jira_reference_name_list={jira_reference_name_list}
                                inherits={state.child_inherits.linked_issue_list}
                            />
                            {/* {
                                state.custom_fileds_jira_settings && state.custom_fileds_jira_settings[0] ?
                                <CustomFieldsDialog
                                    prev_state={state.custom_field_data}
                                    flipAndOpenAdditionalFiledsForm={flipAndOpenAdditionalFiledsForm}
                                    jira_reference_name_list={jira_reference_name_list}
                                    inherits={state.child_inherits.custom_field_data}
                                    allStagesAdditionalFileds={onlyAdditionalFields}
                                />
                            } */}
                            <div className={classes.formCard} >
                                <div class={"bg-f8-grey " + " " + classes.custom_css}>
                                    <div class="card-header with-btn custom-switch">
                                        <div class="heading">Do you want to Update Jira Additional Fields?</div>
                                        <Input
                                            type="switch"
                                            label=""
                                            name="additional_issue_card"
                                            data={state}
                                            error={state.error}
                                            onChangeHandler={toggleState}
                                        />
                                    </div>
                                </div>
                            </div>

                            <>
                            
                                {state.additional_issue_card &&
                                    <>{onlyAdditionalFields && onlyAdditionalFields.length > 0 ?
                                        onlyAdditionalFields.map((job, index) => {
                                            return (
                                                <div key={job.jira_reference_key}>
                                                    <CustomFieldsDialog
                                                        openCard={state.additional_issue_card}
                                                        jobData={job}
                                                        prevState={customFields}
                                                        flipAndOpenAdditionalFiledsForm={flipAndOpenAdditionalFiledsForm}
                                                        jira_reference_name_list={jira_reference_name_list}
                                                        allStagesAdditionalFileds={onlyAdditionalFields}
                                                    />
                                                </div>
                                            )
                                        }) : <p 
                                            style={{backgroundColor: '#fff',
                                            margin: '-10px 10px 0px 10px',
                                        paddingBottom:'10px',
                                    paddingTop:'10px'}}
                                        className='font-12 border-left border-right border-bottom text-center'>Additional Fields are not configured</p>
                                    }</>
                                }
                            </>
                            {
                                pipeline_basic_data?.pipeline_vars && pipeline_basic_data?.pipeline_vars?.length > 0
                                &&
                                <PipelineVariableOverrideFields
                                    prevState={{}}
                                    isOpen={state.pipelineVarsDialouge}
                                    handleClose={flipPipelineVaiableDialouge}
                                    postFinalData={setPipelineVarsToState}
                                    pipelineVars={pipeline_basic_data?.pipeline_vars}
                                    inherits={state.child_inherits.pipeline_vars}
                                />
                            }

                            {/* <div className={classes.formCard} >
                                <div class={"bg-f8-grey " + " " + classes.custom_css}>
                                    <div class="card-header with-btn custom-switch">
                                        <div class="heading">Do you want to Update Pipeline Vars?</div>
                                        <Input
                                            type="switch"
                                            label=""
                                            name="pipeline_vars_card"
                                            data={state}
                                            error={state.error}
                                            onChangeHandler={toggleState}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            {/* <>
                                {state.pipeline_vars_card &&

                                    <PipelineVarsListing
                                        openCard={state.pipeline_vars_card}
                                        pipelineVars={pipeline_basic_data?.pipeline_vars}
                                        prevState={{}}
                                        flipAndOpenAdditionalFiledsForm={flipPipelineVaiableDialouge}
                                        allStagesAdditionalFileds={onlyAdditionalFields}
                                    />
                                }
                            </> */}

                            <div>
                                <BodyRunParameter>
                                    <div></div>
                                    <div></div>
                                    <ButtonSec>
                                        <button onClick={handleToggle}>Advance Configruation
                                            <IconButton >   {
                                                !open ?
                                                    <ExpandMoreIcon style={{ color: '#0086ff', fontSize: '18px' }} />
                                                    :
                                                    <ExpandLessIcon style={{ color: '#0086ff', fontSize: '18px' }} />
                                            }
                                            </IconButton>
                                        </button>
                                    </ButtonSec>
                                </BodyRunParameter>

                            </div>
                            {open ?
                                <KeyValue callback={updateExtraParams} /> : null
                            }
                        </div>
                        <SubmitFooter style={(state.selectedIssueKeyForJiraAdditionalFieldDialouge) ? { display: 'none' } : null} >
                            <ButtonSec style={{ justifyContent: 'flex-end' }}>
                                <button className="btn-grey-outline" onClick={toggleDismiss} style={{ backgroundColor: 'transparent' }}>Dismiss</button>
                                <button onClick={validateAndTrigger} className="btn-grey-outline btn-blue-curve">Run Pipeline</button>
                            </ButtonSec>
                        </SubmitFooter>
                        {console.log(state, "fshfhsaj")}
                        {
                            state.selectedIssueKeyForJiraAdditionalFieldDialouge ?
                                <>
                                    <RunParamsCustomFields
                                        prevState={customFields[state.selectedIssueKeyForJiraAdditionalFieldDialouge]}
                                        issueKey={state.selectedIssueKeyForJiraAdditionalFieldDialouge}
                                        custom_fields_key_value={state.data.additional_field_key_value}
                                        showAdditionalField={flipState}
                                        handleClose={flipCloseAndRetainState}
                                        flipAndOpenAdditionalFiledsForm={flipAndOpenAdditionalFiledsForm}
                                        flipCloseAndRetainState={flipCloseAndRetainState}
                                        postFinalData={setCustomFieldsToState}
                                        allStagesAdditionalFileds={onlyAdditionalFields}
                                        inherits={state.child_inherits.custom_field_data}
                                        application_id={application_id}
                                    />
                                </> : null
                        }

                        {dialog.open &&
                            <BranchChangeDialog
                                ciFetchErrors={ciFetchErrors}
                                parentState={state}
                                dialogState={dialog}
                                setParentState={setState}
                                setDialog={setDialog}
                                setBranchSaveSuccess={setBranchSaveSuccess}
                                onClose={handleCloseDialog}
                                pipeline_basic_data={pipeline_basic_data}
                                branchRequired={state.branchRequiredForRuntimeParams}
                            />
                        }

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={branchSaveSucces}
                            onClose={() => { setBranchSaveSuccess(false) }}
                            className="z-index-top"
                            autoHideDuration={4000}>
                            <Alert severity="success">
                                {"BRANCH and TAG SUCCESSFULLY SAVED"}
                            </Alert>
                        </Snackbar>
                    </>
                : 
                    <PipelineReleasePackage 
                        toggleDismiss={toggleDismiss}
                        setOpenedReleasePackage={setOpenedReleasePackage}
                        openedReleasePackage={openedReleasePackage}
                        triggerPipeline={triggerPipeline}
                        pipeline_id={pipeline_id}
                    />
                }
            
        </RunParameter>
    )
}
export default RunWithParameter;

const AdvanceConfig = props => {
    return (
        <BodyAdvParameter>
            <React.Fragment>
                <Input
                    type="text"
                    label="Key"
                    placeholder=""
                    data={{}}
                    error={{}}
                    onChangeHandler={{}}
                />
                <Input
                    type="text"
                    label="Value"
                    placeholder=""
                    data={{}}
                    error={{}}
                    onChangeHandler={{}}
                />

                <ButtonSec>
                    <button>
                        Add key
                    </button>
                </ButtonSec>
            </React.Fragment>
        </BodyAdvParameter>
    )
}

const RunParameter = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '15px 10px',
    position: 'relative',
    height: '100%',

    '& .MuiDialog-paperWidthSm': {
        width: '650px'
    },

    '& .with-header': {
        borderTop: '1px solid #dedede'
    },

    '& .grid-layout-controller': {
        gridTemplateColumns: 'auto auto auto auto'
    }
});

const BodyRunParameter = styled('div')({
    flex: 1, // Takes up all available space
    display: 'grid',
    padding: '10px',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1%',

    '& .input-component': {
        '& input': {
            height: '36px'
        }
    },

    '& button': {
        backgroundColor: 'transparent',
        fontSize: '11px',
        color: '#0086ff',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& span.flaticon-downwards-arrow-key': {
            color: '#0086ff',
            marginLeft: '2px',

            '&:before': {
                fontSize: '11px'
            }
        }
    }
});

const ButtonSec = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
});

const SubmitFooter = styled('div')({
    borderRadius: '0px 0px 15px 15px',
    backgroundColor: '#fff',
    padding: '10px',
    borderTop: '1px solid #dedede',
});


const useStyles = makeStyles((theme) => ({

    formCard: {
        backgroundColor: '#fff',
        margin: '10px',
        border: '1px solid #eaeaea'
        // '& .MuiTextField-root': {
        //     '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        //         padding: '0px 14px!important'
        //     }

        // }

    },

    custom_css: {
        '& .custom-switch': {
            borderTop: '0px solid transparent!important',
            '& .card-header': {
                border: 'none!important',
                height: '49px',
                '& .card-header': {

                }
            }
        }
    }
}));


