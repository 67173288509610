import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { IconButton, makeStyles } from "@material-ui/core";
import { Link, useParams } from 'react-router-dom';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import Grid from '@material-ui/core/Grid';

import { Snackbar, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import TriggerActionCard from '../../listing/components/TriggerActionCard'
import { Alert } from '@material-ui/lab';
import { Loading } from '../../../utils/Loading';
import Delete from '../../../../components/genericComponents/Delete'
import MonitoringDialogue from './MonitoringDialogue'

import ManifestView from './ManifestView'
import HistoryRevamp from '../../listing/components/HistoryRevamp'

import OtherDeploymentDetails from './OtherDeploymentDetails';
import CdDetailsOverviewFlow from './CdDetailsOverviewFlow'
import CiDetailsOverviewFlow from './CiDetailsOverviewFlow'
import { GetAuth } from '../../../../util/security';
import JobListingForTemplates from './servicesTemplates/JobListingForTemplates';
import AddJobTemplateToServices from './servicesTemplates/AddJobTemplatesToServices';
import JobConfigureDisplay from './servicesTemplates/JobConfigureDisplay'
import IntegrationsTesting from './IntegrationsTesting';
import DeployActionCard from './DeployActionCard';
import PromoteActionCard from './PromoteActionCard';
import { Input } from '../../../../components/genericComponents/Input';
import CloseIcon from '@material-ui/icons/Close';
import TriggerActionCardNew from '../../listing/components/TriggerActionCardNew';
import DeployActionCardNew from './DeployActionCardNew';
import EnvSelectorNew from '../../../../components/genericComponents/EnvSelectorNew';
import JobSummaryCardForDynamicJobs from './servicesTemplates/jobCard/JobSummaryCardForDynamicJobs';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';
const SetupSummary = React.memo(({ job_code_queryString, setselectedServiceEnv, ...props }) => {
    var selectedEnvDetails = props.selectedEnvDetails;
    const post_refresh_count = props.post_refresh_count ? props.post_refresh_count : null;
    const handleOpenAddEnvDialog = props.handleOpenAddEnvDialog ? props.handleOpenAddEnvDialog : () => { }
    let service_data = props.service_data;

    // console.log('line 38', pipeline_id, execution_id);
    const show_deployment_status = props.show_deployment_status;
    console.log('setup_s_p', props);

    const addEnvLink = "/application/" + selectedEnvDetails.applicationId + "/service/" + selectedEnvDetails.serviceId + "/env/add";
    var service_name = props.serviceSummaryData ? props.serviceSummaryData.serviceName : "NoName";
    console.log(props.data, "afgcdscsdvsv")
    return (
        <>
            <NewCard
                handleOpenAddEnvDialog={handleOpenAddEnvDialog}
                setselectedServiceEnv={setselectedServiceEnv}
                job_code_queryString={job_code_queryString}
                service_data={props?.service_data}
                top_summary_data={props.top_summary_data}
                refresh={props.refresh}
                service_name={service_name}
                selectedEnvDetails={selectedEnvDetails}
                data={props.data}
                post_refresh_count={post_refresh_count}
                show_deployment_status={show_deployment_status}
                single_service_data={props.single_service_data} />
        </>
    )
})

SetupSummary.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default SetupSummary;

const NoEnvAvailable = props => {
    const application_id = props.application_id;
    const component_id = props.component_id;

    return (
        <div className="overviewCard" >
            <div className="overviewCard__body">
                <div className="blank-div">
                    <div className="blank-div-text">
                        No Environment Configured
                        Please Add Environment
                    </div>
                    <Link className="text-anchor-blue" to={"/application/" + application_id + "/service/" + component_id + "/revamp/env/add"}>
                        <AddIcon />Add Environment
                    </Link>
                </div>
            </div>
        </div>
    )
}

NoEnvAvailable.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}
const NewCard = React.memo(({ job_code_queryString, setselectedServiceEnv, ...props }) => {
    const post_refresh_count = props.post_refresh_count ? props.post_refresh_count : null
    const single_service_data = props.single_service_data;
    const handleOpenAddEnvDialog = props.handleOpenAddEnvDialog ? props.handleOpenAddEnvDialog : () => { }
    const component_id = props.data.selectedServiceEnv.component_id
    const component_env_id = props.data.selectedServiceEnv.id
    
    var selectedEnvDetails = props.selectedEnvDetails
    const data = props.data ? props.data: null;
    console.log("default_master_env_tab_order", data.default_master_env_tab_order)
    var service_name = props.service_name;
    const show_deployment_status = props.show_deployment_status;
    const [state, setState] = useState({
        selectedCiCdTab: data.show_deploy_details == "true" ? 2 : 1,
        selectedMasterEnvTabOrder: data.default_master_env_tab_order,
        selectedServiceEnvTabOrder: data.default_service_env_tab_order,
        selectedServiceEnv: data.selectedServiceEnv,
        service_name: service_name,
        history_child_inherits: {},
        refresh_count: 1,
        available_settings: [],
        jobTrigger: false,
        cd_settings: [],
        ci_data_loading: true,
        cd_data_loading: true,
        show_setup_screen: false,
        dynamicJobs: null,
        selectedJobData: {},
        complete_ci_data: {},
        version: null
    })

    const permission = useFetchPermissions();
    const [cddata, setcdData] = useState({ data: {}, error: {}, allConfiguredCd: [], current_cd_data: [], availableDeploymentList: [] })
    const [componentView, setComponentView] = useState('jobListing')
    const [selectedSubEnv, setSelectedSubEnv] = useState()
    const [selectedJobTemplate, setSelectedJobTemplate] = useState(null) // job template data
    const [selectedTemplateAndJobData, setSelectedTemplateAndJobData] = useState({}) // previous configurations and target job data
    const [configuredJobs, setConfiguredJobs] = useState() // all configure jobs
    const [otherDeployInfoTab, setOtherDeployInfoTab] = useState(data.show_deploy_details == "true" ? 2 : 1,)
    const [deployDetailsTab, setDeployDetailsTab] = useState(1)
    const [bpFormEnabled, setBpFormEnabled] = useState(false);
    const [manifestFormEnabled, setManifestFormEnabled] = useState(false);
    const [tabListForVersion2, setTabListForVersion2] = useState([{
        order: 1,
        tabName: 'Env Build Details'
    },
    {
        order: 2,
        tabName: 'Env Deploy Details'
    },])
    
    const { application_id } = useParams();


    useEffect(() => {
        if(data){
            console.log(data.serviceTabsForMasterEnv,"serviceTabsForMasterEnv", state.selectedMasterEnvTabOrder)
            setState((new_state) => ({
                ...new_state,
                masterEnvTabList: data.masterEnvTabList,
                selectedMasterEnvTabOrder: data.default_master_env_tab_order,
                serviceTabsForMasterEnv: data.serviceTabsForMasterEnv,
                environmentsMap : data.environmentsMap,
            }))
        }
        
    }, [data, data.masterEnvTabList, data.serviceTabsForMasterEnv])
    console.log(state.serviceTabsForMasterEnv, state.selectedMasterEnvTabOrder, 'selectedMasterEnvTabOrder')
    const sub_env_list = state.serviceTabsForMasterEnv ? state.serviceTabsForMasterEnv[state.selectedMasterEnvTabOrder] : []
    const service_env_name =  sub_env_list && sub_env_list[0]? sub_env_list[0].tabName: null;
    useEffect(() => {
        setselectedServiceEnv(state?.selectedServiceEnv)
    }, [state.selectedServiceEnv])
    useEffect(() => {
        setComponentView('jobListing')
        setJobTemplateJobsForSelectedServiceEnv()
    }, [state.selectedServiceEnvTabOrder])

    useEffect(() => {
        console.log(state.dynamicJobs, 'data_00p_lmn')
        if (state.dynamicJobs) {
            const defaultJobs = [{
                order: 1,
                tabName: 'Env Build Details'
            },
            {
                order: 2,
                tabName: 'Env Deploy Details'
            },]
            const dynamicTabs = []
           
            state.dynamicJobs?.forEach((job, index) => {

                dynamicTabs.push({ order: index + 3, tabName: job?.job_name, data: job })
            })

            setTabListForVersion2([...defaultJobs, ...dynamicTabs]);
        }
    }, [state.dynamicJobs])

    useEffect(() => {
  
        if (selectedJobTemplate?.job_template) {
            const dynamicJobs = selectedJobTemplate?.job_template?.jobs?.filter(job => {
                if (job?.is_dynamic && job?.is_dependent && job?.dependent_entity == "SERVICE") {
                    return job
                }
            })
            if (dynamicJobs?.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    dynamicJobs: dynamicJobs
                }))
            }
        }
    }, [selectedJobTemplate])

    useEffect(() => {
        state.ci_cd_data?.project_job_template_id != undefined && application_id != undefined &&
            fetchJobTemplatesInfo(application_id, state.ci_cd_data.project_job_template_id)
    }, [state.ci_cd_data, application_id])

    useEffect(() => {
     
        setSelectedSubEnv(getSelectedSubEnv(sub_env_list, state.selectedServiceEnvTabOrder))
    }, [sub_env_list, state.selectedServiceEnvTabOrder])

    useEffect(() => {

        getConfiguredJobs()

    }, [selectedEnvDetails.serviceId, selectedSubEnv])

    function setJobTemplateJobsForSelectedServiceEnv() {
      

        let sub_env_data = sub_env_list.find((item) => item.order == state.selectedServiceEnvTabOrder);
        // Now you can access the tabName property if sub_env_name is found
        let sub_env_name = sub_env_data?.tabName


        let component_env_data = single_service_data?.component_env.find((item) =>
            item.project_env?.name == sub_env_name
        )

      
    }
    useEffect(() => {
        fetchCIData()
    }, [state.multiple_build_enabled])

    function fetchJobTemplatesInfo(applicationId, jobTempalteId) {
      

        let myEndPoint = GenerateURL({ application_id: applicationId, template_id: jobTempalteId }, properties.api.get_template_by_id);

        const requestInfo = (endPoint) => ({
            endPoint: endPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        });

        const handleSuccess = (data, type) => {
            console.log(data)
            let version = data?.version ? data?.version : 'v2'
            if (type == "global_temp") {
                let url = GenerateURL(
                    { service_id: Number(component_id), env_id: Number(component_env_id) },
                    properties.api.env_data_update_url
                );

                InvokeApi(requestInfo(url), (response) => handleSuccessServiceEnvHit(response, type, data), handleFailedServiceEnvHit);
            } else {
                setSelectedJobTemplate(prevState => ({
                    ...prevState,
                    ...data
                }))
            }
            setState(new_state => ({
                ...new_state,
                data_loading: "LOADING",
                template_loading: false,
                version: version
            }));
        };

        const handleError = (error) => {
          
            if (error === "Not found.") {

                let newEndPoint = GenerateURL({ template_id: jobTempalteId }, properties.api.get_single_global_job_template /* + "?version_history=True&all=true" */);
                InvokeApi(requestInfo(newEndPoint), (response) => handleSuccess(response, "global_temp"), handleRetryError);
            } else {
                setState(prevState => ({
                    ...prevState,
                    error: error
                }));
            }
        };

        const handleRetryError = (error) => {
            console.log(error, "retry_error");
            setState(prevState => ({
                ...prevState,
                error: error
            }));
        };

        InvokeApi(requestInfo(myEndPoint), handleSuccess, handleError);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING",
            template_loading: true,
        }));
    }

    function handleSuccessServiceEnvHit(response, type, data) {
        console.log(response, type, data)

        setSelectedJobTemplate(prevState => ({
            ...prevState,
            ...data,
            template_version: response.template_version
        }))
    }

    function handleFailedServiceEnvHit() {

    }


    const jobConfigureSuccess = () => {


        getConfiguredJobs()

        setComponentView('jobListing')
    }


    const deployDetailsTabList = [
        {
            order: 1,
            tabName: 'Deploy Details'
        }, {
            order: 5,
            tabName: 'Integration Testing'
        },
        {
            order: 2,
            tabName: 'Other Deployment Info'
        },
        {
            order: 3,
            tabName: 'Generated Manifest'
        },
    ]
    useEffect(() => {
        if (state.dynamicJobs && otherDeployInfoTab > 2) {
            let actualSelctedJobIndex = otherDeployInfoTab - 3
            const selectedJob = state.dynamicJobs[actualSelctedJobIndex]
            let job_code = selectedJob.job_code
            let metaInfo = { configured: configuredJobs && configuredJobs[job_code] }
            let data = { ...selectedJob, templateName: selectedJobTemplate?.name, ...metaInfo }

            setState(prevState => ({
                ...prevState,
                selectedJobData: {
                    ...prevState.selectedJobData,
                    wholeJob: selectedJob,
                    data: data

                }
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                selectedJobData: {}
            }))
        }

    }, [otherDeployInfoTab, configuredJobs])

    const updateSelectedServiceEnvTab = (selectedTabOrder) => {
        
        setState(new_state => ({
            ...new_state,
            selectedServiceEnvTabOrder: selectedTabOrder,
            env_tab_change: true,
            selectedServiceEnv: getSelectedEnv(state.selectedMasterEnvTabOrder, selectedTabOrder),
            selectedCiCdTab: 1,
            action_card: null,
            deploy_action_card: null,
            promote_action_card: null
        }));
        setDeployDetailsTab(1)
    }

    const updateMasterEnvTabSelection = (selectedTabOrder) => {
        setState(new_state => ({
            ...new_state,
            selectedMasterEnvTabOrder: selectedTabOrder,
            selectedServiceEnv: getSelectedEnv(selectedTabOrder, 0),
            selectedServiceEnvTabOrder: 0,
            env_tab_change: true,
            selectedCiCdTab: 1,
            action_card: null,
            deploy_action_card: null,
            promote_action_card: null
        }));
        setDeployDetailsTab(1)
    }

    const updateSelectedOtherDeployTab = (tabOrder) => {
       
        setOtherDeployInfoTab(tabOrder)
    }
    const updateSelectedDeployDetailsTab = (tabOrder) => {
        setDeployDetailsTab(tabOrder)

    }
    function getSelectedEnv(selectedMasterEnvTabOrder, selectedServiceEnvTabOrder) {
        console.log(state.environmentsMap,selectedMasterEnvTabOrder,selectedServiceEnvTabOrder,"sdvcgdcdvhcvgsdvc")
        var envList = state.environmentsMap[selectedMasterEnvTabOrder]
        return envList ? envList[selectedServiceEnvTabOrder] : null
    }
   
    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var filter_cd_versioning_data = data.filter(item => item.key == "VERSIONING_CD_ENABLE");
        var deploy_env_offline = data.filter(item => item.key == "DISABLE_DEPLOY_IF_ENV_OFFLINE");
        var disable_parallel_build = data.find(item => item.key === "DISABLE_PARALLEL_BUILD")
        var multiple_build_enabled = data.find(item => item.key === "MULTIPLE_BUILD_CONFIGURATION")
        localStorage.setItem("system_settings", data)
        setState(new_state => ({
            ...new_state,
            available_settings: data,
            cd_settings: filter_cd_versioning_data,
            deploy_env_offline_flag: deploy_env_offline,
            disable_parallel_build: disable_parallel_build,
            multiple_build_enabled: multiple_build_enabled?.value == "true" ? true : false,
            data_loading: false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }


    function toggleDeployActionCard() {

        setState(fresh_state => ({
            ...fresh_state,
            deploy_action_card: !fresh_state.deploy_action_card,
            action_card: false,
            promote_action_card: false
        }));
    }

    function togglePromoteActionCard() {

        setState(fresh_state => ({
            ...fresh_state,
            promote_action_card: !fresh_state.promote_action_card,
            deploy_action_card: false,
            action_card: false
        }));
    }

    function toggleTriggerActionCard() {
        setState(new_state => ({
            ...new_state,
            promote_action_card: false,
            deploy_action_card: false,
            action_card: !new_state.action_card
        }));
    }

    function handleClose() {
        setState(prevState => ({ ...prevState, jobTrigger: false }))
    }
    function closeActionCard(trigger) {
        setState({
            ...state,
            trigger: trigger
        });
    }
    function closeCard() {
        setState({
            ...state,
            action_card: null,
            deploy_action_card: null,
            promote_action_card: null
        });
    }
    function refreshGrid() {
       
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,

        }));

    }

    function widgetApiHit() {
        let requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: state.selectedServiceEnv.id }, properties.api.widget_data + "?job_code=deploy_job&widget_code=DEPLOYMENT_ANALYTICS"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onWidgetSuccess, onWidgetFail);
        setState(prevState => ({
            ...prevState,
            cd_data_loading: true,
        }));
    }

    function onWidgetSuccess(response) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
            deployments_list: response.map(item => { return { label: item, id: item } }),
        }));
    }

    function onWidgetFail(error) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
        }))
    }

    function fetchCdData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: state.selectedServiceEnv.id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true
        }));
    }

    function onFetchCdDataSuccess(response) {
       
        var approved_deployment_list = response.filter(item => item.status === "APPROVED")
        setState(new_state => ({
            ...new_state,
            cd_response: approved_deployment_list,
            cd_data: response && response.length > 0 ? response : null,
            cd_configured: response && response.length > 0 ? true : false,
            manifest_meta_data: response.manifest_meta_data,
            cd_data_loading: false
        }));

        var input_dropdown = response && response.length > 0 ? getArrAndGroupRepeatedDeploymentName(response) : []
       
        if (input_dropdown && input_dropdown[0]) {
            var filter_current_cd_data = response.filter(item => item.deployment_name == input_dropdown[0].label);
        } else {
            var filter_current_cd_data = [];
        }

        setcdData(new_state => ({
            ...new_state,
            allConfiguredCd: response && response.length > 0 ? response : [],
            allApprovedCd: approved_deployment_list,
            deploymentListWithoutWidget: approved_deployment_list.map(item => { return { label: item.deployment_name, id: item.deployment_name } }),
            availableDeploymentList: input_dropdown,
            data: {
                deployment_name: input_dropdown[0] && input_dropdown[0].label ? input_dropdown[0].label : null,
            },
            current_cd_data: filter_current_cd_data,
        }));
    }

    function onFetchCdDataFail(response) {
        setState(new_state => ({
            ...new_state,
            cd_data: null,
            cd_data_loading: false,
            cd_response: null
        }));
    }

    function fetchRecentBuildDeployData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, env_id: state.selectedServiceEnv.id }, properties.api.env_ci_cd_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onfetchRecentBuildDeployDataSuccess, onfetchRecentBuildDeployDataFail)
    }

    function onfetchRecentBuildDeployDataSuccess(response) {
        fetchSystemSettingsData()
        setState(new_state => ({
            ...new_state,
            ci_cd_data: response
        }));
    }

    function onfetchRecentBuildDeployDataFail(response) {
        setState(new_state => ({
            ...new_state,
            error: response
        }));
    }

    useEffect(() => {
        // console.log(selectedEnvDetails?.service_id, state.selectedServiceEnv?.id, state?.refresh_count, post_refresh_count , "running_useeffect")
        console.log("running_useeffect")
        fetchRecentBuildDeployData();
        fetchCdData();
        // widgetApiHit(); // commented widget api because the data from this api is not being used at this level.
      
    }, [selectedEnvDetails?.service_id, state.selectedServiceEnv?.id, state?.refresh_count, post_refresh_count])


    // console.log(selectedEnvDetails?.service_id, state.selectedServiceEnv?.id, state?.refresh_count, post_refresh_count , "adcbhadvcgsdvggdcsvc")

    function fetchCIData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: state.selectedServiceEnv.id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (state.multiple_build_enabled) {
            requestInfo.endPoint = GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: state.selectedServiceEnv.id }, properties.api.get_ci_child)
        }
        InvokeApi(requestInfo, onFetchCIDataSuccess, onFetchCIDataFail)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: true,

        }));
    }

    function onFetchCIDataSuccess(response) {
        setCiDataFromChild(response);
        setState(new_state => ({
            ...new_state,
            ci_response: response,
            ci_data_loading: false,
        }));
    }

   

    function onFetchCIDataFail(response) {
        setState(new_state => ({
            ...new_state,
            ci_response: null,
            ci_data_loading: false,
        }));
    }
    var selected_env_tab = state.selectedServiceEnvTabOrder
    const ref = useRef();

    var url = GenerateURL({ service_id: selectedEnvDetails.service_id }, properties.api.env_data_post_url, true);
    const is_permitted = permission?.POST.includes(url);

    const getSelectedSubEnv = (env_list, tabOrder) => {

       

        if (env_list && env_list.length > 0 && tabOrder != undefined) {
            let selectedSubEnv = env_list[tabOrder]
            console.log(selectedSubEnv.service_env.id)
            let id = (selectedSubEnv && selectedSubEnv.service_env && selectedSubEnv.service_env) ? selectedSubEnv.service_env.id : null
            return id
        }

        return null
    }

    const getConfiguredJobSuccess = (data) => {

        let configureJobsObj = {}

        data && data.forEach(({ job_code, ...item }) => {
            configureJobsObj[job_code] = item
        })

        setConfiguredJobs(configureJobsObj)
        setState(new_state => ({
            ...new_state,
            loading_configured_jobs: false
        }))
    }

    const getConfiguredJobFailure = (error) => {

      
        setState(new_state => ({
            ...new_state,
            loading_configured_jobs: false,
            configuration_error: error
        }))
    }

    const getConfiguredJobs = () => {

        if (selectedEnvDetails && selectedEnvDetails.serviceId != null && selectedSubEnv != null) {

            var requestInfo = {
                endPoint: GenerateURL({ component_id: selectedEnvDetails.serviceId, sub_env_id: selectedSubEnv }, properties.api.configure_job_post_step_data),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, getConfiguredJobSuccess, getConfiguredJobFailure);

            setState(new_state => ({
                ...new_state,
                loading_configured_jobs: true
            }))
        }
    }



    const handleOnContinueForStepWizard = (targetJobData) => {

        if (targetJobData) {
            let { jobData, previousConfigData } = targetJobData;
            console.log(previousConfigData, 'prvious_configsdsd', jobData);
            let selectedJobSteps = jobData['steps']
            let data = {}
            data['job_code'] = jobData.job_code
            data['job_name'] = jobData.job_name
            data['selected_job_steps'] = selectedJobSteps

            setSelectedTemplateAndJobData(prevState => ({
                ...prevState,
                selectedJobData: data,
                previousConfiguration: previousConfigData
            }))
            setComponentView('stepsWizardView')
        }
    }

  

    const handleCloseDialog = () => {
        setState(new_state => ({
            ...new_state,
            deploy_action_card: false
        }))
    }
    const handleCloseTriggerBuildPromoteDialog = () => {
        setState(new_state => ({
            ...new_state,
            build_action_card: false
        }))
    }
    const handleCloseTriggerPromoteDialog = () => {
        setState(new_state => ({
            ...new_state,
            promote_action_card: false
        }))
    }
    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
       
        var filter_current_cd_data = cddata.allConfiguredCd.filter(item => item.deployment_name == value);
        
        if (filter_current_cd_data == null) {
            setDeployDetailsTab(6)
        }
        setcdData({
            ...cddata,
            data: {
                ...state.data,
                [key]: value,

            },
            error: {
                ...state.error,
                [key]: null,
            },
            current_cd_data: filter_current_cd_data.length > 0 ? filter_current_cd_data : null
        });
        setState(new_state => ({
            ...new_state,
            env_tab_change: true,

        }));

    }
    const onClickAddNewDployment = () => {
       
        if (cddata && cddata.current_cd_data) {
            if (cddata.current_cd_data[0]?.manifest_meta_data) {
               
                setBpFormEnabled(false)
                setManifestFormEnabled(true)
            } else {
                setBpFormEnabled(true)
                setManifestFormEnabled(false)
            }
            if (deployDetailsTab != 6) {
                setDeployDetailsTab(6)
            } else {
                setDeployDetailsTab(1)
            }
        } else {
            if (deployDetailsTab != 6) {
                setDeployDetailsTab(6)
            } else {
                setDeployDetailsTab(1)
            }
        }
    }
   

    var url = GenerateURL({ service_id: selectedEnvDetails?.service_id, env_id: state.selectedServiceEnv?.id }, properties.api.env_build_request_url, true);
    const is_build_permited = permission?.POST.includes(url);

    var deploy_url = GenerateURL({ service_id: selectedEnvDetails?.service_id, env_id: state.selectedServiceEnv?.id }, properties.api.env_deploy_request_url, true);
    const is_deploy_permited = permission?.POST.includes(deploy_url);

    var promote_url = GenerateURL({ service_id: selectedEnvDetails?.service_id, env_id: state.selectedServiceEnv?.id }, properties.api.env_build_request_url, true);
    const is_promote_permited = permission?.POST.includes(promote_url);

    function setCiDataFromChild(data) {
        setState((new_state) => ({
            ...new_state,
            complete_ci_data: data,
        }))
    }


    return (
        <>

            {!state.no_env_flag == true ?
                <>
                    <Grid item xs={12} md={12} lg={12}>
                        <div style={{ width: "330px", background: '#fff', position: 'absolute', top: '13px', right: "250px" }}>
                            <EnvSelectorNew envTabList={state.masterEnvTabList}
                                selectedEnvTab={state.selectedMasterEnvTabOrder}
                                envChangeHandler={updateMasterEnvTabSelection}
                                componentEnvTabList={sub_env_list}
                                selectedComponentEnv={selected_env_tab}
                                componentEnvChangeHandler={updateSelectedServiceEnvTab}
                            />
                        </div>
                        <div>
                            <button
                                onClick={handleOpenAddEnvDialog}
                                
                                style={{
                                    position: 'absolute', top: '17px', right: "90px", border: '1px solid #9DC0EE', borderRadius: '6px', width: '150px',
                                    padding: '8px 16px 8px 12px',
                                    backgroundColor: '#fff'
                                }}>
                                <div
                                   

                                    className="text-anchor-blue d-flex align-center font-12 text-right justify-center" style={{ color: '#124D9B' }}><AddIcon style={{ fontSize: '18px' }} />Add Environment</div>
                            </button>
                         
                        </div>

                        <>
                            {
                                state.deploy_action_card &&

                                <DeployActionCardNew
                                    configuration_data={state.ci_cd_data}
                                    closebox={closeCard}
                                    refresh={refreshGrid}
                                    cd_data={state.cd_response}
                                    deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                    available_settings={state.cd_settings}
                                    system_settings={state.available_settings}
                                    refreshCount={state.refresh_count}
                                    top_summary_data={{
                                        service_name: service_name,
                                        env_name: state.selectedServiceEnv.project_env.name,
                                        type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,
                                        is_env_down: state.selectedServiceEnv?.project_env?.is_env_down
                                    }}
                                    extraProps={
                                        {
                                            cluster_id: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.cluster_id ?
                                                state.selectedServiceEnv.project_env.cluster_id : null,
                                            namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : null : null,
                                            service_name: service_name,
                                            project_env_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.name ?
                                                state.selectedServiceEnv.project_env.name : "NoName",
                                            environment_master_name: (properties.envs[state.selectedServiceEnv.project_env ?
                                                state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label).toLowerCase()
                                        }}
                                    service_data={state.selectedServiceEnv}
                                    close_panel={closeActionCard}
                                    application_id={selectedEnvDetails.applicationId}
                                    service_id={selectedEnvDetails.service_id}
                                    env_id={state.selectedServiceEnv.id}
                                    service_env_name={service_env_name}
                                    deploy_action_card={state.deploy_action_card}
                                    handleCloseDialog={handleCloseDialog}
                                    all_available_deployments={
                                        state.cd_response
                                    }
                                />

                            }
                            {
                                state.action_card ?
                                    <TriggerActionCardNew
                                        configuration_data={state.ci_cd_data}
                                        closebox={closeCard}
                                        refresh={refreshGrid}
                                        ci_data={state.ci_response}
                                        complete_ci_data={state.complete_ci_data}
                                        deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                        available_settings={state.cd_settings}
                                        system_settings={state.available_settings}
                                        refreshCount={state.refresh_count}
                                        top_summary_data={{
                                            service_name: service_name,
                                            env_name: state.selectedServiceEnv.project_env.name,
                                            type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,
                                            is_env_down: state.selectedServiceEnv?.project_env?.is_env_down
                                        }}
                                        disable_parallel_build={state.disable_parallel_build ? state.disable_parallel_build : null}
                                        extraProps={
                                            {
                                                cluster_id: state.selectedServiceEnv.project_env ?
                                                    state.selectedServiceEnv.project_env.cluster_id : 1,
                                                namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                    state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : "dev" : null,
                                                service_name: service_name,
                                                project_env_name: state.selectedServiceEnv.project_env ?
                                                    state.selectedServiceEnv.project_env.name : "NoName",
                                                environment_master_name: (properties.envs[state.selectedServiceEnv.project_env ?
                                                    state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label).toLowerCase()
                                            }}
                                        service_data={state.selectedServiceEnv}
                                        close_panel={closeActionCard}
                                        application_id={selectedEnvDetails.applicationId}
                                        service_id={selectedEnvDetails.service_id}
                                        env_id={state.selectedServiceEnv.id}
                                        service_env_name={service_env_name}
                                        build_action_card={state.action_card}
                                        all_available_deployments={state.cd_response}
                                        handleCloseDialog={handleCloseTriggerBuildPromoteDialog}
                                        multiple_build_enabled={state.multiple_build_enabled}
                                        service_env={state.selectedServiceEnv}
                                    />
                                    : null
                            }
                            {
                                state.promote_action_card &&
                                <PromoteActionCard
                                    configuration_data={state.ci_cd_data}
                                    closebox={closeCard}
                                    promote_action_card={state.promote_action_card}
                                    handleCloseDialog={handleCloseTriggerPromoteDialog}
                                    refresh={refreshGrid}
                                    deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                    cd_data={state.cd_response}
                                    available_settings={state.cd_settings}
                                    refreshCount={state.refresh_count}
                                    top_summary_data={{
                                        service_name: service_name,
                                        env_name: state.selectedServiceEnv.project_env.name,
                                        type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,
                                        is_env_down: state.selectedServiceEnv?.project_env?.is_env_down
                                    }}

                                    extraProps={
                                        {
                                            cluster_id: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.cluster_id ?
                                                state.selectedServiceEnv.project_env.cluster_id : null,
                                            namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : null : null,
                                            service_name: service_name,
                                            project_env_name: state.selectedServiceEnv.project_env ?
                                                state.selectedServiceEnv.project_env.name : "NoName",
                                            environment_master_name: (properties.envs[state.selectedServiceEnv.project_env ?
                                                state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label).toLowerCase()
                                        }}
                                    service_data={state.selectedServiceEnv}
                                    close_panel={closeActionCard}
                                    application_id={selectedEnvDetails.applicationId}
                                    all_available_deployments={
                                        state.cd_response
                                    }
                                    service_id={selectedEnvDetails.service_id}
                                    env_id={state.selectedServiceEnv.id}
                                />
                            }


                            {(componentView === 'jobListing' || componentView === 'stepsWizardView') && <>

                                {
                                    state.template_loading ?
                                        <div className="light-loading-container">
                                            <Loading varient="light" />
                                        </div> :
                                        <>
                                            {/* add template code her */}

                                            {

                                                <>
                                                    {
                                                        state.version === 'v3' ?
                                                        selectedJobTemplate ?
                                                            <JobListingForTemplates
                                                                variant='summary-view'
                                                                getConfiguredJobs={getConfiguredJobs}
                                                                editData={
                                                                    {
                                                                        is_permitted: is_permitted,
                                                                        selectedEnvDetails: selectedEnvDetails,
                                                                        selectedServiceEnv: state.selectedServiceEnv,
                                                                        refreshFun: () => props.refresh(selectedEnvDetails.service_id),
                                                                        api_link: GenerateURL({ service_id: selectedEnvDetails.service_id, env_id: state.selectedServiceEnv.id }, properties.api.env_data_update_url)
                                                                    }
                                                                }
                                                                service_data={
                                                                    {
                                                                        name: service_name,
                                                                        service_env: state.selectedServiceEnv.project_env.name,
                                                                        type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,

                                                                    }
                                                                }
                                                                application_id={application_id}
                                                                selectedServiceEnv={state.selectedServiceEnv}
                                                                masterLabel={properties.envs[state.selectedMasterEnvTabOrder - 1].label}
                                                                //wideget_deployment_name = {state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                                setSnackBar={setState}
                                                                component_env_id={selectedJobTemplate &&
                                                                    selectedJobTemplate.id ?
                                                                    selectedJobTemplate.id : ''}
                                                                component_id={selectedEnvDetails.serviceId}
                                                                sub_env_id={selectedSubEnv}
                                                                jobListing={selectedJobTemplate?.job_template?.jobs}
                                                                configuredJobs={configuredJobs}
                                                                selectedJobTemplate={{ data: selectedJobTemplate }}
                                                                version={state.version}
                                                                handleConfigure={handleOnContinueForStepWizard}
                                                                job_code_queryString={job_code_queryString}
                                                            /> :
                                                            <div className="light-loading-container">
                                                            <Loading varient ="light" />
                                                            </div> :
                                                            state.version == 'v2' ?
                                                                <div style={{ marginTop: '25px' }}>

                                                                    <div style={{ marginTop: '15px', minHeight: '120px', display: 'flex', flexDirection: `${state?.version === 'v2' ? 'column' : 'row'}` }}>
                                                                        {
                                                                            state?.version === 'v2' ?
                                                                                <>
                                                                                    <div className="d-flex space-between border-bottom">
                                                                                        <div style={{ width: '70%' }} className="tabs_only" ref={ref}>
                                                                                            <CommonHorizontalTab
                                                                                                variant="v3-advance-tabs"
                                                                                                selectedTabOrder={otherDeployInfoTab - 1}
                                                                                                tabList={tabListForVersion2}
                                                                                                updateSelectedTab={updateSelectedOtherDeployTab} />
                                                                                        </div>
                                                                                        {
                                                                                            !(state.selectedJobData && Object.keys(state?.selectedJobData)?.length > 0) ?
                                                                                                <div className="font-12 d-flex align-center space-between border-bottom mr-10">

                                                                                                    {state?.version !== 'v3' && <div style={{ display: 'inline-block' }}>
                                                                                                        <div className="btn-group" style={{ gap: "5px" }}>

                                                                                                            {
                                                                                                                state.selectedServiceEnv && state.selectedServiceEnv.manual_build ?
                                                                                                                    state.ci_data_loading && false ?
                                                                                                                        <button className="btn btn-flaticon" >
                                                                                                                            <div className='small-loading'></div></button>
                                                                                                                        :
                                                                                                                        is_build_permited ?
                                                                                                                            <>
                                                                                                                                <Tooltip title="Build" >


                                                                                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={toggleTriggerActionCard}><span className="ri-flashlight-line vertical-align-super"></span></button>

                                                                                                                                </Tooltip>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <Tooltip title="You are not allowed to perform this action" >
                                                                                                                                    <button className="btn-sq-icon-primary d-flex align-center">
                                                                                                                                        <span className="ri-flashlight-line vertical-align-super" style={{ color: '#818078' }}></span>
                                                                                                                                    </button>
                                                                                                                                </Tooltip>
                                                                                                                            </>

                                                                                                                    : null
                                                                                                            }
                                                                                                            {
                                                                                                                state.selectedServiceEnv && state.selectedServiceEnv.manual_deploy ?
                                                                                                                    state.cd_data_loading && false ?
                                                                                                                        <button className="btn btn-flaticon" >
                                                                                                                            <div className='small-loading'></div>
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        is_deploy_permited ?
                                                                                                                            <>
                                                                                                                                <Tooltip title="Deploy">
                                                                                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={toggleDeployActionCard}><span className="ri-upload-2-line vertical-align-super"></span></button>
                                                                                                                                </Tooltip>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <Tooltip title="You are not allowed to perform this action" >
                                                                                                                                    <button className="btn-sq-icon-primary d-flex align-center">
                                                                                                                                        <span className="ri-upload-2-line vertical-align-super" style={{ color: '#818078' }}></span>
                                                                                                                                    </button>
                                                                                                                                </Tooltip>
                                                                                                                            </>
                                                                                                                    : null
                                                                                                            }
                                                                                                            {
                                                                                                                is_promote_permited ?
                                                                                                                    <>
                                                                                                                        <Tooltip title="Promote" >
                                                                                                                            <button className="btn-sq-icon-primary d-flex align-center" onClick={togglePromoteActionCard}><span className="ri-share-forward-line vertical-align-super"></span></button>
                                                                                                                        </Tooltip>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <Tooltip title="You are not allowed to perform this action" >
                                                                                                                            <button className="btn-sq-icon-primary d-flex align-center">
                                                                                                                                <span className="ri-share-forward-line vertical-align-super" style={{ color: '#818078' }}></span>
                                                                                                                            </button>
                                                                                                                        </Tooltip>
                                                                                                                    </>
                                                                                                            }
                                                                                                            <HistoryRevamp
                                                                                                                service_id={selectedEnvDetails.serviceId}
                                                                                                                env_name={state.selectedServiceEnv.project_env.name}
                                                                                                                env_id={state.selectedServiceEnv.id}
                                                                                                                service_data={
                                                                                                                    {
                                                                                                                        name: service_name,
                                                                                                                        service_env: state.selectedServiceEnv.project_env.name,
                                                                                                                        type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,
                                                                                                                        deployment_name: cddata && cddata.current_cd_data && cddata.current_cd_data.length > 0 ? cddata.current_cd_data[0].deployment_name : null
                                                                                                                    }
                                                                                                                }
                                                                                                                deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                                                                                is_env_down={state.selectedServiceEnv?.project_env?.is_env_down}
                                                                                                                childInherits={state.history_child_inherits} />
                                                                                                            {/* <Link target="_blank" to={{
                                                                                                                pathname : `/deployment-analytics`,
                                                                                                                search: `application_id=${encodeURIComponent(selectedEnvDetails.application_id)}&service_id=${encodeURIComponent(selectedEnvDetails.service_id)}&env_id=${encodeURIComponent(state.selectedServiceEnv.id)}&env_name=${encodeURIComponent(state.selectedServiceEnv.project_env.name)}&service_data=${encodeURIComponent(JSON.stringify({ name: service_name, type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label }))}&all_available_deployments=${encodeURIComponent(JSON.stringify( state.deployments_list && state.deployments_list.length > 0 ? state.deployments_list : [] ))}`
                                                                                                            }} 
                                                                                                            >
                                                                                                                <button className="btn-sq-icon-primary d-flex align-center" >
                                                                                                                    <span className="ri-airplay-line vertical-align-super"></span>
                                                                                                                </button>
                                                                                                            </Link> */}
                                                                                                            <Link target="_blank" to={{
                                                                                                                pathname : `/deployment-analytics`,
                                                                                                                search: `application_id=${selectedEnvDetails.application_id}&service_id=${selectedEnvDetails.service_id}&service_name=${service_name}&env_id=${state.selectedServiceEnv.id}&env_name=${state.selectedServiceEnv.project_env.name}&env_type=${properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label}`
                                                                                                            }} 
                                                                                                            >
                                                                                                                <button className="btn-sq-icon-primary d-flex align-center" >
                                                                                                                    <span className="ri-airplay-line vertical-align-super"></span>
                                                                                                                </button>
                                                                                                            
                                                                                                            </Link>
                                                                                                            {/* moved env edit in action 3 dots */}
                                                                                                            {
                                                                                                                // is_permitted ?
                                                                                                                //     <Link
                                                                                                                //         style={{ display: "flex", alignItems: "center" }}
                                                                                                                //         to={{
                                                                                                                //             pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/edit",
                                                                                                                //             state: { edit: true, env_id: state.selectedServiceEnv.id }
                                                                                                                //         }} className=""> {/*text-anchor-blue d-flex align-center text-right btn btn-flaticon */}
                                                                                                                //         <button className='btn-sq-icon-primary'>
                                                                                                                //             <span className='ri-edit-line vertical-align-super'></span>
                                                                                                                //         </button>
                                                                                                                //     </Link> :
                                                                                                                //     <Tooltip title="You are not allowed to perform this action" >
                                                                                                                //         {/* <CreateIcon style={{ color: '#818078' }} /> */}
                                                                                                                //         <button className='btn-sq-icon-primary'>
                                                                                                                //             <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                                                                                                //         </button>
                                                                                                                //     </Tooltip>
                                                                                                            }
                                                                                                            {/* move env delete in action 3 dots */}
                                                                                                            {/* <Delete
                                                                                                                display_data_name={state.selectedServiceEnv ? state.selectedServiceEnv.project_env.name : ""}
                                                                                                                data={{ entity_id: state.selectedServiceEnv.id, name: "component_env" }}
                                                                                                                varient="new_button"
                                                                                                                fontSize="font-18"
                                                                                                                refresh={() => { props.refresh(selectedEnvDetails.service_id) }}
                                                                                                                api_link={GenerateURL({ service_id: selectedEnvDetails.service_id, env_id: state.selectedServiceEnv.id }, properties.api.env_data_update_url)}
                                                                                                            /> */}
                                                                                                        </div>
                                                                                                    </div>}

                                                                                                </div> : null
                                                                                        }

                                                                                    </div>
                                                                                    <div className="box-shadow-none" style={{ borderTop: 0, borderRadius: 0, minHeight: '420px' }}>
                                                                                        <div className="card-body pb-0">
                                                                                            {otherDeployInfoTab === 1 ?
                                                                                                <CiDetailsOverviewFlow
                                                                                                    extraProps={
                                                                                                        {
                                                                                                            cluster_id: state.selectedServiceEnv.project_env ?
                                                                                                                state.selectedServiceEnv.project_env.cluster_id : null,
                                                                                                            namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                                                                                state.selectedServiceEnv.project_env.project_env_namespace.namespace_name : "",
                                                                                                            service_name: service_name,
                                                                                                            project_env_name: state.selectedServiceEnv.project_env ?
                                                                                                                state.selectedServiceEnv.project_env.name : "NoName",
                                                                                                            environment_master_name: properties.envs[state.selectedServiceEnv.project_env ?
                                                                                                                state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label
                                                                                                        }
                                                                                                    }
                                                                                                    service_env={state.selectedServiceEnv}
                                                                                                    selectedEnvDetails={selectedEnvDetails}
                                                                                                    templateName={selectedJobTemplate?.name}
                                                                                                    templateVersion={selectedJobTemplate?.template_version}
                                                                                                    setCiCompleteData={setCiDataFromChild}
                                                                                                    multiple_build_enabled={state.multiple_build_enabled}
                                                                                                /> : null
                                                                                            }
                                                                                            {
                                                                                                otherDeployInfoTab === 2 ?
                                                                                                    <div>

                                                                                                        <div className='border-bottom d-flex space-between'>
                                                                                                            <div style={cddata.availableDeploymentList.length > 1 ? { width: "50%" } : {}}>
                                                                                                                <CommonHorizontalTab
                                                                                                                    selectedTabOrder={deployDetailsTab}
                                                                                                                    tabList={deployDetailsTabList}
                                                                                                                    updateSelectedTab={updateSelectedDeployDetailsTab}
                                                                                                                    variant={cddata.availableDeploymentList.length > 1 ? "scrollable_div" : null}
                                                                                                                    leastWidth={cddata.availableDeploymentList.length > 1 ? 529 : null} />
                                                                                                            </div>

                                                                                                            {
                                                                                                                cddata.allConfiguredCd && cddata.allConfiguredCd[0] ?
                                                                                                                    <div className='d-flex align-center space-between border-bottom'

                                                                                                                    >
                                                                                                                        {
                                                                                                                            deployDetailsTab != 6 ?
                                                                                                                                cddata.availableDeploymentList.length > 1 ?
                                                                                                                                    <div className='d-grid align-center mb-0 input-mb-0' style={{ width: '265px', marginLeft: 10, gridTemplateColumns: '1fr' }}>
                                                                                                                                        {/* <div className="font-11">Select Deployment: </div> */}
                                                                                                                                        <Input
                                                                                                                                            type="select-for-multideployment"
                                                                                                                                            name="deployment_name"
                                                                                                                                            list={cddata.availableDeploymentList}
                                                                                                                                            onChangeHandler={onChangeHandler}
                                                                                                                                            label=""
                                                                                                                                            remove_default_value={false}
                                                                                                                                            select_default_value={"Please Select Deployment"}
                                                                                                                                            data={cddata.data}
                                                                                                                                            error={cddata.error}

                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                    : null
                                                                                                                                : null
                                                                                                                        }


                                                                                                                        <button className='btn text-anchor-blue d-flex align-center' onClick={onClickAddNewDployment}>
                                                                                                                            <span className="material-icons material-symbols-outlined">
                                                                                                                                add
                                                                                                                            </span>
                                                                                                                            <span className="btn-link">Setup Other Deployment</span>
                                                                                                                        </button>
                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                            <button
                                                                                                                                className="btn btn-with-icon btn-round"
                                                                                                                                onClick={refreshGrid}
                                                                                                                            >
                                                                                                                                <span className="flaticon-refresh-button-1"></span>
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div> : null}
                                                                                                        </div>
                                                                                                        {
                                                                                                            deployDetailsTab === 1 &&
                                                                                                            <CdDetailsOverviewFlow
                                                                                                                extraProps={
                                                                                                                    {
                                                                                                                        cluster_id: state.selectedServiceEnv.project_env ?
                                                                                                                            state.selectedServiceEnv.project_env.cluster_id : null,
                                                                                                                        namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                                                                                            state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : null : null,
                                                                                                                        service_name: service_name,
                                                                                                                        project_env_name: state.selectedServiceEnv.project_env ?
                                                                                                                            state.selectedServiceEnv.project_env.name : "NoName",
                                                                                                                        environment_master_name: (
                                                                                                                            properties.envs[state.selectedServiceEnv.project_env ?
                                                                                                                                state.selectedServiceEnv.project_env.environment_master_id - 1 :
                                                                                                                                0].label).toLowerCase()
                                                                                                                    }
                                                                                                                }
                                                                                                                available_settings={state.cd_settings}
                                                                                                                refresh_count={state.refresh_count}
                                                                                                                change_in_env_tab={state.env_tab_change}
                                                                                                                manifestFormEnabled={true}
                                                                                                                bpFormEnabled={true}
                                                                                                                all_deployment_list={
                                                                                                                    cddata.allConfiguredCd.length > 0 ?
                                                                                                                        cddata.allConfiguredCd.map(item => {

                                                                                                                            return { label: item.deployment_name, id: item.id }

                                                                                                                        }) : []
                                                                                                                }
                                                                                                                service_env={state.selectedServiceEnv}
                                                                                                                selectedEnvDetails={selectedEnvDetails}
                                                                                                                cd_data={cddata.current_cd_data}
                                                                                                                refreshCallbackFn={refreshGrid}
                                                                                                                multiple_build_enabled={state.multiple_build_enabled}
                                                                                                            />
                                                                                                        }
                                                                                                        {
                                                                                                            deployDetailsTab === 5 &&
                                                                                                            <IntegrationsTesting
                                                                                                                change_in_env_tab={state.env_tab_change}
                                                                                                                service_env={state.selectedServiceEnv}
                                                                                                                selectedEnvDetails={selectedEnvDetails}
                                                                                                            />
                                                                                                        }
                                                                                                        {
                                                                                                            deployDetailsTab == 2 ?
                                                                                                                <OtherDeploymentDetails
                                                                                                                    extraProps={
                                                                                                                        {
                                                                                                                            cluster_id: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.cluster_id ?
                                                                                                                                state.selectedServiceEnv.project_env.cluster_id : 1,
                                                                                                                            namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                                                                                                state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : null : null,
                                                                                                                            service_name: service_name,
                                                                                                                            project_env_name: state.selectedServiceEnv.project_env ?
                                                                                                                                state.selectedServiceEnv.project_env.name : "",
                                                                                                                            environment_master_name:
                                                                                                                                (state.selectedServiceEnv.project_env ? properties.envs[
                                                                                                                                    state.selectedServiceEnv.project_env.environment_master_id - 1].label : properties.envs[1]).toLowerCase()
                                                                                                                        }
                                                                                                                    }
                                                                                                                    cd_data={cddata.current_cd_data}
                                                                                                                    service_env={state.selectedServiceEnv}
                                                                                                                    selectedEnvDetails={selectedEnvDetails}
                                                                                                                    complete_cd_data={state.cd_data}
                                                                                                                    selected_deployment_name={cddata?.data?.deployment_name}
                                                                                                                />
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        {
                                                                                                            deployDetailsTab === 3 ?
                                                                                                                <ManifestView
                                                                                                                    extraProps={{ project_env_id: state.selectedServiceEnv.project_env_id, service_name: state.service_name, selected_deployment_name: cddata?.data?.deployment_name }}
                                                                                                                />
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        {
                                                                                                            deployDetailsTab == 6 &&
                                                                                                            <>
                                                                                                                <div className='d-flex align-center justify-flex-end'>

                                                                                                                    <IconButton style={{ marginRight: 10 }} onClick={onClickAddNewDployment} className="ml-auto d-block mr-10"><CloseIcon className="font-18" /></IconButton>

                                                                                                                </div>
                                                                                                                <CdDetailsOverviewFlow
                                                                                                                    extraProps={
                                                                                                                        {
                                                                                                                            cluster_id: state.selectedServiceEnv.project_env ?
                                                                                                                                state.selectedServiceEnv.project_env.cluster_id : null,
                                                                                                                            namespace_name: state.selectedServiceEnv.project_env && state.selectedServiceEnv.project_env.project_env_namespace ?
                                                                                                                                state.selectedServiceEnv.project_env.project_env_namespace.namespace && state.selectedServiceEnv.project_env.project_env_namespace.namespace.name ? state.selectedServiceEnv.project_env.project_env_namespace.namespace.name : null : null,
                                                                                                                            service_name: service_name,
                                                                                                                            project_env_name: state.selectedServiceEnv.project_env ?
                                                                                                                                state.selectedServiceEnv.project_env.name : "NoName",
                                                                                                                            environment_master_name: (
                                                                                                                                properties.envs[state.selectedServiceEnv.project_env ?
                                                                                                                                    state.selectedServiceEnv.project_env.environment_master_id - 1 :
                                                                                                                                    0].label).toLowerCase()
                                                                                                                        }
                                                                                                                    }
                                                                                                                    available_settings={state.cd_settings}
                                                                                                                    change_in_env_tab={state.env_tab_change}
                                                                                                                    service_env={state.selectedServiceEnv}
                                                                                                                    selectedEnvDetails={selectedEnvDetails}
                                                                                                                    manifestFormEnabled={manifestFormEnabled}
                                                                                                                    bpFormEnabled={bpFormEnabled}
                                                                                                                    all_deployment_list={
                                                                                                                        cddata.allConfiguredCd.length > 0 ?
                                                                                                                            getArrAndGroupRepeatedDeploymentName(cddata.allConfiguredCd) : []
                                                                                                                    }
                                                                                                                    cd_data={[]} />
                                                                                                            </>

                                                                                                        }

                                                                                                    </div>

                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                otherDeployInfoTab > 2 ?
                                                                                                    <>
                                                                                                        {
                                                                                                            state?.selectedJobData && Object.keys(state?.selectedJobData)?.length > 0 &&
                                                                                                            <JobSummaryCardForDynamicJobs
                                                                                                                getConfiguredJobs={getConfiguredJobs}
                                                                                                                allJobs={state.dynamicJobs}
                                                                                                                // service_data={service_data}
                                                                                                                service_data={
                                                                                                                    {
                                                                                                                        name: service_name,
                                                                                                                        service_env: state.selectedServiceEnv.project_env.name,
                                                                                                                        type: properties.envs[state.selectedServiceEnv.project_env ? state.selectedServiceEnv.project_env.environment_master_id - 1 : 0].label,

                                                                                                                    }
                                                                                                                }
                                                                                                                wholeJob={state?.selectedJobData?.wholeJob}
                                                                                                                fetchLastTriggeredData={() => { }}
                                                                                                                // setSnackBar={setSnackBar}
                                                                                                                setSnackBar={setState}
                                                                                                                application_id={application_id}
                                                                                                                selectedServiceEnv={state.selectedServiceEnv}
                                                                                                                // wideget_deployment_name={wideget_deployment_name}
                                                                                                                masterLabel={properties.envs[state.selectedMasterEnvTabOrder - 1].label}
                                                                                                                configuredJobs={configuredJobs}
                                                                                                                component_env_id={selectedJobTemplate &&
                                                                                                                    selectedJobTemplate.id ?
                                                                                                                    selectedJobTemplate.id : ''}
                                                                                                                component_id={selectedEnvDetails.serviceId}
                                                                                                                sub_env_id={selectedSubEnv}
                                                                                                                actions={{
                                                                                                                    handleConfigure: handleOnContinueForStepWizard
                                                                                                                }}
                                                                                                                configuredData={configuredJobs && configuredJobs[state?.selectedJobData?.wholeJob.job_code]}
                                                                                                                data={state?.selectedJobData?.data}
                                                                                                                job_code_queryString={job_code_queryString}
                                                                                                                loading_configured_jobs={state.loading_configured_jobs}
                                                                                                            />
                                                                                                        }
                                                                                                    </>
                                                                                                    // <JobSummaryCardForDynamicJobs />
                                                                                                    : null
                                                                                            }

                                                                                        </div>
                                                                                    </div>

                                                                                </> :
                                                                                <div style={{ width: '100%', fontSize: '12px' }} className='d-flex align-center justify-center'>
                                                                                    {/* <label>No Template, Please Add One</label> */}
                                                                                </div>
                                                                        }

                                                                    </div>

                                                                </div> : <div style={{ background: "#fff", width: '100%', height: "550px" }}>

                                                                </div>
                                                    }
                                                </>

                                            }

                                        </>

                                }

                            </>
                            }
                            {
                                componentView === 'templateList' && < AddJobTemplateToServices component_id={selectedEnvDetails.serviceId} selectedSubEnv={selectedSubEnv} setComponentView={setComponentView} />
                            }
                        </>
                    </Grid>
                    {
                        componentView === 'stepsWizardView' &&
                        <JobConfigureDisplay
                            isOpen={componentView === 'stepsWizardView'}
                            configuredJobDataForEdit={selectedTemplateAndJobData?.previousConfiguration}
                            onSucces={jobConfigureSuccess}
                            component_id={selectedEnvDetails.serviceId}
                            selectedSubEnv={selectedSubEnv}
                            selectedJobData={selectedTemplateAndJobData?.selectedJobData}
                            setComponentView={setComponentView}
                        />
                    }

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={state.jobTrigger}
                        onClose={handleClose}
                        autoHideDuration={4000}>
                        <Alert severity="success">
                            {"SUCCESSFULLY JOB TRIGGERED!"}
                        </Alert>
                    </Snackbar>

                </>
                : <NoEnvAvailable component_id={selectedEnvDetails.service_id} application_id={selectedEnvDetails.applicationId} />}
        </>
    )
})

NewCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const getArrAndGroupRepeatedDeploymentName = (arr) => {
    console.log(arr, "arr_of_data")
    var final_list = [];
    arr.forEach(item => {
        if (final_list.length > 0) {
            let foundDuplicate = final_list.find(single_item => single_item.label == item.deployment_name);
            console.log(foundDuplicate, "arr_of_data")
            if (!foundDuplicate) {
                final_list.push({ id: item.deployment_name, label: item.deployment_name, deployment_id: item.id })
            }
        } else {
            final_list.push({ id: item.deployment_name, label: item.deployment_name, deployment_id: item.id })
        }
    })
    console.log(final_list, "arr_of_data")
    return final_list
}
const useStyles = makeStyles((theme) => ({
    versionBox: {
        display: 'inline-block',
        marginLeft: '6px',
        marginRight: '6px',
        width: '20px',
        height: '23px',
        textAlign: 'center',
        lineHeight: '28px',
        borderRadius: '4px',
        backgroundColor: '#fec835',
        color: '#ffffff',
        fontSize: '9px',
        fontWeight: '600',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginTop:64,
        '& span': {
            fontSize: '12px',
            marginLeft: '1px',
        },
    },

}));