import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import GenerateURL from '../../../../util/APIUrlProvider';
import { GetAuth } from '../../../../util/security';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { GenericValidator, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { Loading, LoadingText } from '../../../utils/Loading';
import { Input } from '../../../../components/genericComponents/Input';
import CurrentProgressData from '../../listing/components/CurrentProgressData';
import LatestDeployStrip from '../../listing/components/LatestDeployStrip';
import { evaluateAgentStatus } from '../../../../util/util';
import BpAgentStatusActionAlert from '../../../../components/genericComponents/BpAgentStatusActionAlert';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import LatestDeployStripNew from '../../listing/components/LatestDeployStripNew';
import SearchDropdownComponent from '../../../../components/genericComponents/SearchDropdownComponent.js/SearchDropdownComponent';
import { nullOptions } from 'yaml/types';
import Zoom from '@material-ui/core/Zoom';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';

const DeployActionCardNew = (props) => {
    const type = "DEPLOY";
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const top_summary_data = props.top_summary_data;
    const env = service_data.env;
    const configuration_data = props.configuration_data;
    const deploy_action_card = props.deploy_action_card;
    const handleCloseDialog = props.handleCloseDialog;
    const is_env_down = props.is_env_down ? props.is_env_down : null
    const deploy_env_offline_flag = props.deploy_env_offline_flag ? props.deploy_env_offline_flag : null;

    const all_available_deployments = props.all_available_deployments && props.all_available_deployments.length > 0 ?
        props.all_available_deployments.map(item => {
            return { label: item.deployment_name ? item.deployment_name : item.label, id: item.id }
        }) : []
    console.log(all_available_deployments, "all_available_deploymentsall_available_deployments")
    const env_name = props.env_name;
    const masterLabel = props.masterLabel;
    const env_details = props.env_details;
    const system_settings = props.system_settings;
    const canary_flag = system_settings.find((sys) => sys.key == "CANARY_DEPLOYMENT");
    console.log("bhejfhds", system_settings, canary_flag);
    const service_env_name = props.service_env_name;
    var cd_data = props.cd_data ? props.cd_data : [];
    var cd_approved_data = {};
    if (cd_data) {
        cd_data.map(item => item.status == "APPROVED" ? cd_approved_data = item : null)
    }
    // var check_if_template_selected = cd_approved_data && cd_approved_data.env_cd_deployment_strategy ? cd_approved_data.env_cd_deployment_strategy.release_template_id ? cd_approved_data.env_cd_deployment_strategy.release_template_id : null : null;
    var check_if_template_selected = true;
    const env_list = [];
    const [cloneList, setcloneList] = useState([])
    var url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url, true);
    const is_permited = useFetchPermissions()?.POST.includes(url);
    const selected_cd_data = props.selected_cd_data ? props.selected_cd_data : null;

    const [state, setState] = useState({
        tag: "",
        event_source: null,
        data: {
            deployment_type: 1,
            deployment_name: [],
        },
        deploy_tag: "",
        tag_loaded_status: false,
        errors: {},
        refresh_count: 1,
        open: false,
        visible: true,
        visibleStrip: true,
        show_bp_agent_warning: false,
        alert_loading: false,
        down_bp_agent_names: null,
        open: false,
        branch_name: null,
        openBranch: false,
    });
    var flag_for_feature_tag = properties.flag_for_feature_id
    // Logic to form env->subenv list
    console.log("cdshjs", cd_data, service_data);
    if (env) {
        env.forEach(element => {
            if (element.id != env_id) {
                env_list.push({
                    id: element.id,
                    label: element.env_master.name + "->" + element.env_name
                });
            }
        });
    }
    function getEnvNamefromId() {
        var env_data = {};
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_data.env_name = item.project_env.name
                    env_data.env_type = service_data.type
                }
            })
        }
        return env_data;
    }

    useEffect(() => {
        if (cd_data && cd_data.length > 0) {
            if (cd_data[0].manifest_meta_data && cd_data[0].manifest_meta_data.manifest_git_repo) {
                testAndGetBranches(cd_data[0].manifest_meta_data.manifest_git_repo.git_url, cd_data[0].manifest_meta_data.manifest_git_repo.credential_id)
            }
            if (cd_data[0].manifest_meta_data && cd_data[0].manifest_meta_data.helm_git_repo) {
                testAndGetBranches(cd_data[0].manifest_meta_data.helm_git_repo.git_url, cd_data[0].manifest_meta_data.helm_git_repo.credential_id)
            }
        }
    }, [])

    useEffect(() => {
        if (type == "DEPLOY" && is_permited) {
            const placeholders = {
                service_id: service_id,
                env_id: env_id,
                cd_id: all_available_deployments[0] && all_available_deployments[0].id ? all_available_deployments[0].id : null
            }
            var requestInfo = {
                endPoint: GenerateURL(placeholders, properties.api.env_build_tags_list_url),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            getCloneEnvList()
            InvokeApi(requestInfo, handleSuccessTagsDataLoad, handleFailedTagsDataLoad);
            setState(prevState => ({
                ...prevState,
                tag_loaded_status: false,
            }))
        }
        return (() => {
            state.event_source && state.event_source.close()
        })
    }, [type, env_id, is_permited]);



    function handleSuccessTagsDataLoad(response) {
        const tags = response.tags;
        var tag_list = [];
        tags.forEach(tag => {
            tag_list.push({
                id: tag,
                label: tag,
            })
        });
        setState(prevState => ({
            ...prevState,
            tag_loaded_status: true,
            tag_list: tag_list,
        }))
    }

    function handleFailedTagsDataLoad(error) {

        setState(prevState => ({
            ...prevState,
            tag_loaded_status: false,
            tag_load_error: error
        }));
    }

    function successBuildTriggerCallBack(response) {

        setState(prevState => ({
            ...prevState,
            trigger: "success",
            feature_tag: "",
            deploy_tag: "",
            deployment_status_check: false,
            deployData: null,
            show_bp_agent_warning: false,
            alert_loading: false,
            data: {
                deployment_type: 1,
            }
        }))
        // setState({
        //     ...state,
        //     trigger: "success",
        //     feature_tag: "",
        //     // deploy_tag:"",
        //     deployment_status_check: false,
        //     deployData:null,
        //     show_bp_agent_warning: false,
        // });
        refreshGrid();
        // props.closebox()
    }

    function failedBuildTriggerCallBack(error) {
        console.log(error, "anbcjsdvs")
        setState({
            ...state,
            trigger: "failed",
            error_msg: error.error ? error.error : "",
            deployData: null,
            show_bp_agent_warning: false,
            alert_loading: false,
        });
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }

    function getCloneEnvList() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: props.service_id }, properties.api.get_clone_env_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        // setShowLoading(true);
        InvokeApi(requestInfo, onFetchCloneSuccess, onFetchCloneFail)
    }
    function onFetchCloneFail() {
        // setShowLoading(false)
    }
    function onFetchCloneSuccess(response) {
        // setShowLoading(false)
        var currentEnvironment = null
        var promote_possible_env_list = JSON.parse(localStorage.getItem("allow_promote_data"));
        promote_possible_env_list = Object.keys(promote_possible_env_list).length > 0 ? promote_possible_env_list : null;
        if (promote_possible_env_list) {
            response.forEach((env, key) => {
                if (env.id == env_id) {
                    currentEnvironment = env
                }
            })
            var possible_promote_list = promote_possible_env_list[currentEnvironment.env_master_name]

            var list = [];
            response.forEach((env, key) => {
                if (possible_promote_list.includes(env.env_master_name)) {
                    list.push({ id: env.id, label: env.project_env.name })
                }
            })
        } else {
            var list = [];
            response.forEach(env => {
                if (env.env_cd_detail && env.id != env_id) {
                    list = [...list, {
                        id: env.id,
                        label: env.project_env.name
                    }]
                }
            })
        }


        if (list.length > 0) {
            setcloneList(list)
            //  setIsCloneVisible(true);
        }
        else {
            //  setRedirect(true);
        }
    }
    const compareAndGetSimilarIds = (selected_data_arr, available_arr) => {
        console.log(selected_data_arr, "selected_data_arrselected_data_arr", available_arr)
        let result = [];
        for (let i = 0; i < selected_data_arr?.length; i++) {
            for (let j = 0; j < available_arr.length; j++) {
                if (selected_data_arr[i].id == available_arr[j].id) {
                    result.push(available_arr[j].label);
                }
            }
        }

        return result;
    }



    function validateAndTrigger(type) {
        var error_deploy_tag = GenericValidator(state.deploy_tag, [VALIDATION_TYPE_REQUIRED]);
        if (flag_for_feature_tag) {
            var error_feature_tag = GenericValidator(state.feature_tag, [VALIDATION_TYPE_REQUIRED]);
        }
        if (state.data.deployment_type == 2) {
            var error_pod_shift = GenericValidator(state.pod_shift, [VALIDATION_TYPE_REQUIRED]);
        }

        if (error_deploy_tag || error_feature_tag) {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    deploy_tag: error_deploy_tag,
                    feature_tag: error_feature_tag,
                }
            });
        } else {
            if (all_available_deployments && all_available_deployments.length > 1) {

                var selected_deploy_labels = compareAndGetSimilarIds(state.data.deployment_name, all_available_deployments);
                console.log(selected_deploy_labels, "sdfsdfsdfsfsdf")
                var data = {
                    tag: state.deploy_tag,
                    deployment_status_check: state.deployment_status_check == "on" ? true : false,
                    deployment_type: state.data.deployment_type == 2 ? "CANARY" : "ROLLING_UPDATE",
                    feature_tag: state.feature_tag,
                    deployment_name: selected_deploy_labels,
                    pod_shift: Number(state.pod_shift)
                }
            } else {
                console.log(state, "fdsalfsjkafsa")

                var data = {
                    tag: state.deploy_tag,
                    deployment_status_check: state.deployment_status_check == "on" ? true : false,
                    deployment_type: state.data.deployment_type == 2 ? "CANARY" : "ROLLING_UPDATE",
                    feature_tag: state.feature_tag,
                    pod_shift: Number(state.pod_shift),
                    manifest_branch_name: state.branch_name
                }
            }

            if (!data.feature_tag) {
                delete data.feature_tag;
            }
            if (!data.pod_shift) {
                delete data.pod_shift
            }

            const resultOfEvalution = evaluateAgentStatus(null, 'deploy')
            if (resultOfEvalution && resultOfEvalution.type === "unHealthy") {
                setState(prevState => ({
                    ...prevState,
                    show_bp_agent_warning: true,
                    deployData: data
                }))
            }
            else {
                setState((prevState) => ({
                    ...prevState,
                    alert_loading: true
                }))
                var cd_id = all_available_deployments[0] && all_available_deployments[0].id ? all_available_deployments[0].id : null
                if (all_available_deployments && all_available_deployments.length > 1) {
                    var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
                } else {
                    var endPoint = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.env_single_deploy_request_url);
                }

                PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack, false, true);
            }

        }

    }

    const TriggerDeployByAlert = () => {
        var selected_deploy_labels;
        if (all_available_deployments && all_available_deployments.length > 1) {
            selected_deploy_labels = compareAndGetSimilarIds(state.data.deployment_name, all_available_deployments);
        } else {
            selected_deploy_labels = []
        }
        let postData = {
            ...state.deployData,
            deployment_name: selected_deploy_labels,
            manifest_branch_name: state.branch_name
        }
        console.log("akkaal", postData);
        setState((prevState) => ({
            ...prevState,
            alert_loading: true,
        }))
        if (postData) {
            var cd_id = all_available_deployments[0] && all_available_deployments[0].id ? all_available_deployments[0].id : null
            if (all_available_deployments && all_available_deployments.length > 1) {
                var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
            } else {
                var endPoint = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.env_single_deploy_request_url);
            }
            // var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
            PostData(endPoint, postData, successBuildTriggerCallBack, failedBuildTriggerCallBack);
        }
    }

    const onChangeHandlerNumber = (e) => {

        if (e.target.name == "pod_shift") {
            setState({
                ...state,
                [e.target.name]: Number(e.target.value),
                errors: {
                    ...state.errors,
                    [e.target.name]: "",
                }
            });
        }
    }


    const onChangeHandler = (e) => {

        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            setState({
                ...state,
                [e.target.name]: e.target.value,
                errors: {
                    ...state.errors,
                    [e.target.name]: "",
                }
            });
        }
    }

    const onBranchChangeHandler = (e) => {
        console.log(e.target.name, e.target.value, "sbvfgdsfhjvbdsf")
        console.log(e.target.name, e.target.value, "shbdhbdshbchsdbc")
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            console.log(e.target.value, "hjdbhsbcjhsdbchsd")
            var name = e.target.name
            var value = e.target.value
            setState((new_state) => ({
                ...new_state,
                [name]: value,
                // errors: {
                //     ...new_state.errors,
                //     [name]: "",
                // }
            }));
        }
    }

    const onParentKeyValueChangeHandler = (k, v) => {
        setState((new_state) => ({
            ...new_state,
            [k]: v,
            // errors: { ...new_state.errors, [k]: "" }
        }));
    }

    function onChangeHandlerForKeyValueUpdated(key, value) {
        // Convert values to numbers if necessary
        if (key === "deployment_name") {
            value = value.map(id => Number(id));
        }

        console.log(key, "FDsafjsajjfksajk")
        if (key === "deployment_name") {

            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [key]: value,
                    // deployment_name: defaultAllSelected(updatedUserIdList)
                },
                user_id_list: updatedUserIdList, // Update user_id_list here
                errors: {
                    ...prevState.errors,
                    [key]: null
                }
            }));
        } else {
            // For other keys, just update the state without affecting user_id_list
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [key]: value
                },
                error: {
                    ...prevState.error,
                    [key]: null
                }
            }));
        }
    }
    function defaultAllSelected(users) {
        var selected = [];
        users.map(user => {
            selected.push(user.id)
        })
        return selected
    }
    function onChangeHandlerForKeyValue(key, value) {
        console.log(key, value, "ksjdhfihsdfh")
        // if (key == "deployment_name") {
        //     value.forEach((id, index) => {
        //         console.log(id, "fsdjajjasf")
        //         value[index] = Number(id);
        //     });
        // }
        setState({
            ...state,
            [key]: value,
            errors: { ...state.errors, [key]: "" }
        });
    }
    console.log(state, "fsdjajjasf");


    const onKeyValueChangeHandler = (k, v) => {

        setState({
            ...state, [k]: v,
            error: { ...state.error, [k]: "" }
        });
    }
    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }
    function handleToggle() {
        setState({
            ...state,
            visible: !state.visible,
        });
    }

    const strategy_list = [{ value: 1, label: 'Rolling' }, { value: 2, label: 'Canary' }]
    function onChangeHandlerRadio(e) {
        const key = e.target.name;
        var value = e.target.value;
        updateData(key, value);
    }
    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }))
    }
    function checkForFeatureTag() {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem("feature_tag", flag_for_feature_tag);
            var feature_tag = localStorage.getItem("feature_tag");
            feature_tag = JSON.parse(feature_tag) === true;
            if (feature_tag) {
                return (
                    <div className={type == "DEPLOY" ? "deploy_class" : ""}>
                        <Input
                            type="text"
                            mandatorySign={type == "BUILD" ? false : true}
                            data={{ feature_tag: state.feature_tag }}
                            name="feature_tag"
                            error={{ feature_tag: state.errors.feature_tag }}
                            onChangeHandler={onChangeHandler}
                            label="Feature Id"
                            placeholder="Enter Feature Id"
                        />
                    </div>
                )
            }
        } else {
            return (
                <p>Sorry, your browser does not support Web Storage.</p>
            )

        }
    }

    function refreshGrid() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,

        }));

    }

    function onClose() {
        setState(prevState => ({
            ...prevState,
            show_bp_agent_warning: false,
            alert_loading: false,
            deployData: null
        }))
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;
    console.log(configuration_data, "configuration_data__")

    const getTheSelectedData = () => {
        return {
            data: {
                deployment_list: []
            }, error: {}
        }
    }
    console.log(state, "Fdsfsafsafa")

    function toggleState() {
        setState((new_state) => ({
            ...new_state,
            open: !new_state.open,
            deploy_tag: null
        }));
    }

    function toggleBranchState() {
        setState((new_state) => ({
            ...new_state,
            openBranch: !new_state.openBranch,
            branch_name: null
        }));
    }

    function testAndGetBranches(git_url, credential_id) {
        const post_data = {
            git_url: git_url,
            credential_id: credential_id,
        };
        if (!post_data.credential_id) {
            delete post_data.credential_id;
        }
        if (props.variant == "ServiceCard") {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail, true);
        } else {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail);
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: true,
        }));

    }

    function onFetchSuccess(data) {
        console.log("fjkdsjnfdsahjfhjsa")
        if (data.branches && data.branches.length > 0) {
            var branch_list = data.branches.map(item => {
                return { id: item, label: item }
            })
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            branch_list: branch_list && branch_list.length > 0 ? branch_list : [],
            action_card: !new_state.action_card
        }));

    }

    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            errors: {
                ...new_state.errors,
                branch_name: error
            }

        }));
    }

    return (
        <>
            <Dialog
                open={deploy_action_card}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog action_card_dialog_width"
                style={{ overflow: "visible", height: "100vh", transition: "1s all ease-in-out" }}
                aria-describedby="alert-dialog-description"
            >
                {/* close button for closing the dialog */}
                <button
                    className='btn float-cancel-button'
                    onClick={props.closebox}
                    style={{ left: "-54px" }}
                >
                    <span className='ri-close-line'></span>
                </button>

                {/* --------Action card Header you can call it top bar 👍------- */}
                <div className='card-header-new'>
                    <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                        <div className='d-flex align-center'>
                            <span className='font-18 ml-5 d-inline-block' style={{ color: '#fff' }}>
                                Trigger a new Deploy
                            </span>
                        </div>
                    </div>
                </div>
                {
                    // if he has permission to perform this action than this will be shown
                    is_permited ? <>
                        <div style={{ overflowY: "scroll", height: "100%", padding: "70px 16px", alignItems: "start", gap: "20px", width: "100%" }} className='d-flex f-direction-column w-100'>

                            <div className='card-new' style={{ width: "inherit", height: "fit-content", paddingBottom: "0px" }}>
                                {
                                    state.branch_loading ? <div style={{ height: '219px', width: "100%" }}><Loading varient="light" /></div> :
                                        <>

                                            <div className='d-flex-top' style={{ padding: "12px 16px 12px 16px", borderBottom: "1px solid #E6E6E6", height: "48px", width: "100%" }}>
                                                <p className=" font-12 d-flex align-center" style={{ gap: "5px" }}>
                                                    <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                        <span className="chip-sq dev-chip" style={{ textTransform: "uppercase", background: "#129E5B" }}>
                                                            {top_summary_data ?
                                                                top_summary_data.env_name :
                                                                getEnvNamefromId().env_type ? getEnvNamefromId().env_type : null}
                                                        </span>
                                                        <span className='ri-arrow-right-s-line ' style={{ fontSize: "20px" }}></span>
                                                    </div>
                                                    <span className='d-flex align-center' style={{ verticalAlign: 'middle', gap: "5px", color: "#787878" }}>Sub Env: <span className='text-grey-new d-flex align-center'>
                                                        {top_summary_data ?
                                                            top_summary_data.env_name :
                                                            getEnvNamefromId().env_name ? getEnvNamefromId().env_name : null}</span>
                                                        <Tooltip title="Please note You are deploying image in Env." placement="top">
                                                            <span className='ri-information-line' style={{ fontSize: "17px" }}></span>
                                                        </Tooltip>
                                                    </span>
                                                </p>

                                            </div>

                                            <BpAgentStatusActionAlert onTrigger={() => TriggerDeployByAlert()} open={state.show_bp_agent_warning} loading={state.alert_loading} type='Deploy Agent' onClose={onClose} />
                                            {state.tag_loaded_status ?

                                                canary_flag && canary_flag.value == "true" && check_if_template_selected ?
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <Input
                                                            type={"radio"}
                                                            label="Deploy Strategy: "
                                                            name="deployment_type"
                                                            list={strategy_list}
                                                            data={state.data}
                                                            error={state.errors}
                                                            onChangeHandler={onChangeHandlerRadio}
                                                        />
                                                    </div>
                                                    : null


                                                :
                                                null}

                                            <div className='card-body-new'>
                                                <>
                                                    {configuration_data && configuration_data.env_cd_detail && configuration_data.env_cd_detail[0] ?
                                                        <>
                                                            {
                                                                state.tag_loaded_status ?
                                                                    <>
                                                                        {/* <div className='d-flex' style={{ width: state.data.deployment_type == 2 ? "36%" : "49%" }}>
                                                                            <Input
                                                                                type={"radio"}
                                                                                label="Deploy Strategy: "
                                                                                name="deployment_type"
                                                                                list={strategy_list}
                                                                                data={state.data}
                                                                                error={state.errors}
                                                                                onChangeHandler={onChangeHandlerRadio}
                                                                            />
                                                                        </div> */}
                                                                        <div className={state.open ? 'd-flex' : "auto-complete-dropdown auto-complete-dropdown-43"} style={{ width: state.data.deployment_type == 2 ? "36%" : "49%" }}>
                                                                            <Input
                                                                                style={{ width: "100%" }}
                                                                                type={state.open ? "text" : "auto-complete-dropdown"}
                                                                                label="Tag"
                                                                                height={"40px"}
                                                                                gap={"0px"}
                                                                                id={"deploy_tag_ds"}
                                                                                name="deploy_tag"
                                                                                placeholder={"Enter value"}
                                                                                getOptionLabel={(option) => option.label}
                                                                                list={state.tag_list}
                                                                                data={{ deploy_tag: state.deploy_tag }}
                                                                                error={{ deploy_tag: state.errors.deploy_tag }}
                                                                                onChangeHandler={state.open ? onChangeHandler : onKeyValueChangeHandler}
                                                                                extraDiv={<button className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0" onClick={toggleState}>{state.open ? "Choose Tag" : "Provide Custom Tag"}</button>}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            (all_available_deployments && all_available_deployments.length > 1) ?
                                                                                <div className='d-block' style={{ width: state.data.deployment_type == 2 ? "36%" : "49%" }}>
                                                                                    <SearchDropdownComponent
                                                                                        list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                                        name={"deployment_name"}
                                                                                        height={"41px"}
                                                                                        gap={"4px !important"}
                                                                                        label="Available Deployments"
                                                                                        uniqueId='available_deployments2112'
                                                                                        mandatorySign
                                                                                        changeParentStateFun={setState}
                                                                                        onSearch={() => { }}
                                                                                        onUpdate={onChangeHandlerForKeyValueUpdated}
                                                                                        data={state.data}
                                                                                        error={state.errors}
                                                                                    />
                                                                                    {/* <Input
                                                                                            type="checkbox"
                                                                                            name="deployment_name"
                                                                                            label="Available Deployments"
                                                                                            mandatorySign
                                                                                            data={{ deployment_name: state.deployment_name }}
                                                                                            error={{ deployment_name: state.errors.deployment_name }}
                                                                                            list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                                            onChangeHandler={onChangeHandlerForKeyValue}
                                                                                        /> */}
                                                                                    {/* <SearchDropdownComponent
                                                                                        list={state.event_list ? state.event_list : []}
                                                                                        name="select_events"
                                                                                        label="Select Events"
                                                                                        uniqueId='select_events_0012'
                                                                                        mandatorySign
                                                                                        changeParentStateFun={setState}
                                                                                        onSearch={() => { }}
                                                                                        onUpdate={() => { }}
                                                                                        data={state.data}
                                                                                        error={state.error}
                                                                                    /> */}

                                                                                </div>
                                                                                : null
                                                                        }

                                                                        {checkForFeatureTag()}
                                                                        {
                                                                            cd_data && cd_data.length > 0 && cd_data[0].manifest_meta_data && cd_data[0].manifest_meta_data.manifest_git_repo_id || cd_data && cd_data.length > 0 && cd_data[0].manifest_meta_data && cd_data[0].manifest_meta_data.helm_git_repo_id ?
                                                                                <div className='section-input d-block' style={{ width: "49%" }}>
                                                                                    <Input
                                                                                        style={{ height: "41px", width: "100%" }}
                                                                                        type={state.openBranch ? "text" : "auto-complete-dropdown"}
                                                                                        name="branch_name"
                                                                                        placeholder="Ex. Master"
                                                                                        subHeading="(Optional)"
                                                                                        getOptionLabel={(option) => option.label}
                                                                                        list={state.branch_list && state.branch_list.length > 0 ? state.branch_list : []}
                                                                                        label="Branch Name (Optional)"
                                                                                        data={{ branch_name: state.branch_name }}
                                                                                        error={{ branch_name: state.errors.branch_name }}
                                                                                        onChangeHandler={state.openBranch ? onBranchChangeHandler : onParentKeyValueChangeHandler}
                                                                                        extraDiv={<p className="mr-0 cursor-pointer text-anchor-blue pr-0" onClick={toggleBranchState}>{state.openBranch ? "Choose Branch" : "Provide Custom Branch"}</p>}
                                                                                        paramsHeading={<span className='' style={{ color: '#124D9B', fontWeight: '500', marginLeft: "3px" }}>Manifest files will be picked from this branch</span>}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            state.data.deployment_type == "2" &&
                                                                            <div className=''>
                                                                                <Input
                                                                                    style={{ width: "100%", height: "41px" }}
                                                                                    type="text"
                                                                                    name="pod_shift"
                                                                                    mandatorySign
                                                                                    placeholder="20%"
                                                                                    label="Pod Shift Percentage"
                                                                                    onChangeHandler={onChangeHandlerNumber}
                                                                                    data={{ deploy_tag: state.pod_shift }}
                                                                                    error={{ deploy_tag: state.errors.pod_shift }}
                                                                                    extraDiv={<>
                                                                                        <Tooltip TransitionComponent={Zoom} title="The percentage of pods which will be shifted to new image tag specified in Deployment. Changes will be done in both Deployment and HPA manifests. Changes in  if there are 10 pods in the baseline (current deployment) and 20% shift is required, then 2 pods will be deployed with new image tag and baseline deployment will be done with 8 pods" placement="right">
                                                                                            <span className='ri-information-line font-18 cursor-pointer'></span>
                                                                                        </Tooltip></>}
                                                                                />
                                                                            </div>
                                                                        }

                                                                    </>
                                                                    :
                                                                    <>
                                                                        {state.tag_load_error ?
                                                                            <>
                                                                                {
                                                                                    state.visible ?
                                                                                        <div className="alert alert-dismissible alert-danger d-flex align-center space-between" style={{ width: "56%", position: "absolute", zIndex: "999", top: "71px", right: "25px" }}>
                                                                                            <p>
                                                                                                Unable to fetch tags. Following could be the reason: {state.tag_load_error && state.tag_load_error.tags ? state.tag_load_error.tags.map(item => (
                                                                                                    <span>{item} : {state.tag_load_error && state.tag_load_error.error} </span>)) : <></>}
                                                                                            </p>
                                                                                            <IconButton onClick={handleToggle}>
                                                                                                <CloseIcon style={{ color: "#721c24" }} />
                                                                                            </IconButton>
                                                                                        </div> :
                                                                                        null
                                                                                }


                                                                                {/* there are two input field by defauld one will be shown */}

                                                                                <div div className='d-block' style={{ width: check_if_template_selected ? "36%" : "49%" }}>
                                                                                    <Input
                                                                                        style={{ width: "100%", height: "41px" }}
                                                                                        type={"text"}
                                                                                        label="Tag"
                                                                                        name="deploy_tag"
                                                                                        placeholder={"Enter value"}
                                                                                        list={state.tag_list}
                                                                                        data={{ deploy_tag: state.deploy_tag }}
                                                                                        error={{ deploy_tag: state.errors.deploy_tag }}
                                                                                        onChangeHandler={onChangeHandler}
                                                                                    />

                                                                                </div>
                                                                                {
                                                                                    all_available_deployments && all_available_deployments.length > 1 ?
                                                                                        <div style={{ width: check_if_template_selected ? "36%" : "49%" }}>
                                                                                            {/* <Input
                                                                                                    type="checkbox"
                                                                                                    name="deployment_name"
                                                                                                    label="Available Deployments"
                                                                                                    mandatorySign
                                                                                                    varient="inner_component"
                                                                                                    data={{ deployment_name: state.deployment_name }}
                                                                                                    error={{ deployment_name: state.errors.deployment_name }}
                                                                                                    list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                                                    onChangeHandler={onChangeHandlerForKeyValue}
                                                                                                /> */}
                                                                                            <SearchDropdownComponent
                                                                                                list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                                                name={"deployment_name"}
                                                                                                height={"41px"}
                                                                                                label="Available Deployments"
                                                                                                uniqueId='available_deployments'
                                                                                                mandatorySign
                                                                                                changeParentStateFun={setState}
                                                                                                onSearch={() => { }}
                                                                                                onUpdate={onChangeHandlerForKeyValueUpdated}
                                                                                                data={state.data}
                                                                                                error={state.errors}
                                                                                            />
                                                                                        </div>
                                                                                        : null}
                                                                                {
                                                                                    flag_for_feature_tag ?
                                                                                        checkForFeatureTag() : null
                                                                                }

                                                                            </>
                                                                            :
                                                                            <div style={{ height: '219px', width: "100%" }}> <LoadingText /></div>

                                                                        }
                                                                    </>
                                                            }


                                                        </>
                                                        : <p className="font-12 text-center" style={{ width: "100%", color: "crimson", marginTop: "35px" }}>Deploy Details not configured</p>
                                                    }

                                                </>

                                            </div>
                                            {configuration_data && configuration_data.env_cd_detail && configuration_data.env_cd_detail[0] ?

                                                <div className="d-flex align-center" style={{ gap: "10px", justifyContent: "space-between", width: "100%", padding: "0px 16px", paddingBottom: "10px", backgroundColor: "#fff" }}
                                                >
                                                    <div></div>
                                                    <div className="section-2 d-flex align-center" style={{ gap: "10px" }}>
                                                        <button className="btn-cancel-blue" style={{ padding: '1rem', width: "84px", height: "40px" }} onClick={props.closebox} >Cancel</button>

                                                        {/* trigger Deploy Btn */}
                                                        {state.alert_loading ?
                                                            <GenericSkeleton width={168} height={39} style={{ borderRadius: '6px' }} rootStyle={{ flex: 0 }} />
                                                            :

                                                            deploy_env_offline_flag && deploy_env_offline_flag.value && deploy_env_offline_flag.value === "true" ?
                                                                (top_summary_data && top_summary_data.is_env_down) || (is_env_down) ?
                                                                    <button className="trigger-deploy-btn d-flex align-center" onClick={handleClickOpenPopover} >
                                                                        <span className='ri-play-circle-line' style={{ fontSize: "20px", fontWeight: "500", }}></span>
                                                                        Trigger Deploy &nbsp; </button>
                                                                    :
                                                                    <button className="trigger-deploy-btn d-flex align-center" onClick={validateAndTrigger} >
                                                                        <span className='ri-play-circle-line' style={{ fontSize: "20px", fontWeight: "500", }}></span>
                                                                        Trigger Deploy &nbsp; </button> :
                                                                <button className="trigger-deploy-btn d-flex align-center" onClick={validateAndTrigger} >
                                                                    <span className='ri-play-circle-line' style={{ fontSize: "20px", fontWeight: "500", }}></span>
                                                                    Trigger Deploy &nbsp; </button>

                                                        }
                                                    </div>
                                                </div> : null

                                            }
                                        </>
                                }
                            </div>

                            {/*This is the particular card thats holds processing data or processed data or you can call it Body */}
                            <div style={{ background: "#F4F4F4", width: "100%" }}>
                                {/* {state.branch_loading ? <div style={{ height: '195.5px' }}><Loading varient="light" /></div> : */}
                                <LatestDeployStripNew
                                    top_summary_data={top_summary_data}
                                    type={type}
                                    is_env_down={top_summary_data && top_summary_data.is_env_down ? top_summary_data.is_env_down : is_env_down ? is_env_down : null}
                                    recent_history={configuration_data}
                                    deployment_list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                    service_id={service_id}
                                    service_data={service_data}
                                    build_status={state.build_status}
                                    refresh={refreshGrid}
                                    closebox={props.closebox}
                                    refresh_count={state.refresh_count}
                                    env_id={env_id}
                                    env_name={top_summary_data?.env_name}
                                    masterLabel={masterLabel}
                                    env_details={env_details}
                                    service_env_name={service_env_name}
                                />
                                {/* } */}
                            </div>
                        </div>
                    </>
                        :

                        // {if you have not permission to perform this action than this will be shown}

                        <div style={{ margin: '15px', height: "100%", width: "100%", justifyContent: "center !important", gap: "10px", fontSize: "20px" }} className='d-flex align-center justify-center' >
                            <h3 className="popup-card-main-heading pd-10" style={{ fontSize: "17px", color: "crimson" }}>
                                You are not allowed to perform this action, Contact Project Admin
                            </h3>
                            <div>
                                <IconButton onClick={props.closebox} >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                }
                <div className='build-card-bottom'>
                    <div>
                        <button onClick={props.closebox}>Close</button>
                    </div>
                </div>


                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={state.trigger === "success" || state.trigger === "failed"}
                    onClose={handleClose}
                    className="z-index-top"
                    autoHideDuration={3000}>

                    {
                        state.trigger === "success" ?
                            <>
                                <Alert severity="success" >
                                    {"SUCCESSFULLY SCHEDULED " + type}
                                </Alert>
                            </> : state.trigger == "failed" ?
                                <Alert severity="error" >
                                    {type + " SCHEDULE FAILED"}
                                    <div className="error-div">
                                        {state.error_msg ? state.error_msg : null}
                                    </div>
                                </Alert>

                                : null
                    }
                </Snackbar>

            </Dialog >




            <Popover
                id={id}
                open={OpenPopover}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover-content" style={{ padding: '20px', width: '400px', }}>

                    <div className='' style={{ width: '100%', position: 'relative' }}>
                        <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
                        <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-10 text-center' />
                        <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                        <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger the deployment as the envrionment is offline now.
                            Please contact your team administrator to get the environment online.</p>
                    </div>

                </div>
            </Popover>
        </>

    )
}
export default DeployActionCardNew;
