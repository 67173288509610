import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormatTime } from '../../../../util/util';
import Box from '@material-ui/core/Box';

import FillApprovalQuestions from './FillApprovalQuestions';
  

  const useStyles = makeStyles({
    stageStatus:{
      height:'30px',
      width:'30px',
      borderRadius:'50%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    failedStage: {
     backgroundColor:'#ff8969',
     '& .flaticon-round-info-button:before':{
       color:'#fff',
     },
     '& .flaticon-thumb-up-button':{
      color:'#fff!important',
      '&:before':{
        color: '#fff!important',
        fontSize:'16px!important'
      },
      '&:hover':{
        cursor:'pointer'
      }
     },

    },
    pendingResume:{
      backgroundColor:'#fff',
      '& .flaticon-play-rounded-button':{
        color:'#52bfe7',
        '&:before':{
          fontSize:'20px!important'
        }
      }
    }
  });

const StageCard = props =>{
    const classes = useStyles();
    const count = props.count;
    const stage = props.stage;
    console.log("checking for instance",stage)
    const show_approval_form = props.show_approval_form;
    const postFinalData = props.postFinalData;
    const show_resume_form = props.show_resume_form;
    var status = null;

    function getStageStatus(){
      const stage_instance = stage.instance;
      if(!stage_instance){
        return "";
      }
      const start_time = new Date(stage_instance.created_at);
      const end_time = new Date(stage_instance.updated_at);
      const difference = end_time - start_time;
      const time_taken = FormatTime(difference);
      status = stage_instance.status;
      const data={completed:0,running:0,failed:0};
      stage_instance.task_instance.forEach(task=>{
        if(task.status == "SUCCESS"){
          data.completed=data.completed+1;
        }else if(task.status == "FAILED"){
          data.failed = data.failed+1;
        }else if(task.status == "RUNNING"){
          data.running = data.running+1;
        }
      });
      const temp = data.running?"from":"in";
      var text = data.completed + " completed, "+data.running+" running, "+data.failed
      +" failed out of "+stage.tasks.length + " jobs "+temp+" "+time_taken ; 
      return text;
    }

    function getProgressStatusIcon(){
      if(!stage.instance){
        return(
          <Count> {count} </Count>
        );
      }else{
        switch(stage.instance.status){
          case "PENDING_APPROVAL":
            return(
              <Count> {count} </Count>
            );
          case "SUCCESS":
            return(
              <ProgressCircle variant="determinate" count={count} success={true}/>
            );
          case "FAILED":
            return(
              <ProgressCircle variant="determinate" count={count} failed={true}/>
            );
          case "IN_QUEUE":
            return(
              <ProgressCircle  variant="indeterminate"  count={count}/>
            );
          case "RUNNING":
            return(
              <ProgressCircle  variant="indeterminate"  count={count}/>
            );
          case "SKIPPED":
            return(
              <ProgressCircle variant="determinate" count={count} skipped={true} />
            );
          case "REVOKED":
            return(
                <ProgressCircle variant="determinate" count={count} revoked={true} />
            );
          case "DISAPPROVED":
            return(
              <ProgressCircle variant="determinate" count={count} failed={true} disapproved={true} />
            );
          case "SUSPENDED":
            return(
              <ProgressCircle variant="determinate" count={count} suspended={true} />
            );
          case "STOPPED":
            return(
              <ProgressCircle variant="determinate" count={count} failed={true}/>
              );
          case "PENDING_RESUME":
            return(
              <Count> {count} </Count>
            );
        }
      }
    }
  
    return(
        <Card>
            <span className="border-stage-card"></span>
            <span className="border-vertical-stage-card"></span>
            <div style={{alignItems:'center', display:'flex'}}>
                <div className={classes.root}>
                    {getProgressStatusIcon()}
                </div>
                <StageName><div className="d-flex"><span className="stage-name-execution"> {stage.name} </span> </div>
                
                <JobRunning>{stage.instance && stage.instance.status == "SKIPPED" ? "this stage is skipped" : stage.instance && stage.instance.status == "DISAPPROVED"? "Pipeline has been disapproved" : stage.instance && stage.instance.status == "SUSPENDED"? "Pipeline has been suspended" :stage.instance && stage.instance.status == "STOPPED"? "Pipeline has been stopped" : getStageStatus()}</JobRunning>
                </StageName>
                {console.log("lpfdslpfds",status)}
                {
                  status === "PENDING_APPROVAL" ? 
                 
                    <FillApprovalQuestions
                    stage_instance_id = {stage.instance&& stage.instance.id ?stage.instance.id:"" }
                    pipeline_id = {props.pipeline_id ? props.pipeline_id : ""}
                    pipeline_instance_id = {props.pipeline_instance_id ? props.pipeline_instance_id : ""}
                    postFinalData={postFinalData}
                    stage_name={stage.name}
                    stage_instance_status={stage.instance && stage.instance.status}
                    questionnaires={stage.questionnaires? stage.questionnaires:[]}
                    />:
                  stage.approval ?
                   stage.instance && stage.instance.id ?
                  <FillApprovalQuestions
                  stage_instance_id = {stage.instance && stage.instance.id ? stage.instance.id :" "}
                  variant="only-view"
                  stage_instance_status={stage.instance && stage.instance.status}
                  pipeline_id = {props.pipeline_id ? props.pipeline_id : ""}
                  pipeline_instance_id = {props.pipeline_instance_id ? props.pipeline_instance_id : ""}
                  postFinalData={postFinalData}
                  questionnaires={[]}
                  />
                 :  null :null
                }
                {
                  status == "PENDING_RESUME" ? 
                  <div className={classes.stageStatus + " " + classes.pendingResume}>
                    <div onKeyDown={()=>{}} onClick={()=>show_resume_form(stage.instance&&stage.instance.id)} className="flaticon-play-rounded-button" role='button' tabIndex={0}></div>
                  </div>:null 
                }
            </div>
            
        </Card>
    )
}


StageCard.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default StageCard;


// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#e3e6e8',
  },
  top: {
    color: '#ffd62a',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  success:{
    color: '#61e187',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  revoked:{
    color: '#c294f1',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  stopped:{
    color: '#ff8969',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  failed:{
    color: '#e9797e',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  skipped:{
    color: '#52bfe7',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  pending:{
    color: '#ffd62a',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
   suspended:{
    color: 'rgb(142, 142, 142)',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  }
}));

const Card = styled('div')({
  backgroundColor: '#4652a2',
  borderRadius:'32px',
  marginLeft:'6rem',
  width:'324px',
  minHeight:'65px',
  display:'flex',
  marginRight:'6rem',
  position:'relative',
  padding:'1rem',
  '& .border-vertical-stage-card':{
    backgroundColor: '#666',
  height:' calc(100% + 1rem)',
  width: '3px',
  position: 'absolute',
  left: '-28px',
  top:' 33px',
  },
  '&:last-child .border-vertical-stage-card':{
   display:'none'
  },
  '&:last-child .border-stage-card':{
    display:'none'
   },
  '& div:first-child':{
      
      alignItems:'center'
  },
  '& span.border-stage-card':{
      height: '3px',
      backgroundColor: '#666',
      width: '30px',
      position: 'absolute',
      left:'-28px',
      top:'30px'
  },
})
const Count = styled('div')({
  fontSize:'12px',
  height: '24px',
  width:'24px',
  fontWeight:900,
  borderRadius:'50%',
  border:'3px solid #fff',
  color:'#fff',
  display:'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const StageName = styled ('div')({
  fontSize:'12px',
  color:'#fff',
  width:'220px',
  lineHeight:'18px',
  margin:'0px 10px',
  '& span.stage-name-execution':{
      display:'inline-block',
      width:'150px',
      textOverflow:'ellipsis',
      overflow:'hidden',
      whiteSpace:'nowrap'
  }
})
const JobRunning = styled('div')({
      fontSize:'11px',
      lineHeight:'1.3',
      color:'#a4abd6',
})

function ProgressCircle(props) {
  const classes = useStylesFacebook();
  const variant = props.variant;
  const success = props.success;
  const failed = props.failed;
  const count = props.count;
  const skipped = props.skipped;
  const disapproved = props.disapproved;
  const suspended = props.suspended;
  const stopped = props.stopped;
  const revoked = props.revoked;
  return (
    <div className={classes.root}>
      
      <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={24}
        key="2"
        thickness={6}
        {...props}
        value={100}
      />
      <CircularProgress
        variant={variant}
        key="1"
        className={success ? classes.success : skipped ? classes.skipped : failed ? classes.failed : suspended? classes.suspended: stopped ? classes.stopped : revoked? classes.revoked:  classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={24}
        value={success || skipped || disapproved || suspended || stopped ? 100 : 50} 
        thickness={6}
        {...props}
      />
      
      <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <p style={{fontSize:'12px', fontWeight:'bold', color:'#fff'}} > {count} </p>
    </Box>
      </Box>
      
    </div>
  );
}

ProgressCircle.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};
